import { createPluginFactory } from "@udecode/plate";

import { CommentLeaf } from "./components";
import { MARK_COMMENT } from "./constants";
import { withComments } from "./normalizers";

export const createCommentsPlugin = createPluginFactory({
  key: MARK_COMMENT,
  isLeaf: true,
  component: CommentLeaf,
  withOverrides: withComments,
});
