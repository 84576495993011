import { makeAutoObservable } from "mobx";
import { AtticusClient } from "../api/atticus.api";

export class AuthStore {
	user : IAuthStore.ProfileProps | null = null;
	token: string | null = null;
	newBookToken: string | null = null;
	abilitiesPublicKey: string | null = null;

	constructor(){
		makeAutoObservable(this);
	}

	setToken = (token: string | null): void => {
		this.token = token;
	}

	setNewBookToken = (newBookToken: string | null): void => {
		this.newBookToken = newBookToken;
	}

	setAbilitiesPublicKey = (abilitiesPublicKey: string | null): void => {
		this.abilitiesPublicKey = abilitiesPublicKey;
	}

	setUserProfile = (details: IAuthStore.ProfileProps | null) => {
		this.user = details;
	}

	updateToken = async (token: string | null): Promise<void> => {
		if (token) await localStorage.setItem("atticus-auth-token", token);
		else await localStorage.removeItem("atticus-auth-token");

		this.setToken(token);
	}

	updateNewBookToken = async (newBookToken: string | null): Promise<void> => {
		if (newBookToken) await localStorage.setItem("atticus-new-book-token", newBookToken);
		else await localStorage.removeItem("atticus-new-book-token");

		this.setNewBookToken(newBookToken);
	}

	updateAbilitiesPublicKey = async (abilitiesPublicKey: string | null): Promise<void> => {
		if (abilitiesPublicKey) await localStorage.setItem("atticus-abilities-public-key", abilitiesPublicKey);
		else await localStorage.removeItem("atticus-abilities-public-key");

		this.setAbilitiesPublicKey(abilitiesPublicKey);
	}


	getUserProfile = async () => await AtticusClient.GetProfile();
	
	loadUserProfile = async () => {
		const user = await this.getUserProfile();
		if(user){	
			this.setUserProfile(user);
		}
	}

	getCurrentUser = () => {
		return this.user;
	}

	resetAuthentication = async () => {
		this.setUserProfile(null);
		this.updateToken(null);
		this.updateNewBookToken(null);
		this.updateAbilitiesPublicKey(null);
	}
}

export default new AuthStore();