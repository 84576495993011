import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import mitt from "mitt";

import useRootStore from "../../store/useRootStore";

import { useOnlineStatus } from "../../utils/hooks/isOffline";

// sync
// import { syncData, SaveTemplateToDB } from "../../../utils/sync";
import { syncData } from "../../utils/sync";

import Header from "../header";
import PDFExporter from "../../components/Shared/PDFExporter/PDFExporter";
import { FontLoader } from "./";

import useWebsocket from "../../utils/hooks/useWebsocket";
import { db } from "../../db/bookDb";
import {
  getCollaboratedBooksFromRemoteDB,
  saveBookToLocalDB,
  syncRemoteBookWithLocalDB,
} from "../../utils/sync/helper";
import { DeleteBooksFromDB, deleteChapterTemplateFromDB, GetBookFromDB } from "../../utils/offline.book.helpers";
import {
  BOOKSHELF_BOOK_ADDED,
  BOOKSHELF_BOOK_REMOVED,
  BOOKSHELF_DETAIL_UPDATE,
  BOOKSHELF_MASTERPAGE_ADDED,
  BOOKSHELF_MASTERPAGE_REMOVED,
} from "../../utils/bookshelf-ws-helper";
import { syncInitialChapters } from "../../utils/sync/bookSync";
import { ShelfMasterPageWSMessageData, ShelfWSMessageData } from "../../types/common";


const WithAuthComponent: React.FC = observer(({ children }) => {
    const { user, loadUserProfile, getUserProfile } = useRootStore().authStore;
    const { initialBooksLoaded, initialChaptersLoaded, setEventEmitter, getEventEmitter } = useRootStore().appStore;
    const { loadBooks, setBooks } = useRootStore().shelfStore;
    const { resetCurrentBook } = useRootStore().bookStore;
    const { loadThemes, saveDevicePreview, getAllDevices } = useRootStore().themeStore;
    const { getSMProfilesFromDB } = useRootStore().socialProfileStore;
    const { loadTemplates } = useRootStore().chapterStore;
    const { loadAllCollaborationDetails, setCollaberatedBooks, setCollaboratedBooksCollaborations, getCollaboratedBooks } = useRootStore().collaborationStore;
    const { setSocket, socket } = useRootStore().bookSyncWebSocketStore;

    const isOnline = useOnlineStatus();
    const syncInterval = useRef<NodeJS.Timeout | null>(null);

    const sync = async () => {
        if (isOnline) {
            await syncData();            
            await loadAllCollaborationDetails();
          }

        // load user's social media profiles in the app state
        getSMProfilesFromDB();

        // Load Account Details
        loadUserProfile();

        // Mount Books mounting getting from indexeddb
        loadBooks();
        resetCurrentBook();

        // Mount themes by getting from indexeddb
        loadThemes();

        // Load Templates
        loadTemplates();
        
        //TODO:BODY
        // SaveTemplateToDB();
        saveDevicePreview(); // saved the book previews at the start
        getAllDevices();
    };

    useEffect(() => {
        if (isOnline) {
            sync();
        }
    }, [isOnline]);

    useEffect(() => {
      if(isOnline) {
        if(initialBooksLoaded && !initialChaptersLoaded) {
          syncInitialChapters();
        }
      }
    }, [initialBooksLoaded]);

    useEffect(() => {
      if (isOnline && initialChaptersLoaded) {
        syncData();    
    }
    }, [initialChaptersLoaded]);
    

    const webSocketUrl = process.env.REACT_APP_SYNC_SERVER || "";
    const socketConnection = useWebsocket(
      webSocketUrl ? `${webSocketUrl}` : ""
    );
    setSocket(socketConnection);

    const handleShelfBookAdded = async (data: ShelfWSMessageData) => {
      const { bookId, isCollabBook } = data;
      if (isCollabBook) {
        const { books: collaborated_books, collaborations } =
          await getCollaboratedBooksFromRemoteDB();
        const filteredBook = collaborated_books.find(
          (book) => book._id === bookId
        );
        if (filteredBook) {
          const newCollabBook = {
            ...filteredBook,
            collaborated: true,
          };

          await saveBookToLocalDB(newCollabBook);
          setCollaberatedBooks(collaborated_books);
          setCollaboratedBooksCollaborations(collaborations);
        }
      } else {
        if (bookId)
          await syncRemoteBookWithLocalDB(bookId).then(async () => {
            const booksFromDb = await db.books.toArray();
            setBooks(booksFromDb);
          });
      }
    };

    const handleShelfBookRemoved = async (data: ShelfWSMessageData) => {
      const { bookId, isCollabBook } = data;
      if (bookId)
        await DeleteBooksFromDB([bookId]).then(async () => {
          const books = await db.books.toArray();
          if (isCollabBook) {
            const collaborated_books = books.filter(
              (book) => book.collaborated
            );
            setCollaberatedBooks(collaborated_books);
          } else {
            const filteredBooks = books.filter((book) => !book.collaborated);
            setBooks(filteredBooks);
          }
        });
    };
    
    const handleShelfBookDetailsUpdate = async (data: ShelfWSMessageData) => {
      const { bookId } = data;

      if (!bookId) return;

      const booksFromDb = await db.books.toArray();
      const book = booksFromDb.find((book) => book._id === bookId);

      if (!book) return;

      const isCollabBook = book.collaborated;

      await syncRemoteBookWithLocalDB(bookId, isCollabBook).then(async () => {
        if (isCollabBook) {
          //getUpdated book
          const shelfBook = await GetBookFromDB(bookId);
          const collaborated_books = await getCollaboratedBooks();

          const updatedCollaboratedBooks = collaborated_books.map((book) =>
            book._id === bookId ? { ...book, ...shelfBook } : book
          );

          setCollaberatedBooks(updatedCollaboratedBooks);
        } else {
          const updatedBooks = await db.books.toArray();
          setBooks(updatedBooks);
        }
      });
    };
  

    const handleShelfMasterPageAdded = async () => {
      loadTemplates();
    };

    const handleShelfMasterPageRemoved = async (
      data: ShelfMasterPageWSMessageData
    ) => {
      const { templateId } = data;
      await deleteChapterTemplateFromDB(templateId).then(() => loadTemplates());
    };

    const handleRecivedUpdate = async (event) => {
      const message = JSON.parse(event.data);
      const { userId } = message.data;
      const userProfile = user || (await getUserProfile());
      if (userProfile?._id === userId) {
        switch (message.type) {
          case BOOKSHELF_BOOK_ADDED:
            await handleShelfBookAdded(message.data);
            break;
          case BOOKSHELF_BOOK_REMOVED:
            await handleShelfBookRemoved(message.data);
            break;
          case BOOKSHELF_MASTERPAGE_ADDED:
            await handleShelfMasterPageAdded();
            break;
          case BOOKSHELF_MASTERPAGE_REMOVED:
            await handleShelfMasterPageRemoved(message.data);
            break;
        }
      }

      if(message.type === BOOKSHELF_DETAIL_UPDATE){
        await handleShelfBookDetailsUpdate(message.data); 
      }
    };

    useEffect(() => {
      if (socket && socket.readyState === WebSocket.OPEN) {
        socket.addEventListener("message", handleRecivedUpdate);
      }
      // Clean up the event listener when the component unmounts
      return () => {
        if (socket) {
          socket.removeEventListener("message", handleRecivedUpdate);
        }
      };
    }, [socket]);

    useEffect(() => {
      // close the socket.
      return () => {
        if (socket) {
          socket.close();
        }
      };
    }, []);


    /** Periodical sync useEffect */
    useEffect(() => {
      const startPeriodicSync = () => {
        if (syncInterval.current) {
          clearInterval(syncInterval.current);
        }
        syncInterval.current = setInterval(async () => {
          if (isOnline) {
            await syncData();
            await loadAllCollaborationDetails();
          }
        }, 6 * 60 * 60 * 1000); // 6 hours in milliseconds
      };

      startPeriodicSync();

      return () => {
        if (syncInterval.current) {
          clearInterval(syncInterval.current);
        }
      };

    }, [isOnline]);

    useEffect(() => {
      const appEventEmitter = mitt<IAppEvents.AppEvents>();
      setEventEmitter(appEventEmitter);
      return () => {
          getEventEmitter()?.all.clear();
      };
    }, [getEventEmitter, setEventEmitter]);

    return (
        <div>
            <FontLoader />
            <Header />
            <div className="main">
                {children}
            </div>
            <PDFExporter />
        </div>
    );
});

const LayoutWithAuth : React.FC = (props) => {
  const { token } = useRootStore().authStore;

  useEffect(() => {
    const handleInvalidToken = e => {
      // check if auth token does not have value
      if (e.key === "atticus-auth-token" && !e.newValue) {
        // reload page to redirect
        window.location.reload();
      }
    };

    // apply listener for storage changes
    window.addEventListener("storage", handleInvalidToken);

    return () => {
      // remove local storage change listener
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  return token ? <WithAuthComponent {...props} /> : <Redirect to="/auth/sign-out"/>;
};

export const WithAuth = observer(LayoutWithAuth);

