import React from "react";
import { observer } from "mobx-react";

import Previewer from "../../components/Previewer/Previewer";
import ThemeWrapper from "../../components/Theme/ThemeWrapper";
import { Wrapper, LHS, Content, RHS } from "../../components/Editor/partials";
import { can } from "../../components/casl/helpers";

export const Formatting: React.FC = () => {
  return (
    can("view", "formatting-section") ? (
      <Wrapper>
        <Content>
          <ThemeWrapper />
        </Content>
        <RHS>
          <Previewer />
        </RHS>
      </Wrapper>
    ) : (
      <></>
    )
  );
};

