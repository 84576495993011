import { useCallback } from "react";
import { find, findIndex } from "lodash";
import { BeforeCapture, DropResult } from "react-beautiful-dnd";

import useRootStore from "../../store/useRootStore";
import { decodeDroppableId } from "../scene/dnd";
import { ChapterMeta, ChapterType } from "../../types/chapter";
import { SideBarItem } from "../../types/sidebar";
import { ExpandedBook } from "../../types/book";
import {
  addNumbering,
  addPlaceholders,
  collapseGroups,
  getAllChildrenIds,
  getBookMetaSummaryForCache,
  mapChaptersToItems,
  removeNonExistingParentIds,
  reorderChapters,
} from "../sidebar";
import { getLastDescendentIndex } from "../chapter";
import { updateVolumeChapterMeta } from "../volumes";

type UseSideBarActionsHookResult = {
  // event handlers for the DND
  onDragEnd: (result: DropResult) => Promise<void>;
  onBeforeCapture: (event: BeforeCapture) => void;
  // items array to be rendered on sidebar
  sideBarItems: SideBarItem[];
  refreshSidebar: (book: ExpandedBook) => void;
  // action handlers for the different items
  handleDeleteScene: (sceneId: number, sceneIndex: number, chapterId: string, deleteContent: boolean) => void;
  handleDeleteChapter: (chapterId: string) => Promise<void>;
  updateChapterMeta: (meta: Partial<ChapterMeta>) => Promise<void>;
};

export function useSideBarActions(): UseSideBarActionsHookResult {
  const {
    book,
    sortChapters,
    saveChapterMetaUpdates,
    deleteChapter,
    debouncedSaveChapterMetaUpdates,
  } = useRootStore().bookStore;
  const { setIsBookLocalUpdate, setChangeEvent, setSyncChapterId} = useRootStore().bookSyncWebSocketStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { resetSelectedChapters, setChapters, expandedChapters, items, setItems, sceneCache } = useRootStore().sideMenuStore;
  const { getEventEmitter } = useRootStore().appStore;
  const { chapterMeta } = useRootStore().chapterStore; 

  /**
   * Fire app event to delete a scene
   * Listeners: 
   *  - src/components/Plate/editor.tsx
   */
  const handleDeleteScene = (sceneId: number, sceneIndex: number, chapterId: string, deleteContent: boolean): void => {
    const emitter = getEventEmitter();
    if(emitter) emitter.emit("delete_scene", { sceneId, sceneIndex, chapterId, deleteContent });
  };

  /**
   * Fire app event to move a scene within a chapter
   * Listeners: 
   *  - src/components/Plate/editor.tsx
   */
  const handleMoveSceneWithinChapter = (chapterId: string, srcSceneIndex: number, destSceneIndex?: number): void => {
    const emitter = getEventEmitter();
    if(emitter) emitter.emit("move_scene", { chapterId, srcSceneIndex, destSceneIndex });
  };

  const handleDeleteChapter = useCallback(
    async (chapterId: string) => {
      const chap = book.chapters.find((chap) => chap._id === chapterId);
      
      setSyncChapterId(chapterId);
      setIsBookLocalUpdate(true);
      chap && chap.type === "image" ? setChangeEvent("full-page-image-chapter-delete") : setChangeEvent("chapter-delete");
      
      await deleteChapter(chapterId, true);

      const { chapterCacheData } = getBookMetaSummaryForCache(book.frontMatter, book.chapters);

      if (chap && chap.type === "image") {
        refreshCache(book._id, "full-page-image-chapter-delete", { "full-page-image-chapter-delete": { chapterId, chapters: chapterCacheData } });
      } else {
        refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId, chapters: chapterCacheData } });
      }
    },
    [book]
  );

  /**
   * Performs the reordering operations after an item is dropped in to a new location
   * - Removes the item from the current location.
   * - Inserts it in the dropped location
   * - Uses the draggableId of the item preceding to the dropped location to find the index to place
   *
   * @param result DropResult from react-beautiful-dnd
   */
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      // dropped outside
      updateItems({frontMatter: book.frontMatter, chapters: book.chapters});
      return;
    }

    // if source index and destination index are the same
    if (result.source.index == result.destination.index)
      return;

    let frontMatter = book.frontMatter;
    let chapters = book.chapters;

    const metaChangedChapterIds: string[] = [];

    // If the item is dropped before the original index
    const isDroppedBefore = result.source.index > result.destination.index;

    // original item
    const item = items[result.source.index];
    const [itemChapterId, itemSceneIndex] = decodeDroppableId(item.id);

    // item before dropped location
    const before = items[result.destination.index - (isDroppedBefore ? 1 : 0)];
    const [beforeChapterId, beforeSceneIndex] = before ? decodeDroppableId(before.id) : [];

    const droppedAfterBodySeparator = before && before.type === "body-separator" && beforeChapterId === "body_separator";

    // Handle DND of Chapters (Chapters can be volumes or parts too)
    if (item.type === "chapter" && ((before && before.type !== "scene") || !before)) {
      const movedChapter = find([...frontMatter, ...chapters], { _id: itemChapterId });
      const beforeChapter = find([...frontMatter, ...chapters], { _id: beforeChapterId });

      if (!movedChapter) return;

      // STEP 01 : Remove chapter from current place

      // Remove from book chapters / frontMatter arrays
      if (item.section === "frontMatter") {
        frontMatter = frontMatter.filter((chap) => chap._id !== movedChapter._id);
      } else {
        chapters = chapters.filter((chap) => chap._id !== movedChapter._id);

        // also remove from inside the volumes
        if (item.chapter.parentChapterId) {
          const parentChapIndex = findIndex(chapters, { _id: item.chapter.parentChapterId });
          if (parentChapIndex !== -1) {
            const previousVolumeProps = chapters[parentChapIndex].volume;
            if (previousVolumeProps) {
              const children = getAllChildrenIds(chapters, chapters[parentChapIndex]._id);
              const frontMatterIds = previousVolumeProps?.frontMatterIds.filter((r) => r !== movedChapter._id) || [];
              const bodyMatterIds = children.filter((r) => !frontMatterIds.includes(r)).filter((r) => r !== movedChapter._id);

              chapters[parentChapIndex].volume = { ...previousVolumeProps, frontMatterIds, bodyMatterIds };

              metaChangedChapterIds.push(chapters[parentChapIndex]._id);
            }
          }
        }
      }

      /**
       * Inserts a given chapterId into the meta of the volume chapter
       * @returns boolean indicating if the move is valid or not
       */
      const insertChapToVolume = (volumeChapId: string, insertAt: number, chapterIdToInsert: string, chapterType: ChapterType): boolean => {
        const volumeChapterIndex = findIndex(chapters, { _id: volumeChapId, type: "volume" });
        if (volumeChapterIndex === -1) throw new Error("Volume not found");
        if (!chapters[volumeChapterIndex].volume) throw new Error("Volume meta not available");

        const frontMatterIds = chapters[volumeChapterIndex].volume?.frontMatterIds || [];
        const frontMatterCount = frontMatterIds.length;

        // +1 is to make body separator into account
        if (insertAt > frontMatterCount + 1) {
          // insert at body  -1 is to remove the the body separator
          if (chapters[volumeChapterIndex].volume !== undefined) {
            const updatedBodyMatterId = getAllChildrenIds(chapters, chapters[volumeChapterIndex]._id).filter((r) => !frontMatterIds.includes(r));
            chapters[volumeChapterIndex].volume!.bodyMatterIds = updatedBodyMatterId;
          }
        } else {
          // [invalid_move] volumes or parts can't be dragged into frontmatter of a volume
          if (["volume", "part"].includes(chapterType)) return false;
          const insertIndex = insertAt > 0 ? insertAt - 1 : 0;
          chapters[volumeChapterIndex].volume?.frontMatterIds.splice(insertIndex, 0, chapterIdToInsert);
        }

        metaChangedChapterIds.push(chapters[volumeChapterIndex]._id);
        return true;
      };


      // Reset parent id of the item
      // todo: undefined won't get persisted in backend since we can't send undefined in json
      // as a workaround its set to book Id
      movedChapter.parentChapterId = book._id;
      metaChangedChapterIds.push(movedChapter._id);

      // STEP 02 : Place item in new place

      // Criteria 1 - Item before the dropped location has a chapter associated [placeholder / scene / chapter / volume / part] etc.
      if (beforeChapter) {
        // [invalid_move] parent dropped inside itself
        if (beforeChapter.parentChapterId === movedChapter._id) return;

        /*  
          Criteria 1.1 - Before chapter is in frontMatter so we just move the item after that.
          this is because frontmatter can't have any nested structures */
        if (before.section === "frontMatter") {
          // [invalid_move] volumes or parts can't be dragged into frontmatter
          if (["volume", "part"].includes(item.chapter.type)) return;

          const insertAt = beforeChapterId ? frontMatter.findIndex((chap) => chap._id === beforeChapterId) + 1 : 0;
          frontMatter.splice(insertAt, 0, movedChapter);
        }

        //  Criteria 1.2 - Item before is in the body section of the book
        if (before.section === "body") {
          // dropped after another chapter / volume / part
          if (before.type === "chapter") {
            // dropped inside a expanded volume or part as first item
            if (["volume", "part"].includes(beforeChapter.type) && expandedChapters.has(beforeChapter._id)) {
              // [invalid_move] volumes cant't be nested or volume or a part can't be moved inside a part
              if (movedChapter.type === "volume" || (beforeChapter.type === "part" && ["volume", "part"].includes(movedChapter.type))) return;

              // insert first element so insert as first element under respective part or volume
              const insertAt = chapters.findIndex((chap) => chap._id === beforeChapterId) + 1;

              chapters.splice(insertAt, 0, movedChapter);

              // update volume metadata
              if (beforeChapter.type === "volume") {
                // return value (valid) indicates if the move of the chapter to the location is valid or not
                const valid = insertChapToVolume(beforeChapter._id, 0, movedChapter._id, movedChapter.type);
                if (!valid) return;
              }
              movedChapter.parentChapterId = beforeChapter._id;
            } else {
              // get the index of last child the before chapter
              const insertAt = getLastDescendentIndex(beforeChapter._id, chapters) + 1;
              chapters.splice(insertAt, 0, movedChapter);

              // if the item before has a parent id make it the parent id of the moved items
              if (beforeChapter.parentChapterId) {
                // find the parent chapter from the list
                const parentChapterIndex = findIndex(chapters, { _id: beforeChapter.parentChapterId });
                const parentChapter = chapters[parentChapterIndex];

                // [invalid_move] volumes cant't be nested or volume or a part moved inside a part
                if (parentChapter && movedChapter.type === "volume" || (parentChapter?.type === "part" && ["volume", "part"].includes(movedChapter.type))) return;

                // if parent chapter is a volume update it's metadata
                if (parentChapter && parentChapter.type === "volume") {
                  const insertAtInsideVolume = insertAt - parentChapterIndex;
                  // return value (valid) indicates if the move of the chapter to the location is valid or not
                  const valid = insertChapToVolume(beforeChapter.parentChapterId, insertAtInsideVolume, movedChapter._id, movedChapter.type);
                  if (!valid) return;
                }

                movedChapter.parentChapterId = beforeChapter.parentChapterId;
              } else {
                // dropped in root level parentChapIdOfPreviousElement is undefined
                movedChapter.parentChapterId = book._id;
              }
            }
          }

          // dropped after a placeholder
          if (before.type === "placeholder") {

            if (beforeChapter.parentChapterId) {
              // find the parent chapter from the list
              const parentChapterIndex = findIndex(chapters, { _id: beforeChapter.parentChapterId });
              const parentChapter = chapters[parentChapterIndex];

              // [invalid_move] volumes cant't be nested or volume or a part moved inside a part
              if (parentChapter && movedChapter.type === "volume" || (parentChapter?.type === "part" && ["volume", "part"].includes(movedChapter.type))) return;
            }

            // Insert after the last child of before chapter Id
            const insertAt = getLastDescendentIndex(beforeChapter._id, chapters) + 1;
            chapters.splice(insertAt, 0, movedChapter);
            movedChapter.parentChapterId = beforeChapter.parentChapterId;
          }

          // dropped after the body separator inside a volume
          // body separator of the book has frontMatter as section. so this is inside a volume
          if (before.type === "body-separator") {

            // [invalid_move]  volumes cant't be inside volume
            if (movedChapter.type === "volume") return;

            // beforeChapterId is the Volume Chapter Id becurse of how the the body separator id is generated
            // volume body separator id format -> `{volumeChapterID}--body_separator`
            const volumeChapterId = beforeChapterId;
            // get the index of volume chapter form chapters
            const volumeChapterIndex = findIndex(chapters, { _id: volumeChapterId, type: "volume" });

            // don't proceed unless volume chapter is found
            if (volumeChapterIndex === -1) return;

            // Make the item parentChapterId as same as the parent volume of body separator
            movedChapter.parentChapterId = volumeChapterId;

            // count of frontMatter chapters in volume
            const frontMatterCount = chapters[volumeChapterIndex].volume?.frontMatterIds.length || 0;

            // New chapter location in book = volumeChapterIndex + amount of front matter in volume + 1
            const insertAt = volumeChapterIndex + frontMatterCount + 1;
            chapters.splice(insertAt, 0, movedChapter);

            // insert item to the start of volume bodyMatter
            chapters[volumeChapterIndex].volume?.bodyMatterIds.unshift(movedChapter._id);

            // Push meta update to the volume chapter
            metaChangedChapterIds.push(chapters[volumeChapterIndex]._id);
            movedChapter.parentChapterId = volumeChapterId;
          }
        }
      }

      // Criteria 2 - Item before is NOT a chapter => case when dropped after body separator or start of frontMatter
      else {
        if (droppedAfterBodySeparator) {
          // add to start of bodyMatter
          chapters.unshift(movedChapter);
        } else {
          // add to start of frontMatter
          frontMatter.unshift(movedChapter);
        }
      }

      // reorder items so children move with parents
      chapters = reorderChapters(chapters);

      // remove parentChapterIds which does not correspond to a existing chapter Id
      // todo (remove): this is to reset chapters in case if a parentChapterId is assigned a wrong value
      const { cleanedChapters, updatedIds } = removeNonExistingParentIds(chapters);
      chapters = cleanedChapters;
      metaChangedChapterIds.push(...updatedIds);

      // update metadata of volume chapters
      const { metaUpdatedChapters, volumeChapterId } = updateVolumeChapterMeta(chapters, movedChapter, book._id);

      if (volumeChapterId) {
        chapters = metaUpdatedChapters;
        metaChangedChapterIds.push(volumeChapterId);
      }

      updateItems({ frontMatter, chapters });
      
      if (movedChapter._id === chapterMeta._id) {
        resetSelectedChapters(chapterMeta._id);
      }

      // update bookStore & sync changes
      await sortChapters(frontMatter, chapters);

      const allChapters = [...frontMatter, ...chapters];

      const changedMeta = metaChangedChapterIds.map((r) => allChapters.find((e) => e._id === r)!).filter((e) => e !== undefined);

      const metaChangePromises: Promise<unknown>[] = [];
      for (let i = 0; i < changedMeta.length; i++) {
        const isCurrentEditorChapter = changedMeta[i]._id === chapterMeta._id;
        metaChangePromises.push(saveChapterMetaUpdates(changedMeta[i], isCurrentEditorChapter, false));
      }
      Promise.all(metaChangePromises);

      const metaSummery = getBookMetaSummaryForCache(frontMatter, chapters);

      refreshCache(book._id, "chapter-index-change", {
        "chapter-index-change": {
          chapters: metaSummery.chapterCacheData,
          frontMatterIds: metaSummery.frontMatterIds,
        },
      });
    }

    //Handle DND of Scenes
    if (item.type === "scene") {
      const beforeChapter = find([...frontMatter, ...chapters], { _id: beforeChapterId });

      // allow only to be dropped inside normal chapters
      if (!beforeChapter || beforeChapter.type !== "chapter") return;

      // movement within the same chapter
      if (itemChapterId === beforeChapterId && itemSceneIndex !== undefined) {
        resetSelectedChapters();
        handleMoveSceneWithinChapter(itemChapterId, itemSceneIndex, beforeSceneIndex);
        refreshCache(book._id, "chapter-contents-change", { "chapter-contents-change": { chapterId: itemChapterId } });
      }

      // between two chapters
      // if (itemChapterId !== beforeChapterId && beforeChapterId !== undefined && itemSceneIndex !== undefined) {
      //   resetSelectedChapters();
      //   moveSceneBetweenChapters(itemChapterId, beforeChapterId, itemSceneIndex, beforeSceneIndex);
      //   refreshCache(book._id, "chapter-contents-change", { "chapter-contents-change": { chapterId: itemChapterId } });
      //   refreshCache(book._id, "chapter-contents-change", { "chapter-contents-change": { chapterId: beforeChapterId } });
      // }

      return;
    }
  };

  const updateItems = ({ frontMatter, chapters }) => {
    let updatedItems = mapChaptersToItems({ frontMatter, chapters }, sceneCache);
    updatedItems = addNumbering(updatedItems, book);
    updatedItems = collapseGroups(updatedItems, expandedChapters, book._id);
    updatedItems = addPlaceholders(updatedItems);
    setItems(updatedItems);
  };

  const refreshSidebar = (book: ExpandedBook) => {
    updateItems({frontMatter: book.frontMatter, chapters: book.chapters});
    setChapters([...book.frontMatter, ...book.chapters]);
  };

  const onBeforeCapture = (e: BeforeCapture) => {
    // TODO : collapse dragging item
  };

  const updateChapterMeta = async (meta) => {
    await debouncedSaveChapterMetaUpdates(meta);
    const { _id, type, startOn, includeIn } = meta;

    refreshCache(meta.bookId, "chapter-properties-change", {
      "chapter-properties-change": {
        chapter: {
          chapterId: _id,
          chapterType: type,
          startOn: startOn || "any",
          includeIn: includeIn || "all",
        },
      },
    });
  };

  return {
    // event handlers for the DND
    onDragEnd,
    onBeforeCapture,

    // items array to be rendered on sidebar
    sideBarItems: items,
    refreshSidebar,
    // action handlers for the different items
    handleDeleteScene,
    handleDeleteChapter,
    updateChapterMeta,
  };
}
