import React from "react";

export const handleTrackChangeKeyDown = (
  event: React.KeyboardEvent<HTMLTextAreaElement>,
  action: (...args: any[]) => void,
  ...args: any[]
): void => {
  if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
    action(...args);
  }
};
