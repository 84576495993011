import React from "react";
import { observer } from "mobx-react";

import {
  Wrapper,
  LHS,
  Content,
  MenuBar,
} from "../../components/Editor/partials";
import { RHSider } from "../../components/Chapters/RHSBar";
import List from "../../components/Chapters/list/List";
import ActionBar from "../../components/Chapters/ActionBar";
import { EditorContainer } from "../../containers/writing/main";

export const Writing: React.FC = observer(() => {
  return (
    <Wrapper>
      <LHS action={<ActionBar />}>
        <List />
      </LHS>
      <Content>
        <EditorContainer />
      </Content>
      <MenuBar>
        <RHSider />
      </MenuBar>
    </Wrapper>
  );
});
