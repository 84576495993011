import React from "react";
import { Row, Col, Button } from "antd";

import { NotificationClearAll } from "../../containers/notification";

export const NotificationHeader = () => (
  <div>
    <Row align="middle" justify="space-between" className="inner-xs">
      <Col>
        <h3>Notifications</h3>
      </Col>
      <Col>
        <NotificationClearAll />
      </Col>
    </Row>
  </div>
);