import _ from "lodash";
import { makeAutoObservable } from "mobx";

import { TComment } from "../components/Plate/plugins/comments/types";
import { CommentOrTC } from "../types/comments";

export class CommentsStore {
  comments: TComment[] = [];
  activeCommentId: string | null = null;
  commentsAndTrackChanges: CommentOrTC[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setActiveCommentId = (activeCommentId: string | null): void => {
    this.activeCommentId = activeCommentId;
  };

  getCommentById = (id?: string | null): TComment | null => {
    if (!id) return null;
    const filteredComments = this.comments.filter(
      (comment) => comment.id === id
    );
    return filteredComments.length > 0 ? filteredComments[0] : null;
  };

  hasDifferentComments = (comments: TComment[]): boolean => {
    if (this.comments.length !== comments.length) {
      return true;
    }
    return this.comments.some((existingComment) => {
      return !comments.some((comment: TComment) =>
        _.isEqual(existingComment, comment)
      );
    });
  };

  setComments = (comments: TComment[]): void => {
    this.comments = comments;
  };

  setCommentsAndTrackChanges = (
    commentsAndTrackChanges: {
      id: string;
      type: "comment" | "track-change";
    }[]
  ): void => {
    this.commentsAndTrackChanges = commentsAndTrackChanges;
  };
}

export default new CommentsStore();
