import React, { useState, useEffect } from "react";
import { Row, Col, Popover, List, Avatar, Form, Input, Select, message, Dropdown, Menu, Button as AntButton } from "antd";

import useRootStore from "../../store/useRootStore";

import { Button } from "../../components/Shared/Buttons";
import { difference, uniq } from "lodash";
import { observer } from "mobx-react";
import { Modal } from "../../components/Shared/Modal";
import { UserIcon } from "../../content/icons";
import {
  BOOKSHELF_BOOK_ADDED,
  BOOKSHELF_BOOK_REMOVED,
  wsSendShelfUpdateMessage,
} from "../../utils/bookshelf-ws-helper";
import { ShelfWSMessageData } from "../../types/common";


const collabSelectors = {
  "co_author": "Co-writer",
  "editor": "Editor",
  "beta_reader": "Beta reader"
};

const collabSelectoWithOwner = {
  ...collabSelectors,
  "author": "Owner"
};

export const InviteContainer = observer(() => {
  const { 
    mounted, 
    showPopover, 
    collaborations, 
    getCollaborationForBook, 
    addCollaboratorToBook, 
    removeCollaboratorToBook, 
    setTogglePopover 
  } = useRootStore().collaborationStore;
  const { book } = useRootStore().bookStore;
  const { user } = useRootStore().authStore;
  const { socket } = useRootStore().bookSyncWebSocketStore;
  const [form] = Form.useForm();
  const [collaborators, setCollaborators] = useState<ICollabStore.AuthorMetaWithCollaborationMeta[]>([]);
  const [deleteCollaborator, setDeleteCollaborator] = useState<ICollabStore.AuthorMetaWithCollaborationMeta | null>();
  const [loading, setLoading] = useState<string | null>(null);

  const cancelLoading = () => setLoading(null);

  const handleInviteOpen = () => {
    setTogglePopover(!showPopover);
  };

  const handleSetDeleteCollaborator = (item: ICollabStore.AuthorMetaWithCollaborationMeta) => {
    setDeleteCollaborator(item);
    setTogglePopover(false);
  };

  const collaboratorTypes = difference(Object.keys(collabSelectors), uniq(Object.keys(collaborators).map(c => collaborators[c].type)));

  const handleSubmit = (values: any) => {
    if (values.email && values.type) {
      handleAddCollaborator(values.type, values.email);
    }
  };

  const handleAddCollaborator = async (type: ICollabStore.CollaboratorType, email: string) => {
    setLoading("inviting");
    try {
      const collaborator = await addCollaboratorToBook({
        type: type,
        email: email,
        bookId: book._id
      });
      form.resetFields(["email"]);
      // notify shelf update to collaborator through ws 
      if(collaborator){
        const data: ShelfWSMessageData = {
          userId: collaborator.userId,
          bookId: book._id,
          isCollabBook: true,
        };
        wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
      }
    } catch (e: any) {
      const { response } = e;
      message.error(response.data.message || "Error in Adding a collaborator");
    } finally {
      cancelLoading();
    }
  };

  useEffect(() => {
    if (mounted && book._id) {
      const collaborators = getCollaborationForBook(book._id);

      //Add original author to the list
      if (user) {
        collaborators.unshift({
          collaboration_id: "default",
          userId: user._id,
          email: user.email,
          status: "accepted",
          type: "author",
          firstName: user.firstName,
          lastName: user.lastName,
          profilePictureURL: user.profilePictureURL
        });
      }
      setCollaborators(collaborators);
    }
  }, [book._id, mounted, collaborations]);

  const options = collaboratorTypes.map((type) => (
    <Select.Option key={type} value={type}>{collabSelectors[type]}</Select.Option>
  ));

  const handleRemoveCollaborator = async (collab: ICollabStore.AuthorMetaWithCollaborationMeta) => {
    try {
      setLoading(collab.collaboration_id);
      await removeCollaboratorToBook(collab.collaboration_id);
      message.success(`${collab.email} has been removed succesfully`);

      // notify shelf update through ws
      const data: ShelfWSMessageData = {
        userId: collab.userId,
        bookId: book._id,
        isCollabBook: true,
      };
      wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_REMOVED, data);

    } catch(e: any) {
      console.log({e});
    } finally {
      cancelLoading();
      setDeleteCollaborator(null);  
    }
  };

  return (
    <>
      <Popover
        overlayClassName="invite-popover"
        title={collaboratorTypes.length > 0 ? (
          <>
            <Form
              form={form}
              initialValues={{
                email: "",
                type: collaboratorTypes[0]
              }}
              onFinish={handleSubmit}
            >
              <div>
                <h3 className="inner-xs">Share book</h3>
                {loading !== "inviting" ? (
                  <Row align="top" gutter={8}>
                    <Col flex={1}>
                      <Form.Item
                        name="email"
                        className="at-form-item at-invite-select"
                        rules={[
                          {
                            type: "email",
                            required: true,
                            message: "Please enter a valid email"
                          },
                        ]}
                      >
                        <Input
                          placeholder="example@org.com"
                          addonAfter={
                            <Form.Item name="type" noStyle valuePropName="option">
                              <Select
                                defaultActiveFirstOption={true}
                                defaultValue={collaboratorTypes[0]}
                              >
                                {options}
                              </Select>
                            </Form.Item>
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col>
                      <Button type="at-primary" htmlType="submit">
                        Invite
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col flex={1}>
                      <Button loading={true} block disabled type="at-secondary" backgroundColor="white">
                        {loading === "inviting" ? "Inviting" : "Invite"}
                      </Button>
                    </Col>
                  </Row>
                )}
                <div className="inner-xs" />
              </div>
            </Form>
          </>
        ) : null}
        content={
          <div className="invite-popover-content">
            <p className="subtext">People with access</p>
            <List
              loading={!mounted}
              dataSource={collaborators}
              locale={{
                emptyText: "No Collaborators"
              }}
              renderItem={(item: ICollabStore.AuthorMetaWithCollaborationMeta) => (
                <List.Item key={item.collaboration_id}>
                  {item.status !== "pending" ? (
                    <List.Item.Meta
                      avatar={<Avatar size="large" src={item.profilePictureURL}>{(item.firstName.charAt(0) + item.lastName.charAt(0)).toLocaleUpperCase()}</Avatar>}
                      title={`${item.firstName} ${item.lastName}`}
                      description={item.email}
                    />
                  ) : (
                    <List.Item.Meta
                      avatar={<Avatar size="large" icon={<UserIcon color="white" />} />}
                      title={"Invited User"}
                      description={item.email}
                    />
                  )}

                  {item.type !== "author" ? (
                    <Dropdown.Button
                      type="text"
                      size="small"
                      overlay={
                        <Menu>
                          <Menu.Item danger onClick={() => handleSetDeleteCollaborator(item)}>Remove</Menu.Item>
                        </Menu>
                      }
                    >
                      {collabSelectoWithOwner[item.type]}
                    </Dropdown.Button>
                  ) : (
                    <AntButton type="text" size="small">
                      Owner
                    </AntButton>
                  )}
                </List.Item>
              )}
            />
          </div>
        }
        trigger="click"
        open={showPopover}
        onOpenChange={handleInviteOpen}
      >
        <AntButton className="invite-dropdown-button" type="text" size="small">
          Invite
        </AntButton>
      </Popover>
      {deleteCollaborator ? (
        <Modal
          open={true}
          title="Remove Collaborator"
          onCancel={() => setDeleteCollaborator(null)}
          leftBtn={{
            backgroundColor: "green",
            type: "at-secondary",
            children: "Cancel",
            onClick: () => setDeleteCollaborator(null)
          }}
          rightBtn={{
            loading: loading === deleteCollaborator.collaboration_id,
            type: "at-primary",
            danger: true,
            onClick: () => handleRemoveCollaborator(deleteCollaborator),
            children: "Remove"
          }}
        >
          {`Are you sure you want to remove the user with the email: ${deleteCollaborator.email} from this book?`}
        </Modal>
      ) : null}

    </>
  );
});