import {
  GetBooksFromDB,
  DeleteBooksFromDB,
  SaveRemoteBookToDB,
  UpdateBookInDB,
  GetAllChapterMetaFromDB,
  GetBookChapterMetaFromDB,
  GetBookFromDB
} from "../offline.book.helpers";
import { AtticusClient } from "../../api/atticus.api";

export const localBookToRemoteBook = (
  localBook: IBookStore.ExpandedBook
): IBookStore.RemoteBook => {
  const {
    isLocal,
    abilities,
    lastSuccessfulSync,
    modifiedAt,
    frontMatter,
    chapters,
    ...book
  } = localBook;
  const allChapters = [...frontMatter, ...chapters];
  return Object.assign(book, { chapters: allChapters });
};

export const getBooksFromIDB = async (): Promise<IBookStore.ExpandedBook[]> => {
  return (await GetBooksFromDB(true)) as IBookStore.ExpandedBook[];
};

export const removeBooksFromIDB = async (bookIds: string[]): Promise<void> => {
  await DeleteBooksFromDB(bookIds);
};

export const getBookFromIDB = async (bookId:string): Promise<IBookStore.ExpandedBook> => {
  return (await GetBookFromDB(bookId,true)) as IBookStore.ExpandedBook;
};

export const saveBookToRemoteDB = async (
  book: IBookStore.Book,
): Promise<Date> => {
  const {abilities, ...otherProps} = book;
  const response = await AtticusClient.PutBook(otherProps as IBookStore.RemoteBook);
  return response.timestamp;
};

export const patchBookInLocalDB = async (
  book: Partial<IBookStore.Book>
): Promise<void> => {
  const { _id, ...bookDetails } = book;
  if (_id) await UpdateBookInDB(_id, bookDetails);
};

export const saveBookToLocalDB = async (
  book: IBookStore.RemoteBook
): Promise<void> => {
  return await SaveRemoteBookToDB(book);
};

export const getInitialBooksFromRemoteDB = async (): Promise<{
  books: IBookStore.InitialBook[];
}> => {
  return await AtticusClient.GetInitialBooks();
};

export const getInitialCollabBooksFromRemoteDB = async (): Promise<{
  collabBooks: IBookStore.InitialBook[];
  collaborations: ICollabStore.BookCollaboration[];
}> => {
  return await AtticusClient.GetInitialCollabBooks();
};

export const syncInitialChaptersWithLocalDB = async (
  remoteBookId: string,
  isCollaborated?: boolean
): Promise<void> => {
  const remoteBook = await AtticusClient.GetBook(remoteBookId);
  return await SaveRemoteBookToDB(remoteBook, isCollaborated);
};

export const getBooksFromRemoteDB = async (): Promise<{
  books: IBookStore.RemoteBook[];
  deletedBookIds: string[];
}> => {
  return await AtticusClient.GetBooks();
};

export const getBookFromRemoteDB = async (bookId:string): Promise<IBookStore.RemoteBook> => {
  return await AtticusClient.GetBook(bookId);
};

export const getCollaboratedBooksFromRemoteDB = async (): Promise<{
  books: IBookStore.RemoteBook[];
  collaborations: ICollabStore.BookCollaboration[];
  themes: IThemeStore.ThemeResponse[];
}> => {
  return await AtticusClient.GetCollaborated();
};

export const syncRemoteBookWithLocalDB = async (
  remoteBookId: string,
  isCollaborated?:boolean
): Promise<void> => {
  const remoteBook = await AtticusClient.GetBook(remoteBookId);
  return await SaveRemoteBookToDB(remoteBook, isCollaborated);
};

export const getChapterIdsToSync = async (): Promise<string[]> => {
  const chapterMeta = await GetAllChapterMetaFromDB();
  return chapterMeta.map(chapter => chapter._id);
};

export const getBookChapterIdsToSync = async (bookId:string): Promise<string[]> => {
  const chapterMeta = await GetBookChapterMetaFromDB(bookId);
  return chapterMeta.map(chapter => chapter._id);
};

export const getBatchedOperations = <T>(batchSize: number, operations: T[]): T[][] => {
  const batches: T[][] = [];
  let index = 0;

  while (index < operations.length) {
    batches.push(operations.slice(index, index + batchSize));
    index += batchSize;
  }

  return batches;
};
