import React, { useCallback } from "react";
import { ToolbarButton } from "@udecode/plate-ui-toolbar";
import {
  usePlateEditorState,
  setNodes,
  isText,
  deselectEditor,
  isExpanded,
  ToolbarButtonProps,
} from "@udecode/plate";
import { v4 as uuidv4 } from "uuid";

import useRootStore from "../../../../../store/useRootStore";
import { MARK_COMMENT } from "../constants";
import { getCommentKey } from "../utils";

const useAddComment = () => {
  const editor = usePlateEditorState();
  const { setActiveCommentId } = useRootStore().commentsStore;

  return useCallback(() => {
    const { selection } = editor;
    if (!isExpanded(selection)) return;

    const id = uuidv4();
    setNodes(
      editor,
      { [MARK_COMMENT]: true, [getCommentKey(id)]: true },
      { match: isText, split: true }
    );

    try {
      deselectEditor(editor);
    } catch (error) {
      console.error(error);
    }

    setTimeout(() => {
      setActiveCommentId(id);
    }, 0);
  }, [editor]);
};

export const CommentToolbarButton = ({ icon }: ToolbarButtonProps) => {
  const addComment = useAddComment();

  const onMouseDown = useCallback<React.MouseEventHandler<HTMLSpanElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      addComment();
    },
    [addComment]
  );

  return <ToolbarButton icon={icon} onMouseDown={onMouseDown} />;
};
