import { makeAutoObservable } from "mobx";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import { isChapterTopLevel } from "../utils/chapter";
import { ChapterMeta, ChapterType } from "../types/chapter";
import { SideBarItem, SideBarChapterItem } from "../types/sidebar";
import { Scene } from "../types/scene";

export class SideMenuStore {
  selectedChapters: string[] = [];
  expandedChapters = new Set<string>();
  selectedChaptersMap: { [chapterId: string]: boolean } = {};
  selectedChapterOptions: { createPart: boolean; createVolume: boolean } = {
    createPart: true,
    createVolume: true,
  };
  sceneCache: Map<string, Scene[]> = new Map();
  /** uuid is used only to trigger useEffect hooks in components when sceneCache Map is updated */
  sceneCacheUUID: string = uuidv4();

  private _chapters: ChapterMeta[];
  public setFocusedChapter:
    | ((chapterId: string) => Promise<void>)
    | null;
  public setFocusedScene: ((focusedScene: IChapterStore.CurrentScene | null) => void) | null;
  private _selectableChapterTypes = [
    "chapter",
    "title",
    "image",
    "custom",
    "part",
    "volume",
  ];
  private _parentChapterId?: string;
  private _bookId?: string;
  private expectOrnamentalBreakDeletion: boolean;
  private sceneLabelsInOrnamentalBreakAtDeletion: string[];
  public items: SideBarItem[] = [];

  constructor() {
    makeAutoObservable(this);
    this.resetSelectedChapters();
    this._chapters = [];
    this.setFocusedChapter = null;
    this.setFocusedScene = null;
    this.expectOrnamentalBreakDeletion = false;
    this.sceneLabelsInOrnamentalBreakAtDeletion = [];
  }

  public setChapterScenes = (chapterId: string, scenes: Scene[]): void => {
    this.sceneCache.set(chapterId, scenes);
    this.sceneCacheUUID = uuidv4();
  }

  public clearSceneCache = (): void => {
    this.sceneCache.clear();
    this.sceneCacheUUID = uuidv4();
  }

  public setItems = (items: SideBarItem[]): void => {
    this.items = items;
  };

  public init = (
    chapters: ChapterMeta[],
    setFocusedChapter: (
      chapterId: string,
    ) => Promise<void>,
    setFocusedScene: (focusedScene: IChapterStore.CurrentScene | null) => void,
    initialSideBarChapterItem: SideBarChapterItem,
    bookId: string
  ): void => {
    const { createPart, createVolume , chapter : initialChapter } = initialSideBarChapterItem;
    this.resetSelectedChapters();
    this.clearSceneCache();
    this.selectChapter(initialChapter._id, initialChapter?.parentChapterId, createPart, createVolume);

    this._chapters = chapters;
    this._bookId = bookId;
    this.setFocusedChapter = setFocusedChapter;
    this.setFocusedScene = setFocusedScene;
  }

  public setChapters = (chapters: ChapterMeta[]): void => {
    this._chapters = chapters;
  }

  public toggleChapterCollapse = (chapterId: string, action: "expand" | "collapse" | "toggle"): void => {
    const expandedSet = new Set(this.expandedChapters);
    /** collapse if its a forced collapase */
    if(action === "collapse"){
      expandedSet.delete(chapterId);
    } else if (action === "expand") {
      expandedSet.add(chapterId);
    } else {
      /** toggle expand and collapase otherwise */
      expandedSet.has(chapterId) ? expandedSet.delete(chapterId) : expandedSet.add(chapterId);
    }
    this.expandedChapters = expandedSet;
  };

  public collapseChapter = (chapterId: string): void => {
    const expandedSet = new Set(this.expandedChapters);
    if (expandedSet.has(chapterId)) {
      expandedSet.delete(chapterId);
      this.expandedChapters = expandedSet;
    }
  }

  public setExpectOrnamentalBreakDeletion = (value: boolean): void => {
    this.expectOrnamentalBreakDeletion = value;
  };

  public getExpectOrnamentalBreakDeletion = (): boolean => {
    return this.expectOrnamentalBreakDeletion;
  };

  public setSceneLabelsInOrnamentalBreakAtDeletion = (
    value: string[]
  ): void => {
    this.sceneLabelsInOrnamentalBreakAtDeletion = value;
  };

  public getSceneLabelsInOrnamentalBreakAtDeletion = (): string[] => {
    return this.sceneLabelsInOrnamentalBreakAtDeletion;
  };

  public getSelectedChapterType = (): ChapterType | undefined => {
    const selectedTypes = new Set(
      this._chapters.map((chapter) => chapter.type)
    );

    if (selectedTypes.size === 0) return;

    if (selectedTypes.has("volume")) return "volume";
    if (selectedTypes.has("part")) return "part";

    return "chapter";
  };

  public captureClickEvent = async (
    event: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>,
    chapterId: string,
    scene?: Scene,
    createPart?: boolean,
    createVolume?: boolean,
    callback?: () => void
  ): Promise<void> => {
    const chapter = this._chapters.find((chapter) => chapter._id === chapterId);

    if (!chapter || !this.setFocusedChapter || !this.setFocusedScene) {
      return;
    }

    if(!scene) this.setFocusedScene(null);

    // if already selected items are from not multi-selectable chapter types
    const alreadySelectedChapterType = this._chapters.find(
      (chap) => chap._id === this.selectedChapters[0]
    )?.type;
    if (
      !this._selectableChapterTypes.includes(
        alreadySelectedChapterType as ChapterType
      )
    ) {
      this.resetSelectedChapters();
    }

    // if the selected chapters have the same parent id
    const hasSameParent =
      (this._parentChapterId &&
        chapter.parentChapterId &&
        this._parentChapterId === chapter.parentChapterId) ||
      // both stored & chapter parentIds are either undefined or has bookId
      (this._bookId &&
        isChapterTopLevel(this._parentChapterId, this._bookId) &&
        isChapterTopLevel(chapter.parentChapterId, this._bookId));

    // not multi-selectable chapter types or scene selected
    if (
      !this._selectableChapterTypes.includes(chapter.type) ||
      hasSameParent === false ||
      scene !== undefined
    ) {
      this.resetSelectedChapters();
      this.selectChapter(
        chapterId,
        chapter.parentChapterId,
        createPart,
        createVolume
      );
      this.setFocusedChapter(chapterId);
      if(scene) this.setFocusedScene({ chapterId, scene });
      return;
    }

    // Command key press for MacOs and control key press in other OS is captured below
    const isMacOs = window.navigator.userAgent.indexOf("Mac") !== -1;
    if (
      (!isMacOs && event.nativeEvent.ctrlKey) ||
      (isMacOs && event.nativeEvent.metaKey)
    ) {
      // control key + click event
      if (this.selectedChaptersMap[chapterId]) {
        this.unSelectChapter(chapterId);
      } else {
        this.selectChapter(
          chapterId,
          chapter.parentChapterId,
          createPart,
          createVolume
        );
      }
    } else if (event.nativeEvent.shiftKey && this.selectedChapters.length > 0) {
      // handle shift event

      const isInCurrentLevel = (chp) =>
        this._bookId && isChapterTopLevel(this._parentChapterId, this._bookId)
          ? isChapterTopLevel(chp.parentChapterId, this._bookId)
          : chp.parentChapterId === this._parentChapterId;

      const chapters = this._chapters.filter((chp) => isInCurrentLevel(chp));
      const chapterIds = chapters.map((chp) => chp._id);
      const indexOfCurrentChapter = chapterIds.indexOf(chapterId);
      const selectedChaptersAsIndexes = this.selectedChapters.map((chp) =>
        chapterIds.indexOf(chp)
      );
      const parentChapter = this._chapters.find(
        (chp) => chp._id === this._parentChapterId
      );

      const canCreatePart = (chapter) =>
        ["part", "volume"].includes(chapter.type) === false &&
        !parentChapter?.parentChapterId;
      const canCreateVolume = (chapter) =>
        chapter.type !== "volume" &&
        (!chapter.parentChapterId || chapter.parentChapterId === this._bookId);

      const minIndexSelected = Math.min(...selectedChaptersAsIndexes);
      const maxIndexSelected = Math.max(...selectedChaptersAsIndexes);

      if (
        Math.abs(indexOfCurrentChapter - minIndexSelected) <
        Math.abs(indexOfCurrentChapter - maxIndexSelected)
      ) {
        this.resetSelectedChapters();
        const min = Math.min(indexOfCurrentChapter, maxIndexSelected);
        const max = Math.max(indexOfCurrentChapter, maxIndexSelected);

        for (let i = min; i <= max; i++) {
          // check if parent is a part then cant create
          const createPart = canCreatePart(chapters[i]);
          const createVolume = canCreateVolume(chapters[i]);
          this.selectChapter(
            chapterIds[i],
            chapter.parentChapterId,
            createPart,
            createVolume
          );
        }
      } else {
        this.resetSelectedChapters();
        const min = Math.min(indexOfCurrentChapter, minIndexSelected);
        const max = Math.max(indexOfCurrentChapter, minIndexSelected);

        for (let i = min; i <= max; i++) {
          const createPart = canCreatePart(chapters[i]);
          const createVolume = canCreateVolume(chapters[i]);
          this.selectChapter(
            chapterIds[i],
            chapter.parentChapterId,
            createPart,
            createVolume
          );
        }
      }
    } else {
      this.resetSelectedChapters();
      this.selectChapter(
        chapterId,
        chapter.parentChapterId,
        createPart,
        createVolume
      );
    }

    if(callback) callback();

    await this.setFocusedChapter(this.selectedChapters[0]);
  };

  /**
   * @description resets selected chapters. If given chapterId to reset to, resets to that chapterId
   * @param {string} [resetTo] resetTo - The Id of the chapter to reset to.
   * @returns void
  */
  public resetSelectedChapters = (resetTo?: string): void => {
    if (resetTo) {
      this.selectedChapters = [resetTo];
      this.selectedChaptersMap = { [resetTo]: true };
      const selectedChapterItem = this.items.find(
        (item) => item.type === "chapter" && item.chapter?._id === resetTo
      ) as SideBarChapterItem;
      this.selectedChapterOptions = { createPart: selectedChapterItem?.createPart, createVolume: selectedChapterItem?.createVolume };
      return;
    }
    this.selectedChapters = [];
    this.selectedChaptersMap = {};
    this.selectedChapterOptions = { createPart: true, createVolume: true };
  };

  public selectChapter = (
    chapterId: string,
    parentChapterId?: string,
    createPart?: boolean,
    createVolume?: boolean
  ): void => {
    this.selectedChapters.push(chapterId);
    this.selectedChaptersMap[chapterId] = true;
    this._parentChapterId = parentChapterId;
    this.selectedChapterOptions.createPart =
      this.selectedChapterOptions.createPart && !!createPart;
    this.selectedChapterOptions.createVolume =
      this.selectedChapterOptions.createVolume && !!createVolume;
  };

  public unSelectChapter = (chapterId: string) => {
    const index = this.selectedChapters.indexOf(chapterId);
    this.selectedChapters.splice(index, 1);
    this.selectedChaptersMap[chapterId] = false;
  };
}

export default new SideMenuStore();
