import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import { FullScreen } from "../../components/Shared/Layouts";
import { EditorWrapper } from "../../components/Plate";

export const EditContainer: React.FC = observer(() => {
  const {
    getAndSetChapterTemplate,
    chapterLoading
  } = useRootStore().bookStore;
  const {
    setChapterTemplateView,
  } = useRootStore().appStore;

  const templateId = (useParams() as { tempId: string }).tempId;

  const mountChapterTemplates = async () => {
    setChapterTemplateView(true);
    if (templateId) {
      await getAndSetChapterTemplate(templateId);
    }
  };

  useEffect(() => {
    mountChapterTemplates();
  }, [templateId]);

  return (
    <FullScreen>
      {!chapterLoading &&
        <EditorWrapper />
      }
    </FullScreen>
  );
});

