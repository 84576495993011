import React from "react";
import { Table, Row, Col, Spin } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

import { BookCardDropdown } from "./book-card-dropdown";
import { BookIcon, MasterPageIcon } from "../../content/icons";

export const BookListView = ({
  items,
  handleButtonClick,
}: {
  items: (IShelfStore.ShelfItem | IShelfStore.ShlefButtonItem)[];
  handleButtonClick?: (buttonType: IShelfStore.ShelfButtonItemType) => void;
}): JSX.Element => {
  const handleCreateNewBookOnClick = () => {
    if (handleButtonClick) handleButtonClick("create-book");
  };

  const parsedBooks = items.map((item) => {
    if (item.type === "create-book") {
      return item;
    }
    return { ...item.item, actionItem: item.actionItem };
  });

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "40%",
      render: (
        _,
        item:
          | IBookStore.Book
          | IChapterStore.IChapterTemplateBase
          | IShelfStore.ShlefButtonItem
      ) => {
        // Render button items
        if ((item as IShelfStore.ShlefButtonItem).type === "create-book") {
          return (
            <Row gutter={16} className="book-list-item" align="middle">
              <Col>
                <Row
                  justify="center"
                  align="middle"
                  className="book-list-item-btn"
                  onClick={() => handleCreateNewBookOnClick()}
                >
                  <BookIcon color="#FFFFFF" />
                </Row>
              </Col>
              <Col flex={1}>
                <h4>Create a new book</h4>
              </Col>
            </Row>
          );
        } else {
          // Render master page and book items
          const dataItem = item as
            | IBookStore.Book
            | IChapterStore.IChapterTemplateBase;
          // check if item is of type book
          const isBookItem = !!(dataItem as IBookStore.Book).frontMatterIds;
          const isInitial = isBookItem && (dataItem as IBookStore.Book).isInitial;

          const coverImage =
            isBookItem && (dataItem as IBookStore.Book).coverImageUrl
              ? `url(${(dataItem as IBookStore.Book).coverImageUrl})`
              : "none";
          const link = isBookItem ?  `/books/${dataItem._id}` : `/masterpage/${dataItem._id}`;
          const icon = isInitial ? (
            <Spin />
          ) : isBookItem ? (
            <BookIcon color="#3568BA" />
          ) : (
            <MasterPageIcon color="#3568BA" />
          );
          return (
            <Row gutter={16} className="book-list-item" align="middle">
              <Col>
                <Link to={isInitial ? "" : link}>
                  <Row
                    justify="center"
                    align="middle"
                    style={{
                      backgroundImage: coverImage,
                    }}
                    className="book-list-item-img"
                  >
                    {coverImage === "none" && icon}
                  </Row>
                </Link>
              </Col>
              <Col flex={1}>
                <h4>{dataItem.title}</h4>
              </Col>
            </Row>
          );
        }
      },
    },
    {
      title: "Details",
      dataIndex: "author",
      key: "details",
      width: "15%",
      render: (author: string[]) => (author?.length > 0 ? author.join(", ") : "-"),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "Project",
      width: "10%",
      render: (project) => (project ? project : "-"),
    },
    {
      title: "Version",
      dataIndex: "versionTags", 
      key: "version",
      width: "10%",
      render: (version) => (version?.length ? version.join(", ") : "-"),
    },
    {
      title: "Last modified",
      dataIndex: "modifiedAt",
      key: "lastModified",
      width: "10%",
      render: (dt) => (dt ? moment(dt).fromNow() : "-"),
    },
    {
      title: "",
      dataIndex: "actionItem",
      key: "action",
      width: "5%",
      render: (actionItem) =>
        actionItem && actionItem.length != 0 ? <BookCardDropdown actions={actionItem} /> : "-",
    },
  ];
  
  return (
    <Table
      bordered
      columns={columns}
      dataSource={parsedBooks}
      pagination={false}
      expandable={{ expandIcon: () => null }}
      rowClassName={item => (item as IBookStore.Book)?.isInitial ? "loading-book-list-item" : "" }
    />
  );
};
