import React, { useState } from "react";
import { observer } from "mobx-react";
import { find } from "lodash";

import useRootStore from "../../store/useRootStore";
import { toJS } from "mobx";
import { Redirect } from "react-router-dom";

const Formatting = (children) => {
  const { collaborations } = useRootStore().collaborationStore;
  const { book } = useRootStore().bookStore;

  const isCollabBook = find(collaborations, { bookId : book._id });
  const [canCollab, setCollab] = useState(true);

  return canCollab ? (
    <>
      {children}
    </>
  ) : <Redirect to={`/books/${book._id}`} />;
};

export const FormattingContainer = observer(Formatting);