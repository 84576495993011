import React, { useState } from "react";
import { observer } from "mobx-react";

import useRootStore from "../../../store/useRootStore";
import { Row, Col, Radio, Space, message, RadioChangeEvent } from "antd";
import { Button } from "../../Shared/Buttons";
import { Modal as AtticusModal } from "../../Shared/Modal";

enum TemplateScope {
  AllBooks = "AllBooks",
  CurrentBook = "CurrentBook",
}

export const MasterPageBottomBar = observer(() => {
  const { chapterTemplate, book } = useRootStore().bookStore;
  const { chapterTemplateView } = useRootStore().appStore;
  const { chapterMeta, syncChapterTemplate } = useRootStore().chapterStore;

  const [templateScope, setTemplateScope] = useState<TemplateScope>(
    TemplateScope.AllBooks
  );

  const [isTemplateModalVisible, setIsTemplateModalVisible] = useState(false);

  const showTemplateModal = () => {
    setIsTemplateModalVisible(true);
  };

  const handleTemplateOk = async () => {
    if (!templateScope) return;
    setIsTemplateModalVisible(false);
    const shouldUpdateAllBooks = templateScope === TemplateScope.AllBooks;
    const templateId = chapterTemplateView
      ? chapterTemplate._id
      : chapterMeta.templateId;
    const chapterId = chapterTemplateView
      ? chapterTemplate.motherChapterId
      : chapterMeta._id;
    await syncChapterTemplate(
      shouldUpdateAllBooks,
      !chapterTemplateView,
      book._id,
      templateId,
      chapterId
    );
    message.success("Changes applied successfully", 4);
  };

  const handleTemplateCancel = () => {
    setIsTemplateModalVisible(false);
  };

  function onTemplateScopeChange(event: RadioChangeEvent) {
    setTemplateScope(event.target.value as TemplateScope);
  }

  return (
    <div className="masterpage-bottombar-wrapper">
      <Row justify="end">
        <Col>
          <Button
            type="at-primary"
            backgroundColor="blue"
            onClick={() => {
              showTemplateModal();
            }}
          >
            Apply changes
          </Button>
        </Col>
        <AtticusModal
          title={<b>You are making a change to a saved master page</b>}
          open={isTemplateModalVisible}
          onCancel={handleTemplateCancel}
          rightBtn={{
            type: "at-primary",
            danger: true,
            onMouseDown: () => {
              handleTemplateOk();
            },
            children: "Apply current changes",
          }}
        >
          <Radio.Group
            onChange={onTemplateScopeChange}
            value={templateScope}
          >
            <Space direction="vertical">
              <Radio value={TemplateScope.AllBooks}>
                Apply to all books that use this saved master page
              </Radio>
              {!chapterTemplateView && (
                <Radio value={TemplateScope.CurrentBook}>
                  Apply only to this book
                </Radio>
              )}
            </Space>
          </Radio.Group>
        </AtticusModal>
      </Row>
    </div>
  );
});
