import Bugsnag from "@bugsnag/js";
import { cloneDeep } from "lodash";
import { deleteYChapterDB } from "@surge-global-engineering/y-indexeddb";

import { db } from "../db/bookDb";
import { Book, ErrorBook, ErrorChapter, InitialBook, RemoteBook } from "../types/book";
import { ChapterMeta, IChapterTemplateBase } from "../types/chapter";
import { DeviceSpec } from "../types/theme";
import { chapterStore } from "../store";

export const SaveNewBookToDB = async(book: Book):Promise<string> => {
    return await db.books.put(book);
};
/**
 * Saves a new book from remote db to local db or replaces an existing book including it's chapters
 * @param book new book to save / existing book to replace
 * @returns void
 */
export const SaveRemoteBookToDB = async (book: RemoteBook, isCollaborated?:boolean): Promise<void> => {
    const allPromises: Promise<string>[] = [];
    const chaptersToSave: ChapterMeta[] = [];
    const { chapters, ...bookWithoutChapters } = book;
    const bookToSave: Book = {
        ...bookWithoutChapters,
        isInitial: false,
        modifiedAt: bookWithoutChapters.lastUpdateAt,
        lastSuccessfulSync: bookWithoutChapters.lastUpdateAt,
        allChangesSynced: true
    };

    if (isCollaborated !== undefined || isCollaborated === true) {
        bookToSave.collaborated = isCollaborated;
    }

    allPromises.push(db.books.put(bookToSave));
    /** extract chapters from the book to save in the db */
    if(Array.isArray(chapters) && chapters.length > 0) {
        for (const chapter of chapters) {
            chaptersToSave.push({
                _id: chapter._id,
                bookId: chapter.bookId,
                title: chapter.title,
                titleScale: chapter.titleScale,
                type: chapter.type,
                subtitle: chapter.subtitle,
                image: chapter.image,
                index: chapter.index,
                lastSuccessfulSync: chapter.lastUpdateAt,
                numbered: chapter.numbered,
                includeIn: chapter.includeIn,
                startOn: chapter.startOn,
                templateId: chapter.templateId,
                fullpageImage: chapter.fullpageImage,
                allChangesSynced: true,
                parentChapterId: chapter.parentChapterId,
                volume: chapter.volume,
                toc: chapter.toc,
                configuration: chapter.configuration,
            });
        }
    }
    allPromises.push(SaveChapterMetaToDB(chaptersToSave));
    await Promise.all(allPromises);
    return;
};

/**
 * @param bookId book id
 * @param includeChapterMeta Include chapter meta in the returned book, default false
 * @returns Book from indexedDB
 */
export const GetBookFromDB = async(bookId: string, includeChapterMeta = false): Promise<IBookStore.ExpandedBook | IBookStore.Book | undefined> => {
    const book = await db.books.get(bookId);
    if(!book) return;
    if(!includeChapterMeta) return book;
    const frontMatter = await GetChapterMetaListFromDB(book.frontMatterIds);
    const chapters = await GetChapterMetaListFromDB(book.chapterIds);
    return ({frontMatter, chapters, ...book});
};

/**
 * @param includeChapterMeta Include chapter meta in the returned books, default false
 * @returns All books in indexedDB
 */
export const GetBooksFromDB = async(includeChapterMeta = false): Promise<IBookStore.ExpandedBook[] | IBookStore.Book[]> => {
    const allBooksInDB = await db.books.toArray();
    if(!includeChapterMeta){
        return allBooksInDB;
    }
    const expandedBooks: IBookStore.ExpandedBook[] = [];
    for(const book of allBooksInDB){
        const frontMatter = await GetChapterMetaListFromDB(book.frontMatterIds);
        const chapters = await GetChapterMetaListFromDB(book.chapterIds);
        expandedBooks.push({frontMatter, chapters, ...book});
    }
    return expandedBooks;
};

/**
 * Removes books from local db along with chapter meta and y chapter bodies 
 * @param bookIds book ids to remove from local db
 */
export const DeleteBooksFromDB = async(bookIds: string[]): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    const books = (await db.books.bulkGet(bookIds)).filter(book => !!book) as IBookStore.Book[];
    const allChaptersToRemove  = books.flatMap(book => [...book.frontMatterIds, ...book.chapterIds]);
    allPromises.push(DeleteChaptersFromDB(allChaptersToRemove));
    allPromises.push(db.books.bulkDelete(bookIds));
    await Promise.all(allPromises);
};


export const SaveChapterTemplateToDB = async (template: IChapterTemplateBase): Promise<void> => {
    await db.chapterTemplates.put({
      ...template,
      modifiedAt: template.lastUpdateAt,
      lastSuccessfulSync: template.lastUpdateAt,
    });

    return;
};

export const GetChapterTemplates = async (templateId: string): Promise<IChapterTemplateBase | undefined> => {
    const dev = await db.chapterTemplates.get(templateId);
    return dev;
};

export const deleteChapterTemplateFromDB = async (
  templateId: string
): Promise<void> => {
  try {
    await db.chapterTemplates.where("_id").equals(templateId).delete();
    const chapterMetas = await db.chapterMetas.toArray();

    const allPromises: Promise<void>[] = [];

    chapterMetas.forEach((meta) => {
      if (meta.templateId === templateId) {
        allPromises.push(
          UpdateChapterMeta(meta._id, { templateId: undefined })
        );

        if (chapterStore.chapterMeta._id === meta._id) {
          const chapterClone = cloneDeep(meta);
          delete chapterClone.templateId;

          chapterStore.setChapterMeta(chapterClone);
        }
      }
    });

    await Promise.all(allPromises);
  } catch (e: any) {
    console.log(e);
  }
};

export const GetAllChapterTemplates = async (): Promise<IChapterTemplateBase[] | undefined> => {
    const dev = await db.chapterTemplates.toArray();
    return dev;
};

// Chapter Template Library
export const UpdateChapterTemplateMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterTemplates.update(chapterId, updates));

    // allPromises.push(db.chapterMetas.update(chapterId, updates));
    // allPromises.push(db.chapterBodies.update(chapterId, updates));
    await Promise.all(allPromises);
};

// Chapter Template Library
export const UpdateChapterTemplateInDB = async (templateId: string, updates: Partial<IChapterTemplateBase>): Promise<void> => {
    await db.chapterTemplates.update(templateId, updates).catch (function (err) {
        Bugsnag.notify(err);
        console.log(err);
    });
};

export const GetAllChapterMetaFromDB = async():Promise<IChapterStore.ChapterMeta[]> => {
    const chapters = await db.chapterMetas.toArray();
    return chapters;
};

export const GetBookChapterMetaFromDB = async(bookId: string):Promise<IChapterStore.ChapterMeta[]> => {
    const chapters = await db.chapterMetas.where("bookId").equals(bookId).toArray();
    return chapters;
};

export const GetChapterMetaListFromDB = async(chapterids: string[]): Promise<IChapterStore.ChapterMeta[]> =>{
    const chapters = await db.chapterMetas.bulkGet(chapterids);
    return chapters.filter(chapter => !!chapter) as IChapterStore.ChapterMeta[];
};

export const GetChapterMetaFromDB = async (chapterId: string): Promise<IChapterStore.ChapterMeta | undefined> => {
    return await db.chapterMetas.get(chapterId);
};

export const FilterChapterMeta = async(filter: Record<string, string | number>): Promise<ChapterMeta[]> => {
    return await db.chapterMetas.where(filter).toArray();
};

export const SaveChapterMetaToDB = async(chapterMeta: IChapterStore.ChapterMeta[]): Promise<string> => {
    return await db.chapterMetas.bulkPut(chapterMeta);
};

export const UpdateBookInDB = async (bookId: string, updates: Partial<Book>): Promise<void> => {
    await db.books.update(bookId, updates);
};

export const UpdateChapterMeta = async (chapterId: string, updates: Partial<ChapterMeta>): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.update(chapterId, cloneDeep(updates)));
    await Promise.all(allPromises);
};

/**
 * Removes chapter meta and y chapter bodies from local db
 * @param chapterIds chapter ids to remove from local db
 */
export const DeleteChaptersFromDB = async (chapterIds: string[]): Promise<void> => {
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.chapterMetas.where("_id").anyOf(chapterIds).delete());
    for(const chapterid of chapterIds) {
        allPromises.push(deleteYChapterDB(chapterid));
    }
    await Promise.all(allPromises);
};

//Themes

export const SaveThemesInIDB = async (themes: IThemeStore.ThemeResponse[]): Promise<void> => {
    const parsedThemes: IThemeStore.ThemeResponse[] = [];

    if (themes) {
        for (const theme of themes) {
            const parsedTheme: IThemeStore.ThemeResponse = {
                _id: theme._id,
                name: theme.name,
                fonts: theme.fonts,
                css: theme.css,
                createdAt: theme.createdAt,
                lastUpdateAt: theme.lastUpdateAt,
                modifiedAt: theme.modifiedAt,
                lastSuccessfulSync: theme.lastSuccessfulSync,
                allChangesSynced: true,
                properties: theme.properties,
                isPredefinedTheme: theme.isPredefinedTheme,
                isFavourite: theme.isFavourite,
            };
            parsedThemes.push(parsedTheme);
        }

        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.theme.bulkPut(parsedThemes));
    }
    return;
};

export const DeleteThemeFromIndexedDB = async (themeId: string): Promise<void> => {
  await db.theme.where("_id").equals(themeId).delete();
};

export const SavePreviewDevice = async (device: DeviceSpec[]): Promise<void> => {
    const allDevices: DeviceSpec[] = [];
    if (device) {
        for (const dv of device) {
            const allT: DeviceSpec = {
                _deviceName: dv._deviceName,
                image: dv.image,
                fonts: dv.fonts
            };
            allDevices.push(allT);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.deviceConfig.bulkPut(allDevices));
    return;
};

export const GetPreviewDeviceDB = async (_deviceName: string): Promise<DeviceSpec | undefined> => {
    const dev = await db.deviceConfig.get(_deviceName);
    return dev;
};

export const GetAllPreviewDevices = async () => {
    const bookTheme = await db.deviceConfig.toArray();

    const unique = bookTheme.map((a) => {
        return a.image;
    });
    return unique;
};


export const GetThemeConfigs = async (): Promise<any[]> => {
    const bookTheme = await db.theme.toArray();
    return bookTheme || [];
};

export const GetAllThemesFromIDB = async (): Promise<IThemeStore.ThemeResponse[]> => {
    const bookTheme = await db.theme.toArray();
    return bookTheme || [];
};

export const GetThemeFromIDB = async (_id: string): Promise<IThemeStore.ThemeResponse | undefined> => await db.theme.get(_id);

export const OverwriteThemeInIDB = async (themeId: string, updates: Partial<IThemeStore.ThemeResponse>): Promise<number> => await db.theme.update(themeId, updates);

export const SaveNewThemeInIDB = async (theme: IThemeStore.ThemeResponse): Promise<string> => await db.theme.add(theme);


// Error Visualization
export const SaveErrorBook = async (error: string[]): Promise<void> => {
    const errorBooks: ErrorBook[] = [];
    if(error) {
        for(const er of error) {
            const allE = {
                _bookId: er
            };
            errorBooks.push(allE);
        }
    }
    const allPromises: Promise<unknown>[] = [];
    allPromises.push(db.failedBooks.bulkPut(errorBooks) );
    return;
};

// Error Visualization
export const GetErrorBook = async (): Promise<ErrorBook[] | undefined> => {
    try {
        const errorBook = await db.failedBooks.toArray();
        return errorBook;
    } catch (error) {
        console.log(error);
    }
};

// Error Visualization
export const SaveErrorBookChapter = async (error: ErrorChapter): Promise<void> => {
    const errorChapters: ErrorChapter[] = [];

    if(error) {
        const allPromises: Promise<unknown>[] = [];
        allPromises.push(db.failedChapters.put({
            ...error
            // lastSuccessfulSync: error.lastSuccessfulSync
         }) );
        return;
    }

};

// Error Visualization
export const GetErrorChapters = async (): Promise<ErrorChapter[] | undefined>=> {
    try {
        const errorChapter = await db.failedChapters.toArray();
        return errorChapter;
    } catch (error) {
        console.log(error);
    }
};


//Do the merge sync with archived theme config

