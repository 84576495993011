import { PlateEditor, toDOMNode, Value } from "@udecode/plate";

import { TCommentText } from "../types";
import { getElementAbsolutePosition } from "../utils";

export const getCommentPosition = <V extends Value>(
  editor: PlateEditor<V>,
  node: TCommentText,
  popoverRef
):
  | {
      left: number;
      top: number;
    }
  | undefined => {
  const DOMNode = toDOMNode(editor, node);
  if (!DOMNode) return;

  const DOMNodePosition = getElementAbsolutePosition(DOMNode);

  const editorDOMNode = toDOMNode(editor, editor);
  if (!editorDOMNode) return;

  const editorRect = editorDOMNode.getBoundingClientRect();

  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const editorY = editorRect.top + scrollTop;
  const editorHeight = editorRect.height;

  let commentPopoverTop = DOMNodePosition.top;
  const initialHeight = 163.2;
  const commentPopoverHeight = popoverRef.current ? popoverRef.current.offsetHeight : initialHeight;

  if(DOMNodePosition.top + commentPopoverHeight > (editorHeight+ editorY)){
    commentPopoverTop = editorHeight+ editorY - commentPopoverHeight;
  }

  // Ensure the popover doesn't go above the editor's top
  if (commentPopoverTop < editorY) {
    commentPopoverTop = editorY;
  }

  const rhsSidebarWidth = 48;
  const rhsMenuWidth = 330;
  const padding = 32;

  return {
    left: window.innerWidth - (rhsSidebarWidth + padding + rhsMenuWidth),
    top: commentPopoverTop,
  };
};
