import { PlateRenderElementProps, findNodePath, setNodes, usePlateEditorState } from "@udecode/plate";
import { Button, Row } from "antd";
import React, { useState } from "react";
import { SettingsIcon } from "../../../../../utils/icon";
import { TextMessagesModal } from "./textMessagesModal";
import { TextMessagesGroupNode, TextMessagesStyle } from "../types";
import { MyEditor } from "../../../config/typescript";
import { can } from "../../../../casl/helpers";

export const TextMessagesGroupComponent = ({
  element,
  children,
}: PlateRenderElementProps) => {
  const { id } = element;
  const editor = usePlateEditorState() as MyEditor;
  const [modal, toggleModal] = useState(false);

  const onUpdateStyle = (style: TextMessagesStyle) => {
    const path = findNodePath(editor, element);
    setNodes(editor, {style} , { at: path });
  };

  return (
    <div className="plate-custom-text-messages-block" id={id as string}>
      <Row style={{ flexDirection: "row-reverse" }}>
        {can("view", "plugin-settings") &&
          <Button
            className="editor-block-action-btn"
            onClick={() => toggleModal(true)}
          >
            <SettingsIcon />
          </Button>
        }
      </Row>
      <Row>{children}</Row>

      {modal && (
        <TextMessagesModal
          element={element as TextMessagesGroupNode}
          onUpdate={onUpdateStyle}
          onClose={() => toggleModal(false)}
        />
      )}
    </div>
  );
};
