import React from "react";
import {  Menu } from "antd";
import moment from "moment";

import { MenuIcon } from "../../content/icons";
import { AtticusDropdown } from "../../components/Shared/Dropdowns";
import { observer } from "mobx-react";
import useRootStore from "../../store/useRootStore";

export const NotificationItemAction = observer(({ item } : { item: INotificationStore.Notification }) => {
  const { clearNotification, markNotficationRead } = useRootStore().notificationStore;

  return (
    <AtticusDropdown
      trigger={["hover"]}
      placement="bottomLeft"
      overlayClassName="book-card-dropdown-overlay"
      className="book-card-dropdown"
      overlay={
        <Menu
          items={[
            {
              key: "dismiss-" + item._id,
              label: "Dismiss notification",
              onClick: () => clearNotification(item._id)
            },
            {
              key: "read-" + item._id,
              label: "Mark as read",
              onClick: () => markNotficationRead(item._id)
            }
          ]}
        />
      }
    >
      <MenuIcon size={15} className="book-card-dropdown-icon" />
    </AtticusDropdown>
  );
});