import { unwrapNodes } from "@udecode/plate";
import { BaseElement, Element, Node, Transforms } from "slate";

import { NodeType } from "../../types";
import { can } from "../../../../casl/helpers";

export const withParagraph = (editor) => {
  const { normalizeNode } = editor;
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // If the element is a paragraph, ensure its children are valid.
    if (
        Element.isElement(node) &&
        (node as BaseElement & NodeType).type === "p"
    ) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (Element.isElement(child) && !editor.isInline(child)) {
          unwrapNodes(editor, { at: childPath });
          return;
        }
      } 
    } else if(!node.type) { // validate if node has type, if no type exist unwrap 
      unwrapNodes(editor, { at: path, split: false });
    }
    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };

  // check for plugin permissions when pasting content
  editor.insertFragment = (nodes: Node[]) => {
    const filteredNodes = nodes.filter((node) => {
      if(["indent", "blockquote", "code_block"].includes((node as BaseElement & NodeType).type)) {
        return can("view", "quote-items-toolbar");
      }

      if((node as BaseElement & NodeType).type === "ornamental-break") {
        return can("view", "scene-break-toolbar");
      }

      if((node as BaseElement & NodeType).type === "page-break") {
        return can("view", "page-break-toolbar");
      }

      if((node as BaseElement & NodeType).type === "endnote") {
        return can("view", "endnotes-toolbar");
      }

      if((node as BaseElement & NodeType).type === "profile") {
        return can("view", "social-media-toolbar");
      }

      return true;
    });

    if(filteredNodes.length > 0) {
      Transforms.insertFragment(editor, filteredNodes);
    }
  };
  return editor;
};