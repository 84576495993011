import { BaseEditor, NodeEntry, Path, Editor, Transforms } from "slate";
import { isElement } from "@udecode/plate-core";

import { MySceneElement } from "../../../config/typescript";
import { ELEMENT_SCENE } from "../createScenePlugin";
import { ELEMENT_ORNAMENTAL_BREAK } from "../createOrnamentalBreakPlugin";

export function withScenes(
  editor,
  currentScene: IChapterStore.CurrentScene | null,
  handleScenes: () => void
) {
  const plateEditor = editor as BaseEditor;
  const { deleteFragment, normalizeNode, deleteBackward, deleteForward } =
    plateEditor;

  plateEditor.deleteBackward = (unit) => {
    if (!currentScene) {
      deleteBackward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteBackward(unit);
      return;
    }
  };

  plateEditor.deleteForward = (unit) => {
    if (!currentScene) {
      deleteForward(unit);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor) return;
    const { anchor } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    if (isAnchorInScene) {
      deleteForward(unit);
      return;
    }
  };

  plateEditor.deleteFragment = (direction) => {
    if (!currentScene) {
      deleteFragment(direction);
      return;
    }
    const currentSceneIndex = currentScene?.scene.sceneIndex;
    const selection = plateEditor.selection;
    if (!selection?.anchor || !selection.focus) return;
    const { anchor, focus } = selection;
    const anchorAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: anchor,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const focusAncestorScene = Editor.above<MySceneElement>(plateEditor, {
      at: focus,
      match: (node) =>
        isElement(node) &&
        node.type === ELEMENT_SCENE &&
        (node as MySceneElement).sceneIndex === currentSceneIndex,
    });
    const isAnchorInScene = !!anchorAncestorScene;
    const isFocusInScene = !!focusAncestorScene;
    /** if both anchor and focus of the current selection is outside of the active scene, do nothing */
    if (!isAnchorInScene && !isFocusInScene) {
      return;
    }
    /** if both anchor and focus of the current selection is within the active scene, delete the selection without modifying  */
    if (isAnchorInScene && isFocusInScene) {
      Transforms.delete(plateEditor, { at: selection });
      return;
    }
    if (isAnchorInScene || isFocusInScene) {
      /** check if anchor point is before focus (can select fragment top to bottom or bottom to top) */
      const isAnchorBeforeFocus = Path.isBefore(anchor.path, focus.path);
      /** if only anchor is within the current scene */
      if (isAnchorInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          anchorAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, anchorAncestorScene[1]);
        if (isAnchorBeforeFocus) {
          Transforms.delete(plateEditor, {
            at: { anchor, focus: sceneEndPoint },
          });
          return;
        } else {
          Transforms.delete(plateEditor, {
            at: { anchor: sceneStartPoint, focus: anchor },
          });
          return;
        }
      }
      /** if only focus is within the current scene */
      if (isFocusInScene) {
        const sceneStartPoint = Editor.start(
          plateEditor,
          focusAncestorScene[1]
        );
        const sceneEndPoint = Editor.end(plateEditor, focusAncestorScene[1]);
        if (isAnchorBeforeFocus) {
          Transforms.delete(plateEditor, {
            at: { anchor: sceneStartPoint, focus },
          });
          return;
        } else {
          Transforms.delete(plateEditor, {
            at: { anchor: focus, focus: sceneEndPoint },
          });
          return;
        }
      }
    }
  };

  plateEditor.normalizeNode = (entry: NodeEntry) => {
    const [node, path] = entry;
    const isOrnamentalBreakNode =
      isElement(node) && node.type === ELEMENT_ORNAMENTAL_BREAK;
    if (isOrnamentalBreakNode) {
      handleScenes();
    }
    normalizeNode(entry);
  };
  return editor;
}
