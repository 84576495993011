import React from "react";
import { observer } from "mobx-react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate-core";
import { Button, Popconfirm } from "antd";
import { removeOrnamentalBreak } from "../transforms/removeOrnamentalBreak";

import useRootStore from "../../../../../store/useRootStore";
import { DeleteIcon } from "../../../../../content/icons";
import { can } from "../../../../casl/helpers";

export const OrnamentalBreakComponent = observer(
  ({ children, element, id }) => {
    const editor = usePlateEditorState(useEventPlateId(id));

    const {
      setExpectOrnamentalBreakDeletion,
      setSceneLabelsInOrnamentalBreakAtDeletion,
    } = useRootStore().sideMenuStore;
    const { currentScene } = useRootStore().chapterStore;
    /** do not render ornamental breaks while in a scene */
    return !currentScene ? (
      <div className="slate-ornamental-break">
        <div></div>
        <div
          contentEditable={false}
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <span>{"***"}</span>
        </div>
        {children}
        <div>
          <Popconfirm
            title="Are you sure want to delete this Ornamental Break?"
            onConfirm={() => {
              //setting the editor onChange event ornamental break control flag
              setExpectOrnamentalBreakDeletion(true);
              setSceneLabelsInOrnamentalBreakAtDeletion(element.sceneLabels);
              removeOrnamentalBreak(element, editor);
              }}
              okButtonProps={{
                  danger: true
              }}
              okText="Delete"
              cancelText="Cancel"
              >{can("view", "plugin-settings") &&
                <Button>
                    <DeleteIcon />
                </Button>
              }
          </Popconfirm>
         </div>
      </div>
    ) : null;
  }
);
