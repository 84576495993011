import { toJS } from "mobx";
import { initBody } from "./initials";
import { generate as generateRandomString } from "randomstring";
import { getAllChildrenIds } from "./sidebar";
import { isChapterInsideVolume, isChapterTopLevel } from "./chapter";
import { ExpandedBook } from "../types/book";
import { PdfExpandedBook } from "../components/Previewer/print/types";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "./chapter";

export type CreateGroupChapterResult = {
  newChapters: IChapterStore.Chapter[];
};

/**
 * Generate metadata for a title chapter with given attributes
 *
 * @param attributes { bookId, parentChapterId }
 * @returns metadata and body for the created title chapter
 */
const generateTitleChapter = (bookId, parentChapterId) => {
  const titlePageId = generateRandomString(16);

  //TODO:Verify
  const newTitleChapter: IChapterStore.Chapter = {
    _id: titlePageId,
    bookId: bookId,
    title: "Title Page",
    type: "title",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    image: "",
    index: 0,
    startOn: "right",
    parentChapterId,
    children: initBody
  };

  return newTitleChapter;
};

/**
 * Generate metadata for a part chapter with given attributes
 *
 * @param attributes { chapterId, bookId, parentChapterId }
 * @returns {CreateGroupChapterResult} created part chapter
 */
export const createPartChapter = ({
  chapterId,
  bookId,
  parentChapterId,
}: {
  chapterId: string;
  bookId: string;
  parentChapterId: string | undefined;
}): CreateGroupChapterResult => {
  const parentChapter: IChapterStore.Chapter = {
    _id: chapterId,
    bookId: bookId,
    title: "Part",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    image: "",
    type: "part",
    index: 0,
    parentChapterId: parentChapterId,
    startOn: "right",
    allChangesSynced: false,
    includeIn: "none",
    children: initBody,
  };

  //TODO:Verify
  // const parentChapterBody: IChapterStore.ChapterBody = {
  //   _id: chapterId,
  //   bookId: bookId,
  //   // TODO: remove this after creating UI for editing part metadata
  //   children: initBody,
  // };

  const titleChapter = generateTitleChapter(bookId, chapterId);

  return {
    newChapters: [parentChapter, titleChapter],
  };
};

/**
 * Creates volume chapter metadata from using the given selected chapters.
 * Also generates the metadata for other needed chapters like TOC & title Page
 *
 * @param [chapterId, bookId, parentChapterId, chapterIds, chapters]
 * @returns {CreateGroupChapterResult} including volume chapter & other new chapter metadata
 */
export const createVolumeChapter = ({
  chapterId,
  bookId,
  parentChapterId,
  chapterIds,
  chapters,
}: {
  chapterId: string;
  bookId: string;
  parentChapterId: string | undefined;
  chapterIds: string[];
  chapters: IChapterStore.ChapterMeta[];
}): CreateGroupChapterResult => {

  const tocChapterId = generateRandomString(16);
  const tocChapter: IChapterStore.Chapter = {
    _id: tocChapterId,
    bookId: bookId,
    title: "Contents",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "toc",
    subtitle: "",
    image: "",
    index: 0,
    startOn: "right",
    parentChapterId: chapterId,
    children: initBody
  };

  const bodyMatterIds = toJS(chapterIds)
    .map((r) => [r, ...getAllChildrenIds(chapters, r)])
    .flat(2);

  const titleChapter = generateTitleChapter(
    bookId,
    chapterId
  );

  const parentChapter: IChapterStore.Chapter = {
    _id: chapterId,
    bookId: bookId,
    title: "Volume",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    image: "",
    type: "volume",
    index: 0,
    parentChapterId: parentChapterId,
    startOn: "right",
    allChangesSynced: false,
    volume: {
      frontMatterIds: [titleChapter._id, tocChapterId],
      bodyMatterIds: bodyMatterIds,
      coverPageImageUrl: "",
    },
    includeIn: "none",
    children: initBody
  };

  return {
    newChapters: [titleChapter, tocChapter, parentChapter]
  };
};

/**
 * Extracts the volume titles from the book
 * @param book Book
 * @returns Volume title chapters
 */
export const getVolumeChapterTitles = (
  book: ExpandedBook | PdfExpandedBook
): IChapterStore.ChapterMeta[] => {
  if (!book.chapters) return [];
  const titleChapters = book.chapters.filter(
    (chapter) =>
      chapter.type === "title" &&
      isChapterInsideVolume(chapter._id, book.chapters)
  );
  const volumeChapters = book.chapters.filter(
    (chapter) => chapter.type === "volume"
  );
  const volumeTitles = volumeChapters.map((chapter) => {
    const titleChapter = titleChapters.find(
      (chap) => chap.parentChapterId === chapter._id
    );
    return {
      ...chapter,
      includeIn: titleChapter ? titleChapter.includeIn : "none",
    };
  });
  return volumeTitles;
};

/**
 * Calculates the data needed to render title chapter depending on whether the chapter is inside a volume/part or not
 * @param book
 * @param chapter title chapter
 * @param medium whether to get titles for print or ebook
 */
export function getTitleChapterData(
  book: ExpandedBook | PdfExpandedBook,
  chapter: IChapterStore.ChapterMeta,
  medium?: "print" | "ebook"
): {
  titleType: "volume" | "part" | "book";
  title: string | undefined;
  subtitle: string | undefined;
  author: string[];
  //TODO:BODY
  volumeTitles: IChapterStore.ChapterMeta[];
  publisherName?: string;
  publisherLogoURL?: string;
  publisherLink?: string;
} {
  let { title, subtitle, author } = book;
  //TODO:BODY
  let volumeTitles: IChapterStore.ChapterMeta[] = [];

  const parentChapter = book.chapters.find(
    (chap) => chap._id === chapter.parentChapterId
  );

  if (parentChapter) {
    title = parentChapter.title;
    subtitle = parentChapter?.subtitle;
    author = [parentChapter.volume?.author || ""];
  } else {
    // get all volume title chapters and filter them according to the medium
    volumeTitles = getVolumeChapterTitles(book).filter((chap) =>
      ["all", medium, undefined].includes(chap.includeIn)
    );
  }

  return {
    titleType:
      parentChapter?.type === "volume"
        ? "volume"
        : parentChapter?.type === "part"
        ? "part"
        : "book",
    title,
    subtitle,
    author,
    volumeTitles,
    publisherName: book.publisherName,
    publisherLogoURL: book.publisherLogoURL,
    publisherLink: book.publisherLink,
  };
}

/**
 * Updates the metadata of the given volume chapter and returns the updated chapter meta array
 *
 * @param chapters book chapter meta
 * @param changedChapter meta of the changed Chapter
 * @param bookId id of the book
 * @returns {ChapterMeta[] | null } book chapter array with updated metadata if update is successful null otherwise
 */
export function updateVolumeChapterMeta(
  chapters: IChapterStore.ChapterMeta[],
  changedChapter: IChapterStore.ChapterMeta,
  bookId: string
): {
  metaUpdatedChapters: IChapterStore.ChapterMeta[];
  volumeChapterId?: string;
  volumeChapterIndex?: number;
} {
  // if the changed chapter is top level nothing to update
  if (isChapterTopLevel(changedChapter.parentChapterId, bookId))
    return { metaUpdatedChapters: chapters };

  const parentChapIndex = chapters.findIndex(
    (chap) => chap._id === changedChapter.parentChapterId
  );
  const parentChapter = chapters[parentChapIndex];
  const volumeMeta = parentChapter.volume;

  if (parentChapter && parentChapter.type === "volume" && volumeMeta) {
    const children = getAllChildrenIds(chapters, chapters[parentChapIndex]._id);
    const frontMatterIds = volumeMeta.frontMatterIds || [];
    const bodyMatterIds = children.filter((r) => !frontMatterIds.includes(r));
    chapters[parentChapIndex].volume = {
      ...volumeMeta,
      frontMatterIds,
      bodyMatterIds,
    };
    // return the updated chapter array along with the changed volume chapter index & id
    return {
      metaUpdatedChapters: chapters,
      volumeChapterId: chapters[parentChapIndex]._id,
      volumeChapterIndex: parentChapIndex,
    };

    // if the parentChapter is a part consider it as the changed chapter & rerun
  } else if (parentChapter && parentChapter.type === "part") {
    return updateVolumeChapterMeta(chapters, parentChapter, bookId);
  }

  return { metaUpdatedChapters: chapters };
}
