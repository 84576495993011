import { makeAutoObservable } from "mobx";

export class BookSyncWebSocketStore {
  socket: WebSocket | null = null;
  changeEvent: IPDFCacheStore.PDFChangeEvent | string | null = null;
  syncChapterId: string | null = null;
  syncChapterType: string | null = null; 
  isBookLocalUpdate = false;
  isChapterLocalUpdate = false;
  isChapterMetaSave = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSocket = (newSocket : WebSocket | null) : void => {
    this.socket = newSocket;
  }

  setChangeEvent = ( changeEvent : IPDFCacheStore.PDFChangeEvent ) : void => {
    this.changeEvent = changeEvent;
  }

  setSyncChapterId = ( chapterId : string) : void => {
    this.syncChapterId = chapterId;
  }

  setSyncChapterType = ( chapterType : string ) : void => {
    this.syncChapterType = chapterType;
  }

  setIsBookLocalUpdate = (isBookLocalUpdate : boolean) : void => {
    this.isBookLocalUpdate = isBookLocalUpdate;
  }

  setIsChapterLocalUpdate = (isChapterLocalUpdate : boolean) : void => {
    this.isChapterLocalUpdate = isChapterLocalUpdate;
  }

  setIsChapterMetaSave = (isChapterMetaSave : boolean) : void => {
    this.isChapterMetaSave = isChapterMetaSave;
  }

  getSocket() : WebSocket | null {
    return this.socket;
  }

  closeSocket() : void {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}

export default new BookSyncWebSocketStore();