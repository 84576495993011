import { EditorAction, EditorSubject } from "./types";
import { createMongoAbility } from "@casl/ability";
import { unpackRules } from "@casl/ability/extra";
import { bookStore, authStore, appStore } from "../../store/";
import { verify } from "jsonwebtoken";
import { isEqual } from "lodash";

/**
 * Checks if a user has permission to perform a specific action on a given subject.
 * @param action - The action the user wants to perform.
 * @param subject - The subject on which the action is performed.
 * @returns `true` if the user has permission, otherwise `false`.
 */
export const can = (action: EditorAction, subject: EditorSubject): boolean => {
  const abilityInstance = getAbilityInstance();
  return abilityInstance.can(action, subject);
};

/**
 * Retrieves the current user's abilities and creates an ability instance based on them.
 * @returns An ability instance initialized with the current user's abilities.
 */
export const getAbilityInstance = () => {
  let packedRules = [];

  const { getCurrentBookAbilities, getCurrentBookId } = bookStore;
  const { abilitiesPublicKey, getCurrentUser } = authStore;
  const { chapterTemplateView } = appStore;

  const currentBookAbilities = getCurrentBookAbilities();

  let abilitiesToken;

  /**
   * Determine which token to use based on the user's permissions.
   * Use newBookToken for AUTHOR-level access to newly created books and master pages.
   */
  if (currentBookAbilities.newBook || chapterTemplateView) {
    abilitiesToken = currentBookAbilities.newBookToken;
  }

  if (!currentBookAbilities.newBook && !chapterTemplateView) {
    abilitiesToken = currentBookAbilities.currentBookToken;
  }

  let decodedAbilities;

  // Verify the token with the public key to retrieve decoded abilities
  if (abilitiesToken) {
    try {
      const verifiedAbilities = verify(abilitiesToken, abilitiesPublicKey);
      decodedAbilities = verifiedAbilities;
    } catch (error) {
      console.log("INVALID PERMISSIONS");
    }
  }
  const currentUser = getCurrentUser();
  const currentBookId = getCurrentBookId();

  /**
   * Validate the logged-in user ID matches the user ID in the decoded abilities
   * for newly created books and master pages.
   */
  if (currentBookAbilities.newBook || chapterTemplateView) {
    if (
      currentUser?._id &&
      decodedAbilities?.userId &&
      isEqual(decodedAbilities.userId, currentUser._id)
    ) {
      packedRules = decodedAbilities.rules;
    }
  }

  /**
   * For collaborated books, verify the user ID and book ID in the decoded abilities.
   */
  if (
    currentUser?._id &&
    currentBookId &&
    decodedAbilities?.userId &&
    decodedAbilities?.bookId
  ) {
    if (
      isEqual(decodedAbilities.userId, currentUser._id) &&
      isEqual(decodedAbilities.bookId, currentBookId)
    ) {
      packedRules = decodedAbilities.rules;
    }
  }

  // Unpack rules from packedRules and create an ability instance
  const rules = unpackRules(packedRules);

  return createMongoAbility(rules as any);
};

export const getSignedAbilitiesForNewBook = () => {
  return authStore.newBookToken || ""; 
};
