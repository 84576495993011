import React, { useState } from "react";
import { observer } from "mobx-react";

import { Button } from "antd";
import useRootStore from "../../store/useRootStore";

export const NotificationClearAll = observer(() => {
  const { clearAllNotification, notifications } = useRootStore().notificationStore;
  const [loading, toggleLoading] = useState(false);

  const handleClearAll = async () => {
    toggleLoading(true);
    try {
      await clearAllNotification();
    } finally {
      toggleLoading(false);
    }
  };

  return notifications.length > 0 
    ? <Button loading={loading} className="notification-clear-btn" size="small" type="text" onClick={handleClearAll}>{!loading ? "Clear All" : "Clearing"}</Button> 
    : null;
});