import { PlateEditor, Value } from "@udecode/plate";
import { getCommentNodeEntries } from "../queries";
import { CommentsRecord, TComment } from "../types";

export const getComments = <V extends Value>(
  editor: PlateEditor<V>
): TComment[] => {
  const comments: TComment[] = [];
  const commentIds: Set<string> = new Set();

  const nodeEntries = getCommentNodeEntries(editor);

  nodeEntries.forEach((nodeEntry) => {
    const [node, path] = nodeEntry;
    const nodeComments: CommentsRecord = node.comments || {};

    Object.values(nodeComments).forEach((comment) => {
      if (!commentIds.has(comment.id)) {
        comments.push(comment);
      }
    });
  });

  return comments;
};
