import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Redirect } from "react-router-dom";

import useRootStore from "../../store/useRootStore";

import StaticHeader from "../header/static";
import { ScrollContainer } from "../../components/Shared/Layouts";

const WithoutAuthComponent: React.FC = ({ children }) => (
  <>
    <StaticHeader />
    <ScrollContainer>
      <div className="auth-container">
        {children}
      </div>
    </ScrollContainer>
  </>
);

const LayoutWithoutAuth: React.FC = (props) => {
  const { token } = useRootStore().authStore;

  useEffect(() => {
    const handleValidToken = e => {
      // check if token has value
      if (e.key === "atticus-auth-token" && e.newValue) {
        // reload page to redirect
        window.location.reload();
      }
    };
    window.addEventListener("storage", handleValidToken);

    return function cleanup() {
      window.removeEventListener("storage", handleValidToken);
    };
  }, []);

  return token ? <Redirect to="/" /> : <WithoutAuthComponent {...props} />;
};

export const WithoutAuth = observer(LayoutWithoutAuth);

