import React from "react";
import { makeAutoObservable, observable, toJS, computed } from "mobx";
import { generate as generateRandomString } from "randomstring";
import { cloneDeep, debounce, findIndex } from "lodash";
import { Modal} from "antd";

//IndexedDB
import { db } from "../db/bookDb";

//helpers
import { removeKey, doSearchReplace, doKeywordCount } from "../utils/helper";
import { initBody, getPresentableChapterType } from "../utils/initials";
import { getWordsCount } from "../components/Plate/helpers";

// types
import { Chapter, ChapterMeta } from "../types/chapter";
// import { SyncStatus } from "../types/sync";

import { IChapterTemplateBase } from "../types/chapter";
// API
import { AtticusClient, ExportResponse } from "../api/atticus.api";

import { GetBookFromDB, GetChapterMetaFromDB, UpdateBookInDB, UpdateChapterMeta,
  GetChapterTemplates, UpdateChapterTemplateInDB,
  SaveErrorBook, GetErrorBook, SaveErrorBookChapter, GetErrorChapters } from "../utils/offline.book.helpers";
//SaveErrorBook, GetErrorBook, SaveErrorBookChapter, GetErrorChapters --- error visualixation
// import { syncBook } from "../utils/sync";
import { syncRemoteBookWithLocalDB } from "../utils/sync/helper";
import { MyRootBlock } from "../components/Plate/config/typescript";
import { ChapterMatterType, SectionType } from "../types/sidebar";
import { getBookEndnotes, getBookEndnotesByChapter } from "../utils/get-book-endnotes";
import { PdfChapterEndnotes, PdfEndnoteSubheading, PdfSlateEndnote } from "../components/Previewer/print/types";
import { SceneUtils } from "../utils/scene/sceneServices";
import { createPartChapter, createVolumeChapter, updateVolumeChapterMeta } from "../utils/volumes";
import { getAllChildrenIds } from "../utils/sidebar";
import { SceneMeta } from "../types/scene";
import { getLastDescendentIndex } from "../utils/chapter";
import SideMenu from "./SideMenu";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../utils/chapter";
import { getPlateChapterBodyForChapter, initializeNewYChapter, addYChapterContentToExistingChapter, replaceYChapterContent } from "../utils/y";
import { GetPlateChapterBodybyIdResponse } from "../types/sync";
import { authStore } from ".";
import { bookSyncWebSocketStore, chapterStore } from ".";
import { syncBookBaseData } from "../utils/sync";

export class BookStore {
  chapterLoading = false;
  //TODO:Verify Not needed ?
  // saving = SyncStatus.saved;
  failedBookLoading = false;
  toggleName = "";
  searchLevel = "chapter";
  extras: IChapterStore.ChapterExtra[] = [];
  searchParams : IBookStore.SearchParams = {
      q: "",
      caseSensitive: false,
      wholeWord: false
  };
  searchStep = 0;
  searchMatchedRanges: IBookStore.DecoratedRange[] = [];
  syncClose = true;
  allBody = {
    active: false,
    numbered: false,
    beginOn: ""
  }
  chapterOffContentMap = new Map();
  book: IBookStore.ExpandedBook = {
    _id: "",
    chapterIds: [],
    chapters: [],
    frontMatterIds: [],
    deletedChapterIds: [],
    frontMatter: [],
    language: "en",
    isbn: "",
    printISBN: "",
    ebookISBN: "",
    publisherName: "",
    publisherLogoURL: "",
    publisherLink: "",
    coverImageUrl: "",
    title: "",
    subtitle: "",
    project: "",
    author: [],
    modifiedAt: new Date(),
    themeId: ""
  }
  chapter: IChapterStore.ChapterMeta = {
    _id: "",
    bookId: "",
    image: "",
    subtitle: "",
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    startOn: "any",
    type: "chapter",
    index: 0,
  }

  // sceneIndex:number|null = null;
  // sceneTitle: string|null = null;
  // sceneCacheMap: { [chapterId: string]: SceneMeta[] } = {};

  chapterTemplate: IChapterTemplateBase ={
    _id: "",
    bookId: "",
    motherChapterId: "",
    type: "chapter",
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    section: "",
    image: "",
    index: 0,
    numbered: false,
    children: []
  }
  //TODO:Verify
  body : MyRootBlock[] = [];
  changeCount = 0;
  wordsCount = 0;
  wordCountType: IBookStore.wordCountType = "chapter";
  errorBookId: string[] = [];
  errorChapterId: string[] = [];
  errorBooks: IBookStore.ErrorBook[] = [];
  errorChapters: IBookStore.ErrorChapter[] = [];


  constructor() {
    makeAutoObservable(this, {
      body: observable.ref,
      chapter: observable.ref,
      wordsCount: observable.struct,
      searchParams: observable.deep,
      searchRange: computed,
    });
  }

  get searchRange() {
    return this.searchMatchedRanges[this.searchStep];
  }

  setBook = (book: IBookStore.ExpandedBook): void => {
    this.book = toJS(book);
  }

  setSearchParams = (s: IBookStore.SearchParams): void => {
    this.searchParams = s;
  }

  setSearcLevel = (s: string): void => {
    this.searchLevel = s;
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setSearchMatchedRanges = (r: IBookStore.DecoratedRange[]) => {
    this.searchMatchedRanges = r;
  }

  // setSelectedSceneIndex = (index:number|null):void=>{
  //   this.sceneIndex = index;
  //   this.setSceneTitle(index !== null ? SceneUtils.listScenes({...this.chapter, children:this.body})[index].sceneName : null);
  // }

  // setSceneTitle = (title:string|null):void=>{
  //   this.sceneTitle = title;
  // }

  setExtras = (list: IChapterStore.ChapterExtra[]) => {
    this.extras = list;
  }

  handleSearchNext = () => {
    if (this.searchStep >= this.searchMatchedRanges.length - 1) {
        this.setSearchStep(0);
    } else {
        this.setSearchStep(this.searchStep + 1);
    }
  };

  handleSearchPrevious = () => {
    if (this.searchStep === 0) {
        this.setSearchStep(this.searchMatchedRanges.length - 1);
    } else {
        this.setSearchStep(this.searchStep - 1);
    }
  };

  handleReplaceForBooks = async (term: string) => {
    const chapterIds =  this.book.chapterIds;
    const chapters = await this.getChapterById(chapterIds);

    const promises: Promise<unknown>[] = [];

    chapters.forEach((d, i) => {
        const occurences = doKeywordCount(d.children, this.searchParams);
        // Only do the replace if occurences are positive for the chapter
        if(occurences){
          const c = doSearchReplace(d.children, this.searchParams, term);
          //TODO:BODY
          // promises.push(this.saveChapterBodyUpdates(d._id, c));
        }
    });

    await Promise.all(promises);
    this.setExtras([]);
  }

  doSearchQuery = async () => {
    const chapters = await this.getChapterById(this.book.chapterIds);
    //TODO:Verify
    const list = chapters.map(d => ({id: d._id, extra: doKeywordCount(d.children, this.searchParams)}));
    this.setExtras(list);
  }

  doSearchSetCount = () => {
    if(this.searchLevel === "book" && this.searchParams.q.length > 0){
        this.debounceSearchQuery();
    } else {
        this.setExtras([]);
    }
  };

  debounceSearchQuery =  debounce(this.doSearchQuery, 1500);

  setSearchStep = (step: number) => {
    this.searchStep = step;
  }

  debounceSearch =  debounce(this.setSearchParams, 1000);

  // Error Visualization
  setErrorBook = async (book_id: string): Promise<void> => {
    this.errorBookId.push(book_id);
    await SaveErrorBook(this.errorBookId);
  };

  // Error Visualization
  setHomeErrorBook = (err: IBookStore.ErrorBook[]): void => {
    this.errorBooks = err;
  }

  // Error Visualization
  setFailedBookLoading = (loading: boolean): void => {
    this.failedBookLoading = loading;
  }

  // Error Visualization
  getErrorBook = async () : Promise<IBookStore.ErrorBook[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedBook = await GetErrorBook();

    if(failedBook) {
      this.setFailedBookLoading(true);
      this.setHomeErrorBook(failedBook);
    }
    return failedBook;
  }

  // Error Visualization
  setErrorChapter = async (chapter_id: string, book_id: string): Promise<void> => {
    this.errorChapterId.push(chapter_id);
    const allE = {
      _chapterId: chapter_id,
      _bookId: book_id
  };
    await SaveErrorBookChapter(allE);
  }

  // Error Visualization
  setHomeErrorChapter = (err: IBookStore.ErrorChapter[]): void => {
    this.errorChapters = err;
  }

  // Error Visualization
  getErrorChapter = async () : Promise<IBookStore.ErrorChapter[] | undefined> => {
    this.setFailedBookLoading(false);
    const failedChapters = await GetErrorChapters();

    if(failedChapters) {
      this.setFailedBookLoading(true);
      this.setHomeErrorChapter(failedChapters);
    }

    return failedChapters;
  }

  setAllBody = (allb: IChapterStore.AllBodyChapter) : void => {
    this.allBody = allb;
  }

  setChapter = (chapter: IChapterStore.ChapterMeta): void => {
    this.chapter = chapter;
  };

  setBody = (body: MyRootBlock[]): void => {
    this.body = body;
  }

  setWordsCount = (wordsCount: number): void => {
    this.wordsCount = wordsCount;
  }

  setWordCountType = (type: IBookStore.wordCountType): void => {
    this.wordCountType = type;
  }

  /** TODO:Verify Not needed? */
  // setSaving = (saving: SyncStatus): void => {
  //   this.saving = saving;
  // }

  setChapterLoading = (loading: boolean): void => {
    this.chapterLoading = loading;
  }

  // goal settiing
  setToggleButtonName = ( name: string): void => {
  this.toggleName = name;
  }

  setSyncErrorMessage = (close: boolean): void => {
    this.syncClose = close;
  }

  setChangeCount = (): void => {
    this.changeCount = this.changeCount + 1;
  }

  getCurrentBookId = (): string => {
    return this.book._id;
  }

  getCurrentBookAbilities = () => {
    const currentUser = authStore.getCurrentUser();

    return {
      newBook: this.book.isLocal,
      currentBookToken: this.book.abilities,
      newBookToken: authStore.newBookToken,
    };
  }

  chapterSetOfflineContent = (chapterId: string, hasOfflineContent: boolean) => {
    this.chapterOffContentMap.set(chapterId, hasOfflineContent);
  }

  chapterHasOfflineContent = (chapterId: string): undefined | boolean => {
    return this.chapterOffContentMap.get(chapterId);
  }

  // chapter template library
  setChapterTemplate = (chapterTemplate: IChapterStore.IChapterTemplateBase): void => {
    this.chapterTemplate = chapterTemplate;
  }

  next = (id: string, chapters: IChapterStore.ChapterMeta[]): void => {
    const index = chapters.map((d) => d._id).indexOf(id);

    if (chapters.length === 1) {
      this.getAndSetCurChapter(this.book.frontMatterIds[0]);
      return;
    }

    if (index < chapters.length - 1)
      this.getAndSetCurChapter(chapters[index + 1]._id);
    else this.getAndSetCurChapter(chapters[index - 1]._id);
  };

  getChapterMatterById = (id: string): ChapterMatterType => {
    if (this.book.frontMatterIds.includes(id)) return "frontMatter";
    return "body";
  };

  getAndSetCurBook = async (bookId: string): Promise<IBookStore.ExpandedBook | undefined> => {
    this.setChapterLoading(true);
    const book = await GetBookFromDB(bookId, true) as IBookStore.ExpandedBook | undefined;
    this.setChapterLoading(false);

    if (book) {
      this.setBook(book);
    }

    return book;
  }

  resetCurrentBook = () => {
    if(this.book._id) {
      this.getAndSetCurBook(this.book._id);
    }
  };

  getAndSetCurChapter = async (chapterId: string, sceneIndex?: number | null): Promise<void> => {
    const chapterMeta = await GetChapterMetaFromDB(chapterId);
    const chapterBody = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
    if (chapterMeta) {
      // this.sceneIndex = null;
      //TODO:Verify
      chapterStore.setChapterBody(chapterBody);
      // if (
      //   sceneIndex !== undefined &&  sceneIndex !== null &&
      //   this.sceneCacheMap[chapterId]?.length > sceneIndex
      // ) {
      //   this.sceneIndex = sceneIndex;
      //   this.sceneTitle = this.sceneCacheMap[chapterId][sceneIndex].sceneName;
      // }
      chapterStore.setChapterMeta(removeKey(chapterMeta, "children") as IChapterStore.ChapterMeta);
    }
  };

  // Chapter Template Library
  getAndSetChapterTemplate = async (templateId: string): Promise<void> => {
    this.setChapterLoading(true);
    const template = await GetChapterTemplates(templateId);
    if(template) {
      chapterStore.setChapterBody(template.children);
      this.setChapterTemplate(template);
      const {_id, bookId="", image, subtitle, title, titleScale, startOn="any", type, index=0} = template;
      chapterStore.setChapterMeta({_id, bookId, image, subtitle, title, titleScale, startOn, type, index});
      this.setChapterLoading(false);
    }
  }

  /**TODO:SYNC currently only considers chapter body sync */
  private compileChapter = async (
    chapterId: string,
    shouldSync = false
  ): Promise<IChapterStore.CompileChapterResponse> => {
    const chapterMeta = await GetChapterMetaFromDB(chapterId);
    if (!chapterMeta)
      throw new Error(
        "Could not find chapter meta in IDB for the given chapterId"
      );
    const chapterBodyResponse = await getPlateChapterBodyForChapter(
      chapterId,
      shouldSync
    );
    return {
      chapter: { ...chapterMeta, children: chapterBodyResponse.chapterBody },
      isClientAlreadyInSync: chapterBodyResponse.isClientAlreadyInSync
    };
  };

  //TODO:Verify
  /**
   * @param chapterIds An array of chapterIds for which the full chapters need to be returned
   * @returns A list of full chapters containing the chapter metas and the chapter bodies
   */
  getChapterById = async (
    chapterIds: string[]
  ): Promise<IChapterStore.Chapter[]> => {
    const promises: Promise<IChapterStore.CompileChapterResponse>[] = [];
    for (const chapterId of chapterIds) {
      promises.push(this.compileChapter(chapterId));
    }
    const compiledChapters = await Promise.all(promises);
    return compiledChapters.map((response) => response.chapter);
  };

   /**TODO:SYNC currently only considers chapter body sync */
  getChaptersForExports = async (
    chapterIds: string[],
    shouldSync = false
  ): Promise<IChapterStore.ExportChapter[]> => {
    const promises: Promise<IChapterStore.CompileChapterResponse>[] = [];
    for (const chapterId of chapterIds) {
      promises.push(this.compileChapter(chapterId, shouldSync));
    }
    const compiledChapters = await Promise.all(promises);
    const exportChapters:IChapterStore.ExportChapter[] = [];
    compiledChapters.map((response) => {
      const shouldRefreshCache = shouldSync ? !response.isClientAlreadyInSync : false;
      const refreshCacheEvent = shouldRefreshCache ? "chapter-contents-change" : undefined;
      exportChapters.push({
        chapter: response.chapter,
        shouldRefreshCache,
        refreshCacheEvent
      });
    });
    return exportChapters;
  };


  // Atomic sync functions
  syncChapterMetaChangesToServer = async (bookId: string, chapterId: string, updates: Partial<IChapterStore.ChapterMeta>): Promise<void> => {
    const newUpdates = { ...updates };
    delete newUpdates["__v"];

    if (newUpdates._id) delete newUpdates["_id"];
    if (newUpdates.bookId) delete newUpdates["bookId"];
    if (newUpdates.allChangesSynced !== undefined) delete newUpdates["allChangesSynced"];
    if (newUpdates.lastSuccessfulSync) delete newUpdates["lastSuccessfulSync"];
    if (newUpdates.fullpageImage && Object.keys(newUpdates.fullpageImage).length === 0)
      delete newUpdates["fullpageImage"];
    if ("st" in newUpdates) delete newUpdates["st"];
    if ("archived" in newUpdates) delete newUpdates["archived"];
    if (newUpdates.titleScale === null) newUpdates["titleScale"] = 100;
    try {
      //TODO:Verify Not needed
      // this.setSaving(SyncStatus.saving);
      const lastSyncAt = await AtticusClient.PatchChapter(bookId, chapterId, newUpdates);

      const batchPromise: Promise<unknown>[] = [];
      batchPromise.push(UpdateChapterMeta(chapterId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      }));

      await Promise.all(batchPromise);
    } catch (e: any) {
      if (e.message !== "Network Error"){
        this.saveSpanshot(bookId);
        Modal.destroyAll();
        // this.setErrorBook(bookId); <-----error vizualisation
        // this.setErrorChapter(chapterId, bookId);  <-----error vizualisation
        if(this.syncClose == true)
        this.countDown();
      }
      console.log(e);
    }
  }

  // Chapter Template Library
  syncChapterTemplateChangesToServer = async (templateId: string, updates: Partial<IChapterStore.IChapterTemplateBase>): Promise<void> => {
    const newUpdates = { ...updates };

    try {
      //TODO:Verify Not needed
      // this.setSaving(SyncStatus.saving);
      const lastSyncAt = await AtticusClient.PatchChapterTemplate(templateId, updates);

      const batchPromise: Promise<unknown>[] = [];

      batchPromise.push(UpdateChapterTemplateInDB(templateId, { ...updates, allChangesSynced: false }));

      await Promise.all(batchPromise);
      //TODO:Verify Not needed
      // this.setSaving(SyncStatus.saved);
    } catch (e: any) {
      //TODO:Verify Not needed
      // this.setSaving(SyncStatus.failed);
      if (e.message !== "Network Error"){
        this.saveSpanshot(templateId);
        Modal.destroyAll();
        // this.setErrorBook(bookId); <-----error vizualisation
        // this.setErrorChapter(chapterId, bookId);  <-----error vizualisation
        if(this.syncClose == true)
        this.countDown();
      }
      console.log(e);
    }
  }

  countDown = () => {
    this.setSyncErrorMessage(true);
    const secondsToGo = 4;
    const modal = Modal.error({
      title: "Oh no! We werent able to save your recent work to our server.",
      closable: true,
      okButtonProps: {
        style: {
          display: "none",
        },
      },
      width: 600,
      content: (
        <div>
        <h5>Use the link below to learn more about why this might happen and what you can do to resolve the error and save your work.</h5>
        <a target="_blank" href='https://www.atticus.io/troubleshooting-synching-errors/' rel="noreferrer">
            https://www.atticus.io/troubleshooting-synching-errors/
        </a>
        </div>
        ),
    });

    setTimeout(() => {
      modal.destroy();
      this.setSyncErrorMessage(false);
    }, secondsToGo * 1000);
  }

  syncNewChapterToServer = async (newChapter: IChapterStore.ChapterMeta): Promise<void> => {
    try {
      const lastSyncAt = await AtticusClient.PutChapter(newChapter);

      await UpdateChapterMeta(newChapter._id, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });

    } catch (e: any) {
      console.log(e.message);
    }
  }

  syncDeleteChapterToServer = async (bookId: string, chapterId: string): Promise<void> => {
    try {
      const lastSyncAt = await AtticusClient.DeleteChapter(bookId, chapterId);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });
    } catch (e: any) {
      throw new Error("Error syncDeleteChapterToServer");
    }
  }

  syncBookChangesToServer = async (bookId: string, changes: Partial<IBookStore.Book>): Promise<void> => {
    try {
      delete changes["__v"];
      delete changes["createdAt"];
      delete changes["lastUpdateAt"];

      //set flag for book sync
      const {setIsBookLocalUpdate} = bookSyncWebSocketStore;
      setIsBookLocalUpdate(true);

      const lastSyncAt = await AtticusClient.PatchBook(bookId, changes);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
      });
    } catch (e: any) {
      console.log("Book could not be synced to server", e.message);
    }
  }

  saveChapterBody = async (id: string, body: MyRootBlock[], skipStoreChapterUpdate = false): Promise<void> => {
    //TODO:Verify
    if (chapterStore.chapterMeta._id === id && !skipStoreChapterUpdate) {
      chapterStore.setChapterBody(body);
    }
    await Promise.all([
      replaceYChapterContent(id, body),
      UpdateBookInDB(this.book._id, { modifiedAt: new Date() })
    ]);
  }

  updateChapterBodyEditorOnChange = async(id: string, body: MyRootBlock[]): Promise<void> => {
    if (chapterStore.chapterMeta._id === id) {
      chapterStore.setChapterBody(body);
    }
  }

  // Chapter Template Library Debounced atomic sync functions
  debouncedSyncChapterTemplateChangesToServer = debounce(this.syncChapterTemplateChangesToServer, 1000);

  // Chapter Template Library
  saveChapterTemplateBodyUpdates = async (id: string, body: MyRootBlock[]): Promise<void> => {
    chapterStore.setChapterBody(body);
    // Persist changes
    const allPromises: Promise<void>[] = [];
    allPromises.push(UpdateChapterTemplateInDB(id, { children: body, allChangesSynced: false }));
    await Promise.all(allPromises);
    //ADD CHAPTER TEMPLATE SYNC
    this.debouncedSyncChapterTemplateChangesToServer(this.chapterTemplate._id, { children:  body });

  }

  saveChapterMetaUpdates = async (meta: IChapterStore.ChapterMeta, updateChapter = true, debounceServerSync = true): Promise<void> => {
    const { setIsChapterLocalUpdate, setIsChapterMetaSave } = bookSyncWebSocketStore;
    const fm: IChapterStore.ChapterMeta[] = this.book.frontMatter.map(d => d._id === meta._id ? { ...d, ...meta } : d);
    const ch: IChapterStore.ChapterMeta[] = this.book.chapters.map(d => d._id === meta._id ? { ...d, ...meta } : d);

    const book: IBookStore.ExpandedBook = {
      ...this.book,
      frontMatter: toJS(fm),
      frontMatterIds: toJS(this.book.frontMatterIds),

      chapters: toJS(ch),
      chapterIds: toJS(this.book.chapterIds),

      modifiedAt: new Date()
    };

    // Persist changes
    const allPromises: Promise<void>[] = [];
    allPromises.push(UpdateChapterMeta(meta._id, {
      title: meta.title,
      titleScale: meta.titleScale,
      subtitle: meta.subtitle,
      image: meta.image,
      type: meta.type,
      numbered: meta.numbered,
      includeIn: meta.includeIn,
      startOn: meta.startOn,
      templateId: meta.templateId,
      allChangesSynced: false,
      fullpageImage: toJS(meta.fullpageImage),
      parentChapterId: meta.parentChapterId,
      configuration: meta.configuration,
      volume: toJS(meta.volume),
      toc:toJS(meta.toc)
    }));

    allPromises.push(UpdateBookInDB(this.book._id, {
      modifiedAt: new Date(),
      frontMatterIds: book.frontMatterIds,
      chapterIds: book.chapterIds,
      allChangesSynced: false,
    }));

    await Promise.all(allPromises);

    this.setBook(book);

    // updateChapter Flag is set to untrue to retain state getting updated in a loop -- not an ideal solution, only a workaround
    if(updateChapter){
        chapterStore.setChapterMeta(meta);
    }

    const tmpMeta = { ...meta };
    delete tmpMeta["__v"];
    delete tmpMeta["children"];
    delete tmpMeta["createdAt"];
    delete tmpMeta["lastUpdateAt"];

    if (debounceServerSync) {
      await this.debouncedSyncChapterChangesToServer(book._id, meta._id, { ...tmpMeta })?.then(()=>{
        setIsChapterLocalUpdate(true);
        setIsChapterMetaSave(true);
      });
    } else {
      await this.syncChapterMetaChangesToServer(book._id, meta._id, { ...tmpMeta }).then(()=>{
        setIsChapterLocalUpdate(true);
        setIsChapterMetaSave(true);
      });
    }
    
    setIsChapterLocalUpdate(true);
    setIsChapterMetaSave(true);
  }

  addNewChapter = async (
    section: SectionType,
    type: IChapterStore.ChapterType,
    chapter?: Partial<IChapterStore.ChapterMeta>,
    body?: MyRootBlock[],
    insertAtEnd?: boolean
  ): Promise<IChapterStore.ChapterMeta | void> => {
    if (!this.book._id) return;
    const chapterBody = body ? cloneDeep(body) : initBody;
    const chapterId = chapter?._id ? chapter._id : generateRandomString(16);
    let chps: Array<string> = [];

    if (section === "frontMatter") {
      chps = this.book.frontMatterIds;
    }

    if (section === "body") {
      chps = this.book.chapterIds;
    }

    const lastDescendentIndex = getLastDescendentIndex(chapterStore.chapterMeta._id, this.book.chapters);
    const parentChapter = this.book.chapters.find(d => d._id === chapterStore.chapterMeta.parentChapterId);
    const insertIntoVolume = parentChapter?.type === "volume" || chapterStore.chapterMeta.type === "volume";
    let indx = insertAtEnd ? chps.length : (lastDescendentIndex + 1);

    // When the section is frontMatter & needs to insert into volume, insert at end of volume frontMatter
    if (section === "frontMatter" && insertIntoVolume) {
      const volumeChapter = parentChapter?.type === "volume" ? parentChapter : chapterStore.chapterMeta;
      const lastDescendentIndex = getLastDescendentIndex(volumeChapter._id, this.book.chapters);
      indx = lastDescendentIndex - (volumeChapter.volume?.bodyMatterIds.length || 0);
    }

    if (indx < 1) indx = chps.length + 1;

    const _c : IChapterStore.ChapterMeta = {
      _id: chapterId,
      bookId: this.book._id,
      title: type === "chapter" ? (chapter?.title? chapter.title : `Chapter ${indx}`) : getPresentableChapterType(type),
      subtitle: "",
      image: "",
      titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
      type: type,
      index: indx,
      startOn: "any",
      allChangesSynced: false,
      parentChapterId: ["volume", "part"].includes(chapterStore.chapterMeta.type) ? chapterStore.chapterMeta._id : parentChapter?._id,
    };

    const newChapterMeta: IChapterStore.ChapterMeta = chapter ? {
      ..._c,
      ...chapter
    } : _c;

    let updatedVolumeChapterMeta: IChapterStore.ChapterMeta | undefined;
    if (insertIntoVolume) {
      const volumeChapter = parentChapter?.type === "volume" ? parentChapter : chapterStore.chapterMeta;
      updatedVolumeChapterMeta = { ...volumeChapter };
      const volumeChapterIndex = this.book.chapters.findIndex(d => parentChapter && d._id ===  parentChapter._id);
      const frontMatterCount = volumeChapter.volume?.frontMatterIds ? volumeChapter.volume?.frontMatterIds.length : 0;
      const insertIndexInVolume = indx - volumeChapterIndex - 1;
      if (updatedVolumeChapterMeta.volume ===  undefined)
        throw ReferenceError();
      if (insertIndexInVolume <= frontMatterCount) {
        updatedVolumeChapterMeta.volume.frontMatterIds.splice(insertIndexInVolume, 0, chapterId);
      } else {
        updatedVolumeChapterMeta.volume.bodyMatterIds.splice(insertIndexInVolume - frontMatterCount, 0, chapterId);
      }
    }

    let frontMatter = {
      all: toJS(this.book.frontMatter),
      ids: toJS(this.book.frontMatterIds)
    };

    let chapters = {
      all: toJS(this.book.chapters),
      ids: toJS(this.book.chapterIds)
    };

    // frontMatter without inserting into volume is in book frontMatter
    if (section === "frontMatter" && !insertIntoVolume) {
      frontMatter = {
        all: this.addAfter(frontMatter.all, indx, newChapterMeta),
        ids: this.addAfter(frontMatter.ids, indx, newChapterMeta._id),
      };
    }

    // both body OR frontMatter with inserting into volume is going to be inside body chapters
    if (section === "body" || section === "frontMatter" && insertIntoVolume) {
      chapters = {
        all: this.addAfter(chapters.all, indx, newChapterMeta),
        ids: this.addAfter(chapters.ids, indx,  newChapterMeta._id)
      };

      if (updatedVolumeChapterMeta !== undefined) {
        chapters.all.splice(chapters.all.findIndex(d => d._id === updatedVolumeChapterMeta?._id), 1, updatedVolumeChapterMeta);
      }
    }

    const newBook = {
      ...this.book,
      frontMatter: frontMatter.all,
      chapters: chapters.all,
      frontMatterIds: frontMatter.ids,
      chapterIds: chapters.ids,
      modifiedAt: new Date(),
    };

    this.setBook(newBook);
    /**
     *  avoid focusing the newly created chapter for offline chapters
     */
    if(chapterId.indexOf("offline") === -1){
      // this.setSelectedSceneIndex(null);
      chapterStore.setChapterBody(chapterBody);
      chapterStore.setChapterMeta(newChapterMeta);
    }

    // array to store promises for db updates
    const dbPromises: Promise<unknown>[] = [];
    if (updatedVolumeChapterMeta !== undefined) {
      dbPromises.push(db.chapterMetas.update(updatedVolumeChapterMeta._id, { volume: toJS(updatedVolumeChapterMeta.volume) }));
    }
    dbPromises.push(db.chapterMetas.add(newChapterMeta));
    //TODO:Verify
    dbPromises.push(initializeNewYChapter(chapterId, chapterBody));
    dbPromises.push(UpdateBookInDB(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      allChangesSynced: false,
    }));

    await Promise.all(dbPromises);

    // array to store promises for chapter sync to server
    const serverSyncPromises: Promise<unknown>[] = [];

    await this.syncNewChapterToServer(newChapterMeta);

    if (updatedVolumeChapterMeta !== undefined) {
      serverSyncPromises.push(this.syncChapterMetaChangesToServer(this.book._id, updatedVolumeChapterMeta._id, { volume: toJS(updatedVolumeChapterMeta.volume) }));
    }

    serverSyncPromises.push(this.syncBookChangesToServer(newBook._id, {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
    }));

    await Promise.all(serverSyncPromises);

    return newChapterMeta;
  }
  addAfter = (array, index, newItem) => {
    return [
        ...array.slice(0, index),
        newItem,
        ...array.slice(index)
    ];
  }

  deleteChapter = async (id: string, next?: boolean): Promise<void> => {
    if (!this.book) return;

    const {chapters, deletedChapterIds = []} = this.book;

    const parentVolumeIndex = chapters.findIndex(
      ({ type, volume }) =>
        type === "volume" &&
        volume &&
        [...volume.bodyMatterIds, ...volume.frontMatterIds].includes(id)
    );

    if (parentVolumeIndex !== -1) {
      const volumeMeta = chapters[parentVolumeIndex];

      if (volumeMeta.volume) {
        const updatedVolumeMeta: ChapterMeta = {
          ...volumeMeta,
          volume: {
            ...volumeMeta.volume,
            frontMatterIds: volumeMeta.volume.frontMatterIds.filter(cId => cId !== id ),
            bodyMatterIds: volumeMeta.volume.bodyMatterIds.filter(cId => cId !== id ),
          },
        };
        chapters.splice(parentVolumeIndex, 1, updatedVolumeMeta);
      }
    }

    const newBook = {
      ...this.book,
      ...({ frontMatterIds: toJS(this.book.frontMatterIds.filter(c => c !== id)) }),
      ...({ frontMatter: toJS(this.book.frontMatter.filter(c => c._id !== id)) }),
      ...({ chapterIds: toJS(this.book.chapterIds.filter(c => c !== id)) }),
      ...({ chapters: toJS(chapters.filter(c => c._id !== id)) }),
      ...({ deletedChapterIds: toJS([...deletedChapterIds, id]) }),
      modifiedAt: new Date(),
    };

    const partialBookUpdates: Partial<IBookStore.Book> = {
      frontMatterIds: newBook.frontMatterIds,
      chapterIds: newBook.chapterIds,
      deletedChapterIds: newBook.deletedChapterIds,
      allChangesSynced: false
    };

    try {
      await this.syncDeleteChapterToServer(newBook._id, id);

      if (next) {
        this.next(id, toJS(this.book.chapters));
      }

      this.setBook(newBook);

      const allPromises: Promise<unknown>[] = [];
      allPromises.push(db.chapterMetas.where("_id").equals(id).delete());
      //TODO:DELETE
      // allPromises.push(db.chapterBodies.where("_id").equals(id).delete());
      allPromises.push(UpdateBookInDB(newBook._id, partialBookUpdates));

      if (parentVolumeIndex !== -1) {
        allPromises.push(db.chapterMetas.update(chapters[parentVolumeIndex]._id, { volume: toJS(chapters[parentVolumeIndex].volume) }));
        allPromises.push(this.syncChapterMetaChangesToServer(this.book._id, chapters[parentVolumeIndex]._id, { volume: toJS(chapters[parentVolumeIndex].volume) }));
      }

      await Promise.all(allPromises);
    } catch (e: any) {
      console.log(e);
      alert("Error deleting chapter");
    }
  }

  sortChapters = async (frontMatter: IChapterStore.ChapterMeta[], chapters: IChapterStore.ChapterMeta[]): Promise<void> => {
    const b = {
      ...this.book,
      // frontmatter

      frontMatter: toJS(frontMatter),
      frontMatterIds: toJS(frontMatter.map(d => d._id)),

      // body
      chapters: toJS(chapters),
      chapterIds: toJS(chapters.map(d => d._id)),

    };
    this.setBook(b);

    await UpdateBookInDB(b._id, {
      frontMatterIds: b.frontMatterIds,
      chapterIds: b.chapterIds,
      allChangesSynced: false,
    });
    await this.syncBookChangesToServer(b._id, {
      frontMatterIds: b.frontMatterIds,
      chapterIds: b.chapterIds,
    });
  }

  orderByIndex = (book: IChapterStore.ChapterMeta[]) => {
    return toJS(book).sort((a, b) => a.index - b.index).map((d, i) => ({
      ...d,
      index: i
    }));
  };

  mergeChapter = async (section: SectionType, id: string): Promise<void> => {
    let index = -1;
    let chapterToPreserve: IChapterStore.ChapterMeta | null = null;
    let chapterToDelete: IChapterStore.ChapterMeta | null = null;

    if (section === "frontMatter") {
      index = findIndex(this.book.frontMatter, { "_id": id });
      chapterToPreserve = this.book.frontMatter[index];
      chapterToDelete = this.book.frontMatter[index + 1];
    }

    if (section === "body") {
      index = findIndex(this.book.chapters, { "_id": id });
      chapterToPreserve = this.book.chapters[index];
      chapterToDelete = this.book.chapters[index + 1];
    }

    if (chapterToPreserve && chapterToDelete) {
      //TODO:Verify
      const chapterBodyToMerge = (await getPlateChapterBodyForChapter(chapterToDelete._id, true)).chapterBody;
      const titleNodes = [{ type: "h2", children: [{ text: chapterToDelete.title }] }] as MyRootBlock[];
      if (chapterToDelete.subtitle?.length) {
        titleNodes.push({ type: "h2", children: [{ text: chapterToDelete.subtitle }] });
      }
      const finalChapterBodyToMerge = [...chapterBodyToMerge, ...titleNodes];
      await addYChapterContentToExistingChapter(id, finalChapterBodyToMerge);
      await this.deleteChapter(chapterToDelete._id);
      this.setChangeCount();
    } else {
      alert("No next Chapter");
    }
  }

  getIdsbyMatter = (m: Array<"front" | "body">) => {
    let ids : Array<string> = [];
    if (m.includes("front"))
      ids = [...ids, ...this.book.frontMatterIds];
    if (m.includes("body"))
      ids = [...ids, ...this.book.chapterIds];

    return ids;
  }
  getBookBodies = async () => {
    const chapterIds = this.book.chapterIds.filter(d => d !== chapterStore.chapterMeta._id);
    const promises:Promise<GetPlateChapterBodybyIdResponse>[] = [];
    //TODO:Verify
    chapterIds.forEach(chapterId => {
      promises.push(getPlateChapterBodyForChapter(chapterId));
    });
    const allChapterBodies = await Promise.all(promises);
    const totalWordCound = allChapterBodies.reduce((total, getChapterBodyResponse) => {
      return total + getWordsCount(getChapterBodyResponse.chapterBody);
    }, 0);

    return {
      words: totalWordCound,
      ids: this.book.chapterIds
    };
  }

  getFullBookWordCount = async(): Promise<number> => {
    // Word count of all the chapters of the current book except the currently being edited chapter.
    const allChaptersExcludingCurrentlyActiveWordCount = await this.getBookBodies();

    const currentChapterWordCount = getWordsCount(chapterStore.chapterBody);

    const totalInitialWordCount =
      allChaptersExcludingCurrentlyActiveWordCount.words +
      currentChapterWordCount;

    return totalInitialWordCount;
  }

  debouncedSyncChapterChangesToServer = debounce(this.syncChapterMetaChangesToServer, 1000);
  /**
   * same as debouncedSyncChapterChangesToServer but has a longer debounce period
   * saved the chapter body in mongodb when its too large to be sent over sockets
   */
  //TODO:Verify Handle with scenes
  debouncedSyncChapterBodyToServer = debounce(this.syncChapterMetaChangesToServer, 3000);
  debouncedSaveChapterBody = debounce(this.saveChapterBody, 1000);
  debouncedUpdateChapterBodyEditorOnChange = debounce(this.updateChapterBodyEditorOnChange, 1000);
  debouncedSaveChapterMetaUpdates = debounce(this.saveChapterMetaUpdates, 400);
  debouncedSaveChapterTemplateBodyUpdates = debounce(this.saveChapterTemplateBodyUpdates, 600);
  // debouncedSaveChapterTemplateUpdates = debounce(this.saveChapterTemplateMetaUpdate, 400);

  //TODO:Verify Not needed
  // putOfflineChapter = async (id: string, title: string, body: MyRootBlock[]) => {
  //   const originChp = await GetChapterMetaFromDB(id);
  //   const _m = this.getChapterMatterById(id);
  //   if(originChp && ["uncategorized", "chapter", "custom"].includes(originChp.type)) {
  //       await this.addNewChapter(
  //           _m,
  //           originChp.type,
  //           {
  //               _id: id + `_offline_${Date.now()}`,
  //               title,
  //           },
  //           body
  //       );
  //   }
  // }

  //TODO:BODY Handle imports
  importChapters = async (fileURL: string, insertIntoVolume: boolean): Promise<string | undefined> => {
    try {
      const response = await AtticusClient.ImportChapters({
        url: fileURL,
        bookId: this.book._id,
        insertIntoVolume: insertIntoVolume,
      });
      //TODO:BODY Update chapter list ?
      await syncRemoteBookWithLocalDB(this.book._id);
      return response.bookId;
    } catch (e: any) {
      console.error(e.message);
      throw e;
    }
  };


  exportBook = async (bookId: string, type: "pdf" | "epub" | "docx"): Promise<ExportResponse> => {
    //TODO:BODY Do full booksync before export, make compatible with new epub gen logic
    // await syncBook(bookId);
    await syncBookBaseData(bookId);
    const resp = await AtticusClient.ExportBook(bookId, type);
    return resp;
  }

  //TODO:Verify
  getBookSnapshot = async (bookId: string): Promise<IBookStore.SnapshotBook | undefined> => {
    const book = await GetBookFromDB(bookId, true) as IBookStore.ExpandedBook | undefined;
    if(!book) return;
    const frontMatterPromises: Promise<IChapterStore.Chapter>[] = [];
    for(const chapter of book.frontMatter){
      const promise = new Promise<IChapterStore.Chapter>((resolve) => {
        getPlateChapterBodyForChapter(chapter._id, true).then(chapterBodyResponse => {
          resolve({...chapter, children: chapterBodyResponse.chapterBody} as IChapterStore.Chapter);
        });
        frontMatterPromises.push(promise);
      });
    }
    const frontMatter = await Promise.all(frontMatterPromises);
    const bodyChapterPromises: Promise<IChapterStore.Chapter>[] = [];
    for(const chapter of book.chapters){
      const promise = new Promise<IChapterStore.Chapter>((resolve) => {
        getPlateChapterBodyForChapter(chapter._id, true).then(chapterBodyResponse => {
          resolve({...chapter, children: chapterBodyResponse.chapterBody} as IChapterStore.Chapter);
        });
        bodyChapterPromises.push(promise);
      });
    }
    const chapters = await Promise.all(bodyChapterPromises);
    return { ...book, frontMatter, chapters };
  }

  saveSpanshot = async(bookId: string): Promise<boolean> => {
    const snapshot = await this.getBookSnapshot(bookId);

    if (snapshot) {
      await AtticusClient.SaveSnapshot(bookId, JSON.stringify(snapshot));
    }

    return true;
  }

  addNewChapterFromTemplate = async (
    chapterTemplate: IChapterTemplateBase,
    type: IChapterStore.ChapterType,
    section?: string
  ): Promise<void> => {
    if (!this.book._id) return;

    const chapter : Partial<IChapterStore.ChapterMeta> = {
      title: chapterTemplate.title,
      subtitle: chapterTemplate.subtitle,
      image: chapterTemplate.image,
      type: type,
      titleScale: chapterTemplate.titleScale,
      startOn: chapterTemplate.startOn || "any",
      templateId: chapterTemplate._id,
      numbered: chapterTemplate.numbered
    };

    const newChapter = await this.addNewChapter(
      section as SectionType,
      type,
      chapter,
    );
    
    if(newChapter) {
      await replaceYChapterContent(newChapter._id, chapterTemplate.children);
    }
    
  }

  getCurrentStoredBook = (): IBookStore.ExpandedBook => {
    return this.book;
  };

  groupChapters = async (selectedChapIds: string[], groupingMode: "volume" | "part"): Promise<IBookStore.ExpandedBook | null> => {

    const book = this.book;
    if (!book) return null;
    if (selectedChapIds.length <= 0) return null;

    const chapters = toJS(book.chapters);
    const chapterIds = toJS(book.chapterIds);

    // get the first index of the first selected chapter
    const insertPartAt = chapterIds.findIndex(chapId=> selectedChapIds.includes(chapId));
    // generate the id for the new parent chapter
    const parentChapterId = generateRandomString(16);

    const chaptersWithoutSelected = chapters.filter((r) => !selectedChapIds.includes(r._id));
    const childChapters = selectedChapIds.map((e) => ({ ...book.chapters.find((k) => k._id === e)  as IChapterStore.ChapterMeta  }));
    const updatedChildren = childChapters.map(r=>({...r,parentChapterId }));

    const params = {chapterId:parentChapterId, bookId: this.book._id, parentChapterId:childChapters[0].parentChapterId};

    const creationResult = groupingMode === "part" ? createPartChapter(params) : createVolumeChapter({ ...params, chapterIds: selectedChapIds,chapters });

    const { newChapters } = creationResult;

    let updatedChapters = [
      ...chaptersWithoutSelected.slice(0, insertPartAt),
      ...newChapters,
      ...updatedChildren,
      ...chaptersWithoutSelected.slice(insertPartAt),
    ];

    const { metaUpdatedChapters, volumeChapterIndex } = updateVolumeChapterMeta([...updatedChapters], newChapters[0], book._id);

    // need to check specifically for undefined since 0 can returned as index
    if (volumeChapterIndex !== undefined) {
      updatedChapters = metaUpdatedChapters;
    }

    const updatedChapterIds = updatedChapters.map((r) => r._id);

    const newBook = {
      ...this.book,
      chapters: toJS(updatedChapters),
      chapterIds: toJS(updatedChapterIds),
      modifiedAt: new Date(),
    };

    // update the in-memory book. this will trigger updates to side menu & other components
    this.setBook(newBook);
    chapterStore.setChapterMeta(newChapters[0]);

    // store promises for updates to the local db
    const promises: Promise<unknown>[] = [];

    for (let i = 0; i < newChapters.length; i++) {
      const { children, ...chapterMeta } = newChapters[i];
      promises.push(db.chapterMetas.add(chapterMeta));
      //TODO:Verify Handle this
      promises.push(initializeNewYChapter(chapterMeta._id, children));
    }

    for (const child of updatedChildren) {
      promises.push(db.chapterMetas.update(child._id, { parentChapterId }));
      //TODO:Verify -> is the below needed ?
      // promises.push(db.chapterBodies.update(child._id, { parentChapterId }));
    }

    if (volumeChapterIndex !== undefined) {
      const updatedVolumeChapter = updatedChapters[volumeChapterIndex];
      promises.push(db.chapterMetas.update(updatedVolumeChapter._id, { volume: updatedVolumeChapter.volume }));
    }

    // update book chapter ids in local db
    promises.push(
      UpdateBookInDB(newBook._id, {
        modifiedAt: new Date(),
        chapterIds: newBook.chapterIds,
        allChangesSynced: false,
      })
    );

    // wait till all updates to local db are executed
    await Promise.all(promises);

    // array to store promises for chapter sync to server
    const serverSyncPromises: Promise<unknown>[] = [];

    for (let i = 0; i < newChapters.length; i++) {
      serverSyncPromises.push(
        this.syncNewChapterToServer({ ...newChapters[i] })
      );
    }

    // wait till all chapters are synced
    await Promise.all(serverSyncPromises);

    await this.syncBookChangesToServer(newBook._id, { chapterIds: newBook.chapterIds });

    // array to store promises for the chapter updates to the server
    const chapterUpdates: Promise<unknown>[]  = [];

    for (const child of childChapters) {
      // add each update to array so we can batch them
      chapterUpdates.push(this.syncChapterMetaChangesToServer(book._id, child._id, { parentChapterId }));
    }

    if (volumeChapterIndex !== undefined) {
      const updatedVolumeChapter = updatedChapters[volumeChapterIndex];
      chapterUpdates.push(this.syncChapterMetaChangesToServer(book._id, updatedVolumeChapter._id, { volume: updatedVolumeChapter.volume  }));
    }

    await Promise.all(chapterUpdates);

    return newBook;
  }


  deleteChapterGroup = async (chapterId: string, preserveChildren: boolean) :Promise<IBookStore.ExpandedBook|undefined> => {
    if (!this.book) return;
    // parts or volumes can only be in body
    const deletedParentIndex = this.book.chapters.findIndex((c) => c._id === chapterId);
    const deletedParent = toJS(this.book.chapters[deletedParentIndex]);

    // if the chapter is not found or if its not a part or a volume return
    if (!deletedParent || !["part", "volume"].includes(deletedParent.type)) return;

    // mark chapterIds for deletion
    const chaptersToDelete: string[] = [];
    // metadata of the updated chapters
    const updatedChapterMeta:ChapterMeta[] = [];

    // util to filter out deleted chapters
    const dropChapters = (chaps: ChapterMeta[]) =>
      toJS(chaps).filter((c) => {
        if (c._id === chapterId) {
          chaptersToDelete.push(c._id);
          return false;
        }
        // delete toc chapter and title chapters of volumes independent of preserveChildren
        else if (c.parentChapterId === chapterId && (c.type === "toc" || c.type === "title")) {
          chaptersToDelete.push(c._id);
          return false;
        }
        // keep the children if the preserve children flag is true, else drop them
        else if (!preserveChildren && c.parentChapterId === chapterId) {
          chaptersToDelete.push(c._id);
          return false;
        } else {
          return true;
        }
      });

    const frontMatter = dropChapters(this.book.frontMatter);

    // drop the deleted chapters & update the metadata
    const chapters = dropChapters(this.book.chapters).map((c,i,chaps) => {

      const chap:ChapterMeta = { ...c };

      // if the deleted group had a parent (volume) update it's meta
      if (c._id === deletedParent.parentChapterId && c.type === "volume" &&  chap.volume) {
        const childrenIds =  getAllChildrenIds(chaps.slice(i),chap._id);
        // Only body matter of a volume can include a part so we need to update only the bodyMatter
        chap.volume = { ...chap.volume, bodyMatterIds: childrenIds.filter((id) => chap.volume?.frontMatterIds.includes(id)) };
        updatedChapterMeta.push(chap);

      } else if (c.parentChapterId === deletedParent._id) {
        // update the parentChapterIds of the children to the parent of the deleted chapter
        chap.parentChapterId = deletedParent.parentChapterId || this.book._id;
        updatedChapterMeta.push(chap);
      }

      return chap;
    });

    const newBook = {
      ...this.book,
      frontMatterIds: frontMatter.map((r) => r._id),
      frontMatter: frontMatter,
      chapterIds: chapters.map((r) => r._id),
      chapters: chapters,
      modifiedAt: new Date(),
    };


    // focus the next chapter before propagating any changes
    const newIndex = deletedParentIndex < chapters.length ? deletedParentIndex : chapters.length - 1;
    this.getAndSetCurChapter(chapters[newIndex]._id);

    this.setBook(newBook);

    const updatePromises: Promise<unknown>[] = [];
    for (let i = 0; i < updatedChapterMeta.length; i++) {
      updatePromises.push(this.saveChapterMetaUpdates(updatedChapterMeta[i], false, false));
    }
    await Promise.all(updatePromises);

    const deletionPromises: Promise<unknown>[] = [];

    for (let i = 0; i < chaptersToDelete.length; i++) {
      deletionPromises.push(db.chapterMetas.where("_id").equals(chaptersToDelete[i]).delete());
      //TODO:Delete
      // deletionPromises.push(db.chapterBodies.where("_id").equals(chaptersToDelete[i]).delete());
      deletionPromises.push(this.syncDeleteChapterToServer(newBook._id, chaptersToDelete[i]));
    }

    deletionPromises.push(UpdateBookInDB(newBook._id, { chapterIds: newBook.chapterIds, allChangesSynced: false }));

    await Promise.all(deletionPromises);

    return newBook;
  }

  getAllEndNotesOfBook = async (): Promise<(PdfSlateEndnote | PdfEndnoteSubheading)[]> => {
    const { frontMatterIds, chapterIds } = this.getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    const chapters = await this.getChapterById(allChapterIds);
    //TODO:Verify
    return getBookEndnotes(chapters);
  };

  getAllEndNotesByChapter = async (): Promise<(PdfChapterEndnotes)[]> => {
    const { frontMatterIds, chapterIds } = this.getCurrentStoredBook();
    const allChapterIds = [...frontMatterIds, ...chapterIds];
    // TODO:BODY Handle endnotes
    // const chapterData = await this.getChapterBodyById(allChapterIds);
    const chapterData = [];
    return getBookEndnotesByChapter(chapterData);
  };

  updateEndnotesChapter = async (updatedTheme: IThemeStore.Theme, theme:  IThemeStore.Theme, refreshCache: (bookId: string, event: IPDFCacheStore.PDFChangeEvent, data?: IPDFCacheStore.RefreshCacheData) => void): Promise<void> => {
    const { properties: styleProps } = theme;
    const { properties: updatedStyleProps } = updatedTheme;
    const isEndnotesChapterNeeded = updatedStyleProps.ePubNotesMode === "END_OF_BOOK" || updatedStyleProps.notesMode === "END_OF_BOOK";
    const book = this.book;
    const endnotesChapters = [...book.frontMatter, ...book.chapters]
      .filter((chapter) => chapter.type === "endnotes");
    const isEndnotesChapterPresent = endnotesChapters.length > 0;

    let includeIn: "all" | "ebook" | "print" | "none" = "none";
    if (updatedStyleProps.ePubNotesMode === "END_OF_BOOK" && updatedStyleProps.notesMode === "END_OF_BOOK") {
      includeIn = "all";
    } else if (updatedStyleProps.ePubNotesMode === "END_OF_BOOK") {
      includeIn = "ebook";
    } else if (updatedStyleProps.notesMode === "END_OF_BOOK") {
      includeIn = "print";
    }

    if (isEndnotesChapterNeeded && !isEndnotesChapterPresent) {
      // we need an endnotes chapter. but it's not available in the book right now
      // so we add new one
      SideMenu.resetSelectedChapters();
      const chapter = await this.addNewChapter("body", "endnotes", { includeIn }, undefined, true);
      if (chapter) {
        // Update the PDF cache
        const allChapterIds = [...book.frontMatterIds, ...book.chapterIds, chapter._id];
        const chapterData = await this.getChapterById(allChapterIds);
        const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
        refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData } });
      }
    }

    if (!isEndnotesChapterNeeded && isEndnotesChapterPresent) {
      // we don't need an endnotes chapter, but it is present in the book right now
      // so we delete it. A for loop is used instead of a simple single chapter deletion to
      // ensure that no multiple endnote chapters survive for long in a book, in the case
      // of break of the invariance where only a single endnotes chapter must be there
      for (const chapter of endnotesChapters) {
        await this.deleteChapter(chapter._id);

        const nonEndNoteChapters = book.chapterIds.filter(chapterId => chapterId !== chapter._id);

        const chapterToSwitchTo = nonEndNoteChapters.length > 0
          ? nonEndNoteChapters[0]
          : null;

        if (chapterToSwitchTo !== null) {
          SideMenu.selectChapter(chapterToSwitchTo);

          if (SideMenu.setFocusedChapter) {
            SideMenu.setFocusedChapter(chapterToSwitchTo);
          }
        } else {
          this.getAndSetCurChapter(book.frontMatterIds[0]);
        }

        // Update PDF cache
        const allChapterIds = [...book.frontMatterIds, ...book.chapterIds];
        const chapterData = await this.getChapterById(allChapterIds);
        const chapterCacheData = chapterData.map(({ _id, type, startOn, includeIn }) => ({ chapterId: _id, chapterType: type, startOn, includeIn } as IPDFCacheStore.ChapterCacheMetaData));
        refreshCache(book._id, "chapter-delete", { "chapter-delete": { chapterId: chapter._id, chapters: chapterCacheData } });
      }
    }

    if (isEndnotesChapterNeeded && isEndnotesChapterPresent) {
      // if the endnotes chapter is already there, but the note modes have changed, update chapter metadata
      if (styleProps.ePubNotesMode !== updatedStyleProps?.ePubNotesMode || styleProps.notesMode !== updatedStyleProps?.notesMode) {
        for (const chapter of endnotesChapters) {
          await this.debouncedSaveChapterMetaUpdates({ ...chapter, includeIn });
          refreshCache(book._id, "chapter-properties-change", {
            "chapter-properties-change": {
              chapter: {
                chapterId: chapter._id,
                chapterType: chapter.type,
                startOn: chapter.startOn,
                includeIn: includeIn, // updated includeIn
              }
            }
          });
        }
      }
    }
  };

  // public moveSceneBetweenChapters = async (fromChapterId: string, toChapterId: string, fromIndex: number, insertAfter?: number): Promise<void> => {
  //   let [srcChapter, destChapter] = await this.getChapterById([fromChapterId, toChapterId]);

  //   const toIndex = insertAfter !== undefined ? insertAfter + 1 : 0;
  //   const sceneMeta = this.sceneCacheMap[fromChapterId];
  //   const sceneContent = SceneUtils.getSceneContent(srcChapter, fromIndex);

  //   srcChapter = SceneUtils.removeScene(srcChapter, fromIndex);
  //   destChapter = SceneUtils.insertScene(destChapter, toIndex, sceneContent, sceneMeta[fromIndex]);

  //   const temp = cloneDeep(this.sceneCacheMap);

  //   temp[fromChapterId] = SceneUtils.listScenes(srcChapter);
  //   temp[toChapterId] = SceneUtils.listScenes(destChapter);

  //   this.sceneIndex = null;
  //   this.sceneCacheMap = temp;

  //   //TODO:Verify
  //   this.body = destChapter.children;
  //   this.chapter = removeKey(destChapter, "children") as IChapterStore.ChapterMeta;

  //   if ([fromChapterId, toChapterId].includes(this.chapter._id)) {
  //     this.setChangeCount();
  //   }

  //   await Promise.all([
  //     //TODO:Verify
  //     replaceYChapterContent(srcChapter._id, srcChapter.children),
  //     replaceYChapterContent(destChapter._id, destChapter.children),
  //     UpdateBookInDB(this.book._id, { modifiedAt: new Date() })
  //   ]);
  //   //TODO:Verify this is not needed as syncing to server also happens with the step above
  //   // await this.syncChapterChangesToServer(this.book._id, fromChapterId, { children: sChapter.children });
  //   // await this.syncChapterChangesToServer(this.book._id, toChapterId, { children: dChapter.children });
  // }


  // public moveSceneWithinChapter = async (chapterId: string, fromIndex: number, insertAfter?: number): Promise<void> => {

  //   const [parentChapter] = await this.getChapterById([chapterId]);
  //   const sceneMeta = cloneDeep(this.sceneCacheMap);

  //   const { chapter: updatedChapter, chapterScenes } = SceneUtils.moveScene(parentChapter, fromIndex, insertAfter);
  //   sceneMeta[chapterId] = chapterScenes;

  //   this.sceneIndex = null; //  This will de-select any selected scenes, so when the selectedChapter updates, it’s not going to try select an invalid scene
  //   this.sceneCacheMap = sceneMeta;
  //   this.body = updatedChapter.children;
  //   this.chapter = removeKey(updatedChapter, "children") as IChapterStore.ChapterMeta;

  //   if (chapterId == this.chapter._id) {
  //     this.setChangeCount();
  //   }

  //   await Promise.all([
  //     //TODO:Verify
  //     replaceYChapterContent(chapterId, updatedChapter.children),
  //     UpdateBookInDB(this.book._id, { modifiedAt: new Date() })
  //   ]);
  //   // TODO:Verify this is not needed anymore
  //   // await this.syncChapterChangesToServer(this.book._id, chapterId, { children: updatedChapter.children });

  // }

}

export default new BookStore();
