import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { getAppLevelFontFaceCss } from "../../utils/font";

export const FontLoader: React.FC = observer(() => {
  const { loadUserFavouriteFonts, userFavouriteFonts, googleFonts } = useRootStore().fontStore;
  const [fontFaceCss, setFontFaceCss] = useState("");
  const isOnline = useOnlineStatus();

  useEffect(() => {
    if (isOnline) {
      loadUserFavouriteFonts();
    }
  }, [isOnline]);

  useEffect(() => {
    setFontFaceCss(
      getAppLevelFontFaceCss([
        ...userFavouriteFonts,
        ...googleFonts
      ])
    );
  }, [userFavouriteFonts, googleFonts]);

  return (
    <style>{fontFaceCss}</style>
  );
});
