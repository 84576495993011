import { find } from "lodash";

import { AtticusClient } from "../../api/atticus.api";
import {
  GetAllThemesFromIDB,
  SaveThemesInIDB,
  DeleteThemeFromIndexedDB,
  SaveNewThemeInIDB,
  OverwriteThemeInIDB,
} from "../offline.book.helpers";

/**
 *  This is un-revamped code from the original sync implementation
 */
export async function syncThemes(): Promise<void> {
  // declare themes
  const offlineThemes = await GetAllThemesFromIDB();
  const serverThemes =  await AtticusClient.GetThemes();

  // Do nothing if there's no custom themes
  if (offlineThemes.length === 0 && serverThemes.length === 0) return;

  // if offline themes is empty save server themes to IDB
  if (!(offlineThemes.length > 0)) return await SaveThemesInIDB(serverThemes);

  // Do sync if both IDB and server has themes
  const allPromises: Promise<any>[] = [];

  // Check if the theme has been deleted in the server, if so, delete from IndexedDB
  const deletePromises: Promise<void>[] = [];

  // Identify themes to delete that are not collaborated
  offlineThemes.filter(theme => !theme.collaborated).forEach((offlineTheme) => {
      const onlineTheme = find(serverThemes, { _id: offlineTheme._id });

      if (!onlineTheme) {
        deletePromises.push(DeleteThemeFromIndexedDB(offlineTheme._id));
      }
  });

  await Promise.all(deletePromises);

  for (let i = 0; i < serverThemes.length; i++) {
    const offlineTheme = find(offlineThemes, { _id: serverThemes[i]._id });

    //if a new theme is available in server, save it to IDB
    if (!offlineTheme) allPromises.push(SaveNewThemeInIDB(serverThemes[i]));
    else {
      // check for allChangesSynced flag to save local offline custom theme changes to server
      if (!offlineTheme.allChangesSynced && !offlineTheme.isPredefinedTheme)
        allPromises.push(
          AtticusClient.SaveTheme(offlineTheme).then(
            async (resp) =>
              await OverwriteThemeInIDB(offlineTheme._id, {
                allChangesSynced: true,
              })
          )
        );

      // For pre-defined themes, the only allowed sync is favouriting
      if (!offlineTheme.allChangesSynced && offlineTheme.isPredefinedTheme) {
        const afterHook = () => {
          OverwriteThemeInIDB(offlineTheme._id, { allChangesSynced: true });
        };

        allPromises.push(
          offlineTheme.isFavourite
            ? AtticusClient.AddThemeToFavourites(offlineTheme._id).then(
                afterHook
              )
            : AtticusClient.RemoveThemeFromFavourites(offlineTheme._id).then(
                afterHook
              )
        );
      }
    }
  }

  await Promise.all(allPromises);
}
