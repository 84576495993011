import { Doc, encodeStateAsUpdate, encodeSnapshot, snapshot, applyUpdate, XmlText } from "yjs";
import { yTextToSlateElement, slateNodesToInsertDelta } from "@slate-yjs/core";
import { fromUint8Array, toUint8Array } from "js-base64";
import hash from "object-hash";
import { IDBInstace } from "@surge-global-engineering/y-indexeddb";

import { AtticusClient } from "../api/atticus.api";
import { getOnlineStatus } from "./hooks/isOffline";
import { MyRootBlock } from "../components/Plate/config/typescript";
import { GetPlateChapterBodybyIdResponse, YHTTPSyncReqPayload, SyncWithRemoteResponse } from "../types/sync";

/**
 * Sends the encoded client state as a single update message to the server
 * Receives updates that server has but the client doesn't along with the hash digest of the 
 * server doc, after applying the updates retrieved from the document state, sent by the client
 * The hash digest of the server is used to calculate if the client was already up-to-date before
 * applying the updates from the server. 
 * @param chapterId chapter id
 * @param clientDoc client ydoc
 * @returns {Uint8Array} Updates the server has but client doesn't
 * @returns {boolean} if client already up-to-date
 */
export const syncDocWithRemote = async(
  chapterId: string,
  clientDoc: Doc
): Promise<SyncWithRemoteResponse> => {
  const clientStateAsUpdate = encodeStateAsUpdate(clientDoc);
  const clientStatePayload = fromUint8Array(clientStateAsUpdate);
  const payload: YHTTPSyncReqPayload = {
    docName: chapterId,
    clientState: clientStatePayload,
  };
  const response = await AtticusClient.SyncDocument(payload);
  const serverHashDigest = response.serverHashDigest;
  const isClientAlreadyInSync = isEmptyUpdate(serverHashDigest, clientDoc);
  const serverDiff = toUint8Array(response.serverDiff);
  applyUpdate(clientDoc, serverDiff);
  return {
    serverDiff,
    isClientAlreadyInSync
  };
};

/**
 * Fetches chapter body updates in local DB in Uint8Array format and returns as an array of 
 * Plate nodes. Optionally, can sync the local DB state with server to fetch changes to the chapter
 * by remote clients
 * @param chapterId chapter id
 * @param [syncWithRemote = false] Sync local and remote updates for chapter before returning chapter body
 * @returns {MyRootBlock[]} Chapter body content as an array of plate nodes 
 * @returns {boolean} If client was already up-to-date, before syncing with the server (if syncWithRemote is set to true, otherwise defaults to false)
 */
export const getPlateChapterBodyForChapter = async(chapterId: string, syncWithRemote = false): Promise<GetPlateChapterBodybyIdResponse> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  let isClientAlreadyInSync = false;
  if(syncWithRemote){
    const isOnline = getOnlineStatus();
    if(isOnline){
      const clientState = encodeStateAsUpdate(ydoc);
      const syncResponse = await syncDocWithRemote(chapterId, ydoc);
      isClientAlreadyInSync = syncResponse.isClientAlreadyInSync;
      await idbInstance.updateDB(syncResponse.serverDiff);
      if(!isClientAlreadyInSync){
        console.log(`chapter body updated for chapter: ${chapterId}}\nis client in sync: ${isClientAlreadyInSync}\nclient state: ${clientState}\ndiff server: ${syncResponse.serverDiff}\n}`);
      }
    }else{
      console.error("Unable to sync chapter updates: No internet connection");
    }
  }
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  const chapterBodyAsNodes = yTextToSlateElement(contentShared).children;
  idbInstance.closeConnection();
  ydoc.destroy();
  return {
    chapterBody: chapterBodyAsNodes as unknown as MyRootBlock[],
    isClientAlreadyInSync : isClientAlreadyInSync
  };
};

/**
 * Creates a new Y chapter in local db and syncs with the remote server if connected to 
 * the internet
 * @param chapterId chapter id for the new chapter
 * @param chapterBody chapter body for the new chapter, in plate format
 */
export const initializeNewYChapter = async(chapterId: string, chapterBody: MyRootBlock[]): Promise<void> => {
  const ydoc = new Doc();
  const delta = slateNodesToInsertDelta(chapterBody);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if(isOnline){
    const syncResponse = await syncDocWithRemote(chapterId, ydoc);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
  ydoc.destroy();
};

/**
 * Replaces the Y chapter content in local db and syncs updates with the remote server if connected to 
 * the internet
 * Warning: This method of replacing content can conflict with how yjs conventionally track updates
 * and therefore might affect conflict resolution.
 * @param chapterId chapter id
 * @param newChapterBody chapter body to replace the original chapter body, in plate format
 */
export const replaceYChapterContent = async(chapterId: string, newChapterBody: MyRootBlock[]):Promise<void> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  //TODO: Do research on a more yjs friendly way of clearing a document.
  contentShared.delete(0, (contentShared.length));
  const delta = slateNodesToInsertDelta(newChapterBody);
  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if(isOnline){
    const syncResponse = await syncDocWithRemote(chapterId, ydoc);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
  ydoc.destroy();
};

/**
 * Adds new content to an existing chapter
 * @param chapterId chapter id to add new content
 * @param newContent the new chapter content to add, in plate format
 */
export const addYChapterContentToExistingChapter = async(chapterId: string, newContent: MyRootBlock[]):Promise<void> => {
  const idbInstance = new IDBInstace(chapterId);
  await idbInstance.initializeConnection();
  const ydoc = new Doc();
  await idbInstance.syncUpdatesFromDBToDoc(ydoc);
  const contentShared = ydoc.get("content", XmlText) as XmlText;
  const delta = slateNodesToInsertDelta(newContent);
  contentShared.applyDelta(delta);
  const docStateAsUpdate = encodeStateAsUpdate(ydoc);
  await idbInstance.updateDB(docStateAsUpdate);
  const isOnline = getOnlineStatus();
  if(isOnline){
    const syncResponse = await syncDocWithRemote(chapterId, ydoc);
    await idbInstance.updateDB(syncResponse.serverDiff);
  }
  idbInstance.closeConnection();
  ydoc.destroy();
};

/**
 * Compares the hashes of the client doc before applying updates from the server, and server doc after 
 * applying the updates from the client, to determine if client doc was already up-to-date and the diff 
 * sent by the server is empty
 * @param serverHashDigest 
 * @param clientDocBeforeUpdate 
 * @returns {boolean} Is diff sent by server empty
 */
export const isEmptyUpdate = (serverHashDigest: string, clientDocBeforeUpdate: Doc): boolean => {
  const clientSnapshotBeforeUpdate = encodeSnapshot(snapshot(clientDocBeforeUpdate));
  const clientDigestBeforeUpdate = hash(clientSnapshotBeforeUpdate);
  return clientDigestBeforeUpdate === serverHashDigest;
};