import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "antd";

import useRootStore from "../../../../../store/useRootStore";
import { TComment } from "../types";
import { CommentThread } from "./CommentThread";

interface CommentsProps {
  resolveComment?: (commentId: string) => void;
  submitReplyComment?: (replyValue: string) => void;
  deleteComment?: (comment: TComment) => void;
  updateComment?: (comment: TComment) => void;
  isResolvedComments: boolean;
}

export const Comments = observer(
  ({
    resolveComment,
    submitReplyComment,
    deleteComment,
    updateComment,
    isResolvedComments,
  }: CommentsProps) => {
    type CommentThread = {
      comment: TComment;
      replies: TComment[];
    };

    const { activeCommentId, comments, setActiveCommentId } =
      useRootStore().commentsStore;

    const { activeTrackChangeId, setActiveTrackChangeId } =
      useRootStore().commentsStore;

    const [commentThreads, setCommentThreads] = useState<CommentThread[]>([]);
    const threadContentRef = useRef<HTMLDivElement | null>(null);
    const [threadContentHeight, setThreadContentHeight] = useState<string | null>(null);
    const activeCommentRef = useRef<HTMLDivElement | null>(null);

    const groupCommentsByThread = (comments: TComment[]): CommentThread[] => {
      const threads: Record<string, CommentThread> = {};

      comments.forEach((comment) => {
        if (!comment.parentId) {
          threads[comment.id] = { comment: comment, replies: [] };
        }
      });

      comments.forEach((comment) => {
        if (comment.parentId && threads[comment.parentId]) {
          threads[comment.parentId].replies.push(comment);
        }
      });

      return Object.values(threads);
    };

    useEffect(() => {
      if (isResolvedComments) {
        setActiveCommentId(null);
      }
      const commentTheads = groupCommentsByThread(comments);

      const filteredComments = commentTheads.filter((thread) => {
        return isResolvedComments
          ? thread.comment.isResolved
          : !thread.comment.isResolved;
      });

      setCommentThreads(filteredComments);
    }, [comments, isResolvedComments]);

    const calculateHeight = () => {
      const bottomBarElement = document.querySelector(
        ".bottom-bar-wrapper"
      ) as HTMLElement;
      if (threadContentRef.current && bottomBarElement) {
        const threadContentTop = threadContentRef.current.getBoundingClientRect().top + window.scrollY;
        const bottomBarHeight = bottomBarElement.getBoundingClientRect().height;
        const availableHeight = `calc(100vh - ${ threadContentTop + bottomBarHeight }px)`;
        setThreadContentHeight(availableHeight);
      }
    };
    useEffect(() => {
      if(activeTrackChangeId){
        setActiveTrackChangeId(null);
      }

      // Calculate height of the comment thread content wrapper on mount and on window resize
      calculateHeight();
      window.addEventListener("resize", calculateHeight);

      return () => {
        window.removeEventListener("resize", calculateHeight);
      };
    }, []);

    useEffect(() => {
      if (activeCommentId && activeCommentRef.current) {
        activeCommentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, [activeCommentId]);

    return (
      <>
        <Row
          className="comment-thread-wrapper"
          ref={threadContentRef}
          style={{ height: `${threadContentHeight}` }}
        >
          <Col span={24}>
            {commentThreads.map((thread) => (
              <CommentThread
                key={thread.comment.id}
                resolveComment={resolveComment}
                submitReplyComment={submitReplyComment}
                deleteComment={deleteComment}
                updateComment={updateComment}
                thread={thread}
            />
            ))}
          </Col>
        </Row>
      </>
    );
  }
);
