import {
  isElement,
  removeNodes,
  PlateEditor,
  TNode,
  TDescendant,
  insertNodes,
  setNodes,
  moveNodes,
  MoveNodesOptions,
  SetNodesOptions,
  TElement,
  TNodeProps,
} from "@udecode/plate";
import {
  BaseEditor,
  Editor,
  EditorNodesOptions,
  Element,
  NodeEntry,
  Node,
} from "slate";

export const getNode = <T extends Node>(
  editor: PlateEditor,
  nodeOptions: EditorNodesOptions<T>
): NodeEntry<T> | undefined => {
  const matchingNodes: NodeEntry<T>[] = [];
  for (const entry of Editor.nodes<T>(editor as BaseEditor, {
    ...nodeOptions,
    at: nodeOptions.at || [],
  })) {
    matchingNodes.push(entry);
  }
  return matchingNodes[0];
};

export const moveNode = (
  editor: PlateEditor,
  options: MoveNodesOptions
): void => {
  return moveNodes(editor, options);
};

export const removeNode = (
  editor: PlateEditor,
  node: NodeEntry<Element>
): void => {
  removeNodes(editor, {
    at: node[1],
  });
};

export const updateNode = <T extends TElement>(
  editor: PlateEditor,
  newProperties: Partial<TNodeProps<T>>,
  options: SetNodesOptions
): void => {
  setNodes<T>(editor, newProperties, options);
};

export const getSiblingNodes = <T extends TNode, S extends TNode>(
  editor: PlateEditor,
  node: NodeEntry<T>,
  siblingType: NodeEntry<S>[0]["type"]
): {
  previous: NodeEntry<S> | undefined;
  next: NodeEntry<S> | undefined;
} => {
  const prevSibling = Editor.previous<S>(editor as BaseEditor, {
    at: node[1],
    match: (n) => isElement(n) && n.type === siblingType,
  });
  const nextSibling = Editor.next<S>(editor as BaseEditor, {
    at: node[1],
    match: (n) => isElement(n) && n.type === siblingType,
  });
  return {
    previous: prevSibling,
    next: nextSibling,
  };
};

export const injectChildrenIntoNode = <T extends TNode>(
  editor: PlateEditor,
  parent: NodeEntry<T>,
  children: TDescendant[],
  offset = 0
): void => {
  insertNodes(editor, children, {
    at: { path: parent[1], offset },
  });
};
