import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "antd";
import { Value } from "@udecode/plate";

import useRootStore from "../../../../../store/useRootStore";

import { TTrackChange, TrackChangeReply } from "../../track-changes/types";
import { TrackChangeThread } from "./TrackChangeThread";

interface TrackChangesProps {

  approveTrackChange?: (trackChange: TTrackChange[]) => void;
  declineTrackChange?: (trackChange: TTrackChange[]) => void;
  submitReplyTrackChange?: (replyValue: Value, trackChange: TTrackChange) => void;
  updateTrackChangeReply?:(trackChangeNode: TTrackChange, updatedReply: string, reply: TrackChangeReply)=> void;
  deleteReply?:(trackChange: TTrackChange, reply:TrackChangeReply)=> void

}

export const TrackChanges = observer(
  ({
    approveTrackChange,
    declineTrackChange,
    submitReplyTrackChange,
    updateTrackChangeReply,
    deleteReply,
  }: TrackChangesProps) => {
   
    const { trackChanges } = useRootStore().trackChangesStore;
    const { activeCommentId, setActiveCommentId } = useRootStore().commentsStore;

    const threadContentRef = useRef<HTMLDivElement | null>(null);
    const [threadContentHeight, setThreadContentHeight] = useState<string | null>(null);

    const calculateHeight = () => {
      const bottomBarElement = document.querySelector(
        ".bottom-bar-wrapper"
      ) as HTMLElement;
      if (threadContentRef.current && bottomBarElement) {
        const threadContentTop = threadContentRef.current.getBoundingClientRect().top + window.scrollY;
        const bottomBarHeight = bottomBarElement.getBoundingClientRect().height;
        const availableHeight = `calc(100vh - ${ threadContentTop + bottomBarHeight }px)`;
        setThreadContentHeight(availableHeight);
      }
    };
    useEffect(() => {
      if(activeCommentId){
        setActiveCommentId(null);
      }
      // Calculate height of the track-change thread content wrapper on mount and on window resize
      calculateHeight();
      window.addEventListener("resize", calculateHeight);

      return () => {
        window.removeEventListener("resize", calculateHeight);
      };
    }, []);

    return (
      <>
        <Row
          className="track-change-thread-wrapper"
          ref={threadContentRef}
          style={{ height: `${threadContentHeight}` }}
        >
          <Col span={24}>
            {(trackChanges as TTrackChange[][]).map((thread, i) => (
              <TrackChangeThread
                approveTrackChange={approveTrackChange}
                declineTrackChange={declineTrackChange}
                submitReplyTrackChange={submitReplyTrackChange}
                updateTrackChangeReply={updateTrackChangeReply}
                deleteReply={deleteReply}
                thread={thread}
                key={i}
              />
            ))}
          </Col>
        </Row>
      </>
    );
  }
);
