import {
  AutoformatRule,
  createPlateEditor,
  CreatePlateEditorOptions,
  createPluginFactory,
  createPlugins,
  createTEditor,
  Decorate,
  DecorateEntry,
  DOMHandler,
  EDescendant,
  EElement,
  EElementEntry,
  EElementOrText,
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_CODE_LINE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_MENTION,
  ELEMENT_MENTION_INPUT,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TABLE,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  ELEMENT_TR,
  ELEMENT_UL,
  EMarks,
  ENode,
  ENodeEntry,
  EText,
  ETextEntry,
//   getPlateActions,
//   getPlateEditorRef,
//   getPlateSelectors,
  getTEditor,
  InjectComponent,
  InjectProps,
  KeyboardHandler,
  NoInfer,
  OnChange,
  OverrideByKey,
  PlateEditor,
  PlatePlugin,
  PlatePluginInsertData,
  PlatePluginProps,
  PlateProps,
  PluginOptions,
  SerializeHtml,
  TElement,
  TImageElement,
  TMediaEmbedElement,
  TMentionElement,
  TMentionInputElement,
  TNodeEntry,
  TReactEditor,
  TTableElement,
  TText,
  TTodoListItemElement,
  useEditorRef,
  useEditorState,
  usePlateEditorRef,
  usePlateEditorState,
  usePlateSelectors,
  WithOverride,
  PlatePluginComponent,
} from "@udecode/plate";
import { CSSProperties } from "styled-components";
import {
  ELEMENT_ALIGN_CENTER,
  ELEMENT_ALIGN_RIGHT,
} from "./../plugins/alignment";
import { ELEMENT_SCENE, ELEMENT_ORNAMENTAL_BREAK } from "../plugins/ornamental-break";


/**
 * Text
 */

export type EmptyText = {
  text: "";
};

export type PlainText = {
  text: string;
};

export interface RichText extends TText {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  code?: boolean;
  kbd?: boolean;
  subscript?: boolean;
  backgroundColor?: CSSProperties["backgroundColor"];
  fontFamily?: CSSProperties["fontFamily"];
  color?: CSSProperties["color"];
  fontSize?: CSSProperties["fontSize"];
  fontWeight?: CSSProperties["fontWeight"];
}

/**
 * Inline Elements
 */

export type LinkType = "internal-link" | "web-link" | "booklinker"

export interface TabbedLinkComponentProps {
  value: string
  type: LinkType
  error?: string
  isFromEditLink?:boolean
  setType(type: LinkType): void
  setValue(value: string): void
}

export interface SelectElementInternalLink {
  value: string
  children: string
}

export interface TLinkElement extends TElement {
  url: string
  linkType?: LinkType
}


export interface MyMentionInputElement extends TMentionInputElement {
  type: typeof ELEMENT_MENTION_INPUT;
  children: [PlainText];
}

export interface MyMentionElement extends TMentionElement {
  type: typeof ELEMENT_MENTION;
  children: [EmptyText];
}

export type MyInlineElement =
  | TLinkElement
  | MyMentionElement
  | MyMentionInputElement;
export type MyInlineDescendant = MyInlineElement | RichText;
export type MyInlineChildren = MyInlineDescendant[];

/**
 * Block props
 */

export interface MyIndentProps {
  indent?: number;
}

export interface MyIndentListProps extends MyIndentProps {
  listStart?: number;
  listStyleType?: string;
}

export interface MyLineHeightProps {
  lineHeight?: CSSProperties["lineHeight"];
}

export interface MyAlignProps {
  textAlign?: CSSProperties["textAlign"];
}

export interface MyBlockElement
  extends TElement,
    MyIndentListProps,
    MyLineHeightProps {
  id?: string;
}

/**
 * Blocks
 */

export interface MyParagraphElement extends MyBlockElement {
  type: typeof ELEMENT_PARAGRAPH;
  children: MyInlineChildren;
}

export interface MyH1Element extends MyBlockElement {
  type: typeof ELEMENT_H1;
  children: MyInlineChildren;
}

export interface MyH2Element extends MyBlockElement {
  type: typeof ELEMENT_H2;
  children: MyInlineChildren;
}

export interface MyH3Element extends MyBlockElement {
  type: typeof ELEMENT_H3;
  children: MyInlineChildren;
}

export interface MyBlockquoteElement extends MyBlockElement {
  type: typeof ELEMENT_BLOCKQUOTE;
  children: MyInlineChildren;
}

export interface MyCodeBlockElement extends MyBlockElement {
  type: typeof ELEMENT_CODE_BLOCK;
  children: MyCodeLineElement[];
}

export interface MyCodeLineElement extends TElement {
  type: typeof ELEMENT_CODE_LINE;
  children: PlainText[];
}

export interface MyTableElement extends TTableElement, MyBlockElement {
  type: typeof ELEMENT_TABLE;
  children: MyTableRowElement[];
}

export interface MyTableRowElement extends TElement {
  type: typeof ELEMENT_TR;
  children: MyTableCellElement[];
}

export interface MyTableCellElement extends TElement {
  type: typeof ELEMENT_TD;
  children: MyNestableBlock[];
}

export interface MyBulletedListElement extends TElement, MyBlockElement {
  type: typeof ELEMENT_UL;
  children: MyListItemElement[];
}

export interface MyNumberedListElement extends TElement, MyBlockElement {
  type: typeof ELEMENT_OL;
  children: MyListItemElement[];
}

export interface MyListItemElement extends TElement, MyBlockElement {
  type: typeof ELEMENT_LI;
  children: MyInlineChildren;
}

export interface MyTodoListElement
  extends TTodoListItemElement,
    MyBlockElement {
  type: typeof ELEMENT_TODO_LI;
  children: MyInlineChildren;
}

export type ImageSize = "small" | "medium" | "large" | number;
export type ImageFlow = "left" | "middle" | "right";
export type ImageLinkType = "web-link" | "internal-link" | "none";

export type ImageNode = {
  url: string;
  caption: string;
  flow: ImageFlow;
  size: ImageSize;
  /**
   * An internal or external link
   */
  link?: ImageLink;
  /**
   * Wrap image
   */
  wrap?: boolean;
  separatePage?: boolean;
};

export type ImageLink = {
  type: ImageLinkType;
  webLink?: string;
  internalLink?: {
    chapterId: string;
  };
};

export interface MyImageElement extends ImageNode, MyBlockElement {
  type: typeof ELEMENT_IMAGE;
  children: [EmptyText];
}

export interface MyMediaEmbedElement
  extends TMediaEmbedElement,
    MyBlockElement {
  type: typeof ELEMENT_MEDIA_EMBED;
  children: [EmptyText];
}

export interface MyHrElement extends MyBlockElement {
  type: typeof ELEMENT_HR;
  children: [EmptyText];
}

export type MyNestableBlock = MyParagraphElement;

export type MyBlock = Exclude<MyElement, MyInlineElement>;
export type MyBlockEntry = TNodeEntry<MyBlock>;

export interface MyAlignElement extends MyBlockElement {
  type: typeof ELEMENT_ALIGN_CENTER | typeof ELEMENT_ALIGN_RIGHT;
}

export interface MySceneElement extends TElement {
  id: number;
  type: typeof ELEMENT_SCENE;
  sceneIndex: number;
  sceneTitle?: string;
}

export interface MyOrnamentalBreakElement extends TElement {
  id: number;
  type: typeof ELEMENT_ORNAMENTAL_BREAK;
}

export type MyRootBlock =
  | MyParagraphElement
  | MyH1Element
  | MyH2Element
  | MyH3Element
  | MyBlockquoteElement
  | MyCodeBlockElement
  | MyTableElement
  | MyBulletedListElement
  | MyNumberedListElement
  | MyTodoListElement
  | MyImageElement
  | MyMediaEmbedElement
  | MyHrElement
  | MyAlignElement;

export type PlateNodes = MyRootBlock[];
export type ATNode = {
    type?: string,
    children: PlateNodes,
    [key: string]: unknown
}[]

/**
 * Editor types
 */

export type MyEditor = PlateEditor<PlateNodes> & { isDragging?: boolean };
export type MyReactEditor = TReactEditor<PlateNodes>;
export type MyNode = ENode<PlateNodes>;
export type MyNodeEntry = ENodeEntry<PlateNodes>;
export type MyElement = EElement<PlateNodes>;
export type MyElementEntry = EElementEntry<PlateNodes>;
export type MyText = EText<PlateNodes>;
export type MyTextEntry = ETextEntry<PlateNodes>;
export type MyElementOrText = EElementOrText<PlateNodes>;
export type MyDescendant = EDescendant<PlateNodes>;
export type MyMarks = EMarks<PlateNodes>;
export type MyMark = keyof MyMarks;

/**
 * Plate types
 */

export type MyDecorate<P = PluginOptions> = Decorate<P, PlateNodes, MyEditor>;
export type MyDecorateEntry = DecorateEntry<PlateNodes>;
export type MyDOMHandler<P = PluginOptions> = DOMHandler<P, PlateNodes, MyEditor>;
export type MyInjectComponent = InjectComponent<PlateNodes>;
export type MyInjectProps = InjectProps<PlateNodes>;
export type MyKeyboardHandler<P = PluginOptions> = KeyboardHandler<
  P,
  PlateNodes,
  MyEditor
>;
export type MyOnChange<P = PluginOptions> = OnChange<P, PlateNodes, MyEditor>;
export type MyOverrideByKey = OverrideByKey<PlateNodes, MyEditor>;
export type MyPlatePlugin<P = PluginOptions> = PlatePlugin<
  P,
  PlateNodes,
  MyEditor
>;
export type MyPlatePluginInsertData = PlatePluginInsertData<PlateNodes>;
export type MyPlatePluginProps = PlatePluginProps<PlateNodes>;
export type MyPlateProps = PlateProps<PlateNodes, MyEditor>;
export type MySerializeHtml = SerializeHtml<PlateNodes>;
export type MyWithOverride<P = PluginOptions> = WithOverride<
  P,
  PlateNodes,
  MyEditor
>;

/**
 * Plate store, Slate context
 */

export const getMyEditor = (editor: MyEditor) =>
  getTEditor<PlateNodes, MyEditor>(editor);
export const useMyEditorRef = () => useEditorRef<PlateNodes, MyEditor>();
export const useMyEditorState = () => useEditorState<PlateNodes, MyEditor>();
export const useMyPlateEditorRef = (id?: string) =>
  usePlateEditorRef<PlateNodes, MyEditor>(id);
export const useMyPlateEditorState = (id?: string) =>
  usePlateEditorState<PlateNodes, MyEditor>(id);
export const useMyPlateSelectors = (id?: string) =>
  usePlateSelectors<PlateNodes, MyEditor>(id);

/**
 * Utils
 */
export const createMyEditor = () => createTEditor() as MyEditor;
export const createMyPlateEditor = (
  options: CreatePlateEditorOptions<PlateNodes, MyEditor> = {}
) => createPlateEditor<PlateNodes, MyEditor>(options);
export const createMyPluginFactory = <P = PluginOptions>(
  defaultPlugin: PlatePlugin<NoInfer<P>, PlateNodes, MyEditor>
) => createPluginFactory(defaultPlugin);
export const createMyPlugins = (
  plugins: MyPlatePlugin[],
  options?: {
    components?: Record<string, PlatePluginComponent>;
    overrideByKey?: MyOverrideByKey;
  }
) => createPlugins<PlateNodes, MyEditor>(plugins, options);

export type MyAutoformatRule = AutoformatRule<PlateNodes, MyEditor>;
