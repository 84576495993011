import { toWords as numbersToWord } from "number-to-words";
import {
  themeProperties,
} from "../configs/prebuilt-theme-properties";
import { StyleSheet } from "@surge-global-engineering/rpdf-renderer";
import { getThemeConfigsForChapterTypes } from "../configs/chapter-theme-configs";
import { PdfTheme, PdfChapterType, PdfChapter, PdfExpandedBook, PdfThemeProperties } from "../types";
import { flatten } from "lodash";
import { ADDITIONAL_CHAPTER_MARGINS, CM_FACTOR, fontsData, ChapterTypeExtraStyleConfig } from "../configs";
import { Alignment, FontVariant, HeaderFontStyle, HeaderImage, ThemeStyleProps, getNumericPartFromVariant, headerStyleToFontVariant } from "@surge-global-engineering/css-generator";
import { TextNodeProps } from "@surge-global-engineering/react-pdf-components";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../../../../utils/chapter";


interface TextStylesProperties {
  font: string;
  size: number;
  align: "left" | "center" | "right";
  style: IThemeStore.HeaderFontStyle[];
  width: number;
}

type TitleType = "chapterTitle" | "chapterNo" | "chapterSubtitle"


const getChapterNumberLabel = (type: string | null, chapterNumber?: number) => {
  if (!chapterNumber) return null;
  switch (type) {
    case "number":
      return `${chapterNumber}`;
    case "chapter+number":
      return `Chapter ${chapterNumber}`;
    case "word":
      return `${numbersToWord(chapterNumber)}`;
    case "chapter+word":
      return `Chapter ${numbersToWord(chapterNumber)}`;
    default:
      return null;
  }
};

const chapterNumberToTitleCase = (sentence: string) => {
  if (!sentence) return sentence;
  const words = sentence.split(/\s+/);
  return words
    .map(
      (word) => {
        if (word.indexOf("-") == -1)
          return `${word.charAt(0).toUpperCase()}${word.substr(1).toLowerCase()}`;

        return word.split("-").map((_word) => `${_word.charAt(0).toUpperCase()}${_word.substr(1).toLowerCase()}`).join("-");
      }
    )
    .join(" ");
};


const isPrebuiltTheme = (themeId: string) => {
  return Object.keys(themeProperties).includes(themeId);
};

const centimetersToPt = (cm: number): number => {
  return cm * CM_FACTOR;
};

const mapHeaderFooterFontSize = (size?: number, baseFontSize?: number) => {
  if (!size) {
    return baseFontSize || "8pt";
  }
  return `${4 + 2 * size}pt`;
};

const withoutUnit = (value: string, unit: string): number => {
  return parseFloat(value.replace(unit, ""));
};

const prepareThemeConfigForChapterType = (theme: PdfTheme, chapterType: PdfChapterType) => {
  const {
    titleCard: cTitleCard,
    paragraph: cParagraph,
    firstParagraph: cFirstParagraph,
    trim: cTrim,
    margin: cMargin,
    baseFontSize: cBaseFontSize
  } = getThemeConfigsForChapterTypes(chapterType);
  const { properties: themeProperties } = theme;
  const { titleCard, paragraph, firstParagraph, trim, margin, baseFontSize } = themeProperties;
  return {
    ...theme,
    properties: {
      ...themeProperties,
      titleCard: { ...titleCard, ...cTitleCard },
      paragraph: { ...paragraph, ...cParagraph },
      firstParagraph: { ...firstParagraph, ...cFirstParagraph },
      trim: { ...trim, ...cTrim },
      margin: { ...margin, ...cMargin },
      baseFontSize: cBaseFontSize ? cBaseFontSize : baseFontSize
    }
  };
};


const chapterFormatter = (child) => {
  if (child.type === "h2") {
    const childrenObject: any = child.children;
    if (
      childrenObject.length === 1 &&
      childrenObject[0].text === ""
    ) {
      child.removeOnFilter = true;
    }
  }

  if (child.type === "p") {
    // Intentional line breaks are structured this way.
    const childrenObject: any = child.children;
    if (
      childrenObject.length === 1 &&
      childrenObject[0].text === ""
    ) {
      return child;
    }

    // Remove empty text nodes which are not intentional line breaks
    child.children = childrenObject.filter((textNode) => {
      return textNode.text !== "";
    });
  }

  // Format aligned nodes
  if(child.type === "align_left" || child.type === "align_center" || child.type === "align_right") {
    child.children = child.children.map((c) => {
      return chapterFormatter(c);
    });
  }

  return child;
};

const getFormattedChapter = (chapter: PdfChapter): PdfChapter => {
  let formattedChapter = chapter;
  if (chapter.type === "dedication") {
    formattedChapter = {
      ...chapter,
      children: chapter.children.map((c: any) => {
        if (c.type === "p") {
          return {
            type: "align_center",
            children: [c]
          };
        }

        if (c.type === "align_right") {
          return {
            ...c,
            type: "align_center",
          };
        }

        return c;
      }),
    };
  }

  if (chapter.type === "also-by") {
    formattedChapter = {
      ...chapter,
      children: chapter.children.map((c: any) => {
        if (c.type === "p") {
          return {
            type: "align_center",
            children: [c]
          };
        }

        if (c.type === "align_right") {
          return {
            ...c,
            type: "align_center",
          };
        }

        return c;
      }),
    };
  }

  if (chapter.type === "epigraph") {
    formattedChapter = {
      ...chapter,
      children: flatten(chapter.children.map((c: any) => {
        if (c.type === "p") {
          return {
            ...c,
            type: "blockquote",
          };
        }

        if (c.type === "align_center" || c.type === "align_right") {
          return (c.children as any).map(d => {
            return {
              ...d,
              type: "blockquote"
            };
          });
        }

        return c;
      })),
    };
  }

  if (chapter.type === "chapter") {
    formattedChapter = {
      ...chapter,
      children: chapter.children
        .map((child) => {return chapterFormatter(child);})
        .filter((c: any) => c?.removeOnFilter !== true),
    };
  }

  return formattedChapter;
};

const getAdjustedBookDimensions = (
  theme: PdfTheme,
  book: PdfExpandedBook,
  // images?: PdfCustomImages,
  // isThemeEditing?: boolean,
  // customThemeHeaders?: PdfCustomThemeHeader | null
): { height: number, width: number, marginOutside: number, paddingBottom: number, paddingTop: number, marginInside: number } => {
  //inputs -> book, theme , customThemeHeaders
  const isFullBleed = () => {

    const fullPageImageChapters = book.chapters?.filter(chapter => chapter.fullpageImage?.printExtent === "full-bleed");

    // check for full-page-images
    if (fullPageImageChapters && fullPageImageChapters?.length > 0) return true;

    // custom or pre-build config
    if (theme.isPredefinedTheme && theme.properties.image?.printExtent !== "margins") return true;

    // while editing
    if (!theme.isPredefinedTheme && theme.properties.image?.printExtent === "full-bleed") return true;

    return false;
  };

  const computeDimensions = (trimAdjuster: number) => {
    const { bottom, top } = ADDITIONAL_CHAPTER_MARGINS;
    const height = (theme.properties.trim.height) + (trimAdjuster * 2);
    const width = (theme.properties.trim.width) + (trimAdjuster);
    const paddingTop = (theme.properties.margin.top + top) + (trimAdjuster);
    const paddingBottom = (theme.properties.margin.bottom + bottom) + (trimAdjuster);
    const marginOutside = (theme.properties.margin.outer) + (trimAdjuster);

    return { height, width, paddingTop, paddingBottom, marginOutside, marginInside: theme.properties.margin.inner };
  };

  return computeDimensions(isFullBleed() ? 0.3 : 0);
};
//TODO:BODY Handle this
const getImages = (chapter: IChapterStore.ChapterMeta, theme: PdfTheme) => {

  // TODO: Check the logic here

  if (theme.properties.image) {
    return {
      ...theme.properties.image,
      url: chapter.image,
      chapterId: chapter._id,
    };
  }

  // if (style?.image) {
  //     return {
  //         ...style.image,
  //         chapterId: chapter._id,
  //     };
  // }

  return theme.properties.image;
};

// const hasLightText = (styles: PdfThemeBase | null, customThemeBuilderView?: string, customThemeHeaders?: PdfCustomThemeHeader) => {
//     const isThemeEditing = customThemeBuilderView === "customThemeStep2";
//     if (isThemeEditing) {
//         return customThemeHeaders?.textLight === "light";
//     }
//     return styles?.properties?.textLight === "light";
// };

const hasFulBleedImage = (chapter: PdfChapter, theme: PdfTheme) => {
  const isTitleImageVisible = theme.properties.titleCard.image;
  let _image: HeaderImage | null = null;

  if (theme.isPredefinedTheme) {
    _image = theme.properties.image;
    if (theme.properties.individualChapterImage) {
      _image.url = chapter.image;
    }
  }

  if (!theme.isPredefinedTheme && isTitleImageVisible && theme.properties.image) {
    _image = theme.properties.image;
    if (theme.properties?.individualChapterImage) {
      _image.url = chapter.image;
    }
  }

  if (!_image) return false;

  const { printExtent, placement, url } = _image;

  if (placement !== "background-image") return false;
  if (!url) return false;
  if (printExtent !== "full-bleed") return false;
  return true;
};

const prepareThemeConfigForFontFamily = (theme: PdfTheme): PdfTheme => {
  const { baseFontSize, printBaseFont } = theme.properties;
  const config = fontsData.find(({ family }) => family === printBaseFont);
  if (!config) return theme;
  const { baseFontSizeMultiplier } = config;
  if (!baseFontSizeMultiplier) return theme;
  return { ...theme, properties: { ...theme.properties, baseFontSize: baseFontSize * baseFontSizeMultiplier } };
};


const getFontSizesForChapterTitleCard = (selector: TitleType, properties: PdfThemeProperties) => {
  const { size } = properties[selector] as TextStylesProperties;

  return size;
};


/*
  Get flex based value based on alignment
*/
const getAlignSelf = (
  align?: "left" | "center" | "right"
): "center" | "auto" | "flex-start" | "flex-end" | "baseline" | "stretch" => {
  switch (align) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "auto";
  }
};

/*
  Get Chapter Header Title Card style based on Selector
*/
const getHeaderSelectorStyle = (
  selector: TitleType,
  themeProperties: PdfThemeProperties,
  chapter: IChapterStore.ChapterMeta,
  lightText = false
) => {
  const { image } = themeProperties;
  const { type, titleScale = CHAPTER_TITLE_HIGHEST_SCALE } = chapter;
  const config = themeProperties[selector] as IThemeStore.HeaderElement;
  let fontSize: number = getFontSizesForChapterTitleCard(selector, themeProperties);

  // scale chapter size even more based on titleScale percantile
  if(selector === "chapterTitle"){
    fontSize = (fontSize / 100) * titleScale;
  }

  /*

    Chapter Overrides is no longer in use

    // Check if chapter overrides is object and exist
    if(typeof chapterOverrides === "object" && chapterOverrides){
      // Check if chapter overrides contains the current chapter type and selector
      if(
        Object.keys(chapterOverrides).includes(chapterType)
        && Object.keys(chapterOverrides[chapterType]).includes(selector)
      ){
        // re-assign config with overrides
        config = chapterOverrides[chapterType][selector];
      }
    }
  */

  const { align, font, style, width, printExtras } = config;
  const fontVariant = headerStyleToFontVariant(style);
  let fontStyle;
  let fontWeight;
  if (
    [
      FontVariant.weight_100,
      FontVariant.weight_200,
      FontVariant.weight_300,
      FontVariant.weight_500,
      FontVariant.weight_600,
      FontVariant.weight_700,
      FontVariant.weight_800,
      FontVariant.weight_900,
    ].includes(fontVariant)
  ) {
    fontWeight =  parseInt(fontVariant);
    fontStyle = "normal";
  }

  if (
    [
      FontVariant.italic_100,
      FontVariant.italic_200,
      FontVariant.italic_300,
      FontVariant.italic_500,
      FontVariant.italic_600,
      FontVariant.italic_700,
      FontVariant.italic_800,
      FontVariant.italic_900,
    ].includes(fontVariant)
  ) {
    fontStyle = "italic";
    fontWeight = getNumericPartFromVariant(fontVariant) || 400;
  }

  const styles = StyleSheet.create({
    [selector]: {
      textAlign: align,
      fontFamily: font,
      fontSize,
      lineHeight: 1.2,
      width: `${width}%`,
      alignSelf: getAlignSelf(align),
      paddingLeft: canApplyPaddingForTextContents(image, align) ? "3%" : 0,
      paddingBottom: 4,
      // don't apply padding Bottom if the selector is subtitle
      ...(selector !== "chapterNo" ? {
        paddingTop: 4,
      } : {}),
      // To disable the decorations of the special chapter types
      ...(!["chapter", "toc"].includes(type) ? {
        borderBottom: 0,
        borderTop: 0
      } : {}),
      ...printExtras,
      ...(lightText ? {
        color: "white"
      } : {}),
      ...(fontWeight ? {fontWeight} : {}),
      ...(fontStyle ? {fontStyle} : {})
    },
  });

  return styles[selector];
};

const getChapterHeaderFontStyles = (selector: TitleType, themeProperties: PdfThemeProperties) => {
  const { style } = themeProperties[selector] as IThemeStore.HeaderElement;

  const textNodeProps: TextNodeProps = {};

  if (style.includes(HeaderFontStyle.bold)) {
    textNodeProps.bold = true;
  }

  if (style.includes(HeaderFontStyle.italic)) {
    textNodeProps.italic = true;
  }

  if (style.includes(HeaderFontStyle.underlined)) {
    textNodeProps.underline = true;
  }

  if (style.includes(HeaderFontStyle.smallcaps)) {
    textNodeProps.smallCaps = true;
  }

  return textNodeProps;
};

const getContentAreaHeight = (theme: ThemeStyleProps) => {
  const { trim: { height, }, margin: { bottom, top } } = theme;
  const heightPt = centimetersToPt(height);
  const bottomPaddingPt = centimetersToPt(bottom);
  const topPaddingPt = centimetersToPt(top);
  return heightPt - (bottomPaddingPt + topPaddingPt);

};

const getContainerStyle = (theme: ThemeStyleProps, chapterType: IChapterStore.ChapterType) => {
  const { titleCardExtras } = theme;
  const styles = StyleSheet.create({
    chapterTitleCardContainer: {
      minHeight: 130,
      ...ChapterTypeExtraStyleConfig(chapterType, getContentAreaHeight(theme)).container,
      paddingTop: 22,
      paddingBottom: 15,
      ...titleCardExtras?.print,
    },
  });
  return styles.chapterTitleCardContainer;
};

const canApplyPaddingForTextContents = (image: HeaderImage, textAlignment: Alignment): boolean => {
  const _images = image;
  if (_images) {
    const { printExtent, placement } = _images;

    /**
     * The below condition applies left padding to the chapter title card
     * if the chapter image is selected as the background for the title card
     * and if the print extent is not full-bleed.
     *
     * With the ticket AT-2093, the padding is enabled only if the text elements
     * are left aligned as it prevents elements from centering properly.
     */
    if (printExtent === "margins" && placement === "background-image" && textAlignment === "left") return true;
  }
  return false;
};


export {
  getAdjustedBookDimensions,
  getChapterNumberLabel,
  getFormattedChapter,
  getImages,
  getAlignSelf,
  getContentAreaHeight,
  getContainerStyle,
  getHeaderSelectorStyle,
  getFontSizesForChapterTitleCard,
  getChapterHeaderFontStyles,
  chapterNumberToTitleCase,
  isPrebuiltTheme,
  centimetersToPt,
  mapHeaderFooterFontSize,
  prepareThemeConfigForChapterType,
  // hasLightText,
  hasFulBleedImage,
  prepareThemeConfigForFontFamily,
  withoutUnit,
  canApplyPaddingForTextContents
};
