import { getNodeEntries, PlateEditor, TNodeEntry, Value } from "@udecode/plate";

import { TCommentText } from "../types";
import { isCommentNodeById } from "../utils";

export const getCommentNodesById = <V extends Value>(
  editor: PlateEditor<V>,
  id: string
): TNodeEntry<TCommentText>[] => {
  return Array.from(
    getNodeEntries<TCommentText>(editor, {
      at: [],
      match: (n) => isCommentNodeById(n, id),
    })
  );
};
