import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";

import { db } from "../db/bookDb";
import { AtticusClient } from "../api/atticus.api";
import { getPlateChapterBodyForChapter, replaceYChapterContent } from "../utils/y";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../utils/chapter";
import { GetAllChapterTemplates, GetChapterTemplates, SaveChapterTemplateToDB, UpdateChapterMeta } from "../utils/offline.book.helpers";
import { SaveTemplateToDB, syncChapterTemplateChangesWithLocalChapters } from "../utils/sync/chapterTemplateSync";
import { appStore } from ".";

import { MyRootBlock } from "../components/Plate/config/typescript";
import { Scene } from "../types/scene";

export class ChapterStore {
  constructor() {
    makeAutoObservable(this);
  }
  public chapterMeta: IChapterStore.ChapterMeta = {
    _id: "",
    bookId: "",
    image: "",
    subtitle: "",
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    startOn: "any",
    type: "chapter",
    index: 0,
  }
  public chapterBody: MyRootBlock[] = [];
  public chapterScenes: IChapterStore.ChapterScenes | null = null;
  public currentScene: IChapterStore.CurrentScene | null = null;

  setChapterMeta = (meta: IChapterStore.ChapterMeta): void => {
    this.chapterMeta = meta;
  };

  setChapterBody = (body: MyRootBlock[]): void => {
    this.chapterBody = body;
  };

  setChapterScenes = (chapterScenes: IChapterStore.ChapterScenes): void => {
    this.chapterScenes = chapterScenes;
  }

  setCurrentScene = (scene: IChapterStore.CurrentScene | null): void => {
    this.currentScene = scene;
  }

  getChapterMeta = (): IChapterStore.ChapterMeta => {
    return this.chapterMeta;
  };

  getChapterBody = (): MyRootBlock[] => {
    return this.chapterBody;
  };

  getChapterScenes = (): {chapterId: string, scenes: Scene[]} | null => {
    return this.chapterScenes;
  }

  getCurrentScene = (): {chapterId: string, scene: Scene} | null => {
    return this.currentScene;
  }

  fetchAndSetChapterMeta = async (chapterId: string): Promise<void> => {
    const chapterMeta = await db.chapterMetas.get(chapterId);
    if(chapterMeta) {
      this.setChapterMeta(chapterMeta);
    }
  }

  fetchAndSetChapterBody = async (chapterId: string): Promise<void> => {
    const chapterBody = (await getPlateChapterBodyForChapter(chapterId)).chapterBody;
    if(chapterBody) {
      this.setChapterBody(chapterBody);
    }
  }

  setCurrentChapter = async(chapterId: string): Promise<void> => {
    await this.fetchAndSetChapterMeta(chapterId);
    /** no need to await this */
    this.fetchAndSetChapterBody(chapterId);
  } 

  // TODO: Below are chapter template related functions, maybe move them to their own store later ?
  chapterTemplates: IChapterStore.IChapterTemplateBase[] = [];
  storeChapterTemplate = async (
    chapterId: string,
    chapterBody: MyRootBlock[],
    templateName: string,
    section: string
  ): Promise<string> => {
    const templateOutput = await AtticusClient.CreateChapterTemplate(
      chapterId,
      chapterBody,
      templateName,
      section
    );
    const chapterTemplates = await AtticusClient.GetChapterTemplates();

    const currentTemplate = chapterTemplates.find(
      (template) => template._id === templateOutput.templateId
    );

    if (currentTemplate) {
      await SaveChapterTemplateToDB(currentTemplate);
    }
    this.loadTemplates();

    return templateOutput.templateId;
  };

  storeChapterFromTemplate = async (
    templateId: string,
    bookId: string
  ): Promise<boolean> => {
    await AtticusClient.CreateChapterFromTemplate(templateId, bookId);
    return true;
  };

  setChapterTemps = (templates: IChapterStore.IChapterTemplateBase[]): void => {
    this.chapterTemplates = templates;
  };

  // fetch functions
  loadTemplates = async (): Promise<void> => {
    const allChapterTemplatesServer = await AtticusClient.GetChapterTemplates();
    const allChapterTemplates = await GetAllChapterTemplates();

    const mergedChapterTemplates: IChapterStore.IChapterTemplateBase[] = [
      ...(allChapterTemplates || []),
    ];

    allChapterTemplatesServer.forEach(async (serverTemplate) => {
      const localTemplate = mergedChapterTemplates.find(
        (mergedTemplate) => mergedTemplate._id === serverTemplate._id
      );

      if (!localTemplate) {
        mergedChapterTemplates.push(serverTemplate);
        await SaveChapterTemplateToDB(serverTemplate);
      }
    });

    this.setChapterTemps(mergedChapterTemplates || []);
  };

  syncChapterTemplate = async (
    shouldUpdateAllBooks: boolean,
    shouldUpdateTemplate: boolean,
    bookId: string,
    templateId?: string,
    chapterId?: string,
  ): Promise<void> => {
    const { chapterTemplateView } = appStore;
    try {
      let plateChapter;
      if(chapterId) {
        plateChapter = await getPlateChapterBodyForChapter(chapterId);
      }
      if(shouldUpdateAllBooks) {
        const chaptersToUpdate = await AtticusClient.GetChaptersToUpdateWithChapterTemplate(
          templateId as string,
          shouldUpdateAllBooks,
          bookId
        );
        if(!templateId) throw new Error("Missing template Id");
        const template = await GetChapterTemplates(templateId);
        if(!template) throw new Error("Missing template");
        const allPromises: Promise<unknown>[] = [];
        chaptersToUpdate?.forEach((chapterId) => {
          allPromises.push(replaceYChapterContent(chapterId, chapterTemplateView ? template.children : plateChapter.chapterBody));
        });
        
        await Promise.all(allPromises);
      }

      await AtticusClient.SyncChapterTemplate(
        shouldUpdateAllBooks,
        shouldUpdateTemplate,
        bookId,
        templateId,
        chapterId,
        plateChapter.chapterBody,
      );
      
      await SaveTemplateToDB();
      if(!templateId) throw new Error("Missing template Id");
      await syncChapterTemplateChangesWithLocalChapters(templateId, bookId, shouldUpdateAllBooks);
    } catch (e: any) {
      console.log(e);
    }
    await this.loadTemplates();
    AtticusClient.ChapterTemplateChangesSync(templateId as string);
  };

  deleteChapterTemplate = async (templateId: string): Promise<void> => {
    try {
      const deleteChapTemp = await AtticusClient.DeleteChapterTemplate(
        templateId
      );

      if (deleteChapTemp) {
        await db.chapterTemplates.where("_id").equals(templateId).delete();
        const chapterMetas = await db.chapterMetas.toArray();

        const allPromises: Promise<void>[] = [];

        chapterMetas.forEach((meta) => {
          if (meta.templateId === templateId) {
            allPromises.push(
              UpdateChapterMeta(meta._id, { templateId: undefined })
            );

            if (this.chapterMeta._id === meta._id) {
              const chapterClone = cloneDeep(meta);
              delete chapterClone.templateId;

              this.setChapterMeta(chapterClone);
            }
          }
        });

        await Promise.all(allPromises);
      }
    } catch (e: any) {
      console.log(e);
    }
    await this.loadTemplates();
    AtticusClient.ChapterTemplateChangesSync(templateId as string);
  };
}
export default new ChapterStore();
