import { ShelfMasterPageWSMessageData, ShelfWSMessageData } from "../types/common";

export const BOOKSHELF_BOOK_ADDED = "shelf-book-added";

export const BOOKSHELF_BOOK_REMOVED = "shelf-book-removed";

export const BOOKSHELF_MASTERPAGE_ADDED = "shelf-masterpage-added";

export const BOOKSHELF_MASTERPAGE_REMOVED = "shelf-masterpage-removed";

export const BOOKSHELF_DETAIL_UPDATE = "shelf-detail-update";

export const wsSendShelfUpdateMessage = (
  socket: WebSocket,
  action: string,
  data: ShelfWSMessageData | ShelfMasterPageWSMessageData
): void => {
  if (socket && socket.readyState === WebSocket.OPEN) {
    socket.send(
      JSON.stringify({
        action,
        data,
      })
    );
  }
};
