import { PlateEditor, unsetNodes, Value } from "@udecode/plate";

import useRootStore from "../../../../../store/useRootStore";

import { removeCommentMark } from "../transforms";
import { CommentsRecord, TComment, TCommentText } from "../types";
import { MARK_COMMENT } from "../constants";
import { getCommentCount } from "../utils";
import { getCommentNodeEntries } from "../queries";

export const withComments = <
  V extends Value = Value,
  E extends PlateEditor<V> = PlateEditor<V>
>(
  editor: E
): PlateEditor<V> => {
  const { normalizeNode, insertBreak } = editor;

  editor.insertBreak = () => {
    removeCommentMark(editor);
    insertBreak();
  };

  const { setComments, hasDifferentComments } = useRootStore().commentsStore;
  const { book } = useRootStore().bookStore;
  const { user } = useRootStore().authStore;

  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // save all the comments in the store
    const comments: TComment[] = [];
    const commentIds: Set<string> = new Set();

    const nodeEntries = getCommentNodeEntries(editor);

    nodeEntries.forEach((nodeEntry) => {
      const [childNode, childPath] = nodeEntry;
      const nodeComments: CommentsRecord = (childNode.text !== "" && childNode.comments && Object.keys(childNode.comments).length > 0) ? childNode.comments : {};

      Object.values(nodeComments).forEach((comment) => {
        const isCommentOwnedByUser = comment.userId === user?._id;
        let isCommentParentedByUser = false; 
        const parentId = comment.parentId;
        if(parentId){
          const parentComment = nodeComments[parentId];
          if(parentComment.userId === user?._id) isCommentParentedByUser = true;
        }

        if (!commentIds.has(comment.id)) {
          if (book.collaborated) {
            if (
              isCommentOwnedByUser ||
              (comment.parentId && isCommentParentedByUser)
            ) {
              comments.push(comment);
              commentIds.add(comment.id);
            }
          } else {
            comments.push(comment);
            commentIds.add(comment.id);
          }
        }
        
      });
    });

    const hasDifferences = hasDifferentComments(comments);

    if (hasDifferences) {
      setComments(comments);
    }

    //unsest MARK_COMMENT when no comments available in a node
    unsetNodes(editor, MARK_COMMENT, {
      at: path,
      match: (n) =>
        Object.keys(n).includes(MARK_COMMENT) &&
        getCommentCount(n as TCommentText) < 1,
    });

    normalizeNode(entry);
  };

  return editor;
};
