import { BaseEditor, Editor, NodeEntry, Element } from "slate";
import { isElement, PlateEditor, TPath } from "@udecode/plate";

import { Scene } from "../../types/scene";
import {
  MySceneElement,
  MyOrnamentalBreakElement,
} from "../../components/Plate/config/typescript";
import {
  ELEMENT_ORNAMENTAL_BREAK,
  ELEMENT_SCENE,
} from "../../components/Plate";
import {
  removeNode,
  getSiblingNodes,
  injectChildrenIntoNode,
  getNode,
  moveNode,
  updateNode
} from "../slate";

export class SceneUtils {
  static getFormattedScenes(nodeEntries: NodeEntry<MySceneElement>[]): Scene[] {
    const scenes: Scene[] = [];
    nodeEntries.map((nodeEntry) => {
      const [sceneNode, _path] = nodeEntry;
      scenes.push({
        id: sceneNode.id,
        sceneIndex: sceneNode.sceneIndex,
        sceneTitle: sceneNode.sceneTitle,
        sceneNodeEntry: nodeEntry,
      });
    });
    return scenes;
  }
  /**
   * Recursively iterate through the document from the start and find all the scene nodes
   * @param editor Plate editor instance
   * @returns An array of scene node entries, in the order they appear in the document
   */
  static findSceneEntriesInDoc(
    editor: BaseEditor
  ): NodeEntry<MySceneElement>[] {
    const sceneNodes: NodeEntry<MySceneElement>[] = [];
    const getNextSceneNode = (editor: BaseEditor, start: TPath): void => {
      const nextSceneNode = Editor.next<MySceneElement>(editor, {
        at: start,
        match: (node) => isElement(node) && node.type === ELEMENT_SCENE,
      });
      if (nextSceneNode) {
        sceneNodes.push(nextSceneNode);
        getNextSceneNode(editor, nextSceneNode[1]);
      } else {
        return;
      }
    };
    const firstNodeInDoc = Editor.first(editor, []);
    getNextSceneNode(editor, firstNodeInDoc[1]);
    return sceneNodes;
  }

  static getChapterScenes(editor: BaseEditor): Scene[] {
    const sceneNodes = SceneUtils.findSceneEntriesInDoc(editor);
    return SceneUtils.getFormattedScenes(sceneNodes);
  }
  /**
   * Update the title of a scene node in the chapter
   * @param editor Plate editor instance
   * @param chapterScene Scene to update the title of
   * @param title New scene title
   */
  static updateEditorSceneTitle(
    editor: PlateEditor,
    chapterScene: IChapterStore.CurrentScene,
    title: string
  ): void {
    updateNode<MySceneElement>(
      editor, 
      { sceneTitle: title },
      {
        at: [],
        match: (node) =>
          Element.isElement(node) &&
          (node as MySceneElement).sceneIndex ===
            chapterScene?.scene.sceneIndex,
      }
    );
  }

  static moveScenesWithinChapter(
    editor: PlateEditor,
    srcSceneIndex: number,
    destSceneIndex?: number,
    callback?: () => any
  ): void {
    /** if move scenes down or up along the sidebar */
    const isMoveDown =
      destSceneIndex !== undefined && srcSceneIndex < destSceneIndex;
    /**
     * When moving a scene up,
     *  - if the destination scene index is 0 (the begining of the chapter),
     *    the destSceneIndex is returned as undefined by the drag and drop library
     *  - the destination index is returned as a 1 index lower than the actual destination index (hense the +1)
     */
    let actualDestIndex: number;
    if (isMoveDown) {
      actualDestIndex = destSceneIndex;
    } else {
      if (destSceneIndex === undefined) {
        actualDestIndex = 0;
      } else {
        actualDestIndex = destSceneIndex + 1;
      }
    }
    const srcScene = getNode<MySceneElement>(editor, {
      match: (node) =>
        isElement(node) &&
        (node as MySceneElement).sceneIndex === srcSceneIndex,
    });
    const destScene = getNode<MySceneElement>(editor, {
      match: (node) =>
        isElement(node) &&
        (node as MySceneElement).sceneIndex === actualDestIndex,
    });
    if (!srcScene || !destScene) return;
    /** find ornamental break node to move along with the scene */
    const srcSceneSiblingObNodes = getSiblingNodes<
      MySceneElement,
      MyOrnamentalBreakElement
    >(editor, srcScene, ELEMENT_ORNAMENTAL_BREAK);
    let obNodeToMove: NodeEntry<MyOrnamentalBreakElement> | undefined;
    if (isMoveDown) {
      obNodeToMove = srcSceneSiblingObNodes.next;
    } else {
      obNodeToMove = srcSceneSiblingObNodes.previous;
    }
    if (!obNodeToMove) return;
    /**
     * Move the Scene and the Ornamental break
     *
     * When moving scenes/obs down:
     * move the node with the higher path value (node below) first to preserve the path value of the next
     * node to move
     *
     * When moving scenes/obs up:
     * move the node with the lower path value (node above) first to preserve the path value of the next
     * node to move
     *
     * - When moving scenes/obs down:
     *
     * ob node is moved below the dest scene node first, all the nodes below the original position of the ob node
     * moves up by 1 position and therefore the path values are reduced by 1
     *
     * therefore, destScene[1] now points to the newly positioned ob node instead of the end of the scene node
     *
     * next the scene node is moved below the newly positioned ob node
     *
     * - When moving scenes/obs up:
     *
     * ob node is moved above the dest scene node first, all the nodes above the original position of the ob node moves
     * down by 1 position and therefore the path values are increased by 1
     *
     * therefore, destScene[1] now points to the newly positioned ob node instead of the start of the scene node
     *
     * next the scene node is moved above the newly positioned ob node
     * */

    moveNode(editor, { at: obNodeToMove[1], to: destScene[1] });
    moveNode(editor, { at: srcScene[1], to: destScene[1] });
    if (callback) callback();
    return;
  }

  /**
   * scene deletion can be done in two ways
   *  - delete scene with the content
   *  - delete scene but merge the content with the adjacent scene
   *
   * When deleting a scene, always delete the next ornamental break, if no next ornamental break
   * delete the previous ornamental break
   *
   * When deleting scenes by merging the content, always merge content with the next scene
   * If no next scene, merge content with the previous scene
   * When merging content with the next scene, merge content at the start of the next scene
   * When merging content with the previous scene, merge content at the end of the previous scene
   */
  static removeEditorScene(
    editor: PlateEditor,
    sceneId: number,
    sceneIndex: number,
    deleteContent: boolean,
    callback?: () => any
  ): void {
    const currentScene = this.getSceneNode(editor, sceneId, sceneIndex);
    if (!currentScene) return;
    const ornamentalBreakSiblings = getSiblingNodes<
      MySceneElement,
      MyOrnamentalBreakElement
    >(editor, currentScene, ELEMENT_ORNAMENTAL_BREAK);
    const ornamentalBreakToRemove =
      ornamentalBreakSiblings.next || ornamentalBreakSiblings.previous;
    if (!ornamentalBreakToRemove) return;
    const deleteDirection = ornamentalBreakSiblings.next
      ? "forward"
      : "backward";
    if (deleteContent) {
      if (deleteDirection === "forward") {
        removeNode(editor, ornamentalBreakToRemove);
        removeNode(editor, currentScene);
      } else {
        removeNode(editor, currentScene);
        removeNode(editor, ornamentalBreakToRemove);
      }
    } else {
      const sceneSiblings = getSiblingNodes<MySceneElement, MySceneElement>(
        editor,
        currentScene,
        ELEMENT_SCENE
      );
      const contentToMerge = currentScene[0].children;
      if (deleteDirection === "forward") {
        const destScene = sceneSiblings.next;
        if (destScene)
          injectChildrenIntoNode(editor, destScene, contentToMerge);
        removeNode(editor, ornamentalBreakToRemove);
        removeNode(editor, currentScene);
      } else {
        const destScene = sceneSiblings.previous;
        if (destScene)
          injectChildrenIntoNode(
            editor,
            destScene,
            contentToMerge,
            destScene[0].children.length
          );
        removeNode(editor, currentScene);
        removeNode(editor, ornamentalBreakToRemove);
      }
    }
    if (callback) callback();
  }

  static getSceneNode(
    editor: PlateEditor,
    sceneId: number,
    sceneIndex: number
  ): NodeEntry<MySceneElement> | undefined {
    return getNode<MySceneElement>(editor, {
      match: (n) =>
        isElement(n) &&
        (n as MySceneElement).id === sceneId &&
        (n as MySceneElement).sceneIndex === sceneIndex,
    });
  }
}
