import React from "react";

import { CommentIcon } from "../../../content/icons";
import { CommentToolbarButton } from "../plugins/comments/components";
import { appStore } from "../../../store";
import { BalloonToolbar } from "./BalloonToolBar";

export const FloatingToolBar: React.FC = () => {
  const { chapterTemplateView } = appStore;

  if(chapterTemplateView) return null;
  
  return (
    <div className="floating-toolbar">
      <BalloonToolbar theme="light" ignoreReadOnly={true} >
        <CommentToolbarButton
          icon={
            <div className="floating-toolbar-icon-wrapper">
              <div className="icon">
                <div className="icon-svg-wrapper">
                  <CommentIcon />
                </div>
              </div>
              <span className="floating-toolbar-button-text">Comment</span>
            </div>
          }
        />
      </BalloonToolbar>
    </div>
  );
};
