import { findNode, PlateEditor, TNodeEntry, Value } from "@udecode/plate";

import { TCommentText } from "../types";
import { getCommentKey } from "../utils";

export const findCommentNodeById = <V extends Value>(
  editor: PlateEditor<V>,
  id: string
): TNodeEntry<TCommentText> | undefined => {
  return findNode<TCommentText>(editor, {
    at: [],
    match: (n) => n[getCommentKey(id)],
  });
};
