import { makeAutoObservable } from "mobx";
import { debounce, remove } from "lodash";
import { arabToRoman } from "roman-numbers";
import { BookCacheStoreSnapshot, PDFReadyTOCSummeryItem } from "../workers/types";
import { ChapterCacheData, ChapterCacheMetaData } from "../types/pdf-cache";
import { shouldIncludeChapterInPrint } from "../utils/helper";
import { isChapterTopLevel } from "../utils/chapter";

// const isFrontMatterChapter = (chapterType: IChapterStore.ChapterType) => {
//     return ["copyrights", "title", "blurbs", "dedication", "epigraph", "foreword", "introduction", "preface", "prologue", "toc"].includes(chapterType);
// };

/**
 * Returns the id of the included first body chapter 
 * 
 * @returns String ChapterId
 */
const getFirstBodyChapterId = (chapters: (ChapterCacheData & ChapterCacheMetaData)[], frontMatterIds: string[]): string | undefined => {
    if (chapters.length === frontMatterIds.length) return;

    const bodyChapters = chapters.filter(chap => ![...frontMatterIds].includes(chap.chapterId));
    for (const chap of bodyChapters) {
        if (shouldIncludeChapterInPrint(chap)) return chap.chapterId;
    }
};

/**
 * Computes the number of pages that appear before a specified chapter.
 * This method starts counting from zero from the beginning of the body
 * @param chapters Available chapters
 * @param targetChapterId Target chapter
 * @param frontMatterIds frontMatter Ids of the book
 * @returns Number of pages prior to the target chapter
 */
const getOffsetPageCount = (chapters: (ChapterCacheData & ChapterCacheMetaData)[], targetChapterId: string, frontMatterIds: string[]) => {
    const targetChapter = chapters.find(chapter => chapter.chapterId === targetChapterId);
    if (!targetChapter) {
        throw new Error("Target chapter is not in the reference chapters array");
    }
    let frontMatterPages = 0;
    let lastPageNumber = 0;
    for (let index = 0; index < chapters.length; index++) {
        const cacheChapter = chapters[index];
        const { chapterId: cacheChapterId, pageCount } = cacheChapter;
        let { startOn } = cacheChapter;

        // if the target chapter is excluded it means we should break at the previous chapter to return the last page number
        if (!shouldIncludeChapterInPrint(targetChapter) && cacheChapterId === targetChapterId) break;

        // if the current chapter is excluded the page count should be considered as 0 and skip
        if (!shouldIncludeChapterInPrint(cacheChapter)) continue;

        // add white pages
        // if startOn undefind set default values
        if (!frontMatterIds.includes(cacheChapterId) && !startOn) {
            startOn = "right";
        }
        if (frontMatterIds.includes(cacheChapterId) && !startOn) {
            startOn = "any";
        }

        // set first body chapter starting side to right
        const firstBodyChapterId = getFirstBodyChapterId(chapters, frontMatterIds);
        if (firstBodyChapterId === cacheChapterId) {
            startOn = "right";
        }

        // check for page side and add blank pages as necessary
        if ((startOn === "left" && lastPageNumber !== 0 && lastPageNumber % 2 === 0) || (startOn === "right" && lastPageNumber !== 0 && lastPageNumber % 2 !== 0)) {
            lastPageNumber += 1;

            // keep count of the frontMatter chapter count to reset counting for body and back matter
            if (frontMatterIds.includes(chapters[index].chapterId)) {
                frontMatterPages += 1;
            }
        }

        if (targetChapterId === cacheChapterId) break;

        if (pageCount) {
            lastPageNumber += pageCount;

            // keep count of the frontMatter chapter count to reset counting for body and back matter
            if (frontMatterIds.includes(chapters[index].chapterId)) {
                frontMatterPages += pageCount;
            }
        }
    }

    if (!frontMatterIds.includes(targetChapter.chapterId)) {
        lastPageNumber -= (frontMatterPages + (frontMatterPages % 2));
    }

    return lastPageNumber;
};

export class PDFCacheStore {
    bookCaches: { [bookId: string]: IPDFCacheStore.BookCacheData } = {};
    
    resetRendererCallback: (() => void) | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Initialize book cache
     * @param bookId Book ID
     * @param chapters Chapter data
     */
    initializeBookCache = (bookId: string, chapters: IPDFCacheStore.ChapterCacheMetaData[], frontMatterIds: string[]): void => {
        const bookCacheData: IPDFCacheStore.BookCacheData = {
            chapterCaches: chapters.map((data) => {
                return { ...data, blobUrl: null, pageCount: null, status: "invalid" } as (IPDFCacheStore.ChapterCacheData & IPDFCacheStore.ChapterCacheMetaData);
            }),
            fullPageCount: null,
            frontMatterIds
        };
        this.bookCaches = { ...this.bookCaches, [bookId]: bookCacheData };
        // console.log("PDF Cache initialized", { bookId });
    }

    /**
     * Remove book cache
     * @param bookId Book ID
     */
    removeBookCache = (bookId: string): void => {
        const _bookCaches = { ...this.bookCaches };
        delete _bookCaches[bookId];
        this.bookCaches = { ..._bookCaches };
    }

    /**
     * Find the chapter to be rendered in the pdf generator
     * 
     * @summary
     * - If TOC is `invalid`: Go ahead and render it [If `all` the other chapters were `partial`/`valid`, we can get the TOC to `valid` state. Otherwise, TOC will come to `partial` state]
     * - If TOC is `partial`: If `all` other chapters are in `partial`/`valid` states, go ahead and render it
     * - Otherwise give priority to the other chapters
     * 
     * @param bookId Book ID
     * @returns `{ chapterId: string, pageNumberOffset: string }`
     */
    private getNextChapter = (bookId: string): { pageNumberOffset: number, chapterId: string, tocSummary?: PDFReadyTOCSummeryItem[], digest?:string, includeIn: "all" | "ebook" | "print" | "none" } | null => {
        const { chapterCaches: allChapterCaches, frontMatterIds } = this.getBookCacheData(bookId);
        const tocChapters = allChapterCaches.filter(cache => cache.chapterType === "toc");

        if (tocChapters.filter((cache => isChapterTopLevel(cache.parentChapterId, bookId))).length === 0) {
            throw new Error(`TOC Chapter not found in PDFCacheStore book:${bookId}`);
        }

        for (const { chapterType, status, chapterId, includeIn, digest } of allChapterCaches) {
            if (chapterType === "toc") {
                if (status === "invalid") {
                    // console.log(chapterType, status);
                    return { chapterId, pageNumberOffset: getOffsetPageCount(allChapterCaches, chapterId, frontMatterIds), includeIn };
                }
            }

            if (chapterType !== "toc" && ["invalid", "partial"].includes(status)) {
                // console.log(chapterType, status);
                return { chapterId, pageNumberOffset: getOffsetPageCount(allChapterCaches, chapterId, frontMatterIds), includeIn, digest };
            }

            if (chapterType === "toc") {
                if (status === "partial") {
                    if (allChapterCaches.filter(cache => ["valid", "partial"].includes(cache.status)).length === allChapterCaches.length) {
                        // console.log(chapterType, status);
                        return { chapterId, pageNumberOffset: getOffsetPageCount(allChapterCaches, chapterId, frontMatterIds), tocSummary: this.getChapterFirstPageNumbers(bookId), includeIn };
                    }
                }
            }
        }

        return null;
    }

    /**
     * Wrapper to filter out excluding chapters 
     * 
     * @summary
     * - If the chapter is not included in "all" or "print", it will be skipped 
     * - Page number count will be set to 0 and blob url will be empty for the skipped chapters
     * 
     * @param bookId Book ID
     * @returns `{ chapterId: string, pageNumberOffset: string }`
     */
    getNextChapterTobeRendered = (bookId: string): { pageNumberOffset: number,  digest?:string, chapterId: string, tocSummary?: PDFReadyTOCSummeryItem[], } | null => {
        let nextChapter = this.getNextChapter(bookId);
        while (nextChapter && !shouldIncludeChapterInPrint({ includeIn: nextChapter.includeIn || "all" })) {
            this.makeChapterAsValid(bookId, nextChapter.chapterId, 0, null, 0);
            nextChapter = this.getNextChapter(bookId);
        }

        return nextChapter;
    }

    /**
     * Make chapter as `valid` chapter and update cache metadata
     * 
     * @param bookId Book ID
     * @param chapterId Chapter ID
     * @param pageCount Page count
     * @param blobUrl Generated PDF URL
     * @param endNoteCount Endnote count of the chapter 
     */
    makeChapterAsValid = (bookId: string, chapterId: string, pageCount: number, blobUrl: string | null, endNoteCount: number, digest?:string): void => {
        const tocChapters = this.getTOCChapters(bookId);
        if (tocChapters.map((chap)=>chap.chapterId).includes(chapterId)) {
            this.validateTOCChapterStatus(bookId ,chapterId);
            this.updateChapterCacheData(bookId, chapterId, { blobUrl, pageCount, endNoteCount });
            return;
        }
        this.updateChapterCacheData(bookId, chapterId, { blobUrl, digest, pageCount, status: "valid", endNoteCount });
        this.calculateBookCacheFullPageCount(bookId);
    }

    /**
     * @summary Returns the Total Endnote count of all the previous chapters
     * @param bookId Book ID
     * @param chapterId  Chapter ID
     * @returns total count of Endnotes of previous chapters
     */
    getEndNoteOffsetValue = (bookId: string, chapterId: string): number => {
        const {chapterCaches} = this.bookCaches[bookId];
        let totalEndNoteCount = 0;
        for (const chapter of chapterCaches) {
            if (chapter.chapterId === chapterId) break;
            totalEndNoteCount += chapter.endNoteCount||0;
        }

        return totalEndNoteCount;
    }

    /**
     * Get book cache data
     * @param bookId Book ID
     * @returns `{BookCacheData}`
     */
    getBookCache = (bookId: string): IPDFCacheStore.BookCacheData => {
        return this.getBookCacheData(bookId);
    }

    /**
     * Find the specific chapter cache in a specific book
     * 
     * @param bookId Boom ID
     * @param chapterId Chapter ID
     * @returns 
     */
    getChapterCache = (bookId: string, chapterId: string): (IPDFCacheStore.ChapterCacheData & IPDFCacheStore.ChapterCacheMetaData) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const chapterCache = bookCacheData.chapterCaches.find(cache => cache.chapterId === chapterId);
        if (!chapterCache) {
            throw new Error(`Chapter not found in PDFCacheStore Book:${bookId}`);
        }
        return chapterCache;
    }

    /**
     * Get the current snapshot of cache store blob urls for valid chapters only
     * 
     * @param bookId Book ID
     * @returns Map of [Chapter ID] -> [Blob url]
     */
    getCacheStoreSnapshot = (bookId: string): BookCacheStoreSnapshot => {
        return this.getBookCacheData(bookId).chapterCaches.reduce((result, cache) => {
            return {
                ...result,
                [cache.chapterId]: cache.status === "valid" ? cache.blobUrl : null
            };
        }, []) as any;
    }

    /**
     * Check whether the book is exist
     * 
     * @param bookId Book ID
     * @returns 
     */
    bookExist = (bookId: string): boolean => {
        return !!this.bookCaches[bookId];
    }

    /**
     * Update metadata of given chapters in a specific book
     * 
     * @param bookId Book ID
     * @param chapters Metadata of the chapters
     */
    private updateBookCacheChapters = (bookId: string, chapters: IPDFCacheStore.ChapterCacheMetaData[]): void => {
        const { chapterCaches, fullPageCount, frontMatterIds } = this.getBookCacheData(bookId);
        const updatedChapterCaches = chapters.map(({ chapterId, chapterType, startOn }) => {
            const cachedChapter = chapterCaches.find(cache => cache.chapterId === chapterId);
            if (cachedChapter && cachedChapter.startOn === startOn && cachedChapter.chapterType === chapterType) {
                return cachedChapter;
            }
            return { blobUrl: null, chapterId, chapterType, startOn, pageCount: null, status: "invalid" } as (IPDFCacheStore.ChapterCacheData & IPDFCacheStore.ChapterCacheMetaData);
        });
        this.bookCaches = { ...this.bookCaches, [bookId]: { chapterCaches: updatedChapterCaches, fullPageCount, frontMatterIds } };
    }

    /**
     * Get cache data from a specific book
     * 
     * @param bookId Book ID
     * @returns 
     */
    private getBookCacheData = (bookId: string) => {

        const bookCacheData = this.bookCaches[bookId];
        if (!bookCacheData) {
            throw new Error("Book not found in PDFCacheStore");
        }
        return bookCacheData;
    }

    /**
     * Calculate full page count
     * @param bookId Book ID
     * @returns 
     */
    private calculateBookCacheFullPageCount = (bookId: string) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const { chapterCaches, frontMatterIds } = bookCacheData;
        if (chapterCaches.filter(cache => cache.status === "invalid").length > 0) return;
        let lastPageNumber = 0;
        const firstBodyChapterId = getFirstBodyChapterId(chapterCaches, frontMatterIds);

        for (let index = 0; index < chapterCaches.length; index++) {
            const chapterCache = chapterCaches[index];
            const { pageCount, chapterId } = chapterCache;
            let { startOn } = chapterCache;

            // if the current chapter is excluded the page count should be considered as 0 and skip
            if (!shouldIncludeChapterInPrint(chapterCache)) continue;

            if (pageCount) {
                // if startOn undefind set default values
                if (!this.isFrontMatterChapter(bookId, chapterId) && !startOn) {
                    startOn = "right";
                }
                if (this.isFrontMatterChapter(bookId, chapterId) && !startOn) {
                    startOn = "any";
                }
                // set first body chapter starting side to right
                if (firstBodyChapterId === chapterId) {
                    startOn = "right";
                }
                // check for page side and add blank pages as necessary
                if ((startOn === "left" && lastPageNumber !== 0 && lastPageNumber % 2 === 0) || (startOn === "right" && lastPageNumber !== 0 && lastPageNumber % 2 !== 0)) {
                    lastPageNumber += pageCount + 1;
                } else {
                    lastPageNumber += pageCount;
                }
                // to end frontmatter always on a left side page
                if (this.isFrontMatterChapter(bookId, chapterCaches[index].chapterId) && !this.isFrontMatterChapter(bookId, chapterCaches[index + 1].chapterId)) {
                    if (lastPageNumber !== 0 && lastPageNumber % 2 !== 0) {
                        lastPageNumber++;
                    }
                }
            }

        }
        this.bookCaches = { ...this.bookCaches, [`${bookId}`]: { chapterCaches, fullPageCount: lastPageNumber, frontMatterIds } };
    }

    /**
     * Update chapter cache data for a given chapter in a given book
     * 
     * @param bookId Book ID
     * @param chapterId Chapter ID
     * @param chapterCacheStatus Cache status
     * @param pageCount Page count
     * @param blobUrl Generated PDF URL
     */
    private updateChapterCacheData = (bookId: string, chapterId: string, data: Partial<Omit<IPDFCacheStore.ChapterCacheMetaData & IPDFCacheStore.ChapterCacheData, "chapterId">>) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const { chapterCaches, frontMatterIds } = bookCacheData;
        const _chapterCaches = [
            ...chapterCaches.map(cache => {
                if (chapterId === cache.chapterId) {
                    return { ...cache, ...data };
                }
                return cache;
            })
        ];
        this.bookCaches = { ...this.bookCaches, [`${bookId}`]: { chapterCaches: _chapterCaches, fullPageCount: bookCacheData.fullPageCount, frontMatterIds } };
    }

    /**
     * Update cache status for a given chapter in a given book
     * 
     * @param bookId Book ID
     * @param chapterIds Chapter ID
     * @param chapterCacheStatus Cache status
     */
    public changeChaptersCacheStatus = (bookId: string, chapterIds: string[], chapterCacheStatus: IPDFCacheStore.CacheStatus) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const { chapterCaches, frontMatterIds } = bookCacheData;
        const _chapterCaches = [
            ...chapterCaches.map(cache => {
                if (chapterIds.includes(cache.chapterId)) {
                    return { ...cache, status: chapterCacheStatus };
                }
                return cache;
            })
        ];
        this.bookCaches = { ...this.bookCaches, [`${bookId}`]: { chapterCaches: _chapterCaches, fullPageCount: bookCacheData.fullPageCount, frontMatterIds } };
    }

    /**
     * Remove cache from a given chapter in a given book
     * 
     * @param bookId Book ID
     * @param chapterId Chapter ID
     */
    private removeChapterCache = (bookId: string, chapterIds: string[]) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const { chapterCaches, frontMatterIds, fullPageCount } = bookCacheData;
        remove(chapterCaches, ({ chapterId }) => chapterIds.includes(chapterId));
        remove(frontMatterIds, (chapterId) => chapterIds.includes(chapterId));
        this.bookCaches = { ...this.bookCaches, [`${bookId}`]: { chapterCaches, fullPageCount, frontMatterIds } };
    }

    /**
     *  Get all chapter IDs in a given book
     * @param bookId Book ID
     * @returns string[]
     */
    private getAllChapterIds = (bookId: string) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const chapterIds = bookCacheData.chapterCaches.map(cache => cache.chapterId);
        return chapterIds;
    }

    /**
     * Get all chapter cache in a given book
     * @param bookId Book ID
     * @returns 
     */
    private getAllChapterCaches = (bookId: string) => {
        const bookCacheData = this.getBookCacheData(bookId);
        return bookCacheData.chapterCaches;
    }

    /**
     * Get TOC cahpter caches in a given book
     * @param bookId Book ID
     * @returns 
     */
    private getTOCChapters = (bookId: string) => {
        const bookCacheData = this.getBookCacheData(bookId);
        const tocChapters = bookCacheData.chapterCaches.filter(cache => cache.chapterType === "toc");
        if (tocChapters.length == 0) {
            throw new Error(`TOC Chapter not found in PDFCacheStore book:${bookId}`);
        }
        return tocChapters;
    };

    /**
     * Validate TOC status
     * 
     * @summary
     * - If all chapters without TOC is `valid` then make TOC as `valid` otherwise `partial`
     * 
     * @param bookId Book ID
     */
    private validateTOCChapterStatus = (bookId: string , tocChapterId:string) => {
        const tocChapters = this.getTOCChapters(bookId);
        const { chapterCaches } = this.getBookCacheData(bookId);
        const chapterCachesWithoutTOC = chapterCaches.filter(cache => cache.chapterType !== "toc");
        const isAllChaptersWithoutTOCValid = chapterCachesWithoutTOC.filter(({ status }) => (["invalid"].includes(status))).length === 0;
        if (isAllChaptersWithoutTOCValid && tocChapters.find((chap)=>chap.chapterId === tocChapterId)?.status === "partial") {
            this.changeChaptersCacheStatus(bookId, [tocChapterId], "valid");
        } else {
            this.changeChaptersCacheStatus(bookId, [tocChapterId], "partial");
        }
    }

    /**
     * Get chapter first page numbers
     * !This returns summary data for all chapters (for all fontmatter and body chapters)
     * @param bookId Book Id
     * @returns 
     */
    private getChapterFirstPageNumbers = (bookId: string): PDFReadyTOCSummeryItem[] => {
        const { chapterCaches, frontMatterIds } = this.getBookCacheData(bookId);

        return chapterCaches.map(({ chapterId }) => {
            let pageNumber = (getOffsetPageCount(chapterCaches, chapterId, frontMatterIds) + 1);
            if (this.isFrontMatterChapter(bookId, chapterId)) {
                pageNumber = arabToRoman(pageNumber);
            }
            return { chapterId, pageNumberText: pageNumber.toString() } as PDFReadyTOCSummeryItem;
        });
    }

    /**
     * Get the ID of a new chapter added
     * @param bookId Book ID
     * @param chapters Chapter data
     * @returns 
     */
    private getAddedChapterId = (bookId: string, chapters: IPDFCacheStore.ChapterCacheMetaData[]) => {
        const currentChapterCaches = this.getAllChapterCaches(bookId);
        const currentChapterIds = currentChapterCaches.map(({ chapterId }) => chapterId);
        const updatedChapterIds = chapters.map(({ chapterId }) => chapterId);
        const newChapterIds = updatedChapterIds.filter(id => !currentChapterIds.includes(id));
        if (newChapterIds.length === 0) {
            return null;
        }
        return newChapterIds[0];
    }

    /**
     * Update frontMatter ids
     * @param bookId 
     * @param ids 
     * @param updateFor 
     */
    private updateBookCacheFrontMatterIds = (bookId: string, ids: string[]) => {
        const { chapterCaches, fullPageCount } = this.getBookCacheData(bookId);
        this.bookCaches = { ...this.bookCaches, [`${bookId}`]: { chapterCaches, fullPageCount, frontMatterIds: ids } };
    }

    /**
     * Check if the given chapter is in the frontmatter
     * 
     * @param bookId Book Id
     * @param chapterId Chapter Id
     * @returns If chapter is in the frontMatter return `true` else return `false`
     */
    private isFrontMatterChapter = (bookId: string, chapterId: string) => {
        const { frontMatterIds } = this.getBookCacheData(bookId);
        return frontMatterIds.includes(chapterId);
    }

    /**
     * Call reset renderer callback
     * 
     */
    private resetRenderer = (): void => {
        if (!this.resetRendererCallback) return;
        this.resetRendererCallback();
    }

    /**
     * Set reset renderer callback
     * @param resetRendererCallback Reset renderer callback function
     */
    setResetRendererCallback = (resetRendererCallback: () => void): void => {
        this.resetRendererCallback = resetRendererCallback;
    }

    /**
     * Refresh cache for the cache change events in a given book according to the rules
     * 
     * @summary
     * 
     * - Contents in Chapter X changes
     *      -  Mark Chapter X as `invalid`
     *      -  Mark TOC and all chapters after Chapter X as `partial`
     * - Theme props change / full page image changes
     *      -  Mark all as `invalid`
     * - Book props change
     *      -  Mark all as `invalid`
     * - Chapter title/subtitle on Chapter X changes
     *      -  Mark TOC as `invalid`
     *      -  Mark all chapters after TOC as `partial`
     *      -  Mark chapter X as `invalid`
     * - Chapter gets added
     *      -  Mark TOC as `invalid`
     *      -  Mark all chapters after TOC as `partial`
     *      -  Mark new chapter as `invalid`
     * - Chapter gets deleted
     *      -  Mark TOC as `invalid`
     *      -  Mark all chapters after TOC as `partial`
     *      -  Remove the deleted chapter from cache
     * 
     * @param bookId Book ID
     * @param event Cache change event
     * @param data Cache change event data
     */
    refreshCache = (bookId: string, event: IPDFCacheStore.PDFChangeEvent, data?: IPDFCacheStore.RefreshCacheData): void => {
        if (!["chapter-add"].includes(event)) {
            this.resetRenderer();
        }

        const getImmediateTocChapterId = (chapterId: string): string => {
            const bookCacheData = this.getBookCacheData(bookId);
            const tocChapters = this.getTOCChapters(bookId);
            const currentChapter = bookCacheData.chapterCaches.find((chap) => chap.chapterId === chapterId);
            
            if (!currentChapter) {
                throw("Chapter not found");
            }

            let parentChapter = bookCacheData.chapterCaches.find((chap) => chap.chapterId === currentChapter?.parentChapterId);

            if (parentChapter?.chapterType === "part" && parentChapter.parentChapterId) {
                const parentId = parentChapter.parentChapterId;
                parentChapter = bookCacheData.chapterCaches.find((chap) => chap.chapterId === parentId);
            }

            if (!parentChapter || isChapterTopLevel(parentChapter.chapterId, bookId)) {
                return tocChapters[0].chapterId;
            }

            const tocChapter = tocChapters.find((chap) => chap.parentChapterId === parentChapter?.chapterId);

            if (!tocChapter) {
                throw("Immediate toc chapter not found");
            }

            return tocChapter.chapterId;
        };

        const changeTOCChapterCacheStatus = (status: IPDFCacheStore.CacheStatus, secondTocChapterId: string | undefined, applyToAllTocChapters?: boolean) => {
            const tocChapters = this.getTOCChapters(bookId);
            if (applyToAllTocChapters) {
                this.changeChaptersCacheStatus(bookId, tocChapters.map((chap) => chap.chapterId), status);
                return;
            }
            this.changeChaptersCacheStatus(bookId, tocChapters.filter((chap) => isChapterTopLevel(chap.parentChapterId, bookId) || chap.chapterId === secondTocChapterId).map((chap) => chap.chapterId), status);
        };

        // TODO: Invalidate Endnotes chapter and affected chapters

        const criterias: IPDFCacheStore.RefreshCacheCriterias = {
            "theme-change": () => {
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "book-properties-change": () => {
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "toc-properties-change": () => {
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "chapter-index-change": () => {
                const chapters = data?.["chapter-index-change"]?.chapters;
                const frontMatterIds = data?.["chapter-index-change"]?.frontMatterIds;
                if (!chapters) {
                    throw new Error(`Chapters not found in PDFCacheStore book:${bookId} refresh request`);
                }
                if (!frontMatterIds) {
                    throw new Error(`FrontMatterIds not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.updateBookCacheChapters(bookId, chapters);
                this.updateBookCacheFrontMatterIds(bookId, frontMatterIds);
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "full-page-image-chapter-add": () => {
                const chapters = data?.["full-page-image-chapter-add"]?.chapters;
                if (!chapters) {
                    throw new Error(`Chapters not found in PDFCacheStore book:${bookId} refresh request`);
                }
                const addedChapterId = this.getAddedChapterId(bookId, chapters);
                if (!addedChapterId) return;
                this.updateBookCacheChapters(bookId, chapters);
                const allChapterCaches = this.getAllChapterCaches(bookId);
                const imageChapterCachesExist = allChapterCaches.filter(cache => cache.chapterType === "image").length > 0;
                if (imageChapterCachesExist) {
                    this.updateBookCacheChapters(bookId, chapters);
                    const allChaptersIds = this.getAllChapterIds(bookId);
                    const tocChapters = this.getTOCChapters(bookId);
                    const allChapterIdsWithoutNewChapterId = allChaptersIds.filter(id => id !== addedChapterId);
                    const bookTocChapterIndex = allChaptersIds.findIndex(id => tocChapters[0].chapterId === id);
                    const effectedChapterIds = allChapterIdsWithoutNewChapterId.slice(bookTocChapterIndex + 1, allChaptersIds.length);
                    this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                    changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(addedChapterId));
                } else {
                    this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
                }
            },
            "full-page-image-chapter-delete": () => {
                const chapterId = data?.["full-page-image-chapter-delete"]?.chapterId;
                const chapters = data?.["full-page-image-chapter-delete"]?.chapters;
                if (!chapterId || !chapters) {
                    throw new Error(`Deleting chapter and Current chapters not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.removeChapterCache(bookId, [chapterId]);
                const imageChapterCachesExist = chapters?.filter(cache => cache.chapterType === "image").length > 0;
                this.updateBookCacheChapters(bookId, chapters);
                if (imageChapterCachesExist) {
                    const allChapters = this.getAllChapterIds(bookId);
                    const tocChapters = this.getTOCChapters(bookId);
                    const bookTocChapterIndex = allChapters.findIndex(id => tocChapters[0].chapterId === id);
                    const effectedChapterIds = allChapters.slice(bookTocChapterIndex + 1, allChapters.length);
                    this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                    changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(chapterId));
                } else {
                    this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
                }
            },
            "full-page-image-chapter-change": () => {
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "chapter-add": () => {
                const chapters = data?.["chapter-add"]?.chapters;
                if (!chapters) {
                    throw new Error(`Chapters not found in PDFCacheStore book:${bookId} refresh request`);
                }
                const addedChapterId = this.getAddedChapterId(bookId, chapters);
                if (!addedChapterId) return;
                this.updateBookCacheChapters(bookId, chapters);
                const allChapterIds = this.getAllChapterIds(bookId);
                const allChapterIdsWithoutNewChapterId = allChapterIds.filter(id => id !== addedChapterId);
                const tocChapters = this.getTOCChapters(bookId);
                const bookTocChapterIndex = allChapterIds.findIndex(id => tocChapters[0].chapterId === id);
                const effectedChapterIds = allChapterIdsWithoutNewChapterId.slice(bookTocChapterIndex + 1, allChapterIds.length);
                changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(addedChapterId));
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
            },
            "chapter-delete": () => {
                const chapterId = data?.["chapter-delete"]?.chapterId;
                const chapters = data?.["chapter-delete"]?.chapters;
                if (!chapterId || !chapters) {
                    throw new Error(`Deleting chapter and Current chapters not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.removeChapterCache(bookId, [chapterId]);
                this.updateBookCacheChapters(bookId, chapters);
                const allChapters = this.getAllChapterIds(bookId);
                const tocChapters = this.getTOCChapters(bookId);
                const bookTocChapterIndex = allChapters.findIndex(id => tocChapters[0].chapterId === id);
                const effectedChapterIds = allChapters.slice(bookTocChapterIndex + 1, allChapters.length);
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(chapterId));
            },
            "chapter-subtitle-change": () => {
                const chapterId = data?.["chapter-subtitle-change"]?.chapterId;
                if (!chapterId) {
                    throw new Error(`Chapter not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.changeChaptersCacheStatus(bookId, [chapterId], "invalid");
                const allChapters = this.getAllChapterIds(bookId);
                const tocChapters = this.getTOCChapters(bookId);
                const bookCacheData = this.getBookCacheData(bookId);
                const bookTocChapterIndex = allChapters.findIndex(id => tocChapters[0].chapterId === id);
                const effectedChapterIds = allChapters.slice(bookTocChapterIndex + 1, allChapters.length);
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(chapterId));
            },
            "chapter-title-change": () => {
                const chapterId = data?.["chapter-title-change"]?.chapterId;
                if (!chapterId) {
                    throw new Error(`Chapter not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.changeChaptersCacheStatus(bookId, [chapterId], "invalid");
                const allChapters = this.getAllChapterIds(bookId);
                const tocChapters = this.getTOCChapters(bookId);
                const bookCacheData = this.getBookCacheData(bookId);
                const chapter = bookCacheData.chapterCaches.find(
                  (chapter) => chapter.chapterId === chapterId
                );

                if (chapter && ["part", "volume"].includes(chapter.chapterType)) {
                  let titleChapterIds: string[] = [];
                  if (chapter.chapterType === "part") {
                    titleChapterIds = bookCacheData.chapterCaches
                      .filter((chapter) => {
                        return (
                          chapter.chapterType === "title" &&
                          chapter.parentChapterId === chapterId
                        );
                      })
                      .map((chapter) => chapter.chapterId);
                  } else if (chapter.chapterType === "volume") {
                    titleChapterIds = bookCacheData.chapterCaches
                      .filter((chapter) => {
                        return (
                          chapter.chapterType === "title" &&
                          (chapter.parentChapterId === chapterId ||
                            !chapter.parentChapterId)
                        );
                      })
                      .map((chapter) => chapter.chapterId);
                  }
                  this.changeChaptersCacheStatus(
                    bookId,
                    titleChapterIds,
                    "invalid"
                  );
                }
                  
                const bookTocChapterIndex = allChapters.findIndex(id => tocChapters[0].chapterId === id);
                const effectedChapterIds = allChapters.slice(bookTocChapterIndex + 1, allChapters.length);
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                changeTOCChapterCacheStatus("invalid", getImmediateTocChapterId(chapterId));
            },
            "chapter-contents-change": () => {
                const chapterId = data?.["chapter-contents-change"]?.chapterId;
                const invalidateOnly = data?.["chapter-contents-change"]?.invalidateOnly;

                if (!chapterId) {
                    throw new Error(`Chapter not found in PDFCacheStore book:${bookId} refresh request`);
                }

                if(invalidateOnly !== "others") { 
                    this.changeChaptersCacheStatus(bookId, [chapterId], "invalid");
                }

                const allChapterIds = this.getAllChapterIds(bookId);
                const bookCacheData = this.getBookCacheData(bookId);
                const chapterIndex = allChapterIds.findIndex(id => chapterId === id);
                const effectedChapterIds = allChapterIds.slice(chapterIndex + 1, allChapterIds.length);

                if(invalidateOnly == "current") return;

                changeTOCChapterCacheStatus("partial", getImmediateTocChapterId(chapterId));
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
            },
            "chapter-list-properties-change": () => {
                const chapters = data?.["chapter-list-properties-change"]?.chapters;
                if (!chapters) {
                    throw new Error(`Chapter Ids not found in PDFCacheStore book:${bookId} refresh request`);
                }
                chapters.forEach((chap) => this.updateChapterCacheData(bookId, chap.chapterId, chap));
                const changedChapterIds = chapters.map(({ chapterId }) => chapterId);
                const allChapterCaches = this.getAllChapterCaches(bookId);
                const allChapterIds = allChapterCaches.map(({ chapterId }) => chapterId);
                const chapterIndexData = allChapterCaches.map(({ chapterId }, index) => ({ chapterId, index }));
                const minimumChapterIndex = Math.min(...chapterIndexData.map(({ index }) => index));
                const effectedChapterIds = allChapterIds.slice(minimumChapterIndex + 1, allChapterIds.length);
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
                this.changeChaptersCacheStatus(bookId, changedChapterIds, "invalid");
                changeTOCChapterCacheStatus("partial", undefined, true);
            },
            "chapter-merge": () => {
                const chapters = data?.["chapter-merge"]?.chapters;
                if (!chapters) {
                    throw new Error(`Chapter not found in PDFCacheStore book:${bookId} refresh request`);
                }
                const updatedChapterIds = chapters.map(({ chapterId }) => chapterId);
                const currentAllChapters = this.getAllChapterCaches(bookId);
                const removedChapters = currentAllChapters.filter(({ chapterId }) => !updatedChapterIds.includes(chapterId));
                const removedChapterIds = removedChapters.map(({ chapterId }) => chapterId);
                this.removeChapterCache(bookId, removedChapterIds);
                this.updateBookCacheChapters(bookId, chapters);
                this.changeChaptersCacheStatus(bookId, this.getAllChapterIds(bookId), "invalid");
            },
            "chapter-properties-change": () => {
                const chapter = data?.["chapter-properties-change"]?.chapter;
                if (!chapter) {
                    throw new Error(`Chapter not found in PDFCacheStore book:${bookId} refresh request`);
                }
                this.updateChapterCacheData(bookId, chapter.chapterId, chapter);
                this.changeChaptersCacheStatus(bookId, [chapter.chapterId], "invalid");
                const allChapterIds = this.getAllChapterIds(bookId);
                const chapterIndex = allChapterIds.findIndex(id => chapter.chapterId === id);
                const effectedChapterIds = allChapterIds.slice(chapterIndex + 1, allChapterIds.length);
                changeTOCChapterCacheStatus("partial", undefined, true);
                this.changeChaptersCacheStatus(bookId, effectedChapterIds, "partial");
            }
        };

        criterias[event]();

    }

    /**
     * refreshCache debounced at 5 seconds
     */
    debouncedRefreshCache = debounce(this.refreshCache, 5000);

    refreshCacheEditorOnChange = (bookId: string, chapterId: string): void => {
        const debounceOnEditorChange = debounce(() => {
            this.refreshCache(bookId, "chapter-contents-change", {
                "chapter-contents-change": {
                chapterId,
                invalidateOnly: "current",
                },
            });
            this.debouncedRefreshCache(bookId, "chapter-contents-change", {
                "chapter-contents-change": {
                chapterId,
                invalidateOnly: "others",
                },
            });
        }, 800);

        debounceOnEditorChange();
    }
}

export default new PDFCacheStore();