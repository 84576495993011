/**
 * Given parentChapterID if child chapter is top level in the book
 *
 * @param parentChapterId parentChapterId of the chapter to check
 * @param bookId
 * @returns true if the chapter is in the top level
 */
export const isChapterTopLevel = (
  parentChapterId: string | undefined,
  bookId: string
): boolean => [undefined, bookId].includes(parentChapterId);

/**
 * @param chapterId
 * @param chapters
 * @returns Volume Chapter if an ancestor volume exists
 */
//TODO:BODY
export const getChapterAncestorVolume = (
  chapterId: string,
  chapters: IChapterStore.ChapterMeta[]
): IChapterStore.ChapterMeta | undefined => {
  const getAncestorVolume = (
    internalChapterId: string
  ): IChapterStore.ChapterMeta | undefined => {
    const chapter = chapters.find((r) => r._id === internalChapterId);
    if (!chapter) return undefined;
    if (chapter.type === "volume") return chapter;
    if (chapter.parentChapterId)
      return getAncestorVolume(chapter.parentChapterId);
    return undefined;
  };

  const chapter = chapters.find(
    (candidateChapter) => candidateChapter._id === chapterId
  );

  if (!chapter?.parentChapterId) return undefined;
  return getAncestorVolume(chapter.parentChapterId);
};

/**
 * Checks if the chapter in question has an ancestor volume chapter
 * @param chapterId
 * @param chapters
 * @returns true if the chapter in question has an ancestor volume chapter
 */
//TODO:BODY
export const isChapterInsideVolume = (
  chapterId: string,
  chapters: IChapterStore.ChapterMeta[]
): boolean => {
  return !!getChapterAncestorVolume(chapterId, chapters);
};

/**
 * Get the last descendent chapter index of the chapters array for a chapterId
 * @param parentChapterId chapterId of the parent to get the last descendent index
 * @param chapters
 * @returns {number} index of the last child under the parent chapter
 */
export const getLastDescendentIndex = (
  parentChapterId: string,
  chapters: IChapterStore.ChapterMeta[]
): number => {
  const children = chapters.filter(
    (chap) => chap.parentChapterId === parentChapterId
  );
  if (children.length === 0) {
    return chapters.findIndex((chap) => chap._id === parentChapterId);
  } else {
    return getLastDescendentIndex(children[children.length - 1]._id, chapters);
  }
};

/**
 * Get all the ancestor chapters of a chapter in the chapters array
 * @param chapterId
 * @param chapters
 * @returns {ChapterMeta[]} array of ancestor chapters
 */
//TODO:BODY
export const getAncestralChapters = (
  chapterId: string,
  chapters: IChapterStore.ChapterMeta[]
): IChapterStore.ChapterMeta[] => {
  const chapter = chapters.find((chap) => chap._id === chapterId);
  if (!chapter) return [];
  const parentChapter = chapters.find(
    (chap) => chap._id === chapter.parentChapterId
  );
  if (!parentChapter) return [];
  return [parentChapter, ...getAncestralChapters(parentChapter._id, chapters)];
};

export const CHAPTER_TITLE_LOWEST_SCALE = 66; // Value to represent a lowest scale for a Title Scale
export const CHAPTER_TITLE_HIGHEST_SCALE = 100; // Value to represent the highest scale for a Title Scale