import { makeAutoObservable, toJS } from "mobx";
import { debounce, find, uniq } from "lodash";

import { AtticusClient } from "../api/atticus.api";
import {
  UpdateBookInDB,
  GetBooksFromDB,
  DeleteBooksFromDB,
  SaveChapterMetaToDB,
  SaveNewBookToDB,
} from "../utils/offline.book.helpers";
import { getOnlineStatus } from "../utils/hooks/isOffline";
import {
  localBookToRemoteBook,
  saveBookToRemoteDB,
  patchBookInLocalDB,
  syncRemoteBookWithLocalDB,
} from "../utils/sync/helper";
import { getInitBookContent } from "./helpers";
import { getSignedAbilitiesForNewBook } from "../components/casl/helpers";
import { authStore, bookSyncWebSocketStore, collaborationStore } from ".";
import { ShelfWSMessageData } from "../types/common";
import { BOOKSHELF_BOOK_ADDED, BOOKSHELF_BOOK_REMOVED, wsSendShelfUpdateMessage } from "../utils/bookshelf-ws-helper";


const fallbackThemeId = process.env.REACT_APP_DEFAULT_THEME || "finch";


export class ShelfStore {
  loading = true;
  books: IBookStore.Book[] = [];
  collab_books: IBookStore.Book[] = [];
  sortBy: IShelfStore.BookSortOptionType = "recently-added";
  searchTerm = "";
  view = "grid";
  newBookModal = false;
  uploadBookModal = false;
  newBoxsetModal = false;

  constructor() {
    makeAutoObservable(this);
  }

  setModal = (key: "newBookModal" | "uploadBookModal" | "newBoxsetModal", show: boolean) => {
    this[key] = show;
  }
  
  // Getters and Setters

  pushBook = (book: IBookStore.Book): void => {
    this.books.push(book);
  }

  setLoading = (loading: boolean): void => {
    this.loading = loading;
  }

  setBooks = (books: IBookStore.Book[]): void => {
    this.books = books;
  }

  setShelfCollabBooks = (books: IBookStore.Book[]): void => {
    this.collab_books = books;
  }

  setSortBy = (sortBy: IShelfStore.BookSortOptionType) => {
    this.sortBy = sortBy;
  }

  setSearchTerm = (term: string) => {
    this.searchTerm = term;
  }

  setView = (view: "grid" | "list") => {
    this.view = view;
  }

  updateBookInBooks = (book: IBookStore.Book) => {
    const b = find(this.books, {"_id": book._id});
    if(b && b._id){
        const bks = this.books.map(d => d._id === b._id ? b : d);
        this.setBooks(bks);
    }
  }

  // fetch functions
  loadBooks = async (setLoading = true): Promise<void> => {
    this.setLoading(setLoading);
    const booksFromDb = await GetBooksFromDB();
    this.setBooks(booksFromDb);
    this.setLoading(false);
  }

  removeAccessByCollabUser = async (bookId: string): Promise<boolean> => {
    const res = await AtticusClient.RemoveAccessFromCollabBook(bookId);
    const filtered = this.collab_books.filter((book) => book._id !== bookId);
    this.collab_books = filtered;
    return res;
  }

  // Group and return authors and projects
  getAuthors = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      book.author.forEach((author) => list.add(author));
    });

    return Array.from(list) as string[];
  }

  getProjects = (): string[] => {
    const list = new Set();
    this.books.forEach((book) => {
      list.add(book.project);
    });

    return Array.from(list) as string[];
  }

  getVersionTags = (): string[] => {
    const tags: string[] = [];

    this.books.forEach((book) => {
      if (Array.isArray(book.versionTags)) {
        tags.push(
          ...book.versionTags
        );
      }
    });

    return uniq<string>(tags);
  }

  deleteBook = async (bookId: string): Promise<void> => {
    try{
      await AtticusClient.DeleteBook(bookId);
      await DeleteBooksFromDB([bookId]).then(()=> {
        const { user } = authStore;
        const { socket } = bookSyncWebSocketStore;
        // notify bookshelf update through ws
        if (user) {
          const data: ShelfWSMessageData = {
            userId: user._id,
            bookId: bookId,
            isCollabBook: false,
          };
          if (socket)
           wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_REMOVED, data);
        }
      });

      const filtered = this.books.filter((book) => book._id !== bookId);
      this.setBooks(filtered);
    }catch(error){
      console.error(error);
      throw error;
    }
  }

  duplicateBook = async (bookId: string): Promise<void> => {
    try {
      const duplicatedBookId = await AtticusClient.DuplicateBook(bookId);
      await syncRemoteBookWithLocalDB(duplicatedBookId).then(() => {
        const { user } = authStore;
        const { socket } = bookSyncWebSocketStore;
        if (user) {
          const data: ShelfWSMessageData = {
            userId: user._id,
            bookId: duplicatedBookId,
            isCollabBook: false,
          };
          if (socket)
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
        }
      });
      await this.loadBooks();
    } catch (e: any) {
      console.log(e);
      throw e;
    }
    // const bookResponse = await AtticusClient.GetBooks();
    // for(const bk of bookResponse.books) {
    //   await saveRemoteBooksToLocalDB(bk._id);
    // }
    // await this.loadBooks();
    // location.reload();
  }

  newBook = async (params: IShelfStore.BookForm): Promise<void> => {
    const newBook: IBookStore.ExpandedBook = {
      _id: params._id,
      coverImageUrl: "",
      title: params.title,
      author: params.author,
      project: params.project,
      modifiedAt: new Date(),
      createdAt: new Date(),
      chapterIds: [],
      frontMatterIds: [],
      deletedChapterIds: [],
      frontMatter: [],
      chapters: [],
      themeId: fallbackThemeId,
    };
    const chapterContent = getInitBookContent(params._id, params.chapterId);
    const { body, copyright, toc, title } = chapterContent;
    newBook.chapterIds = [body._id];
    newBook.frontMatterIds = [title._id, copyright._id, toc._id];
    newBook.chapters = [body];
    newBook.frontMatter = [title, copyright, toc];

    const allPromises: Promise<unknown>[] = [];
    allPromises.push(
      SaveChapterMetaToDB([
        ...newBook.frontMatter,
        ...newBook.chapters,
      ])
    );

    const abilities = getSignedAbilitiesForNewBook();

    const newBookWithAbilities = {
      ...newBook,
      isLocal: true, // newly created book in local
      abilities
    };

    const { chapters, frontMatter, ...bookWithoutChapters } = newBookWithAbilities;

    allPromises.push(SaveNewBookToDB(bookWithoutChapters));
    await Promise.all(allPromises);
    const isOnline = getOnlineStatus();
    if (isOnline) {
      /**
       * sync newly created book with the server immediately if online
       * if offline, new book gets saved to remote db when book sync is
       * automatically triggered when the browser goes online
       */
      const bookToSync = localBookToRemoteBook(newBook);
      const timestamp = await saveBookToRemoteDB(bookToSync);
      await patchBookInLocalDB({
        _id: bookToSync._id,
        abilities,
        lastSuccessfulSync: timestamp,
        allChangesSynced: true,
      });
    }
    this.loadBooks();
  }

  newBoxset = async (params: IShelfStore.BoxsetForm): Promise<string> => {
    try {
      const resp = await AtticusClient.createBoxset({
        title: params.title,
        author: params.author,
        project: params.project,
        bookIds: params.bookIds
      });
      await syncRemoteBookWithLocalDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    }
    catch (e: any) {
      console.error(e.message);
      throw e;
    }
  }

  onUpload = async (params: IShelfStore.BookFormFile): Promise<string | undefined> => {
    try {
      const resp = await AtticusClient.ImportDocument({
        url: params.fileURL,
        author: params.author,
        title: params.title,
        project: params.project,
      }, params.fileType);
      await syncRemoteBookWithLocalDB(resp.bookId);
      await this.loadBooks();
      return resp.bookId;
    } catch (e: any) {
      console.log(e.message);
      throw e;
    }
  }

  persistBook = async(bookId: string, changes: Partial<IBookStore.Book>): Promise<void> => {
    try {
      delete changes["__v"];
      delete changes["createdAt"];
      delete changes["lastUpdateAt"];

      const lastSyncAt = await AtticusClient.PatchBook(bookId, changes);
      await UpdateBookInDB(bookId, {
        lastSuccessfulSync: lastSyncAt.timestamp,
        allChangesSynced: true,
        ...toJS(changes),
      });
    } catch (e: any) {
      console.log(e);
    }
  }

  debouncedPersistBook = debounce(this.persistBook, 400);

  saveBook = async (bookId: string, changes: Partial<IBookStore.Book>, localOnly?: boolean) : Promise<void> => {
    const bookIndex = this.books.findIndex((b) => b._id === bookId);
    if (bookIndex > -1) {
      const book = {
        ...this.books[bookIndex],
        ...changes,
      };
      
      const updatedBooks = [...this.books];
      updatedBooks[bookIndex] = book;
      
      this.setBooks(updatedBooks);
      // set flag book sync
      const { setIsBookLocalUpdate} = bookSyncWebSocketStore;  
      setIsBookLocalUpdate(true);

      if(!localOnly){
        this.debouncedPersistBook(bookId, changes);
      }
    }
  }
}

export default new ShelfStore();
