import React, { useState } from "react";
import { observer } from "mobx-react";

import { Badge } from "antd";
import { NotificationIcon } from "../../content/icons";

import useRootStore from "../../store/useRootStore";

export const NotificationNavButton = observer(() => {
  const { totalUnread } = useRootStore().notificationStore;

  return totalUnread ? (
    <Badge size="small" count={totalUnread}>
      <NotificationIcon />
    </Badge>
  ) : (
    <NotificationIcon />
  );
});