import React from "react";
import { Row, Col } from "antd";
import { observer } from "mobx-react";

import EditBookDetailsForm from "../../components/Shared/Forms/EditBookDetails";
import EditPublisherDetailsForm from "../../components/Shared/Forms/EditPublisherDetails";
import BookStats from "../../components/Books/BookStat";
import ExportContainer from "../../components/Books/ExportContainer";
import SnapshotContainer from "../../components/Books/SnapshotContainer";

import { AtticusTab, TabProps } from "../../components/Shared/Tabs";
import { can } from "../../components/casl/helpers";

export const EditBook = observer(() => {
  const tabItems: TabProps[] = [
    {
      key: "book-details",
      label: "Book details",
      children: <EditBookDetailsForm />,
    },
    {
      key: "publisher-details",
      label: "Publisher details",
      children: <EditPublisherDetailsForm />,
    },
  ];

  return (
    can("view", "edit-book-details") ? (
      <div className="edit-book-wrapper">
        <div className="edit-book-scroller">
          <Row>
            <Col xs={24} md={14} lg={16} xxl={18}>
              <div className="edit-book-form-cover">
                <AtticusTab
                  variant="primary-blue"
                  defaultActiveKey="book-details"
                  hideCountBadge={true}
                  items={tabItems}
                />
              </div>
            </Col>
            <Col xs={24} md={10} lg={8} xxl={6}>
              <div className="edit-book-info-cover">
                <BookStats />
                <ExportContainer />
                <SnapshotContainer />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ) : (
      <></>
    )
  );
});

