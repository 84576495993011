import React from "react";
import { Can as CASLCan } from "@casl/react";
import { ReactNode } from "react";
import { EditorAction, EditorSubject } from "./types";
import { observer } from "mobx-react";
import { getAbilityInstance } from "./helpers";

interface Props {
  action: EditorAction;
  subject: EditorSubject;
  passThrough?: boolean;
  children: ReactNode;
}

/**
 * React component that checks if the current user has permission to perform a specific action on a subject.
 * Uses CASL for permission checking.
 * @param action - The action the user wants to perform.
 * @param subject - The subject on which the action is performed.
 * @param passThrough - Optional flag to render children regardless of permission.
 * @param children - React nodes to be rendered if the user has permission.
 */
const Can = observer(
  ({ action, subject, children, passThrough = false }: Props) => {
    const abilityInstance = getAbilityInstance();
    return (
      <CASLCan
        do={action}
        on={subject}
        ability={abilityInstance}
        passThrough={passThrough}
      >
        {children}
      </CASLCan>
    );
  }
);

export default Can;
