import React from "react";
import { List} from "antd";
import { NotificationItem } from "./";

export const NotificationList = (
  {
    notifications
  }: {
    notifications: INotificationStore.Notification[]
  }) => (
  <List
    dataSource={notifications}
    locale={{
      emptyText: <></>
    }}
    renderItem={NotificationItem}
  />
);