import React from "react";
import { Layout } from "antd";

const { Sider } = Layout;

export const MenuBar: React.FC = ({ children }) => (
  <Sider
    collapsible={false}
    className='_right'
    breakpoint='xl'
    collapsedWidth={48}
    theme='light'
    width={48}
    trigger={null}
  >
    {children}
  </Sider>
);