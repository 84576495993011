import React, { useState } from "react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate-core";
import useRootStore from "../../../../../store/useRootStore";
import { Modal, Button, Popover } from "antd";
import { SwapOutlined, DeleteOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";

import { removeProfileFromEditor, getElementPath } from "../transforms";
import { getSocialMediaIcons } from "../../../../Previewer/print/configs/social-media-icons";
import { can } from "../../../../casl/helpers";

export const SocialMediaLinkComponent = ({ element, children, id }) => {
  const editor = usePlateEditorState(useEventPlateId(id));

  const { setSMProfilePathToReplace, setShowProfileModal } =
    useRootStore().socialProfileStore;

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleEditFromEditor = () => {
    const elementPath = getElementPath(element, editor);
    setSMProfilePathToReplace(elementPath);
    setShowProfileModal(true);
  };

  const removeProfile = () => {
    const elementPath = getElementPath(element, editor);
    removeProfileFromEditor(elementPath, editor);
    setShowConfirmDeleteModal(false);
  };


  const RenderSocialProfiles = observer((iconName) => {
    return (
      <div>
        {iconName.iconName.url.map((prof) => {
          const { name } = prof;
          const iconNameWSize = name + "-Small";

          const getSM = getSocialMediaIcons(iconNameWSize);
          const smName = getSM?.smLink;
          const smID = getSM?.id;

          return (
            <div key={smID} className="social-profile-icons">
              <img style={{ width: 30, height: 30 }} src={smName} />
            </div>
          );
        })}
      </div>
    );
  });

  const EditProfilePopover = () => {
    return (
      <div>
        <Button type="primary" onClick={() => handleEditFromEditor()}>
          Change Profile
        </Button>
      </div>
    );
  };

  const DeleteProfilePopover = () => {
    return (
      <div>
        <Button
          type="primary"
          danger
          onClick={() => setShowConfirmDeleteModal(true)}
        >
          Delete
        </Button>
      </div>
    );
  };

  return (
    <div className="slate-ornamental-break">
      <div></div>
      <div
        contentEditable={false}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RenderSocialProfiles iconName={element} />
        {children}
      </div>
      <div>
        {can("view", "plugin-settings") &&
          <>
            <Popover placement="top" content={EditProfilePopover}>
              <SwapOutlined
                className="edit-profile-icon"
                onClick={() => handleEditFromEditor()}
              />
            </Popover>
            <Popover placement="top" content={DeleteProfilePopover}>
              <DeleteOutlined
                className="edit-profile-icon danger"
                onClick={() => setShowConfirmDeleteModal(true)}
              />
            </Popover>
          </>
        }
        <Modal
          visible={showConfirmDeleteModal}
          onCancel={() => setShowConfirmDeleteModal(false)}
          closable={false}
          footer={[
            <Button
              key="back"
              type="primary"
              onClick={() => setShowConfirmDeleteModal(false)}
            >
              Cancel
            </Button>,
            <Button key="submit" type="primary" danger onClick={removeProfile}>
              Remove
            </Button>,
          ]}
        >
          <div className="delete-profile-modal-body">
            <p>
              Are you sure you want to remove this Profile from the chapter?
            </p>
          </div>
        </Modal>
      </div>
    </div>
  );
};
