

import React from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import { BookActionItem } from "./BookActionItem";

import {
  GreenPlayIcon,
  BookIcon,
  UploadIcon,
  BoxsetIcon,
  GreenPlayIconWithBox
} from "../../content/icons";

import { TextAndIconLink } from "../Shared/Links/TextWithIconLink";
import { UploadBookForm, NewBookModal, NewBoxsetModal } from "./forms";
import { useOnlineStatus } from "../../utils/hooks/isOffline";
import { ShelfWSMessageData } from "../../types/common";
import { BOOKSHELF_BOOK_ADDED, wsSendShelfUpdateMessage } from "../../utils/bookshelf-ws-helper";


export const BookActionSection = observer(() => {
  const { 
    newBookModal, 
    newBoxsetModal, 
    uploadBookModal, 
    setModal,
    onUpload,
    newBook,
    newBoxset 
  } = useRootStore().shelfStore;
  const { user } = useRootStore().authStore;
  const { socket } = useRootStore().bookSyncWebSocketStore;
  
  const { push } = useHistory();

  const isOnline = useOnlineStatus();

  return (
    <>
      <div className="book-action-section inner-m">
        <div className="book-action-section-left">
          <BookActionItem 
            isDisabled={!isOnline}
            icon={<UploadIcon color="#fff" />}
            onClick={() => setModal("uploadBookModal", true)}
            label="Upload a book"
          />
          <BookActionItem
            icon={<BookIcon color="#fff" />}
            onClick={() => setModal("newBookModal", true)}
            label="Start a new book"
          />

          <BookActionItem 
            icon={<BoxsetIcon color="#fff" />}
            onClick={() => setModal("newBoxsetModal", true)}
            label="Create a new boxset"
          />
        </div>
        <div className="book-action-tutorial-card">
          <div className="tutorial-card-left">
            <p className="left-txt-line-1">Learn how to use and write your ebook with Atticus</p>
            <p className="left-txt-line-2">Create professional print books and eBooks easily with Atticus.</p>
            <TextAndIconLink
              link={"https://www.atticus.io/tutorials"}
              text={"See tutorials"}
              icon={<GreenPlayIcon />}
              isExternalLink={true}
            />
          </div>
          <a className="tutorial-card-right" href="https://www.atticus.io/tutorials" target="_blank" rel="noreferrer">
            <GreenPlayIconWithBox />
          </a>
        </div>
      </div>     
      <UploadBookForm
        showModal={uploadBookModal}
        onUpload={async (params) => {
          const bookId = await onUpload(params);
          if (user) {
						const data: ShelfWSMessageData = {
              userId: user._id,
              bookId: bookId,
              isCollabBook: false,
						};
						wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
					}
          setModal("uploadBookModal", false);
          push(`/books/${bookId}`);
        }} 
        onClose={() => setModal("uploadBookModal", false)}
      />
      <NewBookModal  
        showModal={newBookModal}  
        onNewBook={async (params) => {
          await newBook(params);
          if (user) {
            const data: ShelfWSMessageData = {
              userId: user._id,
              bookId: params._id,
              isCollabBook: false,
            };
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
          }
          setModal("newBookModal", false);
          push(`/books/${params._id}/${params.chapterId}`);
        }}        
        onClose={() => setModal("newBookModal", false)}
      />
      <NewBoxsetModal 
        showModal={newBoxsetModal}  
        onNewBoxset={async (params) => {
          const bookId = await newBoxset(params);
          if (user) {
            const data: ShelfWSMessageData = {
              userId: user._id,
              bookId: bookId,
              isCollabBook: false,
            };
            wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
          }
          setModal("newBoxsetModal", false);
          push(`books/${bookId}`);
        }}
        onClose={() => setModal("newBoxsetModal", false)}
      />
    </>
  );
});
