
import React, { useEffect, useRef, useState } from "react";
import { MyRootBlock } from "./config/typescript";

import { Editor } from "./editor";

//store
import useRootStore from "../../store/useRootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { SceneUtils } from "../../utils/scene/sceneServices";
import { OnlineChecker, Synchronization, WritingHabitWordCounter } from "../Goals/WritingHabit/components";

export const EditorWrapper = observer(() => {
  const { book, changeCount, chapterTemplate} = useRootStore().bookStore;
  const { chapterMeta: currentChapter } = useRootStore().chapterStore;
  const { setSidebarPreviewerOnlyMode, chapterTemplateView } = useRootStore().appStore;

  // const hasSceneCacheMapUpdate = useRef<boolean>(false);
  const hasNewKeyBeenGenerated = useRef<boolean>(false);

  const [key, setKey] = useState<string>(
    Date.now().toString()
  );

  const generateNewKey = () => {
    setKey(Date.now().toString());
  };

  // Trigger a state change on change of book, chapter or changeCount to re-render the Editor.
  useEffect(() => {
    // setLocalBody(
    //   // toJS(sceneIndex !== null ? SceneUtils.getSceneContent({...chapter, children:body}, sceneIndex).children : body)
    //   toJS(body)
    // );
    hasNewKeyBeenGenerated.current = true;
    generateNewKey();
  }, [book._id, currentChapter._id, changeCount, chapterTemplateView, chapterTemplate._id]);

  // useEffect(() => {
  //   if (hasSceneCacheMapUpdate.current && hasNewKeyBeenGenerated.current) {
  //     // setLocalBody(
  //     //   toJS(
  //     //     sceneIndex !== null
  //     //       ? SceneUtils.getSceneContent(
  //     //           { ...chapter, children: body },
  //     //           sceneIndex
  //     //         ).children
  //     //       : body
  //     //   )
  //     // );
  //     setLocalBody(toJS(body));
  //     generateNewKey();
  //   }

  //   hasNewKeyBeenGenerated.current = false;
  //   hasSceneCacheMapUpdate.current = false;
  // }, [body]);

  // useEffect(() => {
  //   hasSceneCacheMapUpdate.current = true;
  // }, [sceneCacheMap]);

  useEffect(() => {
    // set setSidebarPreviewerOnlyMode to false for !isWithoutEditor() chapters unless it's a master page
    setSidebarPreviewerOnlyMode(chapterTemplateView);
  }, [chapterTemplateView]);
  

  return <>
    <OnlineChecker />
    <Synchronization />
    <WritingHabitWordCounter />
    {/* {localBody.length > 0 ? <Editor initialBodyValue={localBody} key={key} sceneIndex={sceneIndex} /> : null} */}
    <Editor key={key} />
  </>;
});
