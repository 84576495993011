
import React, { useState } from "react";
import {
    findNodePath,
    setNodes,
    usePlateEditorState,
    PlateRenderElementProps,
    TElement
} from "@udecode/plate";
import { Button } from "antd";

import { MyEditor } from "../../../config/typescript";
import { BlockquoteModal } from "./blockquoteModal";
import { BlockquoteElementProps, BlockquoteNode } from "../types";
import { SettingsIcon } from "../../../../../content/icons";
import { can } from "../../../../casl/helpers";

export const BlockquoteComponent = ({ element, children, attributes } : PlateRenderElementProps) => {
    const editor = usePlateEditorState() as MyEditor;
    const [modal, toggleModal] = useState(false);

    const onUpdateQuotee = (el: BlockquoteElementProps) => {
        const path = findNodePath(editor, element);
        setNodes(editor, { quotee: el.quotee }, { at: path });
    };

    return (
        <div className="editor-block-cont">
            <blockquote className="slate-blockquote" {...attributes}>
                {children}
                {element.quotee && (
                    <div contentEditable={false} className="slate-blockquote-quotee">{element.quotee as string}</div>
                )}
            </blockquote>
            <div className="editor-block-action" contentEditable={false}>
                {can("view", "plugin-settings") &&
                    <Button
                        //onClick={e => e.stopPropagation()}
                        className="editor-block-action-btn"
                        onClick={() => toggleModal(true)}
                    >
                        <SettingsIcon size={16} />
                    </Button>}        
            </div>
            {modal && (
                <BlockquoteModal
                    element={element as BlockquoteNode}
                    onUpdate={onUpdateQuotee}
                    onClose={() => toggleModal(false)}
                />
            )}
        </div>
    );
};