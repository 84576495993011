import React from "react";
import { Menu } from "antd";
import { FunctionComponent } from "react";
import useRootStore from "../../store/useRootStore";
import { getBookMetaSummaryForCache } from "../../utils/sidebar";
import {can} from "../casl/helpers";
interface ContextMenuProps {
  top?: number;
  left?: number;
  canDisplay: boolean;
}
interface ContextMenuOption {
  name: string;
  key: string;
  action: () => void;
  disabled?: boolean;
}
const ContextMenu: FunctionComponent<ContextMenuProps> = ({ top, left, canDisplay }) => {
  const { selectedChapters, selectedChapterOptions, resetSelectedChapters } = useRootStore().sideMenuStore;
  const { groupChapters } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;

  const invalidatePDFCache = (book: IBookStore.ExpandedBook) => {
    const { chapterCacheData } = getBookMetaSummaryForCache(book.frontMatter, book.chapters);
    refreshCache(book._id, "chapter-add", { "chapter-add": { chapters: chapterCacheData } });
  };

  const getOptions = () => {
    const menu: ContextMenuOption[] = [];
    if (selectedChapters.length > 0) {
      menu.push(
        {
          name: "Create a part",
          key: "1",
          action: async () => {
            setIsChapterLocalUpdate(true);
            setChangeEvent("chapter-add");
            resetSelectedChapters();
            const book = await groupChapters(selectedChapters, "part");
            if (book !== null) invalidatePDFCache(book);
          },
          disabled: !selectedChapterOptions.createPart,
        },
        {
          name: "Create a volume",
          key: "2",
          action: async () => {
            setIsChapterLocalUpdate(true);
            setChangeEvent("chapter-add");
            resetSelectedChapters();
            const book = await groupChapters(selectedChapters, "volume");
            if (book !== null) invalidatePDFCache(book);
          },
          disabled: !selectedChapterOptions.createVolume,
        }
      );
    }
    return menu;
  };
  return (
    can("view", "create-volumes-parts") ? (
      <Menu className="context-menu" style={{ display: canDisplay ? "block" : "none", top, left }}>
        {getOptions().map((option) => (
          <Menu.Item key={option.key} onClick={option.action} disabled={option.disabled}>
            {option.name}
          </Menu.Item>
        ))}
      </Menu>
    ) : <></>
  );
};
export default ContextMenu;
