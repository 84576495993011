import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Col, Row } from "antd";
import { TComment } from "../Plate";
import useRootStore from "../../store/useRootStore";
import {
  TrackChangeReply,
  TTrackChange,
} from "../Plate/plugins/track-changes/types";
import { CommentThread } from "../Plate/plugins/comments/components/CommentThread";
import { TrackChangeThread } from "../Plate/plugins/track-changes/components/TrackChangeThread";
import { Value } from "@udecode/plate";
import { TCommentThread } from "../../types/comments";
import { calculateThreadWrapperHeight } from "./helper";

interface CommentsAndTCProps {
  resolveComment?: (commentId: string) => void;
  submitReplyComment?: (replyValue: string) => void;
  deleteComment?: (comment: TComment) => void;
  updateComment?: (comment: TComment) => void;
  approveTrackChange?: (trackChange: TTrackChange[]) => void;
  declineTrackChange?: (trackChange: TTrackChange[]) => void;
  submitReplyTrackChange?: (
    replyValue: Value,
    trackChange: TTrackChange
  ) => void;
  updateTrackChangeReply?: (
    trackChangeNode: TTrackChange,
    updatedReply: string,
    reply: TrackChangeReply
  ) => void;
  deleteReply?: (trackChange: TTrackChange, reply: TrackChangeReply) => void;
}

export const CommentsAndTC: React.FC<CommentsAndTCProps> = 
  observer(({
    resolveComment,
    submitReplyComment,
    deleteComment,
    updateComment,
    approveTrackChange,
    declineTrackChange,
    submitReplyTrackChange,
    updateTrackChangeReply,
    deleteReply,
  }) => {
    const { comments, commentsAndTrackChanges, setActiveCommentId } =
      useRootStore().commentsStore;

    const { trackChanges, setActiveTrackChangeId } =
      useRootStore().trackChangesStore;

    const threadContentRef = useRef<HTMLDivElement | null>(null);
    const [threadContentHeight, setThreadContentHeight] = useState<
      string | null
    >(null);

    useEffect(() => {
      const updateHeight = () => {
        const height = calculateThreadWrapperHeight(threadContentRef);
        setThreadContentHeight(height);
      };

      setActiveCommentId(null);
      setActiveTrackChangeId(null);

      // Calculate height of the thread content wrapper on mount and on window resize
      updateHeight();
      window.addEventListener("resize", updateHeight);

      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }, []);

    const getCommentThread = (
      commentId: string
    ): ICommentsStore.TCommentThread | null => {
      let commentThread: ICommentsStore.TCommentThread | null = null;

      comments.forEach((comment: TComment) => {
        if (comment.id === commentId) {
          commentThread = { comment: comment, replies: [] };
        }

        if (commentThread) {
          if (comment.parentId === commentId) {
            commentThread.replies.push(comment);
          }
        }
      });

      return commentThread;
    };

    const getTrackChangeThread = (
      trackChangeId: string
    ): TTrackChange[] | null => {
      let trackChangeThread: TTrackChange[] | null = null;

      trackChanges.forEach((tc: TTrackChange[]) => {
        if (tc[0].tc.trackChanges.tcId === trackChangeId) {
          trackChangeThread = tc;
        }
      });
      return trackChangeThread;
    };

    return (
      <>
        <Row
          className="rhs-thread-wrapper"
          ref={threadContentRef}
          style={{ height: `${threadContentHeight}` }}
        >
          <Col span={24}>
            {commentsAndTrackChanges.map((item) => {
              if (item.type === "comment") {
                const thread: TCommentThread | null = getCommentThread(item.id);
                if (thread)
                  return (
                    <CommentThread
                      key={item.id}
                      resolveComment={resolveComment}
                      submitReplyComment={submitReplyComment}
                      deleteComment={deleteComment}
                      updateComment={updateComment}
                      thread={thread}
                    />
                  );
                return null;
              } else {
                const tcThread: TTrackChange[] | null = getTrackChangeThread(
                  item.id
                );

                if (tcThread) {
                  return (
                    <TrackChangeThread
                      approveTrackChange={approveTrackChange}
                      declineTrackChange={declineTrackChange}
                      submitReplyTrackChange={submitReplyTrackChange}
                      updateTrackChangeReply={updateTrackChangeReply}
                      deleteReply={deleteReply}
                      thread={tcThread}
                      key={item.id}
                    />
                  );
                }
              }
            })}
          </Col>
        </Row>
      </>
    );
  });
