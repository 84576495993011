import React, { useEffect, useState } from "react";
import useRootStore from "../../../store/useRootStore";
import { observer } from "mobx-react";

import { Button, Menu } from "antd";
import { AtticusDropdown } from "../../Shared/Dropdowns";

import { ChapterConfiguration as TChapterConfiguration, ChapterConfiguratorVariant, IChapterTemplateBase, ChapterMeta } from "../../../types/chapter";
import { CHAPTER_TITLE_LOWEST_SCALE, CHAPTER_TITLE_HIGHEST_SCALE } from "../../../utils/chapter";
import { ChapterSettingsIcon, CheckOutlinedIcon } from "../../../content/icons";
import { can } from "../../casl/helpers";

interface ChapterSettings {
  configuration: TChapterConfiguration,
  titleScale: number
}

interface ChapterConfigurationProps {
  variant?: ChapterConfiguratorVariant;
}

const deserializeConfiguration = (
  props: ChapterSettings
): string[] | undefined => {

  const deserializedArray: string[] = [];
  const { configuration, titleScale } = props;

  // configuration key parse to field values
  for (const [configurationKey, value] of Object.entries(configuration)) {
    if (!value) {
      deserializedArray.push(configurationKey);
    }
  }

  // check for smallest scale 
  if (titleScale === CHAPTER_TITLE_LOWEST_SCALE) {
    deserializedArray.push("titleScale");
  }
  return deserializedArray;
};

const serializeConfiguration = (
  deserializedArray: string[]
): ChapterSettings => {
  return {
    configuration: {
      showChapterImage: !deserializedArray.includes("showChapterImage"),
      showPageNumber: !deserializedArray.includes("showPageNumber"),
      showChapterHeading: !deserializedArray.includes("showChapterHeading"),
      showHeaderFooter: !deserializedArray.includes("showHeaderFooter"),
      showInTableOfContents: !deserializedArray.includes("showInTableOfContents"),
      showFirstSentenceFormatting: !deserializedArray.includes("showFirstSentenceFormatting"),
      showDefaultTextColour: !deserializedArray.includes("showDefaultTextColour"),
    },
    // The condition to toggle between highest or lowest scale
    titleScale: deserializedArray.includes("titleScale") ? CHAPTER_TITLE_LOWEST_SCALE : CHAPTER_TITLE_HIGHEST_SCALE
  };
};

const MenuItemWithCheckAddon = ({ label, ...props }) => {
  return (
    <Menu.Item {...props}>
      <div className = "chapter-settings-menu-wrapper">
        <div className="chapter-settings-menu-icon-holder " >
          <span className="chapter-settings-selected-icon" >
            <CheckOutlinedIcon />
          </span>
        </div>
        {label}
      </div>
    </Menu.Item>
  );
};

const isPropsAvailable = (chapter: IChapterTemplateBase | ChapterMeta) => {
  const { configuration, titleScale } = chapter;
  return  { 
    configuration : configuration || {
      showChapterHeading: true,
      showInTableOfContents: true,
      showFirstSentenceFormatting: true,
      showChapterImage: true,
      showHeaderFooter: true,
      showPageNumber: true,
      showDefaultTextColour: true,
    }, 
    titleScale
  } as ChapterSettings;
};

export const ChapterConfiguration: React.FunctionComponent<ChapterConfigurationProps> =
  observer(({ variant = "default" }) => {
    const {
      debouncedSaveChapterMetaUpdates,
      chapterTemplate,
      debouncedSyncChapterTemplateChangesToServer,
    } = useRootStore().bookStore;
    const { chapterTemplateView } = useRootStore().appStore;
    const { refreshCache } = useRootStore().pdfCacheStore;
    const { setIsChapterLocalUpdate, setChangeEvent } = useRootStore().bookSyncWebSocketStore;
    const { chapterMeta } = useRootStore().chapterStore;

    const [open, setOpen] = useState(false);

    const [selection, setSelection] = useState<string[]>([]);

    // Synchronize local selection state with observable state
    useEffect(() => {
      setSelection(deserializeConfiguration(isPropsAvailable(chapterTemplateView ? chapterTemplate : chapterMeta)) || []);
    }, [chapterMeta, chapterTemplate, chapterTemplateView]);

    return chapterTemplateView ||
      (["image", "chapter"].includes(chapterMeta.type) && can("view", "chapter-configurations")) ? (
      <div>
        <AtticusDropdown
          trigger={["click"]}
          open={open}
          onOpenChange={(open) => {
            setOpen(open);
          }}
          overlay={
            <Menu
              selectedKeys={selection}
              className={"chapter-settings-menu"}
              onClick={async (element) => {
                element.domEvent.preventDefault();

                const configurationKey = element.key;

                if (!selection) return;

                setIsChapterLocalUpdate(true);
                setChangeEvent("chapter-properties-change");

                let newSelection = selection;

                if (selection.includes(configurationKey)) {
                  newSelection = selection.filter(
                    (key) => key !== configurationKey
                  );
                } else {
                  newSelection = [...selection, configurationKey];
                }

                setSelection(newSelection);

                
                const serializedConfiguration =
                  serializeConfiguration(newSelection);

                if (chapterTemplateView) {
                  await debouncedSyncChapterTemplateChangesToServer(
                    chapterTemplate._id,
                    serializedConfiguration
                  );
                } else {
                  await debouncedSaveChapterMetaUpdates({
                    ...chapterMeta,
                    ...serializedConfiguration,
                  });
                  refreshCache(chapterMeta.bookId, "chapter-properties-change", {
                    "chapter-properties-change": {
                      chapter: {
                        chapterId: chapterMeta._id,
                        chapterType: chapterMeta.type,
                        startOn: chapterMeta.startOn || "any",
                        includeIn: chapterMeta.includeIn || "all",
                        ...serializedConfiguration,
                      },
                    },
                  });
                }
              }}
            >
              {variant === "default" && (
                <MenuItemWithCheckAddon
                  key={"showChapterImage"}
                  label={"Hide chapter image"}
                ></MenuItemWithCheckAddon>
              )}
              {variant === "default" && (
                <MenuItemWithCheckAddon
                  key={"showChapterHeading"}
                  label={"Hide chapter heading"}
                ></MenuItemWithCheckAddon>
              )}
              {variant === "default" && (
                <MenuItemWithCheckAddon
                  key={"showPageNumber"}
                  label={"Hide page number"}
                ></MenuItemWithCheckAddon>
              )}
              {variant === "default" && (
                <MenuItemWithCheckAddon
                  key={"showHeaderFooter"}
                  label={"Hide header/footer"}
                ></MenuItemWithCheckAddon>
              )}
              {variant === "default" && (
                <MenuItemWithCheckAddon
                  key={"showFirstSentenceFormatting"}
                  label={"Hide first sentence formatting"}
                ></MenuItemWithCheckAddon>
              )}
              <MenuItemWithCheckAddon
                key={"showInTableOfContents"}
                label={"Hide in table of contents"}
              ></MenuItemWithCheckAddon>
              <MenuItemWithCheckAddon
                key={"titleScale"}
                label={"Use smaller chapter title"}
              ></MenuItemWithCheckAddon>
              <MenuItemWithCheckAddon
                key={"showDefaultTextColour"}
                label={"Invert text color"}
              ></MenuItemWithCheckAddon>
            </Menu>
          }
        >
          <Button className={"btn-chapter-settings"}>
            <ChapterSettingsIcon />
          </Button>
        </AtticusDropdown>
      </div>
    ) : null;
  });
