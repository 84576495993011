import React from "react";
import { observer } from "mobx-react";
import { RenderElementProps } from "slate-react";

import useRootStore from "../../../../../store/useRootStore";
import { MySceneElement } from "../../../config/typescript";

export const SceneComponent: React.FC<RenderElementProps> = observer(
  ({ children, element }) => {
    const { currentScene, chapterMeta: currentChapter } =
      useRootStore().chapterStore;
    const sceneIndex = (element as MySceneElement).sceneIndex;
    const matchingScene =
      currentChapter._id === currentScene?.chapterId &&
      currentScene?.scene.sceneIndex === sceneIndex;
    const shouldRenderScene = currentScene ? matchingScene : true;
    return shouldRenderScene ? (
      <div className="slate-scene" data-scene-index={sceneIndex}>
        {children}
      </div>
    ) : null;
  }
);
