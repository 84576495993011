import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { matchPath, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import { Radio, Row, Col, Tooltip, Button, Popover } from "antd";

import useRootStore from "../../store/useRootStore";

import { ContactSupportIcon, DownloadAppIcon, HomeIcon, SaveIcon } from "../../content/icons";

import miniLogo from "../../content/images/miniLogo.png";
import wordLogo from "../../content/images/wordLogo.png";
import { BackIcon } from "../../content/icons";

import { InstallPWA } from "./install";
import { ProfileAvatar } from "./profile";
import { InviteContainer } from "./invite";
import { NotificationContainer } from "./notification";
import { can } from "../../components/casl/helpers";
import { syncBookBaseData } from "../../utils/sync";

type Navigation = "home" | "book-edit" | "writing" | "formatting" | "profile" | "masterpage" | "welcome" | "collaboration" | "my-books" | "backup";

const paths: { [key: string]: Navigation } = {
  "/": "home",
  "/books/:bookId": "book-edit",
  "/books/:bookId/:chapterId": "writing",
  "/books/:bookId/:chapterId/formatting": "formatting",
  "/masterpage/:tempId": "masterpage",
  "/account/*": "profile",
  "/welcome": "welcome",
  "/my-books": "my-books",
  "/collaboration": "collaboration",
  "/backup": "backup"
};

const Header = observer(() => {
  const { book, chapterTemplate } = useRootStore().bookStore;
  const { chapterMeta } = useRootStore().chapterStore;
  const { isThemeBuilderActive, confirmExitEdit } = useRootStore().themeStore;
  const { chapterTemplateView } = useRootStore().appStore;
  const { push, goBack } = useHistory();
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState<Navigation>("home");

  const isHomePages = ["home", "my-books", "collaboration"].includes(activeTab);
  const isBookPages = ["writing", "formatting", "book-edit"].includes(activeTab);

  // Set map for pagename and page pathname
  const goTo = {
    "home": "/",
    "book-edit": `/books/${book._id}`,
    "writing": `/books/${book._id}/${chapterMeta._id}`,
    "formatting": `/books/${book._id}/${chapterMeta._id}/formatting`,
    "my-books": "/my-books",
    "collaboration": "/collaboration",
    "backup": "/backup"
  };

  // navigator
  const to = (path: string) => push(path);

  //navigate to home page
  const goHome = () => handleChange("home");

  // handler to navigate based on pagename
  const handleChange = async (pagename: string) => {
    const route = goTo[pagename];
    to(route);
    if(pagename === "formatting") await syncBookBaseData(book._id);
  };

  //handler for exiting unsaved theme
  const handleExitTheme = (callback: () => void) => isThemeBuilderActive ? confirmExitEdit(callback) : callback();

  // pattern match with path name
  const doRouteMatch = (path: string) => matchPath(pathname, {
    exact: true,
    path
  });

  // Set tab active based on the pathname match
  const setActiveFromPathname = () => {
    const routes = Object.keys(paths);
    for (let i = 0; i < routes.length; i++) {
      if (doRouteMatch(routes[i])) {
        setActiveTab(Object.values(paths)[i]);
        break;
      }
    }
  };

  useEffect(() => {
    setActiveFromPathname();
  }, [pathname]);

  return (
    <Row className='header' gutter={16} align='middle'>
      <Col span={8}>
        <Row gutter={16} className="book-details-nav" >
          <Col>
            <Row
              className='nav-icon'
              gutter={16}
              align='middle'
              onClick={() => handleExitTheme(goHome)}
            >
              <Col>
                <img height={32} src={miniLogo} />
              </Col>
              {isHomePages ? (
                <Col>
                  <img height={24} src={wordLogo} />
                </Col>
              ) : (
                <Col className='book-details-nav-icon'>
                  <HomeIcon />
                </Col>
              )}
            </Row>
          </Col>

          {(isBookPages || activeTab === "masterpage") && (
            <Col span={12} className="book-details-nav-title-col">
              <h1 className="book-details-nav-title" >{activeTab !== "masterpage" ? book.title : chapterTemplate.title}</h1>
            </Col>
          )}
          {isBookPages && can("view", "edit-book-details") && (
            <Col>
              <Button
                className="book-details-nav-btn"
                onClick={() => activeTab !== "book-edit" ? handleChange("book-edit") : goBack()}
                icon={activeTab === "book-edit" ? <BackIcon /> : null}
              >
                {activeTab === "book-edit" ? "Back" : "Edit book details"}
              </Button>
            </Col>
          )}
        </Row>
      </Col>
      <Col span={8}>
        <Row justify='center'>
          <Radio.Group
            className='at-radio-nav'
            defaultValue='writing'
            value={activeTab}
            onChange={(e) =>
              handleExitTheme(() => handleChange(e.target.value))
            }
          >
            {isHomePages && (
              <>
                <Radio.Button value='home'>Home</Radio.Button>
                <Radio.Button value='my-books'>My books</Radio.Button>
                <Radio.Button value='collaboration'>Collaboration</Radio.Button>
              </>
            )}
            {isBookPages && book && (
              <>
                <Radio.Button value='writing'>Writing</Radio.Button>
                {can("view", "formatting-section") && 
                  <Radio.Button value='formatting'>Formatting</Radio.Button>
                }
              </>
            )}   
            { chapterTemplateView && (
                <>
                <Radio.Button value='home'>Home</Radio.Button>
                <Radio.Button value='my-books'>My books</Radio.Button>
                </>
            )}
          </Radio.Group>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={24} align='middle' justify='end'>
          {isBookPages && (
            <Col>
              <InviteContainer />
            </Col>
          )}
          <Col>
            <Tooltip title="Install app">
              <InstallPWA>
                <DownloadAppIcon />
              </InstallPWA>
            </Tooltip>
          </Col>
          {activeTab !== "welcome" ? (
            <Col>
              <Tooltip title="Content backup">
                <div
                  className='nav-icon'
                  onClick={() => handleExitTheme(() => handleChange("backup"))}
                >
                  <SaveIcon />
                </div>
              </Tooltip>
            </Col>
          ) : null}
          <Col>
            <Tooltip title='Contact support'>
              <a
                className='nav-icon'
                target='_blank'
                href='https://atticus.io/support'
                rel='noreferrer'
              >
                <ContactSupportIcon />
              </a>
            </Tooltip>
          </Col>
          <Col>
            <NotificationContainer />
          </Col>
          <Col>
            <ProfileAvatar
              handler={(path) => handleExitTheme(() => to(path))}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
});

export default Header;
