import React, { FunctionComponent, useEffect, useState } from "react";
import { observer } from "mobx-react";
// 3rd party Components
import { Formik } from "formik";
import { Form, FormItemProps, Input, message } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import { AtticusFullLogo } from "../../content/icons";
import { AtticusTextField } from "../../components/Shared/Form/Input";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";
import {
  BackgroundColor,
  Button,
  ButtonSize,
  ButtonType,
} from "../../components/Shared/Buttons/Button";
import { AtticusPasswordField } from "../../components/Shared/Form";
import { ScrollContainer } from "../../components/Shared/Layouts";

const styles = {
  miniLogo: {
    height: 100,
    width: 100,
  },
};
// types
interface FormErrors {
  email?: string;
  password?: string;
}
interface Props {
  modal: boolean;
}

export const SignIn: FunctionComponent<Props> = observer(({ modal }: Props) => {
  const { updateToken, updateNewBookToken, updateAbilitiesPublicKey, token } = useRootStore().authStore;

  if (token) return <Redirect to="/" />;

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { replace } = useHistory();

  const onFinish = async (values) => {
    try {
      setIsSubmitting(true);
      const resp = await AtticusClient.SignIn(values.email, values.password);
      await updateToken(resp.token);
      await updateNewBookToken(resp.newBookToken);
      await updateAbilitiesPublicKey(resp.abilitiesPublicKey);
      replace("/");
    } catch (e: any) {
      if (e.response && e.response.status === 406) {
        const validationErrors = e.response.data.errors;
        Object.keys(validationErrors).forEach((k) => {
          form.setFields([{ name: k, errors: [validationErrors[k].message] }]);
        });
        message.error("Please fix the highlighted fields", 4);
      } else {
        message.error("Error signing in");
      }
    } finally {
      setIsSubmitting(false);
      form.setFieldsValue({});
    }
  };

  return (
    <>
      <Form
        form={form}
        initialValues={{ email: "", password: "" }}
        onFinish={onFinish}
        layout="vertical"
        validateTrigger={["onBlur"]}
        >
        <div className="auth-card">
          <div className="text-center inner-s">
            <h1>Welcome to Atticus!</h1>
            <p>Sign in to continue to your account.</p>
          </div>
          <div className="inner-xs">
            <div>
              <Form.Item
                className="at-form-item"
                name="email"
                label="Email Address"
                rules={
                  [
                    {
                      type: "email", 
                      required: true, 
                      message: "A valid email is required" 
                    }
                  ]
                }
              >
                <AtticusTextField
                  placeholder="yourname@gmail.com"
                />
              </Form.Item>
              <Form.Item
                className="at-form-item"
                name="password"
                label="Password"
                rules={
                  [
                    { 
                      required: true, 
                      message: "Password is required" 
                    }
                  ]
                }
              >
                <AtticusPasswordField
                  placeholder="Password"
                />
              </Form.Item>
            </div>
            <div className="margin-bottom">
              Forgot your password?{" "}
              <Link to="/auth/forgot-password" className="default-link">
                <b>{"Reset"}</b>
              </Link>
            </div>
            <div className="inner-xs">
              <Button
                block
                type={ButtonType.PRIMARY}
                backgroundColor={BackgroundColor.BLUE}
                size={ButtonSize.LARGE}
                htmlType="submit"
                loading={isSubmitting}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
});