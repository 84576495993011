import { Chapter, ChapterMeta } from "../../../types/chapter";
import { getTitleChapterData } from "../../../utils/volumes";

export const parseTitleCard = (book: IBookStore.ExpandedBook,chapter : IChapterStore.ChapterMeta): string => {

  const { title, subtitle, author, titleType, volumeTitles, publisherLogoURL, publisherName } = getTitleChapterData(book, chapter, "ebook");

  const html = `
      <div class="title">
        <div class="title-card">
          <h1>${title}</h1>
          ${subtitle ? `<h3>${subtitle}</h3>` : ""}
		      ${titleType === "book" ? volumeTitles.map((item)=> `<h3>${item.title}</h3>`).join("") : ""}
          <h2>${author.join(", ")}</h2>
        </div>
        <div class="publisher-details">
          ${
            publisherLogoURL
              ? `<div class="publisher-logo"><img src="${publisherLogoURL}" /></div>`
              : ""
          }
          ${publisherName ? `<div>${publisherName}</div>` : ""}
        </div>
      </div>
    `;
  return html;
};
