import React, { FunctionComponent } from "react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";

//Provider 
import Provider from "./Provider";

/* PAGES */
import {

	/* Non - Authenticated Pages */
	SignIn,
	SignUp,
	ResetPassword,
	ForgotPassword,

	/* Authenticated Pages */
	Overview,
	Welcome,
	Profile,
	MyBooks,
	Collaboration,
	Collaborators,
	ChangePassword,
	Maintenance,
	SignOut,
	BookDebugger,
	Backup,

	/* Book */
	EditBook,
	Writing,
	Formatting,
	RedirectToFirstChapter,

	/* Masterpage */
	Masterpage,

} from "./pages";

//Container
import {
	ProfileTab,
	WithAuth,
	WithoutAuth,
	WithBook
} from "./containers";

// Editor
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";
import RendererCacheInitiator from "./components/Shared/RendererCacheInitiator/RendererCacheInitiator";
import { can } from "./components/casl/helpers";

const Routes: FunctionComponent = () => {

	// set the REACT_APP_MAINTENANCE_MODE=true to enable the Maintenance Mode
	const MAINTENANCE_MODE = process.env.REACT_APP_MAINTENANCE_MODE === "true";

	if (MAINTENANCE_MODE) {
		return <Maintenance />;
	}

	return (
		<Provider>
			<>
				<Router>
					<Switch>
						<Route path="/auth/sign-out" component={SignOut} />
						<Route path="/auth/*">
							<WithoutAuth>
								<Switch>
									<Route path="/auth/sign-in" component={SignIn} />
									<Route path="/auth/create-account" component={SignUp} />
									<Route path="/auth/forgot-password" component={ForgotPassword} />
									<Route path="/auth/reset-password" component={ResetPassword} />
								</Switch>
							</WithoutAuth>
						</Route>
						<Route>
							<WithAuth>
								<Switch>
									<Route path="/redirect-to-first-chapter/:bookId" component={RedirectToFirstChapter} exact />
									<Route path={["/books/:bookId", "/books/:bookId/:chapterId", "/books/:bookId/:chapterId/formatting", "/books/:bookId/redirect-to-first-chapter"]} exact>
										<RendererCacheInitiator />
										<WithBook>
											<Route path="/books/:bookId" component={EditBook} exact />
											<Route path="/books/:bookId/:chapterId" component={Writing} exact />									
											<Route path="/books/:bookId/:chapterId/formatting" component={Formatting} exact />
										</WithBook>
									</Route>
									<Route path="/masterpage/:tempId" exact>
										<Masterpage />
									</Route>
									<Route path="/" exact>
										<Overview />
									</Route>
									<Route path="/my-books" exact>
										<MyBooks />
									</Route>
									<Route path="/collaboration" exact>
										<Collaboration />
									</Route>
									<Route path="/collaborators" exact>
										<Collaborators />
									</Route>
									<Route path="/welcome" exact>
										<Welcome />
									</Route>
									<Route path="/debug" exact>
										<BookDebugger />
									</Route>
									<Route path="/backup" exact component={Backup} />
									<Route path="/account/*" exact>
										<ProfileTab>
											{props => props.profile.email ? (
												<>
													<Route path="/account/change-password" component={ChangePassword} exact />
													<Route path="/account/profile" render={routeProps => <Profile {...props} {...routeProps} />} />
												</>
											) : null}
										</ProfileTab>
									</Route>
								</Switch>
							</WithAuth>
						</Route>
					</Switch>
				</Router>
				<ServiceWorkerWrapper></ServiceWorkerWrapper>

			</>
		</Provider>
	);
};

export default Routes;