//TODO:BODY might need to introduce new interface as input type with chapter body in slate format
export const parseFullPageImage = (chapter: IChapterStore.Chapter): string => {
  let html = "<div class=\"fullpage-image\">";
  if (
    chapter.type === "image" &&
    chapter.fullpageImage &&
    chapter.fullpageImage.imageUrl
  ) {
    if (chapter.fullpageImage.verticalAlignment === "top")
      html += "<div class=\"image-full top\">";
    else if (chapter.fullpageImage.verticalAlignment === "bottom")
      html += "<div class=\"image-full bottom\">";
    else html += "<div class=\"image-full center\">";
    html += `<img src=${chapter.fullpageImage.imageUrl} />`;
    html += "</div>";
  }
  html += "</div>";
  return html;
};
