import React, { FunctionComponent } from "react";
import { observer } from "mobx-react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { deleteYChapterDB } from "@surge-global-engineering/y-indexeddb";

// db
import { db } from "../db/bookDb";

// stores
import useRootStore from "../store/useRootStore";
import querystring from "querystring";

// components
import { Backup, OfflineBackup } from "../components/Backup";


export const SignOut: FunctionComponent = observer(() => {
	const { token, resetAuthentication } = useRootStore().authStore;
    const { search } = useLocation();
	const queryObj = querystring.parse(search.replace("?", ""));
	const expired = queryObj.expired ? true : false;

    const clearYChapters = async () => {
        const chaptersFromIDB = await db.chapterMetas.toArray();
        chaptersFromIDB.map(async chapterMeta => {
            await deleteYChapterDB(chapterMeta._id);
        });
    };

	const clearDatabase = async () => {
        await clearYChapters();
		await db.delete();
		await resetAuthentication();
        window.location.replace("/auth/sign-in");
	};

    if(!token)
        return <Redirect to="/auth/sign-in" />;

	return expired ? (
        <OfflineBackup 
            onComplete={() => {
                clearDatabase();
            }}
        />
    ) : (
        <Backup 
            download
            onComplete={() => {
                clearDatabase();
            }}
        />
	);
});
