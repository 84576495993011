import { PlateEditor, Value, withoutNormalizing } from "@udecode/plate";

import { MARK_COMMENT } from "../constants";
import { findCommentNode } from "../queries";
import { getCommentKeys } from "../utils";

export const removeCommentMark = <V extends Value>(
  editor: PlateEditor<V>
): void => {
  const nodeEntry = findCommentNode(editor);
  if (!nodeEntry) return;
  const keys = getCommentKeys(nodeEntry[0]);
  withoutNormalizing(editor, () => {
    keys.forEach((key) => {
      editor.removeMark(key);
    });

    editor.removeMark(MARK_COMMENT);
  });
};
