import { generate as generateRandomString } from "randomstring";
import { CHAPTER_TITLE_HIGHEST_SCALE } from "../../utils/chapter";

export const getInitBookContent = (
  bookId: string,
  bodyChapterId: string
): IBookStore.InitialBookContent => {
  const bodyChapterMeta: IChapterStore.ChapterMeta = {
    _id: bodyChapterId,
    bookId: bookId,
    title: "",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "chapter",
    image: "",
    startOn: "right",
    subtitle: "",
    index: 0,
  };
  const titleChapterMeta: IChapterStore.ChapterMeta = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Title Page",
    type: "title",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    subtitle: "",
    index: 0,
    image: "",
    startOn: "right",
  };
  const copyrightChapterMeta: IChapterStore.ChapterMeta = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Copyright",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "copyrights",
    subtitle: "",
    image: "",
    index: 0,
    startOn: "left",
  };
  const tocChapterMeta: IChapterStore.ChapterMeta = {
    _id: generateRandomString(16),
    bookId: bookId,
    title: "Contents",
    titleScale: CHAPTER_TITLE_HIGHEST_SCALE,
    type: "toc",
    subtitle: "",
    image: "",
    index: 0,
    startOn: "right",
  };
  const initBookContent = {
    body: bodyChapterMeta,
    title: titleChapterMeta,
    copyright: copyrightChapterMeta,
    toc: tocChapterMeta,
  };
  return initBookContent;
};
