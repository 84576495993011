import React, { FunctionComponent } from "react";

import { getLabel } from "../Chapters/helpers";
import { ellipsizeTitle } from "../../utils/strings";
import {
  ChapterSidebarIcon,
  CopyrightSidebarIcon,
  TitleSidebarIcon,
  ToCSidebarIcon,
} from "../../content/icons";

interface ChapterTemplateBlockItemProps {
  chapter: IChapterStore.IChapterTemplateBase;
}

const ChapterTemplateBlockItem: FunctionComponent<
  ChapterTemplateBlockItemProps
> = (props: ChapterTemplateBlockItemProps) => {
  const { chapter } = props;
  const getChaptertitle = () => {
    if (chapter.title) return chapter.title;
    if (chapter.type === "chapter") return "Untitled";
    return getLabel(chapter.type);
  };

  const ChapterBlockIcon = (): JSX.Element => {
    const color = "#3568BA";
    if (chapter.type === "title")
      return (
        <span className="chapter-block-icon">
          <TitleSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "toc")
      return (
        <span className="chapter-block-icon">
          <ToCSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "copyrights")
      return (
        <span className="chapter-block-icon">
          <CopyrightSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "volume")
      return (
        <span className="chapter-block-icon">
          <ChapterSidebarIcon color={color} />
        </span>
      );
    if (chapter.type === "part")
      return (
        <span className="chapter-block-icon">
          <ChapterSidebarIcon color={color} />
        </span>
      );
    return (
      <span className="chapter-block-icon">
        <ChapterSidebarIcon color={color} />
      </span>
    );
  };

  return (
    <div className="chapter-block-item master-page-block">
      <div className="chapter-block-item-container">
        <div className="chapter-block-item-info-container">
          <div className="chapter-block-item-icon-container">
            {ChapterBlockIcon()}
          </div>
          <div className="chapter-block-item-chapter-name-cover">
            <p className="chapter-block-item-chapter-name active">
              {ellipsizeTitle(getChaptertitle(), 30)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterTemplateBlockItem;
