import React, { FunctionComponent, useState, ReactNode, useEffect } from "react";
import { useHistory } from "react-router-dom";

// components
import { ArrowUpOutlined, PlusOutlined } from "@ant-design/icons";

// custom components
import NewBookModal from "./NewBookModal";
import UploadBookModal from "./UploadBookModal";

import { useOnlineStatus } from "../../utils/hooks/isOffline";

import useRootStore from "../../store/useRootStore";
import NewBoxsetModal from "./NewBoxsetModal";
import { BOOKSHELF_BOOK_ADDED, wsSendShelfUpdateMessage } from "../../utils/bookshelf-ws-helper";
import { ShelfWSMessageData } from "../../types/common";

interface CardButtonProps {
	title: string;
	Icon: ReactNode;
	disabled: boolean;
	onClick: () => void;
}

interface NewBookToolbar {
	onboard?: boolean
}

const CardButton: FunctionComponent<CardButtonProps> = (props: CardButtonProps) => {
	const { Icon, disabled } = props;
	const [hovering, setHovering] = useState(false);

	return (
		<div
			style={{
				cursor: "pointer",
				opacity: disabled ? 0.6 : 1,
			}}
			onMouseDown={(e) => {
				e.preventDefault();
				props.onClick();
			}}
		>
			<div
				onMouseEnter={() => setHovering(true)}
				onMouseLeave={() => setHovering(false)}
				className={`book-list-item card-button${hovering ? " card-button-hover" : ""}`}
				style={{ height: "100%" }}
			>
				<div className="book-cover no-background" style={{ height: "auto" }}>
					{Icon}
				</div>
				<div className="meta">
					<span className="button-text">{props.title}</span>
				</div>
			</div>
		</div>
	);
};

const NewBookToolbar: React.FC<NewBookToolbar> = ({ onboard }) => {
	const { onUpload, newBook, newBoxset } = useRootStore().shelfStore;
	const { setActiveTheme: setTheme, allThemes: themes } = useRootStore().themeStore;
	const { user } = useRootStore().authStore;
	const { socket } = useRootStore().bookSyncWebSocketStore;

	const isOnline = useOnlineStatus();

	const { push } = useHistory();

	const [showUploadModal, setShowUploadModal] = useState(false);
	const [showNewBookModal, setShowNewBookModal] = useState(false);
	const [showNewBoxsetModal, setShowNewBoxsetModal] = useState(false);

	useEffect(() => {
		setTheme(themes[0]);
	}, []);

	const onNewBoxset = async (params) => {
		const bookId = await newBoxset(params);
		// notify shelf update through ws
		if (user) {
			const data: ShelfWSMessageData = {
				userId: user._id,
				bookId: bookId,
				isCollabBook: false,
			};
			wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
		}
		setShowNewBoxsetModal(false);
		push(`/books/${bookId}`);
	};

	return (

		<div className={`new-book-section-container ${onboard && "onboard"}`}>
			<div className="add-button-card black-card">
				<CardButton
					title="Upload"
					disabled={!isOnline}
					Icon={<ArrowUpOutlined />}
					onClick={() => {
						if (isOnline) setShowUploadModal(true);
					}}
				/>
			</div>
			<div className="add-button-card  blue-card">
				<CardButton
					disabled={false}
					title="New Book"
					Icon={<PlusOutlined />}
					onClick={() => setShowNewBookModal(true)}
				/>
			</div>
			{!onboard ?
				<div className="add-button-card blue-card">
					<CardButton
						disabled={false}
						title="New Boxset"
						Icon={<PlusOutlined />}
						onClick={() => setShowNewBoxsetModal(true)}
					/>
				</div>
				: null
			}
			{
				showUploadModal ?
					<UploadBookModal
						onUpload={async (params) => {
							const bookId = await onUpload(params);
							// notify shelf update
							if (user) {
								const data: ShelfWSMessageData = {
									userId: user._id,
									bookId: bookId,
									isCollabBook: false,
								};
								wsSendShelfUpdateMessage(socket, BOOKSHELF_BOOK_ADDED, data);
							}
							setShowUploadModal(false);
							push(`/books/${bookId}`);
						}}
						onClose={() => { setShowUploadModal(false); }}
					/>
					: null}
			{
				showNewBookModal ?
					<NewBookModal
						onNewBook={async (params) => {
							await newBook(params);
							setShowNewBookModal(false);
							push(`/books/${params._id}/${params.chapterId}`);
						}}
						onClose={() => { setShowNewBookModal(false); }}
					/>
					: null}
			{
				showNewBoxsetModal ?
					<NewBoxsetModal
						onNewBoxset={onNewBoxset}
						onClose={() => setShowNewBoxsetModal(false)}
					/>
					: null}
		</div>
	);
};

export default NewBookToolbar;