import { getNodeEntries, PlateEditor, TNodeEntry, Value } from "@udecode/plate";

import { TCommentText } from "../types";
import { isCommentText } from "../utils";

export const getCommentNodeEntries = <V extends Value>(
  editor: PlateEditor<V>
): TNodeEntry<TCommentText>[] => {
  return [
    ...getNodeEntries<TCommentText>(editor, {
      at: [],
      match: (n) => isCommentText(n),
    }),
  ];
};
