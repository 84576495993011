import React, { useState, useEffect, createRef } from "react";
import { Viewer } from "@surge-global-engineering/react-pdf-components";
import { Spin } from "antd";
import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
} from "@ant-design/icons";
import useRootStore from "../../../../store/useRootStore";
import "./PrintPreviewerStyles.scss";
import { observer } from "mobx-react";
import { shouldIncludeChapterInPrint } from "../../../../utils/helper";
import NotIncluded from "./NotIncluded";
import { Button } from "../../../Shared/Buttons";
import { LeftOutlinedIcon, RightOutlinedIcon, VerticalLeftOutlinedIcon, VerticalRightOutlinedIcon } from "../../../../content/icons";

interface PrintPreviewerProps {
  theme: IThemeStore.Theme;
  //TODO:BODY Verify below
  chapter: IChapterStore.ChapterMeta;
  prev: any;
  handlePrevChapter: () => void;
  next: any;
  handleNextChapter: () => void;
  // images?: IThemeStore.CustomImages;
  chapterNumber?: number;
  // customThemeBuilderView?: string;
}

const PrintPreviewer: React.FC<PrintPreviewerProps> = ({
  theme,
  chapter,
  prev,
  handlePrevChapter,
  next,
  handleNextChapter,
}) => {
  const { bookCaches } = useRootStore().pdfCacheStore;
  const { synced } = useRootStore().themeStore;
  const {properties: themeProperties} = theme;
  const { trim } = themeProperties;
  const [loading, setLoading] = useState<boolean>(false);
  const [pageCountLoading, setPageCountLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [bookTotalPageCount, setBookTotalPageCount] = useState<number | null>(
    null
  );
  const [url, setUrl] = useState<string | null>();
  const [viewerContainerTransform, setViewerContainerTransform] =
    useState<string>("scale(0.86)");
  const viewerContainerOuterRef = createRef<HTMLDivElement>();

  // Check if the chapter should be previewed
  // Situations:
  //  1. "includeIn" attribute
  const showChapterPreview = [
    shouldIncludeChapterInPrint({ includeIn: chapter.includeIn || "all" }),
  ].every((condition) => !!condition);

  const resetViewerContainerDimensions = () => {
    const { height, width } = trim;
    const viewerContainerWidth = 382;
    const trimHeight = cmToPx(height);
    const trimWidth = cmToPx(width);
    const scale = viewerContainerWidth / trimWidth;
    viewerContainerOuterRef.current?.style.setProperty(
      "height",
      `${scale * trimHeight - scale * 150}px`
    );
    setViewerContainerTransform(`scale(${scale})`);
  };

  const navigateToPrev = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const navigateToNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const onLoadSuccess = (d) => {
    setTotalPages(d.numPages);
    setCurrentPage((prev) => Math.min(prev, d.numPages));
    resetViewerContainerDimensions();
    setLoading(false);
  };

  const onClick = () => {
    if (!showChapterPreview || !url) return;
    window.open(url, "_blank");
  };

  const handlePrevChapterClick = () => {
    handlePrevChapter();
    setCurrentPage(1);
  };

  const handleNextChapterClick = () => {
    handleNextChapter();
    setCurrentPage(1);
  };

  const cmToPx = (cm: number) => {
    return cm * 37.7952755906;
  };

  useEffect(() => {
    const { chapterCaches, fullPageCount } = bookCaches[chapter.bookId];
    const chapterCache = chapterCaches.find(
      ({ chapterId }) => chapterId === chapter._id
    );
    setBookTotalPageCount(fullPageCount);
    if (!chapterCache) {
      // console.log("We dont have it on chapter cache", chapter._id);
      return;
    }
    const { blobUrl, status } = chapterCache;
    setLoading(status !== "valid");
    if (status === "valid") {
      setUrl((currentBlobUrl) => {
        if (currentBlobUrl !== blobUrl) {
          setCurrentPage(1);
        }
        return blobUrl;
      });
    }
  }, [chapter, bookCaches]);

  useEffect(() => {
    resetViewerContainerDimensions();
  }, []);

  useEffect(() => {
    const isBookCacheLoading =
      bookCaches[chapter.bookId].chapterCaches.filter(
        ({ status }) => status !== "valid"
      ).length > 0;
    setPageCountLoading(isBookCacheLoading);
  }, [bookCaches, chapter]);

  const LoadingIcon = <LoadingOutlined style={{ fontSize: 25 }} spin />;
  return (
    <>
    <div
      style={{
        marginTop: "2rem",
      }}
    >
      <div
        style={{
          position: "relative",
          pointerEvents: "none",
        }}
        ref={viewerContainerOuterRef}
      >
        <div
          className="print-previewer-viewer-container"
          style={{
            transformOrigin: "50% 0%",
            minHeight: "inherit",
            transform: viewerContainerTransform,
            pointerEvents: showChapterPreview ? "all" : "none",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={onClick}
        >
          {showChapterPreview ? (
            <Spin
              spinning={loading || !synced}
              indicator={LoadingIcon}
              style={{
                maxHeight: cmToPx(trim.height),
                height: "100%",
                minHeight: cmToPx(trim.height),
                transform: viewerContainerTransform,
                transformOrigin: "50% 0%",
                display: "grid",
                alignContent: "center",
              }}
            >
              {url && (
                <Viewer
                  url={url}
                  currentPage={currentPage}
                  loading={() => (
                    <div
                      className="fade-in-div"
                      style={{
                        position: "absolute",
                        zIndex: 10,
                        left: "-52px",
                        right: 0,
                        backgroundColor: "white",
                        width: cmToPx(trim.width),
                        height: cmToPx(trim.height),
                        transformOrigin: "50% 0%",
                        transform: viewerContainerTransform,
                      }}
                    ></div>
                  )}
                  onLoadSuccess={onLoadSuccess}
                  style={{
                    width: "max-content",
                    boxShadow: "rgb(150 150 150) 0px 0px 20px",
                  }} />
              )}
            </Spin>
          ) : (
            <NotIncluded />
          )}
        </div>
      </div>
      <div style={{ padding: "0.5rem 0 1rem" }}>
        {pageCountLoading && (
          <p style={{ textAlign: "center" }}>Calculating page count...</p>
        )}
        {!pageCountLoading && (
          <p style={{ textAlign: "center" }}>({bookTotalPageCount} pages)</p>
        )}
      </div>
    </div><div className="previewer-actions">
        <div className="pdf-page-navigation">
          <Button type="at-secondary" backgroundColor="green" className="previous-page" icon={<LeftOutlinedIcon />} onClick={navigateToPrev} disabled={currentPage === 1}>
              Page
            </Button>
            <Button
              type="at-secondary"
              backgroundColor="green"
              className="next-page"
              icon={<RightOutlinedIcon />}
              onClick={navigateToNext}
              disabled={currentPage === totalPages}
            >
              Page
            </Button>
        </div>
        <div className="pdf-chapter-navigation">
          <Button type="at-secondary" backgroundColor="green" className="previous-chapter" icon={<VerticalLeftOutlinedIcon />} onClick={handlePrevChapterClick} disabled={!prev}>
            Chapter
          </Button>
          <Button type="at-secondary" backgroundColor="green" className="next-chapter" icon={<VerticalRightOutlinedIcon />} onClick={handleNextChapterClick} disabled={!next}>
            Chapter
          </Button>
        </div>
      </div>
      </>
  );
};

export default observer(PrintPreviewer);
