import {
    findNode,
    FindNodeOptions,
    PlateEditor,
    TNodeEntry,
    Value,
  } from "@udecode/plate";
  
  import { MARK_COMMENT } from "../constants";
  import { TCommentText } from "../types";
  
  export const findCommentNode = <V extends Value>(
    editor: PlateEditor<V>,
    options?: FindNodeOptions
  ): TNodeEntry<TCommentText> | undefined => {
    return findNode<TCommentText>(editor, {
      match: (n) => n[MARK_COMMENT],
      ...options,
    });
  };