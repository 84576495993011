import { getNodeEntries, PlateEditor } from "@udecode/plate";
import {
  getCommentKeyId,
  isCommentKey,
  isCommentText,
  TComment,
  TCommentText,
} from "../Plate";
import {
  TrackChangesProperties,
  TTrackChangeNodeEntry,
} from "../Plate/plugins/track-changes/types";
import { CommentOrTC } from "../../types/comments";
import { isTrackChangeText } from "../Plate/plugins/track-changes/utils/isTrackChangeText";

export const calculateThreadWrapperHeight = (
  threadContentRef: React.RefObject<HTMLDivElement>
): string | null => {
  const bottomBarElement = document.querySelector(
    ".bottom-bar-wrapper"
  ) as HTMLElement;
  if (threadContentRef.current && bottomBarElement) {
    const threadContentTop =
      threadContentRef.current.getBoundingClientRect().top + window.scrollY;
    const bottomBarHeight = bottomBarElement.getBoundingClientRect().height;
    const availableHeight = `calc(100vh - ${
      threadContentTop + bottomBarHeight
    }px)`;
    return availableHeight;
  }
  return null;
};

export const getAllCommentsAndTC = (editor: PlateEditor, getCommentById: (id: string) => TComment): CommentOrTC[] => {

  const commentsAndTC: CommentOrTC[] = [];

  const commentAndTCNodeEntries = [
    ...getNodeEntries<TTrackChangeNodeEntry | TCommentText>(editor, {
      at: [],
      match: (n) => isTrackChangeText(n) || isCommentText(n),
    }),
  ];

  for (const [childNode, childPath] of commentAndTCNodeEntries) {
    if (childNode.comment) {
      const nodeComments: string[] = [];
      Object.keys(childNode).forEach((key) => {
        if (!isCommentKey(key)) return;
        nodeComments.push(getCommentKeyId(key));
      });
      for (const commentId of nodeComments) {
        const comment: TComment = getCommentById(commentId);
        if (
          comment &&
          !comment.isResolved &&
          !commentsAndTC.some((item) => item.id === commentId)
        ) {
          commentsAndTC.push({
            id: commentId,
            type: "comment",
          });
        }
      }
    }
    if (childNode.trackChanges) {
      const tc = childNode.trackChanges as TrackChangesProperties;
      const tcId = tc.tcId;

      if (tcId && !commentsAndTC.some((item) => item.id === tcId)) {
        commentsAndTC.push({
          id: tcId,
          type: "track-change",
        });
      }
    }
  }

  return commentsAndTC;
};
