import React, { useState } from "react";
import { Layout, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { FullScreen } from "../../Shared/Layouts";

const { Sider } = Layout;

export const LHS: React.FC<{ action?: React.ReactNode }> = ({ children, action }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [open, setOpen] = useState(false);
  
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onBreakpoint = (broken) => {
    if (broken) {
      if (!collapsed) {
        setCollapsed(true);
      }
    } else {
      if (collapsed) {
        setCollapsed(false);
      }
    }
  };

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        className="_left"
        breakpoint="xl"
        collapsedWidth="0"
        theme="light"
        width={280}
        trigger={null}
        onBreakpoint={(broken) => onBreakpoint(broken)}
      >
        <FullScreen className="atticus-sidebar">
          <div className="inner-s sidebar-scroll scroller">
            {children}
          </div>
          {action ? (
            <div>
              {action}
            </div>
          ) : null}
        </FullScreen>
      </Sider>
      {collapsed ?
        <>
          <Drawer
            closable={false}
            bodyStyle={{ color: "black" }}
            width={"300px"}
            maskClosable={true}
            placement="left"
            onClose={onClose} open={open}>

            {/* Change accordingly for collapsed view */}
            <FullScreen className="atticus-sidebar">
              <div className="inner-s sidebar-scroll scroller">
                {children}
              </div>
              {action ? (
                <div>
                  {action}
                </div>
              ) : null}
            </FullScreen>
          </Drawer>
          <div className="left-drawer-menu">
            <MenuOutlined onClick={showDrawer} />
          </div>
        </>
        : null}
    </>
  );
};

