import { AtticusClient } from "../../api/atticus.api";
import { FilterChapterMeta, GetChapterTemplates, SaveChapterTemplateToDB, UpdateChapterMeta } from "../offline.book.helpers";

// Chapter Template Library
export async function SaveTemplateToDB(): Promise<boolean> {
	const templates = await AtticusClient.GetChapterTemplates();

	if (!templates) return false;

	for(const temp of templates) {
		await SaveChapterTemplateToDB(temp);
	}

	return true;
}

export async function syncChapterTemplateChangesWithLocalChapters(templateId: string, bookId: string, shouldUpdateAllBooks: boolean): Promise<void> {
	const template = await GetChapterTemplates(templateId);
	if(!template) return;
	const filter = {templateId};
	if(!shouldUpdateAllBooks) filter["bookId"] = bookId;
	const localChapterMetasUsingTemplate = await FilterChapterMeta(filter);
	localChapterMetasUsingTemplate.map(async chapter => {
		await UpdateChapterMeta(chapter._id, {
			type: template.type,
			title: template.title,
			titleScale: template.titleScale,
			subtitle: template.subtitle,
			image: template.image,
			numbered: template.numbered,
			fullpageImage: template.fullpageImage,
			configuration: template.configuration,
			lastUpdateAt: template.lastUpdateAt,
		});
	});
}
