import React from "react";
import {
    createPlateUI,
    createAutoformatPlugin,
    createBoldPlugin,
    createExitBreakPlugin,
    createHeadingPlugin,
    createHighlightPlugin,
    createItalicPlugin,
    createNodeIdPlugin,
    createResetNodePlugin,
    createSelectOnBackspacePlugin,
    createSoftBreakPlugin,
    createStrikethroughPlugin,
    createSubscriptPlugin,
    createSuperscriptPlugin,
    createTrailingBlockPlugin,
    createUnderlinePlugin,
    createDeserializeMdPlugin,
    createDeserializeCsvPlugin,
    createNormalizeTypesPlugin,
    createDeserializeDocxPlugin,
    AutoformatPlugin,
    ELEMENT_LINK,
    ELEMENT_BLOCKQUOTE
} from "@udecode/plate";
import { createJuicePlugin } from "@udecode/plate-juice";
import { CONFIG } from "./config/config";
import {
    createMyPlugins,
    MyEditor,
    MyPlatePlugin,
    PlateNodes,
} from "./config/typescript";
import {
    createOrnamentalBreakPlugin,
    createScenePlugin,
    createPageBreakPlugin,
    createSansSerifPlugin,
    createMonospacePlugin,
    createSmallCapsPlugin,
    createUndoPlugin,
    createRedoPlugin,
    createLinkPlugin,
    createImagePlugin,
    createRightAlignPlugin,
    createCenterAlignPlugin,
    createParagraphPlugin,
    createListPlugin,
    createVersePlugin,
    createHangingIndentPlugin,
    createSubheadingPlugin,
    createSocialMediaLinkPlugin,
    createEndNotesPlugin,
    createSplitChapterPlugin,
    createBlockquotePlugin,
    createCalloutBoxPlugin,
    ELEMENT_SUBHEADING,
    SubheadingComponent,
    BlockquoteComponent,
    Link,
    createCommentsPlugin
} from "./plugins";
import { createTextMessagePlugin } from "./plugins/text-message";

/*
    Add your custom component here to override existing component if they share the same `key`
*/
const components = createPlateUI({
    [ELEMENT_LINK]: Link,
    [ELEMENT_SUBHEADING]: SubheadingComponent,
    [ELEMENT_BLOCKQUOTE]: BlockquoteComponent
});

export const plugins = createMyPlugins(
    [
        //custom
        createUndoPlugin(),
        createRedoPlugin(),
        createSansSerifPlugin(),
        createMonospacePlugin(),
        createSmallCapsPlugin(),
        createOrnamentalBreakPlugin(),
        createScenePlugin(),
        createPageBreakPlugin(),
        createImagePlugin(),
        createVersePlugin(),
        createHangingIndentPlugin(),
        createEndNotesPlugin(),
        createSubheadingPlugin(),
        createSocialMediaLinkPlugin(),
        createSplitChapterPlugin(),
        createBlockquotePlugin(),
        createTextMessagePlugin(),
        createCalloutBoxPlugin(),
        createCommentsPlugin(),

        //custom with normalizers
        createParagraphPlugin(),
        createCenterAlignPlugin(),
        createRightAlignPlugin(),
        createListPlugin(),

        //default
        createHeadingPlugin(),
        createLinkPlugin(),
        createBoldPlugin(),
        createItalicPlugin(),
        createHighlightPlugin(),
        createUnderlinePlugin(),
        createStrikethroughPlugin(),
        createSubscriptPlugin(),
        createSuperscriptPlugin(),
        createNodeIdPlugin(),
        createAutoformatPlugin<
            AutoformatPlugin<PlateNodes, MyEditor>,
            PlateNodes,
            MyEditor
        >(CONFIG.autoformat),
        createResetNodePlugin(CONFIG.resetBlockType),
        createSoftBreakPlugin(CONFIG.softBreak),
        createExitBreakPlugin(CONFIG.exitBreak),
        createNormalizeTypesPlugin(CONFIG.forceLayout),
        createTrailingBlockPlugin(CONFIG.trailingBlock),
        createSelectOnBackspacePlugin(CONFIG.selectOnBackspace),

        createDeserializeMdPlugin(),
        createDeserializeCsvPlugin(),
        createDeserializeDocxPlugin(),
        createJuicePlugin() as MyPlatePlugin,
    ],
    {
        components,
    }
);
