import { BaseEditor, BaseText, Path } from "slate";
import { ReactEditor } from "slate-react";
import { TText, Value } from "@udecode/plate";

export enum TRACK_CHANGES_OPERATION {
  TEXT_ADDED = "insert",
  TEXT_DELETED = "delete",
  TEXT_UPDATED = "update",
  TEXT_FORMATTED = "format",
  TEXT_RETURN = "return",
}

export type TrackChangeNodeEntry = BaseText & {
  trackChanges: TrackChangesProperties
};

export interface TTrackChange {
  path: Path;
  tc: TTrackChangeNodeEntry
}

export interface TTrackChangeNodeEntry extends TText {
  text: string,
  trackChanges: TrackChangesProperties
  replies: Record<string, TrackChangeReply>
}

export type TrackChangeReply = {
  value: string;
  createdAt: number;
  userId: string;
  replyId: string;
};

export type TrackChangesProperties = {
  operation: TRACK_CHANGES_OPERATION;
  tcId: string;
  userId: string;
  createdAt: number;
  fragment?: boolean;
  formatting: any;
};

export type TrackChangesInfo = {
  operation: TRACK_CHANGES_OPERATION;
  changes?: any[];
};

export type EditorMode = "TrackChanges";
export type Modes = { [key in EditorMode]?: boolean };

export type TrackChangesEditor = BaseEditor &
  ReactEditor & {
    modes: Modes;
  };
