import React, { useEffect, useRef, useState } from "react";
import "tippy.js/animations/scale.css";
import "tippy.js/dist/tippy.css";

import {
    ELEMENT_OL,
    ELEMENT_UL,
    getPluginType,
    MARK_BOLD,
    MARK_ITALIC,
    MARK_STRIKETHROUGH,
    MARK_SUBSCRIPT,
    MARK_SUPERSCRIPT,
    MARK_UNDERLINE,
    ListToolbarButton,
    MarkToolbarButton,
    BlockToolbarButton,
    ELEMENT_H3,
    ELEMENT_H4,
    ELEMENT_H6,
    ELEMENT_H5,
    ELEMENT_PARAGRAPH,
    ELEMENT_H2,
    usePlateEditorState
} from "@udecode/plate";
import { ATNode, useMyPlateEditorRef } from "../config/typescript";
import {
    ImageUploadToolbarButton,
    LinkToolbarButton,
    RedoToolbarButton,
    UndoToolbarButton,
    REDO_PLUGIN,
    UNDO_PLUGIN,
    AlignToolbarButton,
    AlignmentTypes,
    VerseToolbarButton,
    HangingIndentToolbarButton,
    EndNotesToolbarButton,
    SplitChapterToolbarButton,
    SocialMediaToolbarButton,
    OrnamentalBreakToolbarButton,
    PageBreakToolbarButton,
    BlockquoteToolbarButton,
    CalloutBoxToolbarButton
} from "../plugins";

import { Divider, Row, Col, Dropdown, Menu } from "antd";

import { TooltipIcon } from "./TooltipIcon";
import { useOnlineStatus } from "../../../utils/hooks/isOffline";
import useRootStore from "../../../store/useRootStore";
import { SceneUtils } from "../../../utils/scene/sceneServices";
import { BaseOperation } from "slate";
import { TextMessageToolbarButton } from "../plugins/text-message";
import { allowFeatureForTextMessages } from "../plugins/text-message/config";
import { ArrowDownIcon, ArrowUpIcon, BlockQuoteIcon, BoldIcon, CalloutBoxIcon, CenterAlignIcon, HangingIndentIcon, HeadingIcon, ItalicIcon, LeftAlignIcon, LinkIcon, OrderedListIcon, PageBreakIcon, ParagraphIcon, ProfileIcon, RightAlignIcon, SceneBreakIcon, StrikethroughIcon, TextMessageIcon, TickSquare, UnderlineIcon, UnorderedListIcon, VerseIcon } from "../../../content/icons";
import Can from "../../casl/Can";
import { can } from "../../casl/helpers";

export const CURRENT_COLOR = "currentColor";

const COLLAPSED_MARGIN_QUTOE = 775;
const COLLAPSED_MARGIN_SCENE = 724;

const basic_marks = [
  {
    type: MARK_BOLD,
    icon: <BoldIcon color={CURRENT_COLOR}/>,
    toolTipText: "Bold",
  },
  {
    type: MARK_ITALIC,
    icon: <ItalicIcon color={CURRENT_COLOR}/>,
    toolTipText: "Italic",
  },
  {
    type: MARK_UNDERLINE,
    icon: <UnderlineIcon color={CURRENT_COLOR}/>,
    toolTipText: "Underline",
  },
];


const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        resolve(reader && reader.result ? reader.result as string : "");
    };
    reader.onerror = error => reject(error);
});

const getHeadingType = (nodeType: string) => {
  switch(nodeType) {
    case "h2":
      return <HeadingIcon/>;
    case "h3":
      return <HeadingIcon/>;
    case "h4":
      return <HeadingIcon/>;
    case "h5":
      return <HeadingIcon/>;
    case "h6":
      return <HeadingIcon/>;
    default:
      return <ParagraphIcon/>;
  }
};

export const BasicMarkToolbar = () => {
  return (
    <>
      {basic_marks.map((basicMarkItem) => (
        <TooltipIcon
          key={`plugin_${basicMarkItem.type}`}
          title={basicMarkItem.toolTipText}
        >
          <MarkToolbarButton
            reversed
            type={basicMarkItem.type}
            icon={basicMarkItem.icon}
          />
        </TooltipIcon>
      ))}
    </>
  );
};


export const FontVariationToolbar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownVisibleChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <Dropdown
      overlay={
        <Menu className='at-toolbar-dropdown'>
          <Menu.Item key='monospace' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type='monospace'
              clear={["smallcaps", "sansserif"]}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div className='tlb-monospace'>Monospace</div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
          <Menu.Item key='smallcaps' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type='smallcaps'
              clear={["monospace", "sansserif"]}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div className='tlb-smallcaps'>Smallcaps</div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
          <Menu.Item key='sansserif' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type='sansserif'
              clear={["smallcaps", "monospace"]}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div className='tlb-sansserif'>Sans Serif</div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key='subscript' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type={MARK_SUBSCRIPT}
              clear={[MARK_SUPERSCRIPT]}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div>
                      Subscript <sub>2</sub>
                    </div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
          <Menu.Item key='superscript' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type={MARK_SUPERSCRIPT}
              clear={[MARK_SUBSCRIPT]}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div>
                      Superscript <sup>2</sup>
                    </div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key='strikethrough' onClick={handleDropdownVisibleChange}>
            <MarkToolbarButton
              reversed
              type={MARK_STRIKETHROUGH}
              icon={
                <Row align='middle' gutter={15}>
                  <Col className='at-toolbar-menu-col'>
                    <span className='at-tick-square'>
                      <TickSquare />
                    </span>
                  </Col>
                  <Col>
                    <div className='tlb-strikethrough'>Strikethrough</div>
                  </Col>
                </Row>
              }
            ></MarkToolbarButton>
          </Menu.Item>
        </Menu>
      }
      onOpenChange={handleDropdownVisibleChange}
    >
      <span className='at-dropdown-toolbar'>
        <StrikethroughIcon color={CURRENT_COLOR} />

        {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </span>
    </Dropdown>
  );
};

export const HeadingsToolbar = () => {
  const editor = useMyPlateEditorRef();
  const editorState = usePlateEditorState();
  const enabled = allowFeatureForTextMessages(editorState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  let nodeType = ELEMENT_PARAGRAPH;
  if (editor.selection) {
    const path = editor.selection.anchor.path;
    const selectedNode = editor.children[path[0]];
    nodeType = selectedNode.type;

    // handling the wrapper from align
    if (
      selectedNode.type === "align_center" ||
      selectedNode.type === "align_right"
    ) {
      nodeType = selectedNode.children[0].type as string;
    }
  }
  const selectedType = getHeadingType(nodeType);

  const handleDropdownVisibleChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const subheadings = [
    {
      type: ELEMENT_PARAGRAPH,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <p>Paragraph</p>
          </Col>
        </Row>
      ),
    },
    {
      type: ELEMENT_H2,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <div className='element-h2'>Heading 2 </div>
          </Col>
        </Row>
      ),
    },
    {
      type: ELEMENT_H3,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <div className='element-h3'>Heading 3 </div>
          </Col>
        </Row>
      ),
    },
    {
      type: ELEMENT_H4,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <div className='element-h4'>Heading 4 </div>
          </Col>
        </Row>
      ),
    },
    {
      type: ELEMENT_H5,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <div className='element-h5'>Heading 5 </div>
          </Col>
        </Row>
      ),
    },
    {
      type: ELEMENT_H6,
      icon: (
        <Row align='middle' gutter={15}>
          <Col className='at-toolbar-menu-col'>
            <span className='at-tick-square'>
              <TickSquare />
            </span>
          </Col>
          <Col>
            <div className='element-h6'>Heading 6 </div>
          </Col>
        </Row>
      ),
    },
  ];
  return (
    <>
      <div className={`${enabled ? "" : "plate-disabled"}`}>
        <Dropdown
          overlay={
            <Menu className='at-toolbar-dropdown'>
              {subheadings.map((headingElement) => (
                <Menu.Item
                  key={headingElement.type}
                  onClick={handleDropdownVisibleChange}
                >
                  <BlockToolbarButton
                    type={getPluginType(editor, headingElement.type)}
                    icon={headingElement.icon}
                  />
                </Menu.Item>
              ))}
            </Menu>
          }
          onOpenChange={handleDropdownVisibleChange}
        >
          <span className='at-dropdown-toolbar'>
            {selectedType}

            {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </span>
        </Dropdown>
      </div>
    </>
  );
};

export const AlignToolbar = () => {
  const editor = useMyPlateEditorRef()!;
  const editorState = usePlateEditorState();
  const enabled = allowFeatureForTextMessages(editorState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  let nodeType = "align_left";

  if (editor.selection) {
    const path = editor.selection.anchor.path;
    const selectedNode = editor.children[path[0]];
    nodeType = selectedNode.type;
  }

  const handleDropdownVisibleChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getAlignToobarType = (nodeType: string) => {
    switch (nodeType) {
      case "align_left":
        return (
          <AlignToolbarButton
            active={false}
            icon={<LeftAlignIcon color={CURRENT_COLOR} />}
          />
        );
      case "align_center":
        return (
          <AlignToolbarButton
            targetType={AlignmentTypes.center}
            icon={<CenterAlignIcon color={CURRENT_COLOR} />}
          />
        );
      case "align_right":
        return (
          <AlignToolbarButton
            targetType={AlignmentTypes.right}
            icon={<RightAlignIcon color={CURRENT_COLOR} />}
          />
        );
      case "ul":
        return (
          <ListToolbarButton
            type={getPluginType(editor, ELEMENT_OL)}
            icon={<OrderedListIcon color={CURRENT_COLOR} />}
          />
        );
      case "ol":
        return (
          <ListToolbarButton
            type={getPluginType(editor, ELEMENT_UL)}
            icon={<UnorderedListIcon color={CURRENT_COLOR} />}
          />
        );
      default:
        return (
          <AlignToolbarButton
            active={false}
            icon={<LeftAlignIcon color={CURRENT_COLOR} />}
          />
        );
    }
  };

  const selectedType = getAlignToobarType(nodeType);

  const alignToolbarMenu = (
    <Menu className='at-toolbar-menu'>
      <Menu.Item
        key='leftAlign'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Left align' placement='right'>
          <AlignToolbarButton
            active={false}
            icon={<LeftAlignIcon color={CURRENT_COLOR} />}
          />
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='centerAlign'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Center align' placement='right'>
          <AlignToolbarButton
            targetType={AlignmentTypes.center}
            icon={<CenterAlignIcon color={CURRENT_COLOR} />}
          />
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='rightAlign'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Right align' placement='right'>
          <AlignToolbarButton
            targetType={AlignmentTypes.right}
            icon={<RightAlignIcon color={CURRENT_COLOR} />}
          />
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='orderedList'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Ordered list' placement='right'>
          <div className={`${enabled ? "" : "plate-disabled"}`}>
            <ListToolbarButton
              type={getPluginType(editor, ELEMENT_OL)}
              icon={<OrderedListIcon color={CURRENT_COLOR} />}
            />
          </div>
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='unorderedList'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Unordered list' placement='right'>
          <div className={`${enabled ? "" : "plate-disabled"}`}>
            <ListToolbarButton
              type={getPluginType(editor, ELEMENT_UL)}
              icon={<UnorderedListIcon color={CURRENT_COLOR} />}
            />
          </div>
        </TooltipIcon>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className='toolbar-align-div'>
      <Dropdown
        overlay={alignToolbarMenu}
        placement='bottomCenter'
        trigger={["hover"]}
        onOpenChange={handleDropdownVisibleChange}
      >
        <span className='at-dropdown-toolbar'>
          {selectedType}
          {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      </Dropdown>
    </div>
  );
};

export const SceneBreakToolbar = ({toolbarWidth}) => {
  const isOnline = useOnlineStatus();
  const editor = useMyPlateEditorRef();
  const editorState = usePlateEditorState();
  const enabled = allowFeatureForTextMessages(editorState);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownVisibleChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  let nodeType = "blockquote";

  if (editor.selection) {
    const path = editor.selection.anchor.path;
    const selectedNode = editor.children[path[0]];
    nodeType = selectedNode.type;
  }

  const getSceneToobarType = (nodeType: string) => {
    switch (nodeType) {
      case "ornamental-break":
        return (
          <OrnamentalBreakToolbarButton
            type='ornamental-break'
            icon={<SceneBreakIcon color={CURRENT_COLOR} />}
          />
        );
      case "calloutbox":
        return (
          <CalloutBoxToolbarButton
            icon={<CalloutBoxIcon color={CURRENT_COLOR} />}
          />
        );
      case "text_message":
        return (
          <TextMessageToolbarButton
            icon={<TextMessageIcon color={CURRENT_COLOR} />}
          />
        );
      default:
        return (
          <OrnamentalBreakToolbarButton
            type='ornamental-break'
            icon={<SceneBreakIcon color={CURRENT_COLOR} />}
          />
        );
    }
  };

  // const handleSceneCache = async () => {
  //   // Update the scene cache when adding a new ornamental Break
  //   // using the editor ref to get the updated children
  //   if (sceneIndex !== null) {
  //     const newChapter = SceneUtils.reconcileScenes(
  //       SceneUtils.updateScene({ ...chapter, children: body }, sceneIndex, {
  //         children: editor.children,
  //       })
  //     );
  //     updateSceneCache(chapter._id, newChapter);
  //     //TODO:BODY
  //     // await saveChapterBodyToLocal(chapter._id, newChapter.children);
  //     setSelectedSceneIndex(sceneIndex + 1);
  //   } else {
  //     const newChapter = SceneUtils.reconcileScenes({
  //       ...chapter,
  //       children: editor.children,
  //     });
  //     //TODO:BODY
  //     // debouncedSaveChapterBodyLocal(chapter._id, newChapter.children);
  //     updateSceneCache(chapter._id, {
  //       ...chapter,
  //       children: newChapter.children,
  //     });
  //   }
  // };

  const selectedType = getSceneToobarType(nodeType);

  const sceneToolbarMenu = (
    <Menu className='at-toolbar-menu'>
      {can("view", "scene-break-toolbar") && 
      <Menu.Item
        key='sceneBreak'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        {/* <div onClick={handleSceneCache}> */}
        <div>
          <TooltipIcon title='Scene break' placement='right'>
            <OrnamentalBreakToolbarButton
              type='ornamental-break'
              icon={<SceneBreakIcon color={CURRENT_COLOR} />}
            ></OrnamentalBreakToolbarButton>
          </TooltipIcon>
        </div>
      </Menu.Item>
      }
      {can("view", "scene-break-toolbar") && 
        <Divider type='horizontal' className='align-toolbar-divider' />
      }

      {can("view", "page-break-toolbar") && 
        <Menu.Item
          key='pageBreak'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <PageBreakToolBar isDropdown={true} />
        </Menu.Item>
      }
      {can("view", "page-break-toolbar") && 
        <Divider type='horizontal' className='align-toolbar-divider' />
      }
      {can("view", "link-toolbar") && 
        <Menu.Item
          key='link'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <TooltipIcon title='Link' placement='right' zIndex={12}>
            <LinkToolbarButton icon={<LinkIcon color={CURRENT_COLOR} />} />
          </TooltipIcon>
        </Menu.Item>
      }
      <Divider type='horizontal' className='align-toolbar-divider' />
      {can("view", "image-toolbar") &&
        <Menu.Item
          key='image'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          {isOnline ? (
            <TooltipIcon title='Image' placement='right' zIndex={12}>
              <ImageUploadToolbarButton
                transformFile={async (file) => {
                  const transformedURL = await toBase64(file);
                  return transformedURL;
                }}
              />
            </TooltipIcon>
          ) : null}
        </Menu.Item>
      }
      <Divider type='horizontal' className='align-toolbar-divider' />
      {can("view", "callout-toolbar") && 
        <Menu.Item
          key='calloutBox'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <TooltipIcon title='Callout box' placement='right'>
            <CalloutBoxToolbarButton
              icon={<CalloutBoxIcon color={CURRENT_COLOR} />}
            />
          </TooltipIcon>
        </Menu.Item>
      }
      <Divider type='horizontal' className='align-toolbar-divider' />
      {can("view", "text-message-toolbar") &&
        <Menu.Item
          key='textMessage'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <TooltipIcon title='Text message' placement='right'>
            <TextMessageToolbarButton
              icon={<TextMessageIcon color={CURRENT_COLOR} />}
            />
          </TooltipIcon>
        </Menu.Item>
      }
      <Divider type='horizontal' className='align-toolbar-divider' />
      {can("view", "endnotes-toolbar") && 
        <Menu.Item
          key='endNote'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <EndNotesToolbarButton isDropdown={true} />
        </Menu.Item>
      }
      <Divider type='horizontal' className='align-toolbar-divider' />
      {can("view", "social-media-toolbar") &&
        <Menu.Item
          key='profiles'
          className='toolbar-menu-item'
          onClick={handleDropdownVisibleChange}
        >
          <TooltipIcon title='Profile' placement='right' zIndex={12}>
            <div className={`${enabled ? "" : "plate-disabled"}`}>
              <SocialMediaToolbarButton
                type='profile'
                icon={<ProfileIcon color={CURRENT_COLOR} />}
              />
            </div>
          </TooltipIcon>
        </Menu.Item>
      }
    </Menu>
  );

  return (
    <>
      <div className='scene-dropdown'>
        <Dropdown
          overlay={sceneToolbarMenu}
          placement='bottomCenter'
          trigger={["hover"]}
          onOpenChange={handleDropdownVisibleChange}
        >
          <span className='at-dropdown-toolbar'>
            {selectedType}
            {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </span>
        </Dropdown>
      </div>
      {toolbarWidth && toolbarWidth < COLLAPSED_MARGIN_SCENE ? (
        <div className='scene-dropdown-rhs'>
          <Dropdown
            overlay={sceneToolbarMenu}
            placement='bottomCenter'
            trigger={["hover"]}
            onOpenChange={handleDropdownVisibleChange}
          >
            <span className='at-dropdown-toolbar'>
              {selectedType}
              {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </span>
          </Dropdown>
        </div>
      ) : (
        <div className='scene-row'>
          {can("view", "scene-break-toolbar") && 
            // <div onClick={handleSceneCache}>
            <div>
              <TooltipIcon title='Scene break'>
                <OrnamentalBreakToolbarButton
                  type='ornamental-break'
                  icon={<SceneBreakIcon color={CURRENT_COLOR} />}
                ></OrnamentalBreakToolbarButton>
              </TooltipIcon>
            </div>
          }
          {can("view", "page-break-toolbar") && 
            <PageBreakToolBar />
          }
          {can("view", "link-toolbar") && 
            <TooltipIcon title='Link'>
              <LinkToolbarButton icon={<LinkIcon color={CURRENT_COLOR} />} />
            </TooltipIcon>
          }
          {isOnline ? (
            can("view", "image-toolbar") && (
              <TooltipIcon title='Image' zIndex={12}>
                <ImageUploadToolbarButton
                  transformFile={async (file) => {
                    const transformedURL = await toBase64(file);
                    return transformedURL;
                  }}
                />
              </TooltipIcon>
            )
          ) : null}

          {can("view", "callout-toolbar") &&
            <TooltipIcon title='Callout box'>
              <CalloutBoxToolbarButton
                icon={<CalloutBoxIcon color={CURRENT_COLOR} />}
              />
            </TooltipIcon>
          }
          {can("view", "text-message-toolbar") &&
            <TooltipIcon title='Text message'>
              <TextMessageToolbarButton
                icon={<TextMessageIcon color={CURRENT_COLOR} />}
              />
            </TooltipIcon>
          }
          {can("view", "endnotes-toolbar") &&
            <EndNotesToolbarButton />
          }
          {can("view", "social-media-toolbar") &&
            <TooltipIcon title='Profile'>
              <div className={`${enabled ? "" : "plate-disabled"}`}>
                <SocialMediaToolbarButton
                  type='profile'
                  icon={<ProfileIcon color={CURRENT_COLOR} />}
                />
              </div>
            </TooltipIcon>
          }
        </div>
      )}
    </>
  );
};

export const PageBreakToolBar = (props) => {
  const { isDropdown } = props;
  return (
    <TooltipIcon title='Page break' placement={isDropdown ? "right" : "bottom"}>
      <PageBreakToolbarButton icon={<PageBreakIcon color={CURRENT_COLOR} />} />
    </TooltipIcon>
  );
};

export const QuoteItemToolbar = ({ toolbarWidth }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const editor = useMyPlateEditorRef()!;
  let nodeType = "blockquote";

  if (editor.selection) {
    const path = editor.selection.anchor.path;
    const selectedNode = editor.children[path[0]];
    nodeType = selectedNode.type;
  }

  const handleDropdownVisibleChange = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getQuoteToobarType = (nodeType: string) => {
    switch (nodeType) {
      case "blockquote":
        return (
          <BlockquoteToolbarButton
            icon={<BlockQuoteIcon color={CURRENT_COLOR} />}
          />
        );
      case "code_block":
        return (
          <VerseToolbarButton icon={<VerseIcon color={CURRENT_COLOR} />} />
        );
      case "indent":
        return (
          <HangingIndentToolbarButton
            icon={<HangingIndentIcon color={CURRENT_COLOR} />}
          />
        );
      default:
        return (
          <BlockquoteToolbarButton
            icon={<BlockQuoteIcon color={CURRENT_COLOR} />}
          />
        );
    }
  };

  const selectedType = getQuoteToobarType(nodeType);

  const quoteToolbarMenu = (
    <Menu className='at-toolbar-menu'>
      <Menu.Item
        key='blockquote'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Blockquote' placement='right'>
          <BlockquoteToolbarButton
            icon={<BlockQuoteIcon color={CURRENT_COLOR} />}
          />
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='verse'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Verse' placement='right'>
          <VerseToolbarButton icon={<VerseIcon color={CURRENT_COLOR} />} />
        </TooltipIcon>
      </Menu.Item>
      <Divider type='horizontal' className='align-toolbar-divider' />
      <Menu.Item
        key='hangingIndent'
        className='toolbar-menu-item'
        onClick={handleDropdownVisibleChange}
      >
        <TooltipIcon title='Hangingindent' placement='right'>
          <HangingIndentToolbarButton
            icon={<HangingIndentIcon color={CURRENT_COLOR} />}
          />
        </TooltipIcon>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className='quote-dropdown'>
        <Dropdown
          overlay={quoteToolbarMenu}
          placement='bottomCenter'
          trigger={["hover"]}
          onOpenChange={handleDropdownVisibleChange}
        >
          <span className='at-dropdown-toolbar'>
            {selectedType}

            {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </span>
        </Dropdown>
      </div>
      {toolbarWidth && toolbarWidth < COLLAPSED_MARGIN_QUTOE ? (
        <div className='quote-dropdown-rhs'>
          <Dropdown
            overlay={quoteToolbarMenu}
            placement='bottomCenter'
            trigger={["hover"]}
            onOpenChange={handleDropdownVisibleChange}
          >
            <span className='at-dropdown-toolbar'>
              {selectedType}

              {isDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </span>
          </Dropdown>
        </div>
      ) : (
        <div className='quote-row'>
          <TooltipIcon title='Blockquote'>
            <BlockquoteToolbarButton
              icon={<BlockQuoteIcon color={CURRENT_COLOR} />}
            />
          </TooltipIcon>
          <TooltipIcon title='Verse'>
            <VerseToolbarButton icon={<VerseIcon color={CURRENT_COLOR} />} />
          </TooltipIcon>
          <TooltipIcon title='Hangingindent'>
            <HangingIndentToolbarButton
              icon={<HangingIndentIcon color={CURRENT_COLOR} />}
            />
          </TooltipIcon>
        </div>
      )}
    </>
  );
};

export const UndoRedoToolbar = () => {
  const editor = useMyPlateEditorRef()!;
  // const { chapter, updateSceneCache } = useRootStore().bookStore;

  const handleUndoRedoSideEffects = (changes: BaseOperation[]) => {
    
    const hasOrnamentalBreakChange = !!changes.find(
      (r) =>
        (r.type == "insert_node" || r.type == "remove_node") &&
        // types derived from slate does not have the type attributes defined by atticus so we have to cast the node to ATNode
        (r.node as ATNode[0]).type === "ornamental-break"
    );
  
    /**
     * If an ornamental break has been added or removed due to undo/redo,
     * we need to update the scene cache to reflect the changes
     */
    if(hasOrnamentalBreakChange){
      // const newChapter = SceneUtils.reconcileScenes({ ...chapter, children: editor.children });
      // updateSceneCache(chapter._id, { ...chapter, children: newChapter.children });
    }

   
  };

  return (
    <>
      <TooltipIcon title="Undo">
        <UndoToolbarButton onUndo={handleUndoRedoSideEffects} type={getPluginType(editor, UNDO_PLUGIN)} />
      </TooltipIcon>
      <TooltipIcon title="Redo">
        <RedoToolbarButton onRedo={handleUndoRedoSideEffects} type={getPluginType(editor, REDO_PLUGIN)} />
      </TooltipIcon>
    </>
  );
};

export const Toolbar = () => {

  const {editor_setting} = useRootStore().appStore;

  const toolbarRef = useRef<HTMLDivElement>(null);
  const [toolbarWidth, setToolbarWidth] = useState<number | null>(null); 
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      // Detach the event listener
      window.removeEventListener("resize", handleResize);
    };
  }, []); 

  useEffect(() => {
    if (toolbarRef.current) {
      const width = toolbarRef.current.offsetWidth;

      setToolbarWidth(width);
    }
  }, [toolbarRef.current?.offsetWidth, editor_setting.show, screenWidth, editor_setting.isPreviewerOpen]);

  return (
    <>
      <Row className='at-toolbar-row' ref={toolbarRef}>
        <Col className='plate-toolbar-wrapper'>
          <div className='toolbar' ref={toolbarRef}>
            <Can action={"view"} subject={"basic-mark-toolbar"}>  
              <BasicMarkToolbar />
            </Can> 
            <Can action={"view"} subject={"font-variation-toolbar"}>
              <FontVariationToolbar />
            </Can>
            {(can("view", "basic-mark-toolbar") || can("view", "font-variation-toolbar")) &&
              <Divider type='vertical' className='divider' />
            }
            <Can action={"view"} subject={"headings-toolbar"}>
              <HeadingsToolbar />
            </Can>
            <Can action={"view"} subject={"align-toolbar"}>
              <AlignToolbar />
            </Can>
            {(can("view", "headings-toolbar") || can("view", "align-toolbar")) &&
              <Divider type='vertical' className='divider' />
            }
            <SceneBreakToolbar toolbarWidth={toolbarWidth} />
            <Divider type='vertical' className='divider' />
            {can("view", "quote-items-toolbar") &&
              <QuoteItemToolbar toolbarWidth={toolbarWidth} />
            }
            {can("view", "quote-items-toolbar") &&             
              <Divider type='vertical' className='divider' />
            }
            {can("view", "split-chapter-toolbar") &&
              <SplitChapterToolbarButton />
            }
            {can("view", "split-chapter-toolbar") &&
              <Divider type='vertical' className='divider' />
            }
            <UndoRedoToolbar />
          </div>
        </Col>
      </Row>
    </>
  );
};