import React from "react";
import { Layout } from "antd";

const { Content : Parent } = Layout;

export const Content: React.FC = ({ children }) => (
  <Layout className="_center">
    <Parent>
      {children}
    </Parent>
  </Layout>
);