import React from "react";
import { Layout } from "antd";

const { Sider } = Layout;

export const RHS: React.FC = ({ children }) => (
  <Sider
    className="_right"
    theme="light"
    breakpoint="lg"
    collapsedWidth="0"
    width={500}
  >
    <div className="previewer">
      {children}
    </div>
  </Sider>
);