import { useEffect, useState, useRef } from "react";

import useInterval from "use-interval";
import { authStore } from "../../store";

const BOOK_SYNC_URL = process.env.REACT_APP_SYNC_SERVER;

const useBookSyncWebsocket = (bookId: string | null) => {
  const PingInterval = 5000;
  const MaxReconnectInterval = 10000;
  const ReconnectInterval = useRef(0);
  const SocketUrl = useRef("");
  const _ismounted = useRef(false);
  const token = authStore.token;
  const book_Id = bookId;

  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    SocketUrl.current = BOOK_SYNC_URL ? BOOK_SYNC_URL : "";
    if (socket) {
      socket.close();
    } else {
      connect();
    }
  }, [SocketUrl]);

  useEffect(() => {
    _ismounted.current = true;
    return () => {
      _ismounted.current = false;
      if (socket) socket.close();
    };
  }, []);

  useInterval(() => {
    if (socket?.readyState === WebSocket.OPEN) {
      socket?.send(JSON.stringify({
        action: "ping", 
      }));
    }
  }, PingInterval);

  const connect = () => {
    const ws = token ? new WebSocket(SocketUrl.current, token) : new WebSocket(SocketUrl.current);
    let timeoutFunction;

    ws.onopen = () => {
      console.log("WebSocket Connected");
      setSocket(ws);
      ReconnectInterval.current = 0;
      clearInterval(timeoutFunction);

      // Send bookId to the server upon connection
      ws.send(JSON.stringify({ action: "join-room", data: { bookId: book_Id } }));
    };

    ws.onclose = () => {
      if (_ismounted.current) {
        ReconnectInterval.current += 1000;
        console.log(`WebSocket disconnected, attempting to reconnect in ${Math.min(MaxReconnectInterval / 1000, ReconnectInterval.current / 1000)} seconds`);
        timeoutFunction = setTimeout(checkSocket, Math.min(MaxReconnectInterval, ReconnectInterval.current));
      } else {
        console.log("WebSocket connection closed");
      }
    };

    ws.onerror = () => {
      console.log(`WebSocket failed, status: ${ws.readyState}`);
    };
  };

  const checkSocket = () => {
    if (!socket || socket.readyState === WebSocket.CLOSED) connect();
  };

  return socket;
};

export default useBookSyncWebsocket;