import React from "react";
import { PortalBody } from "@udecode/plate-styled-components";

import { useEffect, useState } from "react";
import {
  getSelectionText,
  isSelectionExpanded,
  mergeProps,
  useEditorState,
  useEventEditorSelectors,
} from "@udecode/plate-core";
import {
  flip,
  getSelectionBoundingClientRect,
  offset,
  useVirtualFloating,
  UseVirtualFloatingOptions,
  UseVirtualFloatingReturn,
} from "@udecode/plate-floating";
import { useFocused } from "slate-react";
import {
  BalloonToolbarProps,
  getBalloonToolbarStyles,
  toDOMNode,
  ToolbarBase,
  useEditorRef,
} from "@udecode/plate";

export type FloatingToolbarState = {
  floatingOptions?: UseVirtualFloatingOptions;
  hideToolbar?: boolean;
  ignoreReadOnly?: boolean;
};

export interface CustomFloatingToolBarProps extends BalloonToolbarProps {
  ignoreReadOnly?: boolean;
}

export const useFloatingToolbar = ({
  floatingOptions,
  ignoreReadOnly,
}: {
  floatingOptions?: UseVirtualFloatingOptions;
  ignoreReadOnly?: boolean;
} = {}): UseVirtualFloatingReturn & {
  open: boolean;
} => {
  const focusedEditorId = useEventEditorSelectors.focus();
  const editor = useEditorState();
  const focused = useFocused();

  const [waitForCollapsedSelection, setWaitForCollapsedSelection] =
    useState(false);

  const [open, setOpen] = useState(false);

  const selectionExpanded = editor && isSelectionExpanded(editor);
  const selectionText = editor && getSelectionText(editor);

  // On refocus, the editor keeps the previous selection,
  // so we need to wait it"s collapsed at the new position before displaying the floating toolbar.
  useEffect(() => {
    if (!focused || ignoreReadOnly) {
      setWaitForCollapsedSelection(true);
    }

    if (!selectionExpanded) {
      setWaitForCollapsedSelection(false);
    }
  }, [focused, selectionExpanded]);

  useEffect(() => {
    if (
      !selectionExpanded ||
      !selectionText ||
      (editor.id !== focusedEditorId && !ignoreReadOnly)
    ) {
      setOpen(false);
    } else if (
      selectionText &&
      selectionExpanded &&
      !waitForCollapsedSelection
    ) {
      setOpen(true);
    }
  }, [
    editor.id,
    editor.selection,
    focusedEditorId,
    selectionExpanded,
    selectionText,
    waitForCollapsedSelection,
  ]);

  const floatingResult = useVirtualFloating(
    mergeProps(
      {
        middleware: [
          offset(12),
          flip({
            padding: 96,
          }),
        ],
        placement: "top",
        getBoundingClientRect: getSelectionBoundingClientRect,
        open,
        onOpenChange: setOpen,
      },
      floatingOptions
    )
  );

  const { update } = floatingResult;

  const selectionTextLength = selectionText?.length ?? 0;

  useEffect(() => {
    if (selectionTextLength > 0) {
      update?.();
    }
  }, [selectionTextLength, update]);

  return { ...floatingResult, open };
};

export const BalloonToolbar = (props: CustomFloatingToolBarProps) => {
  const {
    children,
    theme = "dark",
    arrow = false,
    portalElement,
    floatingOptions,
    ignoreReadOnly,
  } = props;

  const { floating, style, placement, open } = useFloatingToolbar({
    floatingOptions,
    ignoreReadOnly,
  });

  const styles = getBalloonToolbarStyles({
    placement,
    theme,
    arrow,
    ...props,
  });

  if (style?.top) {
    const editor = useEditorRef();
    const editorRect = toDOMNode(editor, editor);
    if (editorRect) {
      const { height: editorHeight, y: editorY } =
        editorRect.getBoundingClientRect();
      const toolbarTop = parseFloat(style.top as string);

      if (toolbarTop < editorY) {
        style.top = `${editorY}px`;
      } else if (toolbarTop > editorY + editorHeight) {
        style.top = `${editorY + editorHeight - 40}px`;
      }
    }
  }

  if (!open) return null;

  return (
    <PortalBody element={portalElement}>
      <ToolbarBase
        className={styles.root.className}
        ref={floating}
        style={style}
      >
        {children}
      </ToolbarBase>
    </PortalBody>
  );
};
