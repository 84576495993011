import React from "react";
import { useState } from "react";
import {
    PlateEditor,
    useEventPlateId,
    usePlateEditorState,
    Value,
    findNodePath,
    setNodes,
    removeNodes,
    TNode,
} from "@udecode/plate";

// components
import { Button, Col, Popconfirm, Row } from "antd";

import { EditImage } from "./EditImage";

// types
import { ImageNode, ATNode, MyEditor} from "../../../config/typescript";

// icons
import {
    ImageSettingsIcon,
} from "../../../partials/Icon";
import { DeleteIcon } from "../../../../../content/icons";
import { can } from "../../../../casl/helpers";


export const ImageComponent = (props : any) => {
    const { id, children, element } = props;
    const editor = usePlateEditorState() as MyEditor;
    const [editImage, setEditImage] = useState(false);
    
    return (
        <div className="edt-img">
            {editImage ? (
                <EditImage
                    element={element}
                    onClose={() => {
                        setEditImage(false);
                    }}
                    onUpdate={(updatedImageNode: ImageNode) => {
                        const path = findNodePath(editor, element);
                        setNodes(editor, updatedImageNode as unknown as TNode, { at: path });
                    }}
                />
            ) : null}
            <div contentEditable={false}>
                <div className="edt-img-action">
                    <Row>
                        <Col>
                            {can("view", "plugin-settings") &&
                                <Button
                                    size="small"
                                    onMouseDown={() => {
                                        setEditImage(true);
                                    }}
                                >
                                    <ImageSettingsIcon />
                                </Button>}
                        </Col>
                        <Col>
                            <Popconfirm
                                title="Are you sure you want to delete this Image?"
                                onConfirm={() => {
                                    const path = findNodePath(editor, element);
                                    removeNodes(editor, { at: path });
                                }}
                                okText="Delete"
                                cancelText="Cancel"
                                okButtonProps={{
                                    danger: true,
                                }}
                            >
                                {can("view", "plugin-settings") &&
                                    <Button size="small" className="delete-img-btn">
                                        <DeleteIcon size={15} />
                                    </Button>}
                            </Popconfirm>
                        </Col>
                    </Row>
                </div>
                <div className="imge">
                    <div>
                        <img src={element.url} />
                    </div>
                    <div className="caption">{element.caption}</div>
                </div>
            </div>
            {children}
        </div>
    );
};