import { filter } from "lodash";

import { isMergable } from "../components/Chapters/helpers";
import { ExpandedBook } from "../types/book";
import { ChapterMeta } from "../types/chapter";
import { ChapterCacheMetaData } from "../types/pdf-cache";
import { Scene, SceneMeta } from "../types/scene";
import { SectionType, SideBarChapterItem, SideBarItem, SideBarSceneItem } from "../types/sidebar";
import { isChapterTopLevel } from "./chapter";
import { isNumberedChapter } from "./chapter-numbering";
import { decodeDroppableId } from "./scene/dnd";

/**
 * Adds chapter numbering to sidebar items
 * @param arr {SideBarItem}[]
 * @returns sidebar items with numbering added
 */
export function addNumbering(arr: SideBarItem[], book: ExpandedBook): SideBarItem[] {
  const withNumbers: SideBarItem[] = [];
  let isInsideVolume = false;
  let isVolumeFrontMatter = false;
  let bookLevelNumber = 1;
  let chapterNumber = 1;

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    // when in level 0 assign book level number to chapter number
    if (item.level === 0) {
      chapterNumber = bookLevelNumber;
      isInsideVolume = false;
    }

    if (item.type === "chapter" && item.chapter.type === "volume") {
      // store current number as book level number before resetting
      bookLevelNumber = chapterNumber;
      isInsideVolume = true;
      isVolumeFrontMatter = true;
      // reset numbering on volumes
      chapterNumber = 1;
    }

    if (item.type === "chapter" && isNumberedChapter(item.chapter, book) && !isVolumeFrontMatter) {
      // add chapter number
      withNumbers.push({ ...item, number: chapterNumber });
      // increment chapter number
      chapterNumber += 1;
      // increment book level number
      if (!isInsideVolume) bookLevelNumber += 1;
    } else {
      // once we encounter the body separator reset the frontmatter flag
      if (isInsideVolume && isVolumeFrontMatter && item.type === "body-separator") {
        isVolumeFrontMatter = false;
      }

      // push the current item
      withNumbers.push(item);
    }
  }
  return withNumbers;
}

/**
 * Removes the child chapters of the collapsed chapters
 * @param arr {SideBarItem}[]
 * @param expandedChapters Set of expanded chapters
 * @param bookId current book id -> used to check parentChapterId
 * @returns {SideBarItem}[] with children of the collapsed chapters removed
 */
export function collapseGroups(arr: SideBarItem[], expandedChapters: Set<string>, bookId: string): SideBarItem[] {
  const hiddenChapters = new Set();

  return arr.filter((item) => {
    if (item.type === "scene" && (!expandedChapters.has(item.chapter._id) || hiddenChapters.has(item.chapter._id))) {
      return false;
    }

    if (
      item.type === "chapter" &&
      item.chapter.parentChapterId &&
      item.chapter.parentChapterId !== bookId &&
      (!expandedChapters.has(item.chapter.parentChapterId) || hiddenChapters.has(item.chapter.parentChapterId))
    ) {
      hiddenChapters.add(item.chapter._id);
      return false;
    }

    if (item.type === "body-separator" && item.level !== 0) {
      const [chapterId] = decodeDroppableId(item.id);
      if (!expandedChapters.has(chapterId)) {
        return false;
      }
    }

    return true;
  });
}

/**
 * adds the required placeholders between sidebar items to distinguish between parent / child chapters
 *
 * @param arr {SideBarItem}[]
 * @returns sidebar items with placeholders added
 */
export function addPlaceholders(arr: SideBarItem[]): SideBarItem[] {
  const withPlaceholders: SideBarItem[] = [];

  // store who are the parents at each level need this to create placeholders with correct id so dnd location is correctly picked up
  const lastLevelIds: string[] = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    const isLastItem = arr.length === i + 1;

    // discard any existing placeholders
    if (item.type === "placeholder") continue;
    // push the current item
    withPlaceholders.push(item);

    // store item.id when going a level up
    if (!isLastItem && item.level < arr[i + 1].level) {
      lastLevelIds.push(item.id);
    }

    // add placeholders when level is going down
    if (isLastItem || arr[i + 1].level < item.level) {
      // add placeholders for the diff between current item & next (all the way to root if it's last item)
      const stepsToAdd = isLastItem
        ? item.level
        : item.level - arr[i + 1].level;

      for (let i = 0; i < stepsToAdd; i++) {
        const id = lastLevelIds.pop() || item.id;
        withPlaceholders.push({
          type: "placeholder",
          level: item.level - i - 1,
          id: id + "--placeholder-" + i,
          section: "body",
        });
      }
    }
  }
  return withPlaceholders;
}

export function getBookMetaSummaryForCache(
  frontMatter: ChapterMeta[],
  body: ChapterMeta[]
): { chapterCacheData: ChapterCacheMetaData[]; frontMatterIds: string[] } {
  const chapterCacheData = [...frontMatter, ...body].map(
    ({ _id, type, startOn }) =>
      ({
        chapterId: _id,
        chapterType: type,
        startOn,
      } as IPDFCacheStore.ChapterCacheMetaData)
  );
  const frontMatterIds = frontMatter.map(({ _id }) => _id);

  return { chapterCacheData, frontMatterIds };
}

/**
 * Maps a scene to a sidebar item
 *
 * @param chapter
 * @param level
 * @param scene
 * @param index
 * @returns {SideBarItem}
 */
function mapScene(chapter: ChapterMeta, level: number, scene: Scene, index: number): SideBarSceneItem {
  return {
    chapter: chapter,
    scene,
    id: `${chapter._id}-${index}`,
    section: "body",
    level: level,
    type: "scene",
  };
}

export function mapChaptersToItems(
  chapterMeta: {
    frontMatter: ChapterMeta[];
    chapters: ChapterMeta[];
  },
  sceneCache: Map<string, Scene[]>
): SideBarItem[] {
  const { chapters, frontMatter } = chapterMeta;
  const bookChapters = [...chapters];
  const bookChapterIds = bookChapters.map((r) => r._id);

  // function to get all chapters with a  given parentChapterId
  const getChildChapters = (parentChapterId: string) => filter(bookChapters, { parentChapterId });

  const mapChapters = (level: number, createPart: boolean, createVolume: boolean) => (c: ChapterMeta, index, arr) => {
    // scene  of chapter
    const chapterScenes = sceneCache.get(c._id);
    const sceneItems: SideBarSceneItem[] = [];
    if (chapterScenes && chapterScenes.length > 0) {
      chapterScenes.forEach((scene, i) => {
        sceneItems.push(mapScene(c, level + 1, scene, i));
      });
    }

    const canCreateParts = createPart && c.type !== "part";
    const canCreateVolume = createPart && createVolume && c.type !== "volume";

    const childItemArrays: SideBarItem[][] = getChildChapters(c._id)
      .map(mapChapters(level + 1, canCreateParts, canCreateVolume));

    // add body separator for volumes
    if (c.type === "volume" && c.volume) {
      const separatorIndex = c.volume.frontMatterIds.length;
      const separator: SideBarItem = {
        section: "body",
        type: "body-separator",
        id: c._id + "--body_separator",
        level: level + 1,
      };

      for (let i = 0; i < separatorIndex; i++) {
        //  disable part creation within the frontmatter items
        if(childItemArrays[i].length){
          (childItemArrays[i][0] as SideBarChapterItem).createPart = false;
        }
      }

      childItemArrays.splice(separatorIndex, 0, [separator]);
    }

    const childItems = childItemArrays.flat(5);

    const hasParentChapterOfTypePart = bookChapters.find((chap) => chap._id === c.parentChapterId)?.type === "part";
    const isParentChapterFirstBodyChapter = bookChapterIds.findIndex((id) => id === c.parentChapterId) === 0;
    
    const itemForChapter: SideBarItem = {
      chapter: c,
      id: c._id,
      hasChildren: sceneItems.length > 1 || childItems.length > 0,
      section: "body" as SectionType,
      level: level,
      // begin on is not all allowed for volumes, parts and the chapters where index is 0 and its parent chapter type is not part (if part is not the first body chapter)
      beginOn: !(
        (index === 0 && !hasParentChapterOfTypePart) ||
        (index === 0 && isParentChapterFirstBodyChapter) ||
        ["volume", "part"].includes(c.type)
      ),
      canMerge: isMergable(c.type) && index < arr.length - 1 && isMergable(arr[index + 1].type),
      type: "chapter",
      createPart: canCreateParts && c.type !== "volume",
      createVolume: canCreateVolume,
    };

    // return [itemForChapter, ...childItems, ...sceneItems];
    return [itemForChapter, ...childItems, ...sceneItems];
  };

  const items: SideBarItem[] = [
    // frontMatter
    ...frontMatter.map((c, index, arr) => ({
      chapter: c,
      id: c._id,
      hasChildren: false,
      section: "frontMatter" as SectionType,
      level: 0,
      type: "chapter" as const,
      canMerge: isMergable(c.type) && index < arr.length - 1 && isMergable(arr[index + 1].type),
      beginOn: Boolean(index !== 0),
      createVolume: false,
      createPart: false,
    })),

    // body separator
    {
      section: "frontMatter",
      type: "body-separator",
      id: "body_separator",
      level: 0,
    },

    // chapters
    ...[
      ...bookChapters
        .filter((i) => i && isChapterTopLevel(i.parentChapterId, i.bookId))
        .map(mapChapters(0, true, true))
        .flat(5),
    ],
  ];

  return items;
}

// export function reconcileScenesInSideBar(selectedChapterId: string, scenes: Scene[], sideBarItems: SideBarItem[]): SideBarItem[] {
//   /** remove scenes from previously selected chapters from the sidebar */
//   const sideBarItemsWithoutScenes: SideBarItem[] = [];
//   for(const item of sideBarItems){
//     if(item.type === "scene") continue;
//     if(item.type === "chapter" && (item as SideBarChapterItem).hasScenes){
//       sideBarItemsWithoutScenes.push({...item, hasScenes: false});
//     }else{
//       sideBarItemsWithoutScenes.push(item);
//     }
//   }
//   /** insert scenes from the current selected chapter into the sidebar */
//   /** 
//    * If there's only one scene in chapter body, do not render it as a scene 
//    * Every chapter should have at least one scene, so if there's only one scene in the chapter, it should be rendered as a chapter
//    * */
//   if(scenes.length <= 1) return sideBarItemsWithoutScenes;
//   const chapterIndex = sideBarItemsWithoutScenes.findIndex((item) => item.id === selectedChapterId);
//   if (chapterIndex === -1) return sideBarItemsWithoutScenes;
//   const chapterItem = sideBarItemsWithoutScenes[chapterIndex] as SideBarChapterItem;
//   const chapterLevel = chapterItem.level;
//   const sceneItems = scenes.map((scene, i) => (mapScene(chapterItem.chapter, chapterLevel + 1, scene, i)));
//   const chapterItemWithChildren = { ...chapterItem, hasScenes: true };
//   return [
//     ...sideBarItemsWithoutScenes.slice(0, chapterIndex),
//     chapterItemWithChildren,
//     ...sceneItems,
//     ...sideBarItemsWithoutScenes.slice(chapterIndex + 1),
//   ];
// }

/**
 * Reorders a chapters array so that all children are immediately after the parent while preserving the current order.
 * this is used to  move children when a parent is dragged along.
 *
 * @param chaptersToReorder
 * @returns
 */
export function reorderChapters(chaptersToReorder: ChapterMeta[]): ChapterMeta[] {
  const allIds = chaptersToReorder.map((r) => r._id);

  const withChildren = (e: ChapterMeta) => {
    const children = chaptersToReorder.filter((r) => r.parentChapterId === e._id);
    return [e, children.map((r) => withChildren(r))].flat(2);
  };

  return chaptersToReorder
    .filter((i) => i && (i.parentChapterId === undefined || !allIds.includes(i.parentChapterId!)))
    .map((e) => withChildren(e))
    .flat(2);
}

/**
 * Returns all nested children chapterId for a given chapter
 *
 * @param chapters book chapter meta
 * @param chapterId chapter Id of the parent
 * @returns {string[]} of all children
 */
export function getAllChildrenIds(chapters: ChapterMeta[], chapterId: string): string[] {
  return chapters
    .filter((chap) => chap.parentChapterId === chapterId)
    .map((chap) => [chap._id, ...getAllChildrenIds(chapters, chap._id)])
    .flat(2);
}

/**
 * update chapters & remove parentIds to make sure all parent Ids are valid
 *
 * @param chapters book chapter meta
 * @returns  
 */
export function removeNonExistingParentIds(chapters: ChapterMeta[]): { cleanedChapters: ChapterMeta[]; updatedIds: string[] } {
  const updatedIds: string[] = [];
  const chapterIds = chapters.map((r) => r._id);

  const cleanedChapters = chapters.map((chap) => {
    if (!isChapterTopLevel(chap.parentChapterId, chap.bookId) && chapterIds.includes(chap.parentChapterId!) === false) {
      updatedIds.push(chap._id);
      console.warn(`INVALID PARENT ID : ${chap.parentChapterId} of chapter ${chap._id}`);
      return { ...chap, parentChapterId: chap.bookId };
    }
    return chap;
  });

  return { cleanedChapters, updatedIds };
}
