import React from "react";
import { ManageCollaborators } from "../containers/collaboration";

export const Collaborators = (): JSX.Element => {
  return (
    <>
        <ManageCollaborators />
    </>
  );
};
