import React from "react";
import { useEffect, useState } from "react";
import { Redirect, useHistory, useParams } from "react-router-dom";
import { GetBookFromDB } from "../../../utils/offline.book.helpers";

export const RedirectToFirstChapter = () => {
    const { bookId } = useParams() as { bookId: string, };
    const { push } = useHistory();

    const handler = async (book_id: string) => {
        const b = await GetBookFromDB(book_id) as IBookStore.ExpandedBook;
        if (b) {
            let initialChapterId = "";
           if (b.chapters && b.chapters.length > 0) {
                //if first chapter is volume, then go to its' next chapter
                if (b.chapters[0].type === "volume") {
                    initialChapterId = b.chapters[1]._id;
                } else {
                    initialChapterId = b.chapters[0]._id;
                }
            } else if (b.frontMatterIds && b.frontMatterIds.length > 0) {
                initialChapterId = b.frontMatterIds[0];
            }

            push(`/books/${bookId}/${initialChapterId}`);
        }
    };

    useEffect(() => {
        if (bookId) {
            handler(bookId);
        }
    }, []);

    return null;
};