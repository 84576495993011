import React from "react";

export const handleCommentKeyDown = (
  event: React.KeyboardEvent<HTMLTextAreaElement>,
  action: () => void
): void => {
  if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
    action();
  }
};
