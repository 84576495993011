import { Path } from "slate";
import { setNodes, unsetNodes, PlateEditor } from "@udecode/plate";
import { v4 as uuidv4 } from "uuid";

import {
  CommentsRecord,
  TCommentText,
  getCommentNodesById,
  unsetCommentNodesById,
  TComment,
  getComments,
} from "../plugins/comments";

const setNodeComments = (
  editor: PlateEditor,
  comments: CommentsRecord,
  path: Path
) => {
  unsetNodes<TCommentText>(editor, ["comments"], {
    at: path,
  });
  setNodes(editor, { comments: comments }, { at: path });
};

export const resolveComment = (
  editor: PlateEditor,
  commentId: string,
  setActiveCommentId: (id: string | null) => void
): void => {
  const commentNodes = getCommentNodesById(editor, commentId as string);
  commentNodes.forEach((commentNode) => {
    const [node, path] = commentNode;
    let comments: CommentsRecord = node.comments || {};

    const resolvingComment = comments[commentId];
    comments = {
      ...comments,
      [commentId]: {
        ...resolvingComment,
        isResolved: true,
      },
    };
    setNodeComments(editor, comments, path);
  });
  setActiveCommentId(null);
};

export const submitReplyComment = (
  editor: PlateEditor,
  replyValue: string,
  activeCommentId: string | null,
  userId: string | undefined
): void => {
  const commentId = uuidv4();
  if (activeCommentId && replyValue !== "") {
    const commentNodes = getCommentNodesById(editor, activeCommentId);
    const newComment: TComment = {
      id: commentId,
      value: replyValue,
      createdAt: Date.now(),
      userId: userId as string,
      parentId: activeCommentId,
    };

    commentNodes.forEach((commentNode) => {
      const [node, path] = commentNode;
      const nodeComments: CommentsRecord = node.comments || {};
      setNodes(
        editor,
        {
          comments: { ...nodeComments, [commentId]: newComment },
        },
        { at: path }
      );
    });
  }
};

export const deleteComment = (
  editor: PlateEditor,
  deletingComment: TComment,
  setActiveCommentId: (id: string | null) => void
): void => {
  const commentNodes = getCommentNodesById(
    editor,
    deletingComment.parentId ? deletingComment.parentId : deletingComment.id
  );

  commentNodes.forEach((commentNode) => {
    const [node, path] = commentNode;
    let comments: CommentsRecord = node.comments || {};
    const deletingCommentId = deletingComment.id;

    if (!deletingComment.parentId) {
      comments = Object.fromEntries(
        Object.entries(comments).filter(
          ([key, comment]) =>
            comment.id !== deletingCommentId &&
            comment.parentId !== deletingCommentId
        )
      );
    } else {
      delete comments[deletingCommentId];
    }
    setNodeComments(editor, comments, path);

    if (Object.keys(comments).length === 0) {
      unsetNodes<TCommentText>(editor, ["comment", "comments"], {
        at: path,
      });
    }
  });

  if (!deletingComment.parentId) {
    unsetCommentNodesById(editor, { id: deletingComment.id });
    setActiveCommentId(null);
  }
};

export const updateComment = (
  editor: PlateEditor,
  updatingComment: TComment
): void => {
  const commentNodes = getCommentNodesById(
    editor,
    updatingComment.parentId ? updatingComment.parentId : updatingComment.id
  );

  commentNodes.forEach((commentNode) => {
    const [node, path] = commentNode;
    let comments: CommentsRecord = node.comments || {};
    if (comments) {
      comments = {
        ...comments,
        [updatingComment.id]: updatingComment,
      };
      setNodeComments(editor, comments, path);
    }
  });
};

export const removeUnsubmittedComments = (
  editor: PlateEditor,
  activeCommentId: string
): void => {
  const comments = getComments(editor);
  const commentExists = comments.some(
    (comment) => comment.id === activeCommentId
  );

  if (activeCommentId && !commentExists) {
    unsetCommentNodesById(editor, { id: activeCommentId });
  }
};