import Dexie, { Table } from "dexie";

export class AppDatabase extends Dexie {
    books: Table<IBookStore.Book, string>;
    collaborator_metas: Table<ICollabStore.AuthorMetaWithId, string>;
    collaborations: Table<ICollabStore.BookCollaboration, string>;
    chapterMetas: Table<IChapterStore.ChapterMeta, string>;
    authors: Table<IBookStore.BookAuthor, string>;
    projects: Table<IBookStore.BookProject, string>;
    theme: Table<IThemeStore.ThemeResponse, string>;
    deviceConfig: Table<IThemeStore.DeviceSpec, string>;
    bookGoals: Table<IGoalStore.Goal, string>;
    writingHabits: Table<IHabitStore.Habit, string>;
    sprintTimer: Table<ISprintTimerStore.SprintTimer, string>;
    chapterTemplates: Table<IChapterStore.IChapterTemplateBase, string>
    imageGallery: Table<any, string>
    failedBooks: Table<IBookStore.ErrorBook, string>;
    failedChapters: Table<IBookStore.ErrorChapter, string>;
    userFavouriteFonts: Table<IFontStore.FontItem, string>;
    notifications: Table<INotificationStore.Notification, string>;

    constructor() {
        super("AtticusDatabase");
        //
        // Define tables and indexes
        //
        this.version(13).stores({
            books: "&_id, title, author",
            notifications: "&_id, receiver_id",
            collaborations: "&_id, email",
            collaborator_metas: "&_id, email",
            chapterMetas: "&_id, bookId, templateId, index",
            authors: "&_id,name",
            projects: "&_id,name,books",
            theme: "&_id, name",
            deviceConfig: "&_deviceName",
            bookGoals: "&_id, bookId",
            writingHabits: "&_id, userId",
            sprintTimer: "&_bookId",
            chapterTemplates: "&_id",
            imageGallery: "&_imgId",
            failedBooks: "&_bookId",
            failedChapters: "&_chapterId, _bookId",
            userFavouriteFonts: "&_id, family",
        });

        this.books = this.table("books");
        this.notifications = this.table("notifications");
        this.collaborations = this.table("collaborations");
        this.collaborator_metas = this.table("collaborator_metas");
        this.chapterMetas = this.table("chapterMetas");
        this.authors = this.table("authors");
        this.projects = this.table("projects");
        this.theme = this.table("theme");
        this.deviceConfig = this.table("deviceConfig");
        this.bookGoals = this.table("bookGoals");
        this.writingHabits = this.table("writingHabits");
        this.sprintTimer = this.table("sprintTimer");
        this.chapterTemplates = this.table("chapterTemplates");
        this.imageGallery = this.table("imageGallery");
        this.failedBooks = this.table("failedBooks");
        this.failedChapters = this.table("failedChapters");
        this.userFavouriteFonts = this.table("userFavouriteFonts");
    }
}

export const db = new AppDatabase();
