const pluginProperties = [
    "underline", 
    "bold", 
    "italic",
    "monospace",
    "smallcaps",
    "sansserif",
    "subscript",
    "superscript",
    "strikethrough",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
];

export default pluginProperties;