import React, { FunctionComponent } from "react";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import { Button } from "antd";
import { Modal as AtticusModal } from "../../../Shared/Modal";

import {
  SceneIcon,
  CloseIconWithoutBackground,
} from "../../../../content/icons";

//store
import { useOnlineStatus } from "../../../../utils/hooks/isOffline";

// types
import { useSideBarActions } from "../../../../utils/hooks/useSideBarActions";
import useRootStore from "../../../../store/useRootStore";
import { SideBarSceneItem } from "../../../../types/sidebar";
import ContextMenu from "../../ContextMenu";
import { ellipsizeTitle } from "../../../../utils/strings";
import useContextMenu from "../../../../utils/hooks/useContextMenu";


interface SceneBlockItemProps {
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  item: SideBarSceneItem;
}

const SceneItem: FunctionComponent<SceneBlockItemProps> = ({
  item,
  provided,
  snapshot,
}: SceneBlockItemProps) => {
  const { currentScene } = useRootStore().chapterStore;
  const { scene, chapter, level } = item;
  const { sceneIndex, sceneTitle, id: sceneId } = scene;
  const isActive = currentScene?.scene.sceneIndex === sceneIndex;
  const { handleDeleteScene } = useSideBarActions();
  const { captureClickEvent } = useRootStore().sideMenuStore;
  const listItemRef = React.createRef<HTMLAnchorElement>();
  const [displayContextMenu, anchorPoints] = useContextMenu(listItemRef);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const isOnline = useOnlineStatus();

  const handleDelete = (deleteContent: boolean) => {
    handleDeleteScene(sceneId, sceneIndex, chapter._id, deleteContent);
  };

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...provided.draggableProps.style,
        paddingLeft: level > 0 ? `${level + 1.8}rem` : "",
      }}
    >
      <div
        className={`chapter-block-item${snapshot.isDragging ? " hover" : ""} ${
          isActive ? " active" : ""
        } `}
      >
        <div className="chapter-block-item-container">
          <a
            onClickCapture={(e) =>
              captureClickEvent(e, chapter._id, scene)
            }
            ref={listItemRef}
          >
            <div className="chapter-block-item-info-container">
              <div className="chapter-block-item-icon-container">
                <span className="chapter-block-icon">
                  {" "}
                  <SceneIcon color={isActive ? "#3568BA" : "#171D21"} />
                </span>
              </div>
              <div className="chapter-block-item-chapter-name-cover">
                <p
                  className={`chapter-block-item-chapter-name ${
                    isActive ? " active" : ""
                  }`}
                >
                  {sceneTitle
                    ? ellipsizeTitle(sceneTitle)
                    : `Scene ${sceneIndex + 1}`}
                </p>
              </div>
            </div>
          </a>
          <ContextMenu
            canDisplay={displayContextMenu}
            top={anchorPoints ? anchorPoints.top - 225 : 0}
            left={anchorPoints?.left}
          />

          <div className="action-group">

            <AtticusModal
              open={isModalOpen}
              centered={true}
              title={<b>Delete scene</b>}
              onCancel={() => {
                setIsModalOpen(false);
              }}
              destroyOnClose={true}
              leftBtn={{
                type: "at-secondary",
                className: "link-modal-btn",
                backgroundColor: "green",
                onMouseDown: () => handleDelete(false),
                children: "Merge Content",
              }}
              rightBtn={{
                type: "at-primary",
                danger: true,
                onMouseDown: () => handleDelete(true),
                children: "Delete content",
              }}
            >
              {" "}
              <p className="delete-modal-bottom-padding">
                How do you want to delete "{<b>{sceneTitle}</b>}"?
              </p>
            </AtticusModal>

            {isOnline && (
              <Button
                type="text"
                shape="round"
                size="large"
                className="action-btn"
                onMouseDown={() => {
                  setIsModalOpen(true);
                }}
              >
                <span className="chapter-block-icon">
                  <CloseIconWithoutBackground />
                </span>
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SceneItem;
