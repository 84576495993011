
import React from "react";
import { Row, Col, Card, Radio, Space, Collapse, Button, List, Avatar } from "antd";

import { Modal } from "../../components/Shared/Modal";
import { UserOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";

const { Panel } = Collapse;

const authors = Array(8).fill({
  name: "Anna Marie",
  email: "annamarie@gmail.com"
});

export const ManageCollaborators = observer(() => {
  const {
    collaborators
  } = useRootStore().collaborationStore;

  const sanitized_collaborators = Object.keys(collaborators).map(c => ({ ...collaborators[c], id: c}));
  
  return (
    <Modal
      open={true}
      width={1000}
      className="manage-collaborators"
      title="Remove Collaborators"
    >
      <div className="overlay"></div>
      <Row className="collaborators-content" wrap={false}>
        <Col flex="250px">
          <Card size="small">
            <h3>Co-Writer: <span className="error">8</span>/3</h3>
            <p><span className="error">Remove 5 users </span>to continue</p>
          </Card>
          <div className="inner-s">
            <p>The users will be notified that they've been removed</p>
          </div>
          <div>
            <Radio.Group name="role" defaultValue="co-writer">
              <Space direction="vertical" size="middle">
                <Radio value="co-writer">Co-Writer</Radio>
                <Radio value="editor">Editor</Radio>
                <Radio value="beta-reader">Beta Reader</Radio>
              </Space>
            </Radio.Group>
          </div>
        </Col>
        <Col flex="50px" />
        <Col flex={1}>
          <Row>
            <Collapse defaultActiveKey={["active", "removed"]} ghost>
              <Panel
                header="List of Co-Writers"
                key="active"
                extra={<Button type="link">Select all</Button>}
              >
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={sanitized_collaborators}
                  renderItem={item => (
                    <List.Item>
                      <Card hoverable size="small">
                        <Row gutter={8} align="middle">
                          <Col>
                            <Avatar src={item.profilePictureURL}  size={45} icon={<UserOutlined />} />
                          </Col>
                          <Col>
                            <h4>{item.firstName} {item.lastName}</h4>
                            <p>{item.email}</p>
                          </Col>
                        </Row>
                      </Card>
                    </List.Item>
                  )}
                />
              </Panel>
              <Panel header="Removed Authors" key="removed">
                <List
                  grid={{ gutter: 16, column: 2 }}
                  dataSource={authors}
                  renderItem={item => (
                    <List.Item>
                      <Card hoverable size="small" bodyStyle={{ opacity: 0.5 }}>
                        <Row gutter={8} align="middle">
                          <Col>
                            <Avatar size={45} icon={<UserOutlined />} />
                          </Col>
                          <Col>
                            <h4>{item.name}</h4>
                            <p>{item.email}</p>
                          </Col>
                        </Row>
                      </Card>
                    </List.Item>
                  )}
                />
              </Panel>
            </Collapse>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
});