import React from "react";
import { Avatar, List, Row, Col } from "antd";
import moment from "moment";

import { TimerIcon } from "../../content/icons";
import { notificationTypeToTextMap } from "../../utils/notification";
import { NotificationItemAction } from "../../containers/notification";
import { Link } from "react-router-dom";


export const NotificationItem = (item: INotificationStore.Notification) => {
  return (
    <List.Item
      key={item._id}
      className={`notification-item ${item.read ? "read" : ""}`}
    >
      <Row>
        <Col flex={1}>
          {item.metas[0] && item.type == 2 ? (
            <Link to={`/redirect-to-first-chapter/${item.metas[0]}`}>
              <NotificationItemMeta {...item} />
            </Link>
          ) : <NotificationItemMeta {...item} />}

          <Row align="middle" className="notification-item-date">
            <TimerIcon size={14} color="#d6d6d6" />
            <Col flex={1}>
              <p>
                {moment(item.createdAt).fromNow()}
              </p>
            </Col>
          </Row>
        </Col>
        <Col>
          <NotificationItemAction item={item} />
        </Col>
      </Row>
    </List.Item>
  );
};

export const NotificationItemMeta = (item: INotificationStore.Notification) => (
  <List.Item.Meta
    className="notification-item-meta"
    avatar={item.img
      ? <Avatar src={item.img} />
      : <Avatar src={item.img} >{item.metas[0][0].toLocaleUpperCase()}</Avatar>
    }
    title={
      <div
        dangerouslySetInnerHTML={{
          __html: notificationTypeToTextMap[item.type].replace(/\{(\d+)\}/g, (g0, g1) => item.metas[parseInt(g1, 10)])
        }}
      >
      </div>
    }
  />
);