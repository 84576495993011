import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form, Row, Col, FormItemProps, Spin, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import useRootStore from "../../../store/useRootStore";

import ImageGallery from "../../ImageGallery";
import { ButtonSize, DeleteImageButton } from "../Buttons";
import { AtticusSelect, AtticusTextField } from "../Form";
import FileUpload from "./FileUpload";
import BookBrush from "./BookBrush";
import { UploadIcon, InfoIcon } from "../../../content/icons";

import Languages from "../../../utils/Languages.json";

import { debounce } from "lodash";
import { BOOKSHELF_DETAIL_UPDATE, wsSendShelfUpdateMessage } from "../../../utils/bookshelf-ws-helper";
import { ShelfWSMessageData } from "../../../types/common";

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

const fullWidthFormItemProps: FormItemProps = {
  colon: false,
  labelCol: { span: 24 },
  style: { marginBottom: 15 },
};

interface SelectItem {
  label: string;
  value: string;
}

const bookCoverToolTipText =
  "Cover images for Apple iBooks cannot exceed 1600x2400 pixels or a total of 4 million pixels";

const EditBookDetails = observer(() => {
  const { book } = useRootStore().bookStore;
  const { saveBook, getAuthors, getProjects, getVersionTags } =
    useRootStore().shelfStore;
  const { setBook } = useRootStore().bookStore;
  const { refreshCache } = useRootStore().pdfCacheStore;
  const { user } = useRootStore().authStore;
  const { socket } = useRootStore().bookSyncWebSocketStore;

  const [bookCoverImage, setBookCoverImage] = useState(book.coverImageUrl);
  const [projectFreeText, setProjectFreeText] = useState("");
  const [uploading, setUploading] = useState(false);
  const [chapterOptions, setChapterOptions] = useState<SelectItem[]>([]);
  const [authorOptions, setAuthorOptions] = useState<SelectItem[]>([]);
  const [projects, setProjects] = useState<string[]>([]);
  const [projectOptions, setProjectOptions] = useState<SelectItem[]>([]);
  const [versionOptions, setVersionOptions] = useState<SelectItem[]>([]);
  const [languageOptions, setLanguageOptions] = useState<SelectItem[]>([]);

  useEffect(() => {
    const authors = getAuthors();
    setAuthorOptions(
      authors.map((author) => ({ label: author, value: author }))
    );
    const versions = getVersionTags();
    setVersionOptions(versions.map((tag) => ({ label: tag, value: tag })));
    const projects = getProjects();
    setProjects(projects);
    setLanguageOptions(
      Languages.map((language) => ({
        label: language.name,
        value: language.code,
      }))
    );
  }, []);

  useEffect(() => {
    const projectItems: SelectItem[] = [];
    if (projectFreeText && projects.indexOf(projectFreeText) === -1) {
      projectItems.push({ label: projectFreeText, value: projectFreeText });
    } else {
      projects.map((project) => {
        projectItems.push({ label: project, value: project });
      });
    }
    setProjectOptions(projectItems);
  }, [projects, projectFreeText]);

  useEffect(() => {
    if (book) {
      const dropDownItems: SelectItem[] = [];
      book.frontMatter?.map((chapter) => {
        const fileName = chapter.type === "toc" ? "toc" : chapter._id;
        dropDownItems.push({
          value: fileName,
          label: chapter.title,
        });
      });

      book.chapters?.map((chapter) => {
        dropDownItems.push({
          value: chapter._id,
          label: chapter.title,
        });
      });

      setChapterOptions(dropDownItems);
    }
  }, [book]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (book && form) {
      form.setFieldsValue(book);
    }
  }, [book, form]);

  // Debounce the onFinish function with a delay
  const debouncedOnFinish = debounce((values) => {
    try {
      delete values["CustomTocTitle"];
      const vals: Partial<IBookStore.Book> = {
        ...values,
        coverImageUrl: bookCoverImage,
        custom_toc_title: form.getFieldValue("custom_toc_title"),
      };
      setBook({ ...book, ...vals });
      saveBook(book._id, vals).then(() => {
        if (user) {
          const data: ShelfWSMessageData = {
            userId: user._id,
            bookId: book._id,
          };
          wsSendShelfUpdateMessage(socket, BOOKSHELF_DETAIL_UPDATE, data);
        }
      });
      refreshCache(book._id, "book-properties-change");
    } catch (error) {
      console.error(error);
    }
  }, 500);

  const onFinish = (values) => {
    debouncedOnFinish(values);
  };

  const updateBookCoverUrl = (url: string) => {
    setBookCoverImage(url);
    form.submit();
  };

  const handleSelectedImageUrl = (link: string) => {
    updateBookCoverUrl(link);
  };

  const handleDeafultSearch = (input: string, option: any) => {
    if (option?.children) {
      return option.children.toLowerCase().search(input.trim()) !== -1;
    }
    return false;
  };

  return (
    <Form
      name="validate_other"
      form={form}
      onValuesChange={(_, vals) => onFinish(vals)}
      onFinish={(vals) => onFinish(vals)}
      initialValues={book}
      className="edit-book-ant-form"
    >
      <Row>
        <Col xs={24} sm={24} md={24} lg={14} xl={16}>
          <Form.Item
            name="title"
            label={<span className="edit-book-form-label">Book title</span>}
            rules={[{ required: true, message: "Title cannot be empty" }]}
            {...fullWidthFormItemProps}
          >
            <AtticusTextField
              placeholder="Book title"
              className="edit-book-input-field"
            />
          </Form.Item>
          <Form.Item
            name="subtitle"
            label={<span className="edit-book-form-label">Subtitle</span>}
            rules={[{ required: false }]}
            {...fullWidthFormItemProps}
          >
            <AtticusTextField
              placeholder="Book subtitle"
              className="edit-book-input-field"
            />
          </Form.Item>
          <Form.Item
            name="author"
            label={<span className="edit-book-form-label">Author</span>}
            {...fullWidthFormItemProps}
          >
            <AtticusSelect
              selectOptions={authorOptions}
              mode="tags"
              placeholder="Add or Select Author(s)"
            />
          </Form.Item>
          <Row>
            <Col xs={11}>
              <Form.Item
                name="project"
                label={<span className="edit-book-form-label">Project</span>}
                {...fullWidthFormItemProps}
              >
                <AtticusSelect
                  placeholder="Add or Select Project"
                  selectOptions={projectOptions}
                  showSearch
                  onSearch={(val) => {
                    setProjectFreeText(val);
                  }}
                  onSelect={(val) => {
                    form.setFieldsValue({ project: val });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="language"
                label={<span className="edit-book-form-label">Language</span>}
                {...fullWidthFormItemProps}
              >
                <AtticusSelect
                  selectOptions={languageOptions}
                  showSearch
                  defaultValue="en"
                  filterOption={handleDeafultSearch}
                  placeholder="Select a Language"
                />
              </Form.Item>
            </Col>
            <Col xs={2}></Col>
            <Col xs={11}>
              <Form.Item
                name="versionTags"
                label={<span className="edit-book-form-label">Version</span>}
                {...fullWidthFormItemProps}
              >
                <AtticusSelect
                  placeholder="Add or Select Version(s)"
                  selectOptions={versionOptions}
                  mode="tags"
                />
              </Form.Item>
              <Form.Item
                name="startPage"
                label={<span className="edit-book-form-label">Start page</span>}
                {...fullWidthFormItemProps}
              >
                <AtticusSelect
                  showSearch
                  filterOption={handleDeafultSearch}
                  placeholder="Select a Chapter"
                  selectOptions={chapterOptions}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={24} lg={10} xl={8}>
          <div className="edit-book-image-section-wrapper">
            <Form.Item
              name="coverImg"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={
                <Row align="middle" gutter={4}>
                  <Col>
                    <span className="edit-book-form-label">
                      Book cover
                    </span>
                  </Col>
                  <Col>
                    <Tooltip title={bookCoverToolTipText} placement="rightTop">
                      <Row align="middle">
                        <InfoIcon color="#A2A5A6" />
                      </Row>
                    </Tooltip>
                  </Col>
                </Row>
              }
              {...fullWidthFormItemProps}
            >
              {bookCoverImage ? (
                <div className="edit-book-image-dnd-container">
                  <div
                    className="edit-book-image-cover"
                    style={{ backgroundImage: `url(${bookCoverImage})` }}
                  >
                    <div className="edit-book-image-overlay">
                      <DeleteImageButton onClick={() => updateBookCoverUrl("")} />
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="edit-book-image-dnd-container">
                    <FileUpload
                      folder="cover-images"
                      fileType="image/png, image/jpg, image/jpeg"
                      onFileUpload={(url) => updateBookCoverUrl(url || "")}
                      onFileUploadStart={() => setUploading(true)}
                      onFileUploadEnd={() => setUploading(false)}
                      styleOverrides={{
                        backgroundColor: "#FFFFFF",
                        borderRadius: 4,
                        borderWidth: 2,
                        borderStyle: "dashed",
                        borderColor: "#ADC1E3",
                      }}
                    >
                      <div>
                        {uploading ? (
                          <Spin
                            indicator={
                              <LoadingOutlined className="edit-book-spinner" />
                            }
                          />
                        ) : (
                          <>
                            <span>
                              <UploadIcon color="#3363B9" />
                            </span>
                            <p>
                              <span className="edit-book-image-dnd-text-highlight">
                                Click to upload image
                              </span>{" "}
                              or drag and drop
                            </p>
                          </>
                        )}
                      </div>
                    </FileUpload>
                  </div>
                  <div className="edit-book-image-button-container">
                    <ImageGallery
                      handleImageUrl={handleSelectedImageUrl}
                      renderButtonProps={{
                        type: "at-primary",
                        backgroundColor: "green",
                        block: true
                      }}
                    />
                    <BookBrush
                      type="at-primary"
                      className="book-brush-button"
                      fullWidth
                    >
                      Create with book brush
                    </BookBrush>
                  </div>
                </>
              )}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
});

export default EditBookDetails;
