import React from "react";

import { Wrapper, LHS, Content } from "../../components/Editor/partials";

import List from "../../components/Masterpage/ChapterTemplateList";
import { EditContainer } from "../../containers/masterpage";

export const Masterpage: React.FC = () => {
  return (
    <Wrapper>
      <LHS>
         <List />
      </LHS>
      <Content>
        <EditContainer />
      </Content>
    </Wrapper>
  );
};