import { appStore } from "../../store";
import { syncBook, syncBooks, syncInitialBooks } from "./bookSync";
import { syncBookChapterBodies, syncChapterBodies } from "./chapterBodySync";
import { syncThemes } from "./themeSync";

export const syncData = async():Promise<void> => {
   if(appStore.initialBooksLoaded && appStore.initialChaptersLoaded) {
    await syncBooks();
  } else {
    await syncInitialBooks();
  }

  if(appStore.initialChaptersLoaded) {
    await syncChapterBodies();
  }

  await syncThemes();
};

/** sync data for a single book */
export const syncBookBaseData = async(bookId: string): Promise<void> => {
  await syncBook(bookId);
  await syncBookChapterBodies(bookId);
};