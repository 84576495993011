import { makeAutoObservable, observable, toJS } from "mobx";
import { AtticusClient } from "../api/atticus.api";
import moment from "moment";
import { message } from "antd";
import { find } from "lodash";

export class NotificationStore {
  notifications: INotificationStore.Notification[] = [];
  index = 0;
  hasMore = true;
  count = 5;
  overlay = false;
  totalUnread: number | null = null

  constructor() {
    makeAutoObservable(this);
  }

  setNotifications = (notifications: INotificationStore.Notification[]): void => {
    this.notifications = notifications;
  }

  setIndex = (index: number): void => {
    this.index = index;
  }

  setHasMore = (hasMore: boolean): void => {
    this.hasMore = hasMore;
  }

  setUnreadCount = (count: number) => {
    this.totalUnread = count;
  }

  decreaseUnreadCount = () => {
    if(this.totalUnread){
      this.setUnreadCount(this.totalUnread - 1);
    }
  }
  
  setOverlay = (overlay: boolean) => {
    this.overlay = overlay;
  }

  loadUnreadCount = async () => {
    const { count } = await this.getUnreadNotificationsCount();

    this.setUnreadCount(count);
  }

  appendNotification = (notification: INotificationStore.Notification) => {
    if (notification._id) {
      const notifications = this.notifications;
      notifications.unshift(notification);
      this.setNotifications(notifications);
    }
  }

  unsetNotification = (id: string) => {
    if (id) {
      this.setNotifications(
        this.notifications.filter(notification => id !== notification._id)
      );
    }
  };

  resetNotifications = () => {
    this.setNotifications([]);
    this.setHasMore(false);
    this.setIndex(0);
    this.setUnreadCount(0);
  };

  updateNotification = (id: string, params: Partial<INotificationStore.Notification>) => {
    const notifications = this.notifications;
    const index = notifications.findIndex(d => d._id === id);

    Object.keys(params).forEach((key) => notifications[index][key] = params[key]);

    this.setNotifications(notifications.slice());
  }

  loadNotifications = async () => {
    const { notifications } = await this.getNotifications(this.index, this.count);

    await this.loadUnreadCount();

    if (notifications.length > 0) {
      this.setNotifications([...this.notifications, ...notifications].sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt))));
      this.setIndex(this.index + 1);
    }

    if (notifications.length < this.count) {
      this.setHasMore(false);
    }
  };

  clearNotification = async (id: string) => {
    try {
      await this.dismissNotification(id);

      const notification = find(this.notifications, { _id: id});

      this.unsetNotification(id);
      
      if(notification && !notification.read){
        this.decreaseUnreadCount();
      }
    } catch (e) {
      console.log(e);
    }
  };

  clearAllNotification = async () => {
    try {
      await this.dismissAllNotifications();
      this.resetNotifications();
    } catch (e) {
      message.error("Error in dismissing all notifications");
    }
  };


  markNotficationRead = async (id: string) => {
    try {
      const notification = this.notifications.find(notification => notification._id === id);
      if(notification && !notification.read){
        await this.makeNotficationRead(id);
        this.updateNotification(id, { read: true });
        this.decreaseUnreadCount();
      }
    } catch(e) {
      message.error("Error in making notification read");
    }
  }

  dismissAllNotifications = async () => await AtticusClient.DismissAllNotifications();

  dismissNotification = async (id: string) => await AtticusClient.DismissNotification(id);

  makeNotficationRead = async (id: string) => await AtticusClient.MakeNotificationRead(id);

  getUnreadNotificationsCount = async () => await AtticusClient.GetUnreadNotificationsCount();

  getNotification = async (id: string) => await AtticusClient.GetNotification(id);

  getNotifications = async (index: number, count: number) => await AtticusClient.GetNotifications(index, count);
}

export default new NotificationStore();
