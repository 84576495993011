import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useEventPlateId, usePlateEditorState } from "@udecode/plate";

import useRootStore from "../../../../../store/useRootStore";

import { TCommentText } from "../types";
import { getCommentKeyId, isCommentKey, unsetCommentNodesById } from "../utils";

export const CommentLeaf = observer(({ className, ...props }: any) => {
  const { id, children, leaf } = props;

  const { activeCommentId, comments, setActiveCommentId, getCommentById } =
    useRootStore().commentsStore;
  const { setActiveTrackChangeId} = useRootStore().trackChangesStore;
  const { chapterTemplateView } = useRootStore().appStore;

  const [commentIds, setCommentIds] = useState<string[]>([]);
  const [commentCount, setCommentCount] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [commentId, setcommentId] = useState<string>("");

  const getCommentLeafState = ({ leaf }: { leaf: TCommentText }) => {
    const editor = usePlateEditorState(useEventPlateId());

    useEffect(() => {
      const ids: string[] = [];
      let count = 0;
      let _isActive = false;

      if(chapterTemplateView) return;

      Object.keys(leaf).forEach((key) => {
        if (!isCommentKey(key)) return;

        const id = getCommentKeyId(key);
        setcommentId(id);

        const comment = getCommentById(id);

        if(!comment && activeCommentId !== id) return;

        if (comment?.isResolved) return;

        if (id === activeCommentId) {
          _isActive = true;
          setIsActive(true);
        }

        ids.push(getCommentKeyId(key));
        count++;
      });

      if (!_isActive && isActive) {
        setIsActive(false);

        // Remove comment nodes for unsubmitted comments
        ids.forEach((id) => {
          if (!getCommentById(id)) {
            unsetCommentNodesById(editor, { id });
          }
        });
      }
      setCommentCount(count);
      setCommentIds(ids);
    }, [activeCommentId, comments, isActive]);

    const lastCommentId =
      commentIds.length > 0 ? commentIds[commentIds.length - 1] : undefined;

    return {
      commentCount,
      isActive,
      lastCommentId,
      setActiveCommentId,
    };
  };

  const getCommentLeafRootProps = ({ setActiveCommentId, lastCommentId }) => {
    return {
      props: {
        onClick: React.useCallback(
          (e: React.MouseEvent) => {
            e.stopPropagation();
            setActiveTrackChangeId(null);
            setActiveCommentId(lastCommentId);
          },
          [lastCommentId, setActiveCommentId]
        ),
      },
    };
  };

  const state = getCommentLeafState({ leaf });
  const { props: rootProps } = getCommentLeafRootProps(state);

  if (!state.commentCount) return <>{children}</>;

  let aboveChildren = <>{children}</>;

  if (!state.isActive) {
    for (let i = 1; i < state.commentCount; i++) {
      aboveChildren = <span className={`${className}`}>{aboveChildren}</span>;
    }
  }

  return (
    <span
      className={`${state.isActive ? "active-comment " : ""}${className}`}
      id={"comment_" + commentId}
      {...rootProps}
    >
      {aboveChildren}
    </span>
  );
});
