import { getNodeEntries, PlateEditor, TNodeEntry, Value } from "@udecode/plate";
import { TTrackChangeNodeEntry } from "../types";
import { isTrackChangeText } from "../utils/isTrackChangeText";

export const getTrackChangeNodeEntries = <V extends Value>(
  editor: PlateEditor<V>
): TNodeEntry<TTrackChangeNodeEntry>[] => {  
  return [
    ...getNodeEntries<TTrackChangeNodeEntry>(editor, {
      at: [],
      match: (n) => isTrackChangeText(n),
    }),
  ];
};
