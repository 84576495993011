import React, { FunctionComponent, useState, useEffect } from "react";
import { observer } from "mobx-react";
import * as querystring from "query-string";

// 3rd party Components
import { Checkbox, Form, message } from "antd";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";

// api
import { AtticusClient } from "../../api/atticus.api";

// stores
import useRootStore from "../../store/useRootStore";

// components
import Loading from "../../components/Shared/Loading";
import { AtticusPasswordField, AtticusTextField } from "../../components/Shared/Form";
import { Button, ButtonType } from "../../components/Shared/Buttons";

// types

interface InviteDetails {
  firstName: string;
  lastName: string;
}

interface SignUpForm {
  inviteId: string;
  inviteCode: string;
  firstName: string;
  lastName: string;
  password: string;
  confirmPassword: string;
}

interface Props {
  modal: boolean;
}

export const SignUp: FunctionComponent<Props> = observer(({ modal }: Props) => {
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inviteDetails, setInviteDetails] = useState<InviteDetails | null>(
    null
  );
  const { updateToken, updateNewBookToken, updateAbilitiesPublicKey } = useRootStore().authStore;

  const location = useLocation<Location>();
  const history = useHistory();

  const queryParams = querystring.parse(location.search);

  const { inviteId, inviteCode } = queryParams;

  const [agree, setAgree] = useState(false);

  const onFinish = async (values) => {
    const { confirmPassword, ...restValues } = values;
    const dataToSend = {
      ...restValues,
      inviteId: queryParams.inviteId,
      inviteCode: queryParams.inviteCode,
    };
    try {
      setIsSubmitting(true);
      const resp = await AtticusClient.SignUp(dataToSend);
      updateToken(resp.token);
      await updateNewBookToken(resp.newBookToken);
      await updateAbilitiesPublicKey(resp.abilitiesPublicKey);
    } catch (e: any) {
      if (e.response.status === 406) {
        const errorResponse = e.response.data;
        if (
          errorResponse &&
          errorResponse.errors &&
          errorResponse.errors.email
        ) {
          const errorMessage = errorResponse.errors.email.message;
          message.error(`Oops... Something went wrong: ${errorMessage}`, 4);
        } else {
          message.error("Oops... Something went wrong with your invitation.",4);
        }
      } else {
        message.error("Oops... Something went wrong");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  const fetchInvite = async (inviteId: string, inviteCode: string) => {
    try {
      const resp = await AtticusClient.FetchInvite(inviteId, inviteCode);
      setInviteDetails(resp);
      setLoading(false);
    } catch (e: any) {
      history.push("/");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInvite(inviteId, inviteCode);
  }, [inviteId, inviteCode]);

  if (loading) return <Loading />;

  if (!inviteDetails) return <Redirect to='/' />;

  return (
    <Form
      initialValues={
        {
          firstName: inviteDetails.firstName,
          lastName: inviteDetails.lastName,
          password: "",
        } as SignUpForm
      }
      onFinish={onFinish}
      layout={"vertical"}
      colon={false}
    >
      <div className='auth-card-sign-up'>
        <div className='text-center inner-s'>
          <h1>Create your Atticus account</h1>
        </div>
        <Form.Item
          className='at-form-item'
          label='First Name'
          name='firstName'
          rules={[{ required: true, message: "Please enter your first name" }]}
          required
        >
          <AtticusTextField type='text' placeholder='First Name' />
        </Form.Item>

        <Form.Item
          className='at-form-item'
          label='Last Name'
          name='lastName'
          rules={[{ required: true, message: "Please enter your last name" }]}
          required
        >
          <AtticusTextField type='text' placeholder='Last Name' />
        </Form.Item>

        <Form.Item
          className='at-form-item'
          label='Password'
          name='password'
          rules={[
            { required: true, message: "Please enter your password" },
            {
              min: 6,
              message: "Your password should have at least 6 characters",
            },
          ]}
          required
        >
          <AtticusPasswordField placeholder='Password' />
        </Form.Item>
        <Form.Item
          className='at-form-item'
          label='Confirm Password'
          name='confirmPassword'
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match")
                );
              },
            }),
          ]}
          required
        >
          <AtticusPasswordField placeholder='Confirm password' />
        </Form.Item>
        <Form.Item>
          <div className='inner-s'>
            <Checkbox id='agree' onChange={checkboxHandler} checked={agree}>
              I agree to the{" "}
              <b>
                <a
                  target='_blank'
                  href='https://www.atticus.io/terms-conditions'
                  rel='noreferrer'
                >
                  Terms and Conditions
                </a>
              </b>
            </Checkbox>
          </div>
        </Form.Item>
        <Button
          disabled={!agree}
          type={ButtonType.PRIMARY}
          htmlType='submit'
          loading={isSubmitting}
          block
        >
          Create Account
        </Button>
        <div className='inner-s'>
          <p>
            Already have an account?
            {modal ? (
              <Link to={{ hash: "#signin" }}> Sign In</Link>
            ) : (
              <Link to='/auth/sign-in'> Sign In</Link>
            )}
          </p>
        </div>
      </div>
    </Form>
  );
});
