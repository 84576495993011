// import { Button } from "antd";
import React, { useEffect, useState } from "react";
// import { SettingsIcon } from "../../../../../utils/icon";
import { CalloutBoxModal } from "./CalloutBoxModal";
import {
  PlateRenderElementProps,
  TElement,
  findNodePath,
  setNodes,
  usePlateEditorState,
} from "@udecode/plate";

import { observer } from "mobx-react";
import { CalloutModalStore } from "../../../../../store/CalloutModal";
import { ColorPickerValue } from "antd-colorpicker";
import { CalloutBackground, CalloutBorder } from "../../../../../types/slate";
import { SettingsIcon } from "../../../../../content/icons";
import { Button } from "../../../../Shared/Buttons";
import { can } from "../../../../casl/helpers";

export const CalloutBoxComponent: React.FC<PlateRenderElementProps> = observer(
  (props) => {
    const callOutStore = new CalloutModalStore();
    const { children, element } = props;
    const editor = usePlateEditorState();
    const backgroundProp = element.background as CalloutBackground;
    const borderProp = element.border as CalloutBorder;
    const [rgbBackgroundColor, setRgbBackgroundColor] =
      useState<ColorPickerValue>();
    const [rgbBorderColor, setRgbBorderColor] = useState<ColorPickerValue>();

    // convert hex to rgba
    const convertHexToRGBA = (hex: string, alpha: number) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    useEffect(() => {
      setRgbBackgroundColor(
        convertHexToRGBA(backgroundProp.fillColor, backgroundProp.fillOpacity)
      );
      setRgbBorderColor(
        convertHexToRGBA(borderProp.borderColor, borderProp.borderOpacity)
      );
    }, [
      backgroundProp.fillColor,
      backgroundProp.fillOpacity,
      borderProp.borderColor,
      borderProp.borderOpacity,
    ]);

    // dynamic styles
    const calloutboxStyles = {
      backgroundColor: backgroundProp.fill ? rgbBackgroundColor : "transparent",
      border: borderProp.border
        ? `${borderProp.borderWidth}px ${borderProp.borderStyle} ${rgbBorderColor}`
        : "none",
      borderRadius: borderProp.border ? `${borderProp.borderRadius}px` : "none",
      padding: borderProp.border ? "1em 3em 0em 1em" : "1em 3em 0.1em 1em",
    };

    const [modal, toggleModal] = useState<boolean>(false);

    const onUpdateCalloutBox = (updateCalloutBox: TElement) => {
      const path = findNodePath(editor, element);
      setNodes(editor, updateCalloutBox, { at: path });
    };

    const handleSubmit = async () => {
      onUpdateCalloutBox({
        ...element,
        background: callOutStore.background,
        border: callOutStore.border,
      });
      toggleModal(false);
    };

    const properties = {
      background: backgroundProp,
      border: borderProp,
    };

    // set initial values
    useEffect(() => {
      callOutStore.setValues(properties);
    }, [element, modal]);

    return (
      <div className="editor-block-cont">
        <div className="editor-callout-box" style={calloutboxStyles}>
          {children}
        </div>
        <div className='callout-block-action' contentEditable={false}>
          {can("view", "plugin-settings") &&
            <Button
              type="at-ghost"
              size="small"
              className='callout-block-action-btn'
              onClick={() => toggleModal(true)}
            >
              <SettingsIcon size={16} />
            </Button>}      
        </div>
        {modal && (
          <CalloutBoxModal
            calloutStore={callOutStore}
            onClose={() => toggleModal(false)}
            onSubmit={handleSubmit}
          />
        )}
      </div>
    );
  }
);
