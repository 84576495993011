import { PdfExpandedBook } from "../components/Previewer/print/types";
import { ChapterType } from "../types/chapter";
import { isChapterTopLevel, getChapterAncestorVolume } from "./chapter";

/**
 *
 * @param currentChapter
 * @param book
 * @returns whether the given currentChapter should be numbered or not
 */
export const isNumberedChapter = (
  currentChapter: IChapterStore.ChapterMeta,
  book: IBookStore.ExpandedBook | PdfExpandedBook
): boolean => {
  const numberedChapterTypes: (ChapterType | undefined)[] = [
    "chapter",
    "uncategorized",
    "custom",
    undefined,
  ];
  if (
    numberedChapterTypes.includes(currentChapter.type) &&
    (currentChapter.numbered === undefined || currentChapter.numbered === true) && 
    !book.frontMatterIds?.includes(currentChapter._id)
  ) {
    // If the chapter is not in the frontmatter section of a volume, it's going to be numbered
    const ancestorVolume = getChapterAncestorVolume(
      currentChapter._id,
      book.chapters
    );

    if (!ancestorVolume) {
      return true;
    }

    if (
      !ancestorVolume.volume!.frontMatterIds.includes(currentChapter._id)
    ) {
      return true;
    }
  }

  return false;
};

/**
 *
 * @param currentChapter
 * @param book
 * @returns the chapter number of the given currentChapter if it should be numbered, otherwise returns undefined
 */
export const getChapterNumber = (
  currentChapter: IChapterStore.ChapterMeta,
  book: IBookStore.ExpandedBook | PdfExpandedBook
): number | undefined => {
  if (
    isNumberedChapter(currentChapter, book) &&
    book.chapterIds.indexOf(currentChapter._id) > -1
  ) {
    let curChapterNumber = 1;
    let topLevelChapterNumber = 1;
    //current state is 0 if the current chapter is in top level, otherwise 1
    let currentState = 0;
    for (const chap of book.chapters) {
      //continue the top level numbering when moving from a volume to top level
      if (
        currentState === 1 &&
        isChapterTopLevel(chap.parentChapterId, book._id)
      ) {
        curChapterNumber = topLevelChapterNumber;
        currentState = 0;
      }
      if (chap._id === currentChapter._id) break;
      if (isNumberedChapter(chap, book)) curChapterNumber += 1;

      // restarting the numbering inside a volume
      if (chap.type === "volume") {
        topLevelChapterNumber = curChapterNumber;
        currentState = 1;
        curChapterNumber = 1;
      }
    }
    return curChapterNumber;
  } else return undefined;
};
