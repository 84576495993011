import React from "react";
import { Divider } from "antd";
import { PlateEditor, Value} from "@udecode/plate";

import TextSettings from "../../Writing/TextSettings";
import EditorRightMenuHeader from "./EditorRightMenuHeader";
import FindReplace from "../../Search/FindandReplace";
import Goals from "../../Goals/Goals";
import Previewer from "../../Previewer/Previewer";
import SmartQuotes from "../../SmartQuotes/SmartQuotes";
import { ScrollContainer } from "../../Shared/Layouts";
import { CollaborationRHSMenu } from "../../CollaborationRHSMenu/CollaborationRHSMenu";
import { TComment } from "../../Plate/plugins/comments";
import { TTrackChange, TrackChangeReply } from "../../Plate/plugins/track-changes/types";

interface EditorRightMenuProps {
  view: IAppStore.SettingView;
  resolveComment?: (commentId: string) => void;
  submitReplyComment?: (replyValue: string) => void;
  deleteComment?: (comment: TComment) => void;
  updateComment?: (comment: TComment) => void;
  approveTrackChange?: (trackChange: TTrackChange[]) => void;
  declineTrackChange?: (trackChange: TTrackChange[]) => void;
  submitReplyTrackChange?: (replyValue: Value, trackChange: TTrackChange) => void;
  updateTrackChangeReply?:(trackChangeNode: TTrackChange, updatedReply: string, reply: TrackChangeReply)=> void;
  deleteReply?:(trackChange: TTrackChange, reply:TrackChangeReply)=> void
  doReplace?: (replace: IBookStore.ReplaceParams) => void;
  editor?: PlateEditor<any>;
}

export const EditorRightMenu: React.FC<EditorRightMenuProps> = (props) => {
  const { view, doReplace, editor, resolveComment, submitReplyComment, deleteComment, updateComment, approveTrackChange, declineTrackChange, submitReplyTrackChange, updateTrackChangeReply, deleteReply } = props;

  let content = <></>;
  let title = "";
  let tooltipText = "";

  switch (view) {
    case "setting":
      content = <TextSettings />;
      tooltipText = "This will allow you to configure how the text appears in your editor to make it easier for you to write. This will not impact how the text looks in the formatted book.";
      title = "Editor settings";
      break;
    case "goals":
      content = <Goals/>;
      tooltipText = "This will help you set up writing goals that will help motivate you on your writing journey.";
      title = "Goals";
      break;
    case "collaboration":
      title = "Collaboration";
      content= <CollaborationRHSMenu 
        resolveComment={resolveComment} 
        submitReplyComment={submitReplyComment}
        deleteComment={deleteComment}
        updateComment={updateComment}
        approveTrackChange={approveTrackChange}
        declineTrackChange={declineTrackChange}
        submitReplyTrackChange={submitReplyTrackChange}
        updateTrackChangeReply={updateTrackChangeReply}
        deleteReply={deleteReply}
        />;
      break;
    // case "history":
    //   title = "Versions";
    //   break;
    case "search":
      if(doReplace) {
        content = <FindReplace doReplace={doReplace} />;
        tooltipText = "This will help you to easily find a word in your chapter or book and replace it with another word.";
        title = "Find and replace";
      }
      break;
    // case "notes":
    //   title = "Notes";
    //   break;
    case "previewer":
      content = <Previewer isSidebar={true} />;
      title = "Previewer";
      tooltipText= "This will help you write while seeing, in real time, how your chapter content will appear on the print/epub version of the book once formatting is applied.";
      break;
    default:
      if(editor) {
        content = <SmartQuotes editor={editor} />;
        title = "Smart quotes";
        tooltipText = "This will help you to convert all quotation marks in your chapter to smart quotes.";
      }
      break;
  }

  return (
    <div className={view === "collaboration"? "setting-area collaboration": "setting-area"}>
      <div className="setting-area-col">
        <EditorRightMenuHeader title={title} tooltipText={tooltipText} />
        <div className="heading-divider">
          <Divider />
        </div>
        {view === "collaboration" ? (
          <div>{content}</div>
        ) : (
          <ScrollContainer>{content}</ScrollContainer>
        )}
      </div>
    </div>
  );
};